package io.eqoty.shared.datalayer.objects

import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.cosmwasm.std.types.Coin
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable


@Serializable
data class RoyaltyNft(
    @Contextual val id: BigInteger,
    @Contextual val royaltyPercent: BigInteger,
    val isOwner: Boolean,
    @Contextual val claimableBalances: List<Coin>,
    val releaseId: UInt,
)
