package io.eqoty.composables.views

import androidx.compose.runtime.*
import app.softwork.bootstrapcompose.*
import app.softwork.bootstrapcompose.Color
import io.eqoty.composables.styles.overflowTxtToEllipsisAttrs
import io.eqoty.composables.styles.overflowTxtToEllipsisStyle
import io.eqoty.shared.viewmodel.screens.generic.TrackRowData
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.DOMScope
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLUListElement


private val column0DisplayRules = arrayOf(
    "col-8",
    "col-sm-8",
    "col-md-5",
    "col-lg-5",
    "col-xl-5",
)

// hide on screens smaller than large
private val column1DisplayRules = arrayOf(
    "d-none", "d-lg-block",
    "col-lg-3",
    "col-xl-3",
)

private val column2DisplayRules = arrayOf(
    "d-none", "d-md-block",
    "col-2",
)

private val column3DisplayRules = arrayOf(
    "col-4",
    "col-sm-4",
    "col-md-5",
    "col-lg-2",
    "col-xl-2",
)

@Composable
fun TrackListRow(
    item: TrackRowData, onClickOrganization: (TrackRowData) -> Unit, endContent: (@Composable () -> Unit)? = null
) {
    Row(attrs = {
        style {
            height(100.percent)
            width(100.percent)
        }
        classes("g-0", "p-1")
    }) {
        TrackListItemContent(item, onClickOrganization, endContent)
    }
}

@Composable
fun DOMScope<HTMLUListElement>.TrackListGroupItem(
    item: TrackRowData,
    onClick: (UInt) -> Unit,
    onClickOrganization: (TrackRowData) -> Unit,
    endContent: (@Composable () -> Unit)? = null
) {
    ButtonListItem(attrs = {
        classes("list-group-item-action", "p-2")
        style {
//            display(DisplayStyle.Flex)
            height(90.px)
//            alignItems(AlignItems.Center)
        }
        onClick {
            onClick(item.releaseId)
        }
    }) {
        TrackListRow(item, onClickOrganization, endContent)
    }
}

@Composable
fun DOMScope<HTMLUListElement>.TrackListGroupHeader() {
    ListItem {
        TrackListHeaderRow()
    }
}

@Composable
fun TrackListHeaderRow() {
    Row(attrs = {
        style {
            height(40.px)
        }
        classes("p-1")

    }, styling = {
        Borders {
            Bottom {
                this.color = Color.Dark
            }
        }
    }) {
        Column(attrs = {
            style {
                height(100.percent)
            }
            classes(*column0DisplayRules)
        }, styling = { Text { this.color = Color.Muted } }) {
            Div(attrs = {
                style {
                    height(100.percent)
                    alignItems(AlignItems.Start)
                    justifyContent(JustifyContent.Center)
                    display(DisplayStyle.Flex)
                    flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                    minWidth("0") // needed to enable text-overflow:ellipsis inside a flex
                }
            }) {
                Div(attrs = overflowTxtToEllipsisAttrs) { Text("Title") }
            }
        }

        Column(attrs = {
            style {
                height(100.percent)
            }
            classes(*column1DisplayRules)
        }, styling = { Text { this.color = Color.Muted } }) {
            Div(attrs = {
                style {
                    height(100.percent)
                    alignItems(AlignItems.Start)
                    justifyContent(JustifyContent.Center)
                    display(DisplayStyle.Flex)
                    flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                    minWidth("0") // needed to enable text-overflow:ellipsis inside a flex
                }
            }) {
                Div(attrs = overflowTxtToEllipsisAttrs) { Text("Album") }
            }
        }
        Column(attrs = {
            style {
                height(100.percent)
            }
            classes(*column2DisplayRules)
        }, styling = { Text { this.color = Color.Muted } }) {
            Div(attrs = {
                style {
                    height(100.percent)
                    alignItems(AlignItems.Start)
                    justifyContent(JustifyContent.Center)
                    display(DisplayStyle.Flex)
                    flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                    minWidth("0") // needed to enable text-overflow:ellipsis inside a flex
                }
            }) {
                Div(attrs = { overflowTxtToEllipsisAttrs }) { Text("Duration") }
            }
        }
    }
}

@Composable
fun TrackListItemContent(
    item: TrackRowData, onClickOrganization: (TrackRowData) -> Unit, endContent: (@Composable () -> Unit)? = null
) {
    Column(attrs = {
        style {
            height(100.percent)
        }
        classes(*column0DisplayRules)
    }) {
        Div(attrs = {
            style {
                height(100.percent)
                display(DisplayStyle.Flex)
                flexFlow(FlexDirection.Row, FlexWrap.Nowrap)
                minWidth("0") // needed to enable text-overflow:ellipsis inside a flex
            }
        }) {
            Img(item.trackArtUrl, attrs = {
                style {
                    height(100.percent)
                }
                attr("crossorigin", "")
            })

            Div(attrs = {
                style {
                    justifyContent(JustifyContent.Center)
                    marginLeft(10.px)
                    display(DisplayStyle.Flex)
                    flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                    minWidth("0") // needed to enable text-overflow:ellipsis inside a flex
                }
            }) {
                Div(attrs = overflowTxtToEllipsisAttrs) { Text(value = item.name) }
                var cursorHoveredOverOrganization by remember { mutableStateOf(false) }
                Div(attrs = {
                    onClick { event ->
                        event.stopPropagation()
                        onClickOrganization(item)
                    }
                    onMouseOver { event ->
                        cursorHoveredOverOrganization = true
                    }
                    onMouseOut { event ->
                        cursorHoveredOverOrganization = false
                    }
                    style {
                        overflowTxtToEllipsisStyle()
                    }
                    if (cursorHoveredOverOrganization) {
                        classes("text-decoration-underline")
                    }
                }) { Text(value = item.artistsDisplay) }
            }

        }
    }
    Column(attrs = {
        style {
            height(100.percent)
        }
        classes(*column1DisplayRules)
    }) {
        Div(attrs = {
            style {
                height(100.percent)
                alignItems(AlignItems.Start)
                justifyContent(JustifyContent.Center)
                display(DisplayStyle.Flex)
                flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                minWidth("0") // needed to enable text-overflow:ellipsis inside a flex
            }
        }) {
            Div(attrs = overflowTxtToEllipsisAttrs) { Text(value = item.album) }
        }
    }
    Column(attrs = {
        // hide on screens smaller than medium
        classes(*column2DisplayRules)
    }) {
        Div(attrs = {
            style {
                height(100.percent)
                alignItems(AlignItems.Start)
                justifyContent(JustifyContent.Center)
                display(DisplayStyle.Flex)
                flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                minWidth("0") // needed to enable text-overflow:ellipsis inside a flex
            }
        }) {
            Div(attrs = overflowTxtToEllipsisAttrs) { Text(value = item.privateAudioDurationFormatted) }
        }
    }
    endContent?.let { ec ->
        Column(attrs = {
            classes(
                "d-flex", "align-items-center", "justify-content-end", *column3DisplayRules
            )
        }) {
            ec()
        }
    }
}