package io.eqoty.shared.datalayer.sources.web3

import kotlinx.coroutines.await
import web3.Web3


class JsWeb3Provider : ExternalWeb3Provider,
    EthWeb3Provider {

    var web3: Web3? = null
    override val activeAccountIndex: Int? get() = activeAccountIndexBacking
    private var activeAccountIndexBacking: Int? = null
    override suspend fun setActiveAccountIndex(accountIndex: Int?) {
        activeAccountIndexBacking = accountIndex
    }

    override suspend fun requestAccounts(): List<String> =
        web3?.eth?.requestAccounts()?.await()?.toList() ?: emptyList()

    override suspend fun getAccounts(): List<String> =
        web3?.eth?.getAccounts()?.await()?.toList() ?: emptyList()

    override suspend fun getId(): String? =
        web3?.eth?.net?.getId()?.await()?.toString()

    override suspend fun disconnect() {
        web3?.eth?.currentProvider?.asDynamic().disconnect()
    }

}
