package io.eqoty.shared.datalayer.sources.webservices

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.decodeFromByteArray
import kotlinx.serialization.json.Json
import kotlinx.serialization.protobuf.ProtoBuf

const val ipfsBaseUrl = "https://eqoty.infura-ipfs.io/ipfs/"
const val filebaseIpfsBaseUrl = "https://ipfs.filebase.io/ipfs/"
const val ipfsProtocol = "ipfs://"

class ApiClient {

    @OptIn(ExperimentalSerializationApi::class)
    private val json = Json {
        ignoreUnknownKeys = true
        explicitNulls = false
    }

    val client = HttpClient {

        install(ContentNegotiation) {
            json(json)
        }

        /* Ktor specific logging: reenable if needed to debug requests
        install(Logging) {
            logger = Logger.DEFAULT
            level = LogLevel.INFO
        }
        */
    }

    suspend inline fun <reified T : Any> getResponse(baseUrl: String, endpoint: String): T {
        val url = baseUrl + endpoint
        // please notice, Ktor Client is switching to a background thread under the hood
        // so the http call doesn't happen on the main thread, even if the coroutine has been launched on Dispatchers.Main
        val resp: T = client.get(url).body()
        return resp
    }

    suspend inline fun <reified T : Any> getProtobufResponse(baseUrl: String, endpoint: String): T {
        val url = baseUrl + endpoint
        // please notice, Ktor Client is switching to a background thread under the hood
        // so the http call doesn't happen on the main thread, even if the coroutine has been launched on Dispatchers.Main
        val bytes = client.get(url).readBytes()
        return ProtoBuf.decodeFromByteArray(bytes)
    }

    suspend fun filebaseS3Put(fileName: String, bodyBytes: ByteArray): HttpResponse {
        return client.s3Request(
            "PUT",
            fileName,
            bodyBytes,
            contentType = ContentType.Application.OctetStream,
            "eqoty",
            domain = "filebase.com",
            awsAccess = "14459C85C045478730E7",
            awsSecret = "hH2hfEzPQJFF8seIJxsmYJufmia5SgChOd8zTZ9f"
        )
    }

}
