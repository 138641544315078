package io.eqoty.shared.datalayer.functions

import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.OrganizationProfileMetadata
import io.eqoty.shared.datalayer.objects.ReleaseMetaData
import io.eqoty.shared.datalayer.objects.RemoteAudioResource
import io.eqoty.shared.datalayer.sources.webservices.ipfsProtocol
import io.eqoty.shared.viewmodel.screens.mintsong.MintTrackFormInput
import io.eqoty.shared.viewmodel.screens.organizationprofile.EditOrganizationProfileFormInput
import kotlinx.serialization.encodeToByteArray
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.protobuf.ProtoBuf

suspend fun Repository.createAndAdd721MetadataToIpfs(
    formInput: MintTrackFormInput,
    privateAudioFormats: List<RemoteAudioResource>,
    publicAudioFormats: List<RemoteAudioResource>,
    imageUrl: String,
): String = withRepoContext {
    val metaData = Json.encodeToString(
        ReleaseMetaData(
            name = formInput.name,
            description = formInput.aboutTrack,
            image = imageUrl,
            // pick the best quality ogg file for animationUrl
            animationUrl = publicAudioFormats.filter { it.container == "OGG" }.maxByOrNull { it.size }!!.url,
            properties = formInput.toTrackProperties(privateAudioFormats, publicAudioFormats)
        )
    )
    val addedMetaDataHash = ipfsClient.addText(metaData).hash
    return@withRepoContext ipfsProtocol + addedMetaDataHash
}

suspend fun Repository.createAndAddOrganizationProfileMetadataToIpfs(
    organizationId: ULong,
    organizationName: String,
    bannerImageUrl: String?,
    validatedForm: EditOrganizationProfileFormInput,
): String = withRepoContext {
    val organizationProfileMetadataBytes = ProtoBuf.encodeToByteArray(
        OrganizationProfileMetadata(
            id = organizationId.toString(),
            name = organizationName,
            description = validatedForm.description,
            bannerImage = bannerImageUrl,
        )
    )
    val putResponse = webservices.filebaseS3Put(
        "organization/$organizationId/profile-metadata.binpb",
        organizationProfileMetadataBytes,
    )
    val addedMetaDataHash = putResponse.headers.entries().first { it.key == "x-amz-meta-cid" }.value.first()
    return@withRepoContext ipfsProtocol + addedMetaDataHash
}
