package io.eqoty.composables.screens.myroyalties

import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.Button
import app.softwork.bootstrapcompose.ButtonListItem
import app.softwork.bootstrapcompose.ListGroup
import app.softwork.bootstrapcompose.ListItem
import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.composables.views.TrackListRow
import io.eqoty.shared.viewmodel.screens.generic.TrackRowData
import io.eqoty.shared.viewmodel.screens.myroyalties.MyRoyaltiesListItem
import io.eqoty.shared.viewmodel.screens.myroyalties.MyRoyaltiesType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.DOMScope
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLUListElement

@Composable
fun DOMScope<HTMLUListElement>.MyRoyaltiesListRow(
    item: MyRoyaltiesListItem,
    listType: MyRoyaltiesType,
    onItemClick: (BigInteger) -> Unit,
    onClickOrganization: (TrackRowData) -> Unit,
    onClaimBalanceClick: (BigInteger) -> Unit,
    onMergeRoyaltyTokensClick: () -> Unit,
) {
    Div(
        attrs = {
            style {
                marginTop(8.px)
                marginLeft(2.px)
                marginRight(2.px)
                backgroundColor(rgb(0x22, 0x22, 0x22))
                borderRadius(0.25.cssRem)
            }
            classes("p-2")
        },
    ) {
        ListItem(
            styling = {
                Background {
                    color = app.softwork.bootstrapcompose.Color.Transparent
                }
                Text {
                    color = app.softwork.bootstrapcompose.Color.Light
                }
            },
            attrs = {
                style {
                    height(60.px)
                }
            }
        )
        {
            TrackListRow(item.trackRowData, onClickOrganization) {
                Div(
                    attrs = {
                        style {
                            alignSelf(AlignSelf.Start)
                        }
                    }
                ) {
                    Text(value = item.releaseIdDispTxt)
                }
            }
        }
        var sublist = item.royaltySubListItems
//    if (listType == MyRoyaltiesType.FOR_SALE) {
//        // in case the Favorites tab is selected, only get the favorite countries
//        sublist = sublist.filter { it.isOwner }
//    }
        ListGroup {
            sublist.forEach { royItem ->
                ButtonListItem(
                    styling = {
                        Text {
                            color = app.softwork.bootstrapcompose.Color.Light
                        }
                    },
                    attrs = {
                        onClick {
                            onItemClick(royItem.id)
                        }
                        classes("list-group-item-action")
                    }
                ) {
                    Div(
                        attrs = {
                            style {
                                display(DisplayStyle.Flex)
                                flexFlow(FlexDirection.Row, FlexWrap.Nowrap)
                            }
                            classes("text-nowrap")
                        }
                    ) {
                        Div(
                            attrs = {
                                style {
                                    flexGrow(1)
                                    display(DisplayStyle.Flex)
                                    flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                                }
                            }
                        ) {
                            Div { Text(value = royItem.royaltyIdDispTxt) }
                            Div { Text(value = royItem.rowText) }
                        }
                        if (royItem.showClaimBalanceBtn) {
                            Button(
                                royItem.claimableBalanceBtnText,
                                outlined = true,
                                attrs = {
                                    onClick { event -> event.stopPropagation() }
                                }
                            ) {
                                onClaimBalanceClick(royItem.id)
                            }
                        }
                    }
                }
            }
        }
        if (item.royaltySubListItems.size > 1) {
            Button("Merge First Two") {
                onMergeRoyaltyTokensClick()
            }
        }
    }
}

