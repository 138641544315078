package io.eqoty.shared.datalayer.sources.webservices

import com.ionspin.kotlin.bignum.integer.BigInteger

class DownloadManager {

    val activeItems = mutableMapOf<ResourceDownloadInfo, DownloadProgress>()

    val completedItems = mutableMapOf<ResourceDownloadInfo, DownloadProgress>()

    fun putDownloadItem(info: ResourceDownloadInfo, progress: DownloadProgress) {
        activeItems[info] = progress
    }

    fun onDownloadFailed(info: ResourceDownloadInfo) {
        activeItems.remove(info)
    }

    fun moveActiveItemsToCompletedHistory() {
        completedItems.putAll(activeItems)
        activeItems.clear()
    }

    val activeItemsCount: Int
        get() = activeItems.size
    val activeDownloadItemsInProgress: Map<ResourceDownloadInfo, DownloadProgress>
        get() = activeItems.filter { it.value.percentage < 100.0 }

    val activeDownloadItemsAllCompleted: Boolean
        get() = activeItems.filter { it.value.percentage < 100.0 }.isEmpty()

    val activeDownloadsTotalBytesReceived: Long
        get() = activeItems.values.sumOf { it.bytesReceived }

    val activeDownloadsTotalBytes: Long
        get() = activeItems.values.sumOf { it.byteSize }

    val combinedActiveItemsProgress: Float?
        get() {
            val p = (activeDownloadsTotalBytesReceived.toFloat() / activeDownloadsTotalBytes.toFloat())
            return if (!p.isNaN()) p else null
        }

}

data class ResourceDownloadInfo(
    val displayText: String,
    val url: String,
    val fileName: String,
    val sizeMb: String,
    val releaseId: UInt,
    val purchaseId: BigInteger?
)

data class DownloadProgress(val bytesReceived: Long, val byteSize: Long) {
    val percentage = (bytesReceived.toDouble() / byteSize.toDouble()) * 100.0
}