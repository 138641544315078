package io.eqoty.shared.viewmodel.screens.connectwallet

import io.eqoty.shared.datalayer.sources.web3.WalletProvider
import io.eqoty.shared.viewmodel.screens.ConnectWalletParams
import io.eqoty.shared.viewmodel.StateManager
import io.eqoty.shared.viewmodel.screens.ScreenInitSettings
import io.eqoty.shared.viewmodel.screens.ScreenStack
import kotlin.random.Random
import kotlin.random.nextUBytes

// INITIALIZATION settings for this screen
// this is what should be implemented:
// - a data class implementing the ScreenParams interface, which defines the parameters to the passed to the screen
// - Navigation extension function taking the ScreenParams class as an argument, return the ScreenInitSettings for this screen
// to understand the initialization behaviour, read the comments in the ScreenInitSettings.kt file


fun StateManager.initConnectWallet(
    params: ConnectWalletParams
) = ScreenInitSettings(
    title = "Connect Wallet",
    initState = { ConnectWalletScreenState() },
    callOnInit = {
        events.displayCurrentWalletStatus()
        if (params.selectedWalletProvider != null) {
            events.startConnectSequence(
                if (params.selectedWalletProvider == WalletProvider.InternalRandom) Random.nextUBytes(32) else null,
                params.selectedWalletProvider,
            )
        }
    },
)