package io.eqoty.shared.datalayer.functions

import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.sources.web3.DirectSigningSecretWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.JsKeplrWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.JsWeb3Provider
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import org.w3c.dom.events.Event

actual suspend fun Repository.startWalletAddressChangeListener() = withRepoContext {
    when (val provider = web3Provider) {
        is JsWeb3Provider -> {
            provider.web3!!.eth.currentProvider.on("accountsChanged") { accounts ->
                MainScope().launch {
                    walletAddressChangeFlow.emit((accounts as Array<String>).getOrNull(0))
                }
            }
        }

        is JsKeplrWeb3Provider -> {
            MainScope().launch {
                window.addEventListener("keplr_keystorechange", { e: Event ->
                    MainScope().launch {
                        val account =
                            provider.getAccounts().getOrNull(0)
                        walletAddressChangeFlow.emit(account)
                    }
                })
            }
        }

        is DirectSigningSecretWeb3Provider -> {}

        else -> throw UnsupportedOperationException("startWalletAddressChangeListener with unhandled web3Provider: $web3Provider")
    }
    return@withRepoContext
}