package io.eqoty.composables.screens

import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.Column
import app.softwork.bootstrapcompose.Row
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun LoadingScreen(message: String = "") {
    LoadingRow(message, true)
}

@Composable
fun LoadingRow(message: String = "", fillHeight: Boolean = false) {
    val loadingMessage = "Loading ${message}..."
    Row {
        Column(
            attrs = {
                id("loading-screen")
                classes("py-5")
            }
        ) {
            Row(
                attrs = {
                    classes("justify-content-center", "py-2")
                }
            ) {
                Div(
                    attrs = {
                        id("loading-spinner")
                        classes(
                            "spinner-border",
                            "text-primary"
                        )
                    }
                )
            }
            Row(
                attrs = {
                    classes("justify-content-center")
                }
            ) {
                Div(
                    attrs = {
                        id("loading-message")
                        style {
                            fontSize(1.cssRem)
                            property("width", "fit-content")
                        }
                    }
                ) {
                    Text(loadingMessage)
                }
            }
        }
    }
}