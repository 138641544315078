package io.eqoty.shared.viewmodel

import app.cash.sqldelight.driver.worker.WebWorkerDriver
import co.touchlab.kermit.Logger
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.sources.web3.DirectSigningSecretWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.JsKeplrWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.JsWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.WalletProvider
import mylocal.db.LocalDb
import org.w3c.dom.Worker

suspend fun DKMPViewModel.Factory.getWebInstance(): DKMPViewModel {
    val sqlDriver = WebWorkerDriver(
        Worker(
            js("""new URL("@cashapp/sqldelight-sqljs-worker/sqljs.worker.js", import.meta.url)""")
        )
    )
    LocalDb.Schema.create(sqlDriver).await()
    val ethWeb3Provider = JsWeb3Provider()
    val repository = Repository(
        sqlDriver = sqlDriver,
        web3Providers = mapOf(
            WalletProvider.MetaMask to JsKeplrWeb3Provider(),
            WalletProvider.WalletConnect to ethWeb3Provider,
            WalletProvider.Keplr to JsKeplrWeb3Provider(),
            WalletProvider.InternalRandom to DirectSigningSecretWeb3Provider(),
            WalletProvider.Internal to DirectSigningSecretWeb3Provider()
        )
    )
    return DKMPViewModel(repository)
}