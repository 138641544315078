package io.eqoty.composables.styles

import org.jetbrains.compose.web.css.StyleScope
import org.jetbrains.compose.web.css.overflow
import org.jetbrains.compose.web.css.whiteSpace
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement

val overflowTxtToEllipsisAttrs: AttrBuilderContext<HTMLDivElement> = {
    style {
        overflowTxtToEllipsisStyle()
    }
}

fun StyleScope.overflowTxtToEllipsisStyle() = run {
    // https://stackoverflow.com/questions/17779293/css-text-overflow-ellipsis-not-working
    overflow("hidden")
    whiteSpace("nowrap")
    property("text-overflow", "ellipsis")
}