package io.eqoty.composables.screens.organizationregistrationinfo

import androidx.compose.runtime.*
import androidx.compose.ui.text.intl.Locale
import app.softwork.bootstrapcompose.Button
import app.softwork.bootstrapcompose.Card
import app.softwork.bootstrapcompose.Column
import app.softwork.bootstrapcompose.Row
import app.softwork.bootstrapcompose.icons.*
import dev.icerock.moko.resources.compose.stringResource
import io.eqoty.composables.screens.LoadingRow
import io.eqoty.composables.screens.releaserprofile.UpdatableReleaserInfoText
import io.eqoty.composables.views.FormFloatingTextAreaValidation
import io.eqoty.composables.views.FormFloatingTextInputValidation
import io.eqoty.secret.std.contract.msg.EqotyReleaseMsgs
import io.eqoty.shared.MR
import io.eqoty.shared.ui.utils.BootstrapBreakpoint
import io.eqoty.shared.ui.utils.ScreenSizeUtils
import io.eqoty.shared.ui.utils.smallScaffoldActive
import io.eqoty.shared.viewmodel.screens.organizationregistrationinfo.OrganizationRegistrationInfoFormInput
import io.eqoty.shared.viewmodel.screens.organizationregistrationinfo.OrganizationRegistrationInfoScreenState
import io.michaelrocks.libphonenumber.kotlin.PhoneNumberUtil
import io.michaelrocks.libphonenumber.kotlin.metadata.defaultMetadataLoader
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.svg.SVGElement


@Composable
fun OrganizationRegistrationInfoScreen(
    state: OrganizationRegistrationInfoScreenState,
    onSubmitClick: (organizationId: ULong?, OrganizationRegistrationInfoFormInput) -> Unit
) {
    val phoneNumberUtil: PhoneNumberUtil by remember {
        mutableStateOf(PhoneNumberUtil.createInstance(defaultMetadataLoader()))
    }
    val region = remember { Locale.current.region }

    var formInputs: OrganizationRegistrationInfoInputData? by remember { mutableStateOf(null) }
    if (state.shouldReinitializeFormInputData) {
        formInputs = null
    }
    if (state.loading) {
        LoadingRow()
    } else {
        // fill in the form inputs with the data if it exists after loading is complete
        if (formInputs == null) {
            formInputs = state.organization?.run {
                val phone = pendingInfo?.phone ?: info?.phone
                val phoneFormatted = phone?.run {
                    phoneNumberUtil.format(
                        phoneNumberUtil.parse(
                            this, null
                        ), PhoneNumberUtil.PhoneNumberFormat.INTERNATIONAL
                    )
                }
                OrganizationRegistrationInfoInputData(
                    name = mutableStateOf(pendingInfo?.name ?: info?.name ?: ""),
                    email = mutableStateOf(pendingInfo?.email ?: info?.email ?: ""),
                    phone = mutableStateOf(phone ?: ""),
                    phoneFormatted = mutableStateOf(phoneFormatted),
                    mailingAddress = mutableStateOf(pendingInfo?.mailingAddress ?: info?.mailingAddress ?: ""),
                    website = mutableStateOf(pendingInfo?.website ?: info?.website ?: ""),
                )
            } ?: OrganizationRegistrationInfoInputData(
                phone = mutableStateOf("+${phoneNumberUtil.getCountryCodeForRegion(region)}"),
            )
        }
    }
    Column {
        if (formInputs != null) {
            Row {
                when (ScreenSizeUtils.smallScaffoldActive) {
                    true -> {
                        SmallOrganizationRegistrationInfoScreen(
                            state, phoneNumberUtil, region, formInputs!!, onSubmitClick
                        )
                    }

                    false -> {
                        LargeOrganizationRegistrationInfoScreen(
                            state, phoneNumberUtil, region, formInputs!!, onSubmitClick
                        )
                    }
                }
            }
        }
    }
}


@Composable
private fun SmallOrganizationRegistrationInfoScreen(
    state: OrganizationRegistrationInfoScreenState,
    phoneNumberUtil: PhoneNumberUtil,
    region: String,
    formInputData: OrganizationRegistrationInfoInputData,
    onSubmitClick: (organizationId: ULong?, OrganizationRegistrationInfoFormInput) -> Unit
) {
    Column {}
    Column(auto = true) {
        Row {
            OrganizationStatusMessage(state)
        }
        Row {
            Column {
                H4 {
                    Text(state.title)
                }
                Row {
                    if (state.organization != null) OrganizationCard(
                        phoneNumberUtil, formInputData, state.organization!!
                    )
                    else OrganizationCard(phoneNumberUtil, formInputData, null)
                }
            }
        }
        Row {
            Column {
                if (!state.loading) {
                    RegisterOrganizationForm(state, phoneNumberUtil, region, formInputData, onSubmitClick)
                }
            }
        }
    }
    Column {}
}

@Composable
private fun LargeOrganizationRegistrationInfoScreen(
    state: OrganizationRegistrationInfoScreenState,
    phoneNumberUtil: PhoneNumberUtil,
    region: String,
    formInputData: OrganizationRegistrationInfoInputData,
    onSubmitClick: (organizationId: ULong?, OrganizationRegistrationInfoFormInput) -> Unit
) {
    Column { }
    Column(auto = true, attrs = {
        classes("w-100")
        style {
            maxWidth(BootstrapBreakpoint.Xxl.px.px)
        }
    }) {
        Row {
            OrganizationStatusMessage(state)
        }
        Row {
            Column { }
            Column(auto = true) {
                Row {
                    Column(auto = true, attrs = { style { width(500.px) } }) {
                        Row(attrs = { classes("justify-content-end") }) {
                            Column {
                                H4 {
                                    Text(state.title)
                                }
                                if (state.organization != null) OrganizationCard(
                                    phoneNumberUtil, formInputData, state.organization!!
                                )
                                else OrganizationCard(phoneNumberUtil, formInputData, null)
                            }
                        }
                    }
                    if (!state.loading) {
                        Column(attrs = { classes("ps-0", "pt-3", "overflow-hidden"); style { width(350.px) } }) {
                            RegisterOrganizationForm(state, phoneNumberUtil, region, formInputData, onSubmitClick)
                        }
                    }
                }
            }
            Column { }
        }
    }
    Column {}
}


@Composable
private fun OrganizationStatusMessage(state: OrganizationRegistrationInfoScreenState) {
    Column {
        state.organization?.getStatus()?.run {
            when (this) {
                EqotyReleaseMsgs.Status.PENDING -> {
                    Div(attrs = {
                        style {
                            display(DisplayStyle.Flex)
                            flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                            alignItems(AlignItems.Center)
                        }
                        classes("p-3")
                    }) {
                        Div(attrs = { style { fontSize(1.cssRem); paddingBottom(20.px) } }) {
                            Text(stringResource(MR.strings.organization_registration_pending))
                        }
                        Div(attrs = { style { fontSize(0.9.cssRem); paddingBottom(20.px) } }) {
                            Text(stringResource(MR.strings.organization_registration_pending_details))
                        }
                    }
                }

                EqotyReleaseMsgs.Status.ENABLED -> {
                    Div(attrs = {
                        style {
                            display(DisplayStyle.Flex)
                            flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                            alignItems(AlignItems.Center)
                        }
                        classes("p-3")
                    }) {
                        Div(attrs = { style { fontSize(1.cssRem); paddingBottom(20.px) } }) {
                            Text(stringResource(MR.strings.organization_registration_enabled))
                        }
                        Div(attrs = { style { fontSize(0.9.cssRem); paddingBottom(20.px) } }) {
                            Text(stringResource(MR.strings.organization_registration_enabled_details))
                        }
                    }
                }

                EqotyReleaseMsgs.Status.DISABLED -> {
                    Div(attrs = {
                        style {
                            display(DisplayStyle.Flex)
                            flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                            alignItems(AlignItems.Center)
                        }
                        classes("p-3")
                    }) {
                        Div(attrs = { style { fontSize(1.cssRem); paddingBottom(20.px) } }) {
                            Text(stringResource(MR.strings.organization_registration_disabled))
                        }
                        Div(attrs = { style { fontSize(0.9.cssRem); paddingBottom(20.px) } }) {
                            Text(stringResource(MR.strings.organization_registration_disabled_details))
                        }
                    }
                }
            }
        }
    }
}

@Composable
private fun RegisterOrganizationForm(
    state: OrganizationRegistrationInfoScreenState,
    phoneNumberUtil: PhoneNumberUtil,
    region: String,
    formInputData: OrganizationRegistrationInfoInputData,
    onSubmitClick: (organizationId: ULong?, OrganizationRegistrationInfoFormInput) -> Unit
) {
    val phoneNumberFormatter = remember { phoneNumberUtil.getAsYouTypeFormatter(region) }

    Fieldset(attrs = {
        if (!state.interactionEnabled) {
            attr("disabled", "")
        }
    }) {
        Div(attrs = {
            classes("w-100")
            style {
                maxWidth(BootstrapBreakpoint.Sm.px.px)
                paddingTop(20.px)
                paddingBottom(40.px)
                display(DisplayStyle.Flex)
                flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                alignItems(AlignItems.Center)
            }
        }) {
            val widthStyle: StyleScope.() -> Unit = {
                width(100.percent)
            }
            val formAttrs: AttrBuilderContext<HTMLDivElement> = {
                style {
                    widthStyle()
                }
            }

            FormFloatingTextInputValidation(
                attrs = formAttrs,
                value = formInputData.name.value,
                label = stringResource(MR.strings.name) + " *",
                onValueChange = {
                    formInputData.name.value = it
                },
                error = state.validationResult.nameError
            )
            FormFloatingTextInputValidation(
                attrs = formAttrs,
                value = formInputData.email.value,
                label = stringResource(MR.strings.email) + " *",
                onValueChange = {
                    formInputData.email.value = it
                },
                error = state.validationResult.emailError
            )
            FormFloatingTextAreaValidation(
                attrs = formAttrs,
                value = formInputData.phone.value,
                label = stringResource(MR.strings.phone),
                onValueChange = {
                    formInputData.phone.value = it
                    phoneNumberFormatter.clear()
                    it.forEachIndexed { i, char ->
                        val formatted = phoneNumberFormatter.inputDigit(char)
                        if (i == it.length - 1) {
                            formInputData.phoneFormatted.value = formatted
                        }
                    }
                },
                error = state.validationResult.phoneError
            )
            FormFloatingTextInputValidation(
                attrs = formAttrs,
                value = formInputData.website.value,
                label = stringResource(MR.strings.website),
                onValueChange = {
                    formInputData.website.value = it
                },
                error = state.validationResult.websiteError
            )


            Div(attrs = {
                style {
                    paddingTop(20.px)
                    display(DisplayStyle.Flex)
                    flexFlow(FlexDirection.Row, FlexWrap.Nowrap)
                    alignItems(AlignItems.Start)
                    widthStyle()
                }
            }) {
                Button(
                    title = stringResource(MR.strings.submit),
                ) {
                    onSubmitClick(
                        state.organizationId, OrganizationRegistrationInfoFormInput(
                            phoneNumberUtil,
                            name = formInputData.name.value,
                            email = formInputData.email.value,
                            phone = formInputData.phone.value,
                            mailingAddress = formInputData.mailingAddress.value,
                            website = formInputData.website.value,
                        )
                    )
                }
                Div(attrs = {
                    style {
                        display(DisplayStyle.Flex)
                        flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                        alignItems(AlignItems.Start)
                        justifyContent(JustifyContent.Center)
                        paddingLeft(10.px)
                    }
                }) {
                    state.txErrorMessage?.let { errorMessage ->
                        Div(attrs = {
                            classes("text-danger")
                        }) {
                            Text(errorMessage)
                        }
                    }
                    state.txHash?.let { txHash ->
                        Div {
                            Text(stringResource(MR.strings.success_emark))
                        }
                        Div {
                            Text(stringResource(MR.strings.tx_hash) + ": $txHash")
                        }
                    }
                }
            }
        }
    }
}


@Composable
fun OrganizationCard(
    phoneNumberUtil: PhoneNumberUtil,
    formInputData: OrganizationRegistrationInfoInputData?,
    organization: EqotyReleaseMsgs.Organization? = null,
    onListSongClick: ((organizationId: ULong, organizationName: String) -> Unit)? = null,
    onEditItemClick: ((ULong) -> Unit)? = null,
) {
    Card(
        attrs = {
            classes("w-100"); style { maxWidth(BootstrapBreakpoint.Sm.px.px) }; classes(
            "d-flex", "flex-column"
        )
        },
        bodyAttrs = { classes("d-flex", "flex-column") },
    ) {
        Row(attrs = { classes("pb-2") }) {
            Column(auto = true) {
                PersonSquare(attrs = {
                    style {
                        width(100.px)
                        height(100.px)
                    }
                })
            }
            Column(auto = true, attrs = { classes("overflow-hidden") }) {
                Row {
                    Column {
                        UpdatableReleaserInfoText(
                            accepted = organization?.info?.name,
                            pending = organization?.pendingInfo?.name,
                            inputState = formInputData?.name,
                        )
                    }
                }
                organization?.run {
                    H5(attrs = {
                        style {
                            overflow("hidden")
                            whiteSpace("nowrap")
                            property("text-overflow", "ellipsis")
                        }
                    }) {
                        Text("id: $id")
                    }
                    H5(attrs = {
                        style {
                            overflow("hidden")
                            whiteSpace("nowrap")
                            property("text-overflow", "ellipsis")
                        }
                    }) {
                        Text("Organization Status: ${getStatus()}")
                    }
                }
            }
            onEditItemClick?.let {
                Column(attrs = { classes("d-flex", "justify-content-end") }) {
                    PencilFill {
                        classes("btn", "btn-outline-primary", "p-0")
                        onClick { _ ->
                            onEditItemClick(organization!!.id)
                        }
                        style {
                            width(25.px)
                            height(25.px)
                            property("border-color", Color.transparent)
                        }
                    }
                }
            }
        }
        val iconAttrs: AttrBuilderContext<SVGElement> = {
            style {
                width(20.px)
                height(20.px)
            }
        }
        val iconColumnAttrs: AttrBuilderContext<HTMLDivElement> = {
            classes("pe-1")
        }
        val rowItemAttrs: AttrBuilderContext<HTMLDivElement> = {
            classes("py-1", "align-items-center")
        }
        Row {
            Column {
                Row(attrs = rowItemAttrs) {
                    Column(auto = true, attrs = iconColumnAttrs) {
                        EnvelopeFill(iconAttrs)
                    }
                    Column(attrs = { classes("overflow-hidden") }) {
                        UpdatableReleaserInfoText(
                            accepted = organization?.info?.email,
                            pending = organization?.pendingInfo?.email,
                            inputState = formInputData?.email
                        )
                    }
                }
                Row(attrs = rowItemAttrs) {
                    Column(auto = true, attrs = iconColumnAttrs) {
                        TelephoneFill(iconAttrs)
                    }
                    Column(attrs = { classes("overflow-hidden") }) {
                        UpdatableReleaserInfoText(
                            accepted = organization?.info?.phone?.run {
                                phoneNumberUtil.format(
                                    phoneNumberUtil.parse(
                                        this, null
                                    ), PhoneNumberUtil.PhoneNumberFormat.INTERNATIONAL
                                )
                            },
                            pending = organization?.pendingInfo?.phone?.run {
                                phoneNumberUtil.format(
                                    phoneNumberUtil.parse(
                                        this, null
                                    ), PhoneNumberUtil.PhoneNumberFormat.INTERNATIONAL
                                )
                            },
                            inputState = formInputData?.phoneFormatted,
                        )
                    }
                }
                Row(attrs = rowItemAttrs) {
                    Column(auto = true, attrs = iconColumnAttrs) {
                        Link(iconAttrs)
                    }
                    Column(attrs = { classes("overflow-hidden") }) {
                        UpdatableReleaserInfoText(
                            accepted = organization?.info?.website,
                            pending = organization?.pendingInfo?.website,
                            inputState = formInputData?.website
                        )
                    }
                }
            }
            onListSongClick?.let {
                Column(auto = true, attrs = { classes("d-flex", "align-items-end") }) {
                    Button(
                        "List Song", outlined = true
                    ) {
                        onListSongClick(organization!!.id, organization.info!!.name)
                    }
                }
            }
        }
    }
}
