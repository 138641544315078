package io.eqoty.secret.std.contract.msg

import io.eqoty.cosmwasm.std.types.ContractInfo
import io.eqoty.secret.std.types.Permit
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

object EqotyReleaseMsgs {

    @Serializable
    data class Instantiate(
        @SerialName("instance_id") val instanceId: UShort,
        val admin: String,
        @SerialName("royalty_dealer_code_info") val royaltyDealerCodeInfo: CodeInfo,
        @SerialName("product_dealer_code_info") val productDealerCodeInfo: CodeInfo,
        @SerialName("migratable_snip721_code_info") val migratableSnip721CodeInfo: CodeInfo,
    )


    @Serializable
    data class Execute(
        @SerialName("approve_organizations_pending_info") val approveOrganizationsPendingInfo: IdList? = null,
        @SerialName("approve_releasers_pending_info") val approveReleasersPendingInfo: IdList? = null,
        @SerialName("delete_pending_organizations") val deletePendingOrganizations: IdList? = null,
        @SerialName("delete_pending_releasers") val deletePendingReleasers: IdList? = null,
        @SerialName("register_organization") val registerOrganization: RegisterOrganization? = null,
        @SerialName("register_releasers") val registerReleasers: RegisterReleasers? = null,
        @SerialName("mint_release") val mintRelease: MintRelease? = null,
        @SerialName("set_organization_profile_uri") val setOrganizationProfileUri: SetOrganizationProfileUri? = null,
        @SerialName("set_organization_info") val setOrganizationInfo: SetOrganizationInfo? = null,
        @SerialName("set_organizations_status") val setOrganizationsStatus: SetOrganizationsStatus? = null,
        @SerialName("set_releaser_address") val setReleaserAddress: SetReleaserAddress? = null,
        @SerialName("set_releaser_info") val setReleaserInfo: SetReleaserInfo? = null,
        @SerialName("set_releasers_status") val setReleasersStatus: SetReleasersStatus? = null,
        @SerialName("set_release_listed") val setReleaseListed: SetReleaseListed? = null,
    ) {

        @Serializable
        data class MintRelease(
            @SerialName("eq_royalty_dealer_init_config") val eqRoyaltyDealerInitConfig: EqotyRoyaltyDealerMsgs.InstantiateConfig,
            @SerialName("eq_product_dealer_init_config") val eqProductDealerInitConfig: EqotyProductDealerMsgs.InstantiateConfig,
            @SerialName("mint_release_config") val mintReleaseConfig: MintReleaseConfig,
        ) {
            @Serializable
            data class MintReleaseConfig(
                @SerialName("organization_id") val organizationId: ULong,
                @SerialName("label_extra") val labelExtra: String? = null,
                @SerialName("public_metadata") val publicMetadata: Snip721Msgs.Metadata? = null,
                @SerialName("private_metadata") val privateMetadata: Snip721Msgs.Metadata? = null,
            )
        }

        @Serializable
        data class RegisterOrganization(
            val organization: OrganizationInfo,
            val releasers: List<ReleaserBy>,
        )

        @Serializable
        data class RegisterReleasers(
            @SerialName("registration_forms") val registrationForms: List<ReleaserRegistrationForm>,
        )

        @Serializable
        data class SetOrganizationProfileUri(
            val id: ULong,
            val uri: String?,
        )

        @Serializable
        data class SetOrganizationInfo(
            val id: ULong,
            val info: OrganizationInfo,
        )

        @Serializable
        data class SetOrganizationsStatus(
            val ids: List<ULong>,
            val status: UShort,
        )

        @Serializable
        data class SetReleaserAddress(
            val releaser: ReleaserBy,
            val info: ReleaserInfo,
        )

        @Serializable
        data class SetReleaserInfo(
            val releaser: ReleaserBy,
            val info: ReleaserInfo,
        )

        @Serializable
        data class SetReleasersStatus(
            val releasers: List<ReleaserBy>,
            val status: UShort,
        )

        @Serializable
        data class SetReleaseListed(
            val id: UInt,
            val listed: Boolean,
        )

    }

    @Serializable
    data class ExecuteAnswer(
        @SerialName("mint_release") val mintRelease: MintRelease? = null,
        @SerialName("register_organization") val registerOrganization: RegisterOrganization? = null,
        @SerialName("register_releasers") val registerReleasers: List<BasicReleaserInfo>? = null,
    ) {
        @Serializable
        data class MintRelease(
            @SerialName("release_id") val releaseId: UInt,
            @SerialName("eq_royalty_dealer_addr") val eqRoyaltyDealerAddr: String,
            @SerialName("eq_product_dealer_addr") val eqProductDealerAddr: String,
        )

        @Serializable
        data class RegisterOrganization(
            val id: ULong,
        )
    }

    @Serializable
    data class Query(
        @SerialName("instance_id") val instanceId: InstanceId? = null,
        val organizations: Organizations? = null,
        @SerialName("organizations_batch") val organizationsBatch: OrganizationsBatch? = null,
        @SerialName("organization_memberships") val organizationMemberships: OrganizationMemberships? = null,
        @SerialName("organization_releases") val organizationReleases: OrganizationReleases? = null,
        @SerialName("product_dealer_code_info") val productDealerCodeInfo: ProductDealerCodeInfo? = null,
        @SerialName("product_dealer_contract_by_release_id") val productDealerContractByReleaseId: ContractByReleaseId? = null,
        @SerialName("royalty_dealer_contract_by_release_id") val royaltyDealerContractByReleaseId: ContractByReleaseId? = null,
        @SerialName("releaser_id") val releaserId: ReleaserId? = null,
        @SerialName("releasers_batch") val releasersBatch: ReleasersBatch? = null,
        val releasers: Releasers? = null,
        val releases: Releases? = null,
        @SerialName("releases_batch") val releasesBatch: ReleasesBatch? = null,
    ) {
        @Serializable
        data class ContractByReleaseId(@SerialName("release_id") val releaseId: UInt)

        @Serializable
        data class OrganizationMemberships(
            val permit: Permit, val releaser: ReleaserBy
        )

        @Serializable
        class InstanceId

        @Serializable
        class ProductDealerCodeInfo

        @Serializable
        data class ReleaserId(
            val permit: Permit, val address: String
        )

        @Serializable
        data class ReleasersBatch(
            val permit: Permit, val ids: List<ULong>
        )

        @Serializable
        data class OrganizationsBatch(
            val permit: Permit, val ids: List<ULong>
        )

        @Serializable
        data class Organizations(
            val permit: Permit,
            val status: UShort? = null,
            @SerialName("start_index") val startIndex: UInt? = null,
            @SerialName("max_count") val maxCount: UInt? = null,
        )

        @Serializable
        data class OrganizationReleases(
            val id: ULong,
            @SerialName("start_index") val startIndex: UInt? = null,
            @SerialName("max_count") val maxCount: UInt? = null,
        )

        @Serializable
        data class Releasers(
            val permit: Permit,
            val status: UShort? = null,
            @SerialName("start_index") val startIndex: UInt? = null,
            @SerialName("max_count") val maxCount: UInt? = null,
        )

        @Serializable
        data class ReleasesBatch(
            val permit: Permit,
            val ids: List<UInt>,
        )

        @Serializable
        data class Releases(
            val permit: Permit,
            @SerialName("start_index") val startIndex: UInt? = null,
            @SerialName("max_count") val maxCount: UInt? = null,
        )
    }

    @Serializable
    data class QueryAnswer(
        @SerialName("instance_id") val instanceId: UShort? = null,
        @SerialName("num_tokens") val numTokens: Snip721Msgs.QueryAnswer.NumTokens? = null,
        @SerialName("code_info") val codeInfo: CodeInfo? = null,
        @SerialName("contract_by_release_id") val contractByReleaseId: ContractByReleaseId? = null,
        @SerialName("organization_memberships") val organizationMemberships: List<ULong>? = null,
        @SerialName("organization_releases") val organizationReleases: List<UInt>? = null,
        val organizations: List<Organization>? = null,
        @SerialName("releaser_id") val releaserId: ULong? = null,
        val releasers: List<Releaser>? = null,
        val releases: List<Release>? = null,
    ) {
        @Serializable
        data class ContractByReleaseId(val contract: ContractInfo?)

    }

    @Serializable
    data class ReleaserBy(
        val address: String? = null,
        val id: ULong? = null,
    )

    @Serializable
    data class CodeInfo(
        @SerialName("code_id") val codeId: ULong,
        @SerialName("code_hash") val codeHash: String,
    )

    @Serializable
    data class OrganizationInfo(
        val name: String,
        val email: String,
        @SerialName("mailing_address") val mailingAddress: String? = null,
        val phone: String? = null,
        val website: String? = null,
    )

    @Serializable
    data class ReleaserRegistrationForm(
        val info: ReleaserInfo,
        val address: String? = null,
    )

    @Serializable
    data class ReleaserInfo(
        @SerialName("first_name") val firstName: String,
        @SerialName("last_name") val lastName: String,
        val email: String,
        val phone: String? = null,
        @SerialName("mailing_address") val mailingAddress: String? = null,
        @SerialName("gov_id") val govId: String? = null,
        val selfie: String? = null,
    )

    @Serializable
    data class Organization(
        val id: ULong,
        val info: OrganizationInfo?,
        @SerialName("pending_info") val pendingInfo: OrganizationInfo?,
        val status: UShort,
        @SerialName("profile_uri") val profileUri: String?,
        @SerialName("releaser_ids") val releaserIds: List<ULong>,
    ) {
        fun getStatus(): Status = Status.entries.first { it.value == status }
    }

    @Serializable
    data class BasicReleaserInfo(
        val id: ULong,
        @SerialName("first_name") val firstName: String,
        @SerialName("last_name") val lastName: String,
        val address: String,
        val status: UShort,
    )

    @Serializable
    data class Releaser(
        val id: ULong,
        val address: String,
        val info: ReleaserInfo?,
        @SerialName("pending_info") val pendingInfo: ReleaserInfo?,
        val status: UShort,
    ) {
        fun getStatus(): Status = Status.entries.first { it.value == status }
    }

    @Serializable
    data class Release(
        val id: UInt,
        val listed: Boolean,
        @SerialName("organization_id") val organizationId: ULong,
        @SerialName("product_dealer") val productDealer: ContractInfo,
        @SerialName("royalty_dealer") val royaltyDealer: ContractInfo,
        @SerialName("public_metadata") val publicMetadata: Snip721Msgs.Metadata?,
        @SerialName("private_metadata") val privateMetadata: Snip721Msgs.Metadata?,
    )

    @Serializable
    data class IdList(
        val ids: List<ULong>,
    )

    @Serializable
    enum class Status(val value: UShort) {
        PENDING(0u), ENABLED(1u), DISABLED(2u),
    }
}
