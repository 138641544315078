package io.eqoty.shared.devicelayer.functions

import co.touchlab.kermit.Logger
import io.eqoty.kryptools.aes256gcm.Aes256Gcm
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.AttachmentEndpoint
import io.eqoty.shared.datalayer.sources.ipfs.IPFSHTTPClient
import io.eqoty.shared.devicelayer.PlaybackProgressListener
import io.ktor.client.utils.*
import io.ktor.utils.io.*
import io.ktor.utils.io.core.*
import kotlin.math.max

expect suspend fun Repository.playAudio(
    endpoint: AttachmentEndpoint,
    playbackProgressListener: PlaybackProgressListener
)

suspend fun catStream(
    endpoint: AttachmentEndpoint,
    ipfsClient: IPFSHTTPClient,
    aes256Gcm: Aes256Gcm,
    onDownloadStarted: (contentLength: Int, authTagBytesSize: Int) -> Unit = { _, _ -> },
    onDecryptedBytes: (decryptedBytes: ByteArray) -> Unit
) {
    val isEncrypted: Boolean = endpoint.key != null
    ipfsClient.catStream(endpoint.hash) {
        val channel: ByteReadChannel = it.byteReadChannel
        var i = 0
        val bytesPerEncryptedBlock = 16
        val authTagBytesSize = if (isEncrypted) 16 else 0
        val contentLength = it.contentLength.toInt()
        onDownloadStarted(contentLength, authTagBytesSize)
        var bytesRead = 0
        val tag = mutableListOf<Byte>()
        val contentSize = contentLength - authTagBytesSize
        while (!channel.isClosedForRead) {
            val amountToRead = if (channel.availableForRead < bytesPerEncryptedBlock) {
                DEFAULT_HTTP_BUFFER_SIZE
            } else channel.availableForRead - (channel.availableForRead % bytesPerEncryptedBlock)
            var bytes = channel.readRemaining(amountToRead.toLong()).readBytes()
            val amountRead = bytes.size
            bytesRead += bytes.size
            if (bytesRead > contentSize) {
                val tagBytesStart = max(bytes.size - (bytesRead - contentSize), 0)
                val tagRange = tagBytesStart until bytes.size
                tag += bytes.slice(tagRange)
                if (tagRange.count() == bytes.size) continue
                // remove tag from bytes
                bytes = bytes.copyOf(bytes.size - tagRange.count())
            }
            if (isEncrypted) {
                if (amountRead % bytesPerEncryptedBlock != 0 && !channel.isClosedForRead) {
                    Logger.w(
                        "Still more to read... and amountRead % bytesPerEncryptedBlock != 0\n" +
                                "amountRead: $amountRead\n" +
                                "This shouldn't happen"
                    )
                }
                val aesCred = endpoint.key!!
                val decrypted = aes256Gcm.decryptAtIndexUnauthenticated(
                    aesCred.iv, aesCred.key,
                    bytes.asUByteArray(),
                    i,
                ).asByteArray()
                i += amountRead / bytesPerEncryptedBlock
                bytes = decrypted
            }
            onDecryptedBytes(bytes)
        }
    }
}