package io.eqoty.shared.datalayer.functions

import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.sources.web3.InternalWalletTxRequest
import io.eqoty.shared.datalayer.utils.bi


suspend fun Repository.splitRoyaltyToken(
    releaseId: UInt, tokenId: BigInteger, splitCount: Int, internalWltTxRequest: InternalWalletTxRequest?
): String = withRepoContext {
    val royaltyDealer = getRelease(releaseId)!!.royaltyDealer
    val receipt = fragmentRoyaltyToken(royaltyDealer, tokenId, splitCount.bi, internalWltTxRequest)

    return@withRepoContext receipt.transactionHash
}