package io.eqoty.shared.viewmodel.screens.home

import dev.icerock.moko.resources.StringResource
import io.eqoty.shared.MR
import io.eqoty.shared.viewmodel.ScreenState

// here is the data class defining the state for this screen

data class HomeState(
    val isLoading: Boolean = false,
    val distributionFeatureCards: List<FeatureCard> = listOf(
        FeatureCard(MR.strings.home_distro_feature_card_0_title, MR.strings.home_distro_feature_card_0_body),
        FeatureCard(MR.strings.home_distro_feature_card_1_title, MR.strings.home_distro_feature_card_1_body),
        FeatureCard(MR.strings.home_distro_feature_card_2_title, MR.strings.home_distro_feature_card_2_body),
    ),
    val basicFeatureCards: List<FeatureCard> = listOf(
        FeatureCard(MR.strings.home_feature_card_0_title, MR.strings.home_feature_card_0_body),
        FeatureCard(MR.strings.home_feature_card_1_title, MR.strings.home_feature_card_1_body),
        FeatureCard(MR.strings.home_feature_card_2_title, MR.strings.home_feature_card_2_body),
    ),
    val perkFeatureCards: List<FeatureCard> = listOf(
        FeatureCard(MR.strings.home_feature_card_3_title, MR.strings.home_feature_card_3_body),
        FeatureCard(MR.strings.home_feature_card_4_title, MR.strings.home_feature_card_4_body),
        FeatureCard(MR.strings.home_feature_card_5_title, MR.strings.home_feature_card_5_body),
        FeatureCard(MR.strings.home_feature_card_6_title, MR.strings.home_feature_card_6_body),
    )
) : ScreenState

data class FeatureCard(
    val header: StringResource,
    val body: StringResource,
) : ScreenState