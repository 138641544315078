package io.eqoty.shared.viewmodel.screens

import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.sources.web3.TxRequestInfo
import io.eqoty.shared.datalayer.sources.web3.WalletProvider
import io.eqoty.shared.viewmodel.screens.myroyalties.MyRoyaltiesType
import io.eqoty.shared.viewmodel.screens.store.StoreType
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable

/***
 * [ScreenParams] is an interface which defines the parameters to the passed to the screen if any are needed.
 * Each class which implements it should be a data class and should always be set as [Serializable]
 *
 * Note: we are defining all implementations here instead of inside a screen's
 * io.eqoty.shared.viewmodel.screens.`screen-name` subpackage, because all classes implementing
 * a sealed interface must be in the same package. The reason we are using a sealed interface is that it
 * allows the list of ScreenParams subclasses that can be serialized in a polymorphic way to be
 * determined at compile time vs having to explicitly registered them at runtime.
 * See: https://github.com/Kotlin/kotlinx.serialization/blob/master/docs/polymorphism.md#open-polymorphism
 */
@Serializable
sealed interface ScreenParams


@Serializable
data class ConnectWalletParams(val selectedWalletProvider: WalletProvider? = null) : ScreenParams

@Serializable
data class MyRoyaltiesParams(val listType: MyRoyaltiesType) : ScreenParams

@Serializable
data class StoreParams(val listType: StoreType) : ScreenParams

@Serializable
data class WalletTxRequestScreenParams(val txRequestInfo: TxRequestInfo) : ScreenParams

@Serializable
data class MintSongScreenParams(
    val organizationId: ULong,
    val organizationName: String,
) : ScreenParams


@Serializable
data class RoyaltyNftDetailParams(
    @Contextual val releaseId: UInt,
    @Contextual val id: BigInteger,
    val name: String
) : ScreenParams


@Serializable
data class OrganizationRegistrationInfoParams(
    val organizationId: ULong?,
) : ScreenParams

@Serializable
data class OrganizationProfileParams(
    val organizationId: ULong,
    val organizationName: String,
) : ScreenParams

