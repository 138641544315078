package io.eqoty.shared.viewmodel.screens.releaserprofile

import co.touchlab.kermit.Logger
import io.eqoty.secret.std.contract.msg.EqotyReleaseMsgs
import io.eqoty.shared.datalayer.functions.*
import io.eqoty.shared.datalayer.sources.web3.ExternalWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.InternalWeb3Provider
import io.eqoty.shared.viewmodel.Events
import io.eqoty.shared.viewmodel.StateManager
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.scaffold.setWalletScreenExpanded
import io.eqoty.shared.viewmodel.screens.topbar.updateWalletBtnInfo
import io.eqoty.shared.viewmodel.screens.wallettxrequest.internalWalletTxRequestResultSharedFlow
import io.eqoty.shared.viewmodel.screens.wallettxrequest.setInternalWalletTxRequest
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.first

/********** EVENT functions, called directly by the UI layer **********/


fun Events.refreshReleaserInfoOnWalletChanges() = inScreenScopeLaunchInBackground(ScreenStack.Main) {
    dataRepository.walletAddressChangeFlow.collect { newWalletAddress ->
        Logger.d("refreshReleaserInfoOnWalletChanges: onCollect:$newWalletAddress")
        displayReleaserInfo()
    }
}

private var displayReleaserInfoJob: Job? = null

fun Events.displayReleaserInfo() {
    // prevent multiple instances of refresh from being executed
    if (displayReleaserInfoJob?.isActive == true) {
        return
    }
    displayReleaserInfoJob = inScreenScopeLaunch(ScreenStack.Main) {
        if (!dataRepository.getWalletConnected()) {
            stateManager.updateScreen(ScreenStack.Main, ReleaserProfileScreenState::class) {
                it.copy(
                    loading = false,
                    releaser = null,
                    releaserId = null,
                )
            }
            return@inScreenScopeLaunch
        }

        stateManager.updateScreen(ScreenStack.Main, ReleaserProfileScreenState::class) {
            it.copy(
                loading = true,
                releaser = null,
                releaserId = null,
                walletAddress = dataRepository.getWalletAddress(),
                shouldReinitializeFormInputData = true,
            )
        }
        val releaserId = dataRepository.getReleaserId()

        stateManager.updateScreen(ScreenStack.Main, ReleaserProfileScreenState::class) {
            it.copy(
                releaserId = releaserId, loading = releaserId != null,
            )
        }

        val releaser = if (releaserId != null) {
            dataRepository.getReleasersBatch(listOf(releaserId)).first()
        } else {
            null
        }
        stateManager.updateScreen(ScreenStack.Main, ReleaserProfileScreenState::class) {
            it.copy(
                releaser = releaser, loading = false, shouldReinitializeFormInputData = false,
            )
        }
        return@inScreenScopeLaunch
    }
}

fun Events.validateReleaserRegistrationForm(
    formInput: ReleaserProfileFormInput, onSuccess: (formInput: EqotyReleaseMsgs.ReleaserInfo) -> Unit
) = inScreenScopeLaunch(ScreenStack.Main) {
    stateManager.updateScreen(ScreenStack.Main, ReleaserProfileScreenState::class) {
        it.copy(
            interactionEnabled = false, txErrorMessage = null
        )
    }
    val validationResult = formInput.validate()
    stateManager.updateScreen(ScreenStack.Main, ReleaserProfileScreenState::class) {
        it.copy(
            validationResult = validationResult,
        )
    }
    if (!validationResult.hasNoErrors()) {
        stateManager.updateScreen(ScreenStack.Main, ReleaserProfileScreenState::class) {
            it.copy(
                interactionEnabled = true
            )
        }
    } else {
        onSuccess(formInput.toReleaserInfo())
    }
}

fun Events.registerReleaser(
    releaserInfo: EqotyReleaseMsgs.ReleaserInfo,
) = inScreenScopeLaunch(ScreenStack.Main) {
    stateManager.updateScreen(ScreenStack.Main, ReleaserProfileScreenState::class) {
        it.copy(
            loading = true
        )
    }
    val txHash = when (val web3Provider = dataRepository.web3Provider) {
        is InternalWeb3Provider<*> -> {
            try {
                setWalletScreenExpanded(true)?.join()
                dataRepository.registerReleasers(listOf(releaserInfo)) { txRequestInfo ->
                    setInternalWalletTxRequest(txRequestInfo)
                    val requestResult = internalWalletTxRequestResultSharedFlow.first()
                    require(txRequestInfo == requestResult.txRequestInfo)
                    requestResult.result
                }
            } catch (t: Throwable) {
                t.printStackTrace()
                setReleaserProfileScreenError(stateManager, t.message ?: "Register releasers failed")
                null
            } finally {
                setWalletScreenExpanded(false)?.join()
            }
        }

        is ExternalWeb3Provider -> {
            try {
                dataRepository.registerReleasers(listOf(releaserInfo), null)
            } catch (t: Throwable) {
                setReleaserProfileScreenError(stateManager, t.message ?: "Register releasers failed")
                null
            }
        }

        else -> throw NotImplementedError("web3Provider: $web3Provider not handled")
    }

    stateManager.updateScreen(ScreenStack.Main, ReleaserProfileScreenState::class) {
        it.copy(
            loading = false, interactionEnabled = true, txHash = txHash?.transactionHash
        )
    }
    updateWalletBtnInfo()?.join()
}

fun Events.setReleaserInfo(
    releaserId: ULong,
    releaserInfo: EqotyReleaseMsgs.ReleaserInfo,
) = inScreenScopeLaunch(ScreenStack.Main) {
    stateManager.updateScreen(ScreenStack.Main, ReleaserProfileScreenState::class) {
        it.copy(
            loading = true
        )
    }
    val txHash = when (val web3Provider = dataRepository.web3Provider) {
        is InternalWeb3Provider<*> -> {
            try {
                setWalletScreenExpanded(true)?.join()
                dataRepository.setReleaserInfo(releaserId, releaserInfo) { txRequestInfo ->
                    setInternalWalletTxRequest(txRequestInfo)
                    val requestResult = internalWalletTxRequestResultSharedFlow.first()
                    require(txRequestInfo == requestResult.txRequestInfo)
                    requestResult.result
                }
            } catch (t: Throwable) {
                t.printStackTrace()
                setReleaserProfileScreenError(stateManager, t.message ?: "Set releaser info failed")
                null
            } finally {
                setWalletScreenExpanded(false)?.join()
            }
        }

        is ExternalWeb3Provider -> {
            try {
                dataRepository.setReleaserInfo(releaserId, releaserInfo, null)
            } catch (t: Throwable) {
                setReleaserProfileScreenError(stateManager, t.message ?: "Set releaser info failed")
                null
            }
        }

        else -> throw NotImplementedError("web3Provider: $web3Provider not handled")
    }

    stateManager.updateScreen(ScreenStack.Main, ReleaserProfileScreenState::class) {
        it.copy(
            loading = false, interactionEnabled = true, txHash = txHash?.transactionHash
        )
    }
    updateWalletBtnInfo()?.join()
}

private fun setReleaserProfileScreenError(stateManager: StateManager, errorMessage: String) {
    stateManager.updateScreen(ScreenStack.Main, ReleaserProfileScreenState::class) {
        it.copy(
            txErrorMessage = errorMessage, interactionEnabled = true
        )
    }
}