package io.eqoty.shared.viewmodel.screens

import io.eqoty.shared.viewmodel.URI

enum class MainStackL1ScreenTab(val screenIds: List<URI>) {
    Home(listOf(Level1Navigation.HomeProvider.screenIdentifier.URI)),
    Store(listOf(Level1Navigation.StoreProvider.screenIdentifier.URI)),
    Library(
        listOf(
            Level1Navigation.NftPurchaseLibraryProvider.screenIdentifier.URI,
            Level1Navigation.MyRoyaltiesProvider.screenIdentifier.URI,
        )
    ),
    Releaser(
        listOf(
            Level1Navigation.ReleaserProfileProvider.screenIdentifier.URI,
            Level1Navigation.MyOrganizationsProvider.screenIdentifier.URI
        )
    );

    companion object {
        private val map = entries.associateBy(MainStackL1ScreenTab::screenIds)
        fun getScreenTab(screenId: URI): MainStackL1ScreenTab {
            return map.keys.firstOrNull { it.contains(screenId) }?.let { map[it] }
                ?: throw Error("Could not map screenId: $screenId to a Level1ScreenTab")
        }
    }
}