package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.ColumnAdapter
import io.eqoty.shared.datalayer.objects.AttachmentAccess
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String

public data class RemoteAudioResourceTable(
  public val id: String,
  public val url: String,
  public val access: AttachmentAccess,
  public val priority: Int,
  public val size: Long,
  public val container: String,
  public val codec: String,
  public val bitDepth: Int?,
  public val bitRate: Int,
  public val sampleRate: Int,
  public val channels: Int,
  public val lossless: Boolean,
  public val nftMetadataId: String,
) {
  public class Adapter(
    public val accessAdapter: ColumnAdapter<AttachmentAccess, String>,
    public val priorityAdapter: ColumnAdapter<Int, Long>,
    public val bitDepthAdapter: ColumnAdapter<Int, Long>,
    public val bitRateAdapter: ColumnAdapter<Int, Long>,
    public val sampleRateAdapter: ColumnAdapter<Int, Long>,
    public val channelsAdapter: ColumnAdapter<Int, Long>,
  )
}
