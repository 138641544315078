package io.eqoty.composables.screens.organizationprofile

import androidx.compose.runtime.*
import app.softwork.bootstrapcompose.*
import app.softwork.bootstrapcompose.icons.PencilFill
import app.softwork.bootstrapcompose.icons.PlusLg
import dev.icerock.moko.resources.compose.stringResource
import io.eqoty.composables.screens.LoadingScreen
import io.eqoty.composables.styles.overflowTxtToEllipsisStyle
import io.eqoty.composables.views.FormFloatingTextAreaValidation
import io.eqoty.composables.views.TrackListHeaderRow
import io.eqoty.shared.MR
import io.eqoty.shared.ui.utils.ScreenSizeUtils
import io.eqoty.shared.ui.utils.smallScaffoldActive
import io.eqoty.shared.viewmodel.screens.generic.TrackRowData
import io.eqoty.shared.viewmodel.screens.organizationprofile.EditOrganizationProfileFormInput
import io.eqoty.shared.viewmodel.screens.organizationprofile.OrganizationProfileScreenState
import org.jetbrains.compose.web.ExperimentalComposeWebSvgApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.dom.*
import kotlin.math.min

@Composable
fun OrganizationProfileScreen(
    state: OrganizationProfileScreenState,
    onPurchase: (UInt) -> Unit,
    onClickPreviewPlay: (UInt) -> Unit,
    onClickOrganization: (TrackRowData) -> Unit,
    onShowEditProfilePopup: (show: Boolean) -> Unit,
    onChooseBannerImageFileClick: () -> Unit,
    onValidateSaveEditProfile: (EditOrganizationProfileFormInput) -> Unit,
) {
    val isSmallScreen = ScreenSizeUtils.smallScaffoldActive
    Column(attrs = { classes("vh-100") }) {
        Row(attrs = { style { height(33.percent) } }) {
            Div(attrs = {
                classes("card", "border-0", "p-0", "h-100", "w-100")
            }) {
                state.bannerImageUrl?.let {
                    Img(src = it, attrs = {
                        style {
                            variable("object-fit", "cover")
                        }
                        attr("crossorigin", "")
                        classes("h-100")
                    })
                }
                Div(attrs = {
                    classes(
                        "card-img-overlay",
                        "d-flex",
                        "flex-column",
                        "justify-content-between",
                        if (isSmallScreen) "p-3" else "p-4"
                    )
                }) {
                    Div(attrs = { classes("card-title", "d-flex", "justify-content-end") }) {
                        if (state.editProfileAllowed) {
                            when (state.editProfileIsLoading) {
                                true -> {
                                    Div(attrs = {
                                        classes("spinner-border", "text-light")
                                        style {
                                            width(25.px)
                                            height(25.px)
                                        }
                                        attr("role", "status")
                                    }) {
                                        Span(attrs = { classes("visually-hidden") }) {
                                            Text("Loading...")
                                        }
                                    }
                                }

                                false -> {
                                    Button(outlined = true, attrs = {
                                        classes("py-0")
                                        style {
                                            height(100.percent)
                                            property("border-color", Color.transparent)
                                        }
                                        attr("aria-haspopup", "true")
                                        attr("aria-expanded", "false")

                                        onClick { event ->
                                            event.stopPropagation()
                                        }
                                    }, content = {
                                        PencilFill(attrs = { style { height(25.px); width(25.px) } })
                                    }) {
                                        onShowEditProfilePopup(true)
                                    }
                                }
                            }
                        }
                    }
                    H3(attrs = {
                        classes("card-text", "px-2", "fw-bold")
                        style {
                            fontSize(
                                min(
                                    (150.0 / state.organizationName.length), (if (isSmallScreen) 14.0 else 8.0)
                                ).vw
                            )
                            overflowTxtToEllipsisStyle()
                        }
                    }) {
                        Text(state.organizationName)
                    }
                }
            }
        }

        TrackListHeaderRow()
        Row(attrs = { style { minHeight(300.px) } }) {
            ListGroup(attrs = { classes("g-0") }) {
                state.organizationReleaseListItems.forEach { item ->
                    OrganizationProfileReleasesListRow(
                        item = item,
                        onClickPreviewPlay = onClickPreviewPlay,
                        onPurchase = onPurchase,
                        onClickOrganization = onClickOrganization,
                    )
                }
            }
        }
        Row {
            H4 { Text(stringResource(MR.strings.about)) }
        }
        Row(attrs = { classes("px-2") }) {
            Card(
                attrs = { style { minHeight(300.px); maxWidth(700.px) }; classes("d-flex", "flex-column") },
                bodyAttrs = { classes("d-flex", "flex-column") },
            ) {
                Div(attrs = { classes("card-text", "lead") }) {
                    Text(
                        state.organizationProfileMetadata?.description ?: ""
                    )
                }
            }

        }
        if (state.isLoading) {

            LoadingScreen()

        }

        if (state.showEditProfilePopup) {
            EditProfilePopup(
                state, onShowEditProfilePopup, onChooseBannerImageFileClick, onValidateSaveEditProfile
            )
        }
    }
}

@Composable
fun EditProfilePopup(
    state: OrganizationProfileScreenState,
    onShowEditProfilePopup: (show: Boolean) -> Unit,
    onChooseBannerImageFileClick: () -> Unit,
    onValidateSaveEditProfile: (EditOrganizationProfileFormInput) -> Unit,
) {
    var description by remember { mutableStateOf(state.organizationProfileMetadata?.description ?: "") }
    Modal(header = "${stringResource(MR.strings.edit_profile)}: ${state.organizationName}", onDismissRequest = {
        onShowEditProfilePopup(false)
    }, footer = {
        Row(attrs = { classes("d-flex", "align-content-end") }) {
            Column {
                Button("Close") {
                    onShowEditProfilePopup(false)
                }
            }
            Column {
                Button("Save") {
                    onValidateSaveEditProfile(
                        EditOrganizationProfileFormInput(
                            newOrganizationBannerImgFile = state.organizationBannerImgFile,
                            existingOrganizationBannerImgUrl = state.organizationProfileMetadata?.bannerImage,
                            description = description
                        )
                    )
                }
            }
        }
    }) {
        Column {
            Row {
                H5(attrs = {
                    classes("fw-bold")
                }) {
                    Text(stringResource(MR.strings.banner_image))
                }
            }
            AddOrganizationBannerImage(state, onChooseBannerImageFileClick)
            FormFloatingTextAreaValidation(
                attrs = {
                    classes("mt-3")
                },
                textAreaStyle = { height(175.px) },
                value = description,
                label = stringResource(MR.strings.about),
                onValueChange = {
                    description = it
                },
                error = null
            )
        }
    }
}


@Composable
private fun AddOrganizationBannerImage(
    state: OrganizationProfileScreenState, onChooseBannerImageFileClick: () -> Unit
) {
    val error = state.editProfileValidationResult.organizationBannerFileError
    val hasError = error != null
    Div(attrs = {
        style {
            flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
            display(DisplayStyle.Flex)
        }
    }) {
        Button(outlined = true, content = {
            Div(attrs = {
                style {
                    display(DisplayStyle.Flex)
                    flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                    alignItems(AlignItems.Center)
                    justifyContent(JustifyContent.Center)
                    width(100.percent)
                    height(100.percent)
                    if (state.organizationBannerImgFile == null && state.bannerImageUrl == null) {
                        border {
                            style(LineStyle.Dashed)
                        }
                    }
                    textAlign("center")
                }
                if (hasError) {
                    classes("border-danger")
                }
            }) {
                if (state.organizationBannerImgFile == null && state.bannerImageUrl == null) {
                    AddAlbumArtButtonContentsNotChosen()
                } else {
                    Img(state.organizationBannerImgFile?.dataUrl ?: state.bannerImageUrl!!, attrs = {
                        style {
                            width(100.percent)
                            height(100.percent)
                            property("object-fit", "cover")
                        }
                        attr("crossorigin", "")
                    })
                }
            }
        }, attrs = {
            style {
                width(100.percent)
                property("aspect-ratio", 21.0 / 9.0)
                padding(0.px)
                property("border-color", Color.transparent)
            }
        }) {
            onChooseBannerImageFileClick()
        }
        if (hasError) {
            Div(attrs = {
                classes("text-danger", "small")
                style {
                    marginTop(0.25.cssRem)
                }
                attr("role", "alert")
            }) {
                Text(error!!)
            }
        }
    }
}

@OptIn(ExperimentalComposeWebSvgApi::class)
@Composable
private fun AddAlbumArtButtonContentsNotChosen() {
    PlusLg(attrs = {
        style {
            height((1.0 / 3.0 * 100.0).percent)
            width((1.0 / 3.0 * 100.0).percent)
        }
    })
    Div(attrs = {
        style {
            height((1.0 / 3.0 * 100.0).percent)
            display(DisplayStyle.Flex)
            flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
            justifyContent(JustifyContent.Center)
        }
    }) {
        Div { Text(stringResource(MR.strings.upload_banner_image_res_reqs_l0)) }
        Div { Text(stringResource(MR.strings.upload_banner_image_res_reqs_l1)) }
        Div { Text(stringResource(MR.strings.upload_banner_image_file_reqs)) }
    }
}