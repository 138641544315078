package io.eqoty.composables.screens.nftpurchaselibrary

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import app.softwork.bootstrapcompose.Button
import app.softwork.bootstrapcompose.icons.ThreeDotsVertical
import io.eqoty.composables.views.TrackListGroupItem
import io.eqoty.shared.viewmodel.screens.generic.TrackRowData
import io.eqoty.shared.viewmodel.screens.nftpurchaselibrary.NftPurchaseLibraryListItem
import kotlinx.uuid.UUID
import org.jetbrains.compose.web.ExperimentalComposeWebSvgApi
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.DOMScope
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLUListElement

@Composable
fun DOMScope<HTMLUListElement>.NftPurchaseLibraryRow(
    item: NftPurchaseLibraryListItem,
    onItemClick: (UInt) -> Unit,
    onClickOrganization: (TrackRowData) -> Unit,
    showDownloadModal: (TrackRowData) -> Unit,
) {
    TrackListGroupItem(
        item.trackRowData,
        onItemClick,
        onClickOrganization,
    ) {
        NftPurchaseLibraryRowEndOptionsDropdown(item.trackRowData, showDownloadModal)
    }
}

@OptIn(ExperimentalComposeWebSvgApi::class)
@Composable
private fun NftPurchaseLibraryRowEndOptionsDropdown(
    trackRowData: TrackRowData,
    showDownloadModal: (TrackRowData) -> Unit
) {
    Div(
        attrs = {
            style {
                height(100.percent)
            }
            classes("dropdown")
        }
    ) {
        val dropdownMenuBtnId = remember { "dropdownMenuButton${UUID()}" }
        Button(
            outlined = true,
            attrs = {
                classes("py-0")
                style {
                    height(100.percent)
                    property("border-color", Color.transparent)
                }
                id(dropdownMenuBtnId)
                attr("data-bs-toggle", "dropdown")
                attr("aria-haspopup", "true")
                attr("aria-expanded", "false")

                onClick { event ->
                    event.stopPropagation()
                }
            },
            content = {
                ThreeDotsVertical { }
            }
        ) {

        }
        dropdownItems(dropdownMenuBtnId, trackRowData, showDownloadModal)
    }
}


@Composable
private fun dropdownItems(
    id: String,
    trackRowData: TrackRowData,
    showDownloadModal: (TrackRowData) -> Unit
) {
    Div(
        attrs = {
            classes("dropdown-menu")
            attr("aria-labelledby", id)
            onClick { event ->
                event.stopPropagation()
            }
        }
    ) {
        dropdownDownload(trackRowData, showDownloadModal)
    }
}

@Composable
private fun dropdownTextItem(text: String) {
    A(
        href = "#",
        attrs = {
            classes("dropdown-item")
        }
    ) {
        Text(text)
    }
}

@Composable
private fun dropdownDownload(trackRowData: TrackRowData, showDownloadModal: (TrackRowData) -> Unit) {
    Button(
        "Download",
        attrs = {
            classes("dropdown-item")
        }
    ) {
        showDownloadModal(trackRowData)
    }

}

