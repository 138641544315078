package io.eqoty.composables.navigation

import androidx.compose.runtime.*
import androidx.compose.runtime.saveable.SaveableStateHolder
import io.eqoty.composables.screens.scaffold.Scaffold
import io.eqoty.shared.viewmodel.Navigation
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.scaffold.ScaffoldScreenState

@Composable
fun Navigation.Router(screenUIsStateHolder: SaveableStateHolder) {
    val scaffoldScreenState by stateProvider.getScreenStateFlow(
        ScreenStack.Scaffold,
        screenStackToNavigationState[ScreenStack.Scaffold]!!.topScreenIdentifier
    ).collectAsState()
    screenStackToNavigationState =
        remember { mutableStateMapOf(*screenStackToNavigationState.entries.map { it.toPair() }.toTypedArray()) }

    screenUIsStateHolder.SaveableStateProvider(screenStackToNavigationState[ScreenStack.Scaffold]!!.topScreenIdentifier.URI) {
        Scaffold(
            scaffoldScreenState as ScaffoldScreenState,
            screenUIsStateHolder,
        )
    }
}

