package io.eqoty.shared.viewmodel.screens.organizationprofile

import io.eqoty.cosmwasm.std.types.Coin
import io.eqoty.secret.std.contract.msg.EqotyReleaseMsgs
import io.eqoty.shared.datalayer.objects.OrganizationProfileMetadata
import io.eqoty.shared.datalayer.objects.Release
import io.eqoty.shared.datalayer.sources.filesystem.CommonFile
import io.eqoty.shared.datalayer.sources.webservices.filebaseIpfsBaseUrl
import io.eqoty.shared.datalayer.sources.webservices.ipfsBaseUrl
import io.eqoty.shared.datalayer.sources.webservices.ipfsProtocol
import io.eqoty.shared.viewmodel.ScreenState
import io.eqoty.shared.viewmodel.screens.generic.TrackRowData
import io.eqoty.shared.viewmodel.utils.baseUnitToStdUnit
import io.eqoty.shared.viewmodel.utils.stdUnitDenom

// here is the data class defining the state for this screen

data class OrganizationProfileScreenState(
    val organizationId: ULong,
    val organizationName: String,
    val organizationProfileMetadata: OrganizationProfileMetadata? = null,
    val organization: EqotyReleaseMsgs.Organization? = null,
    val organizationReleaseListItems: List<OrganizationReleaseListItem> = emptyList(),
    val editProfileAllowed: Boolean = false,
    val isLoading: Boolean = true,
    val showEditProfilePopup: Boolean = false,
    val organizationBannerImgFile: CommonFile? = null,
    val editProfileValidationResult: EditOrganizationProfileFormInputErrors = EditOrganizationProfileFormInputErrors(),
    val editProfileInteractionEnabled: Boolean = true,
    val editProfileTxErrorMessage: String? = null,
    val editProfileIsLoading: Boolean = false,
) : ScreenState {
    val bannerImageUrl = organizationProfileMetadata?.bannerImage?.run {
        filebaseIpfsBaseUrl + removePrefix(ipfsProtocol)
    }
}


data class OrganizationReleaseListItem(
    private val _release: Release,
    private var _price: Coin?
) {
    // in the ViewModel classes, our computed properties only do UI-formatting operations
    // (the arithmetical operations, such as calculating a percentage, should happen in the DataLayer classes)
    val trackRowData = TrackRowData(_release, null)
    val price = _price?.baseUnitToStdUnit(4)
    val denom = _price?.stdUnitDenom
}