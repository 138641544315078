package io.eqoty.secret.std.contract.msg

import io.eqoty.cosmwasm.std.types.Coin
import io.eqoty.cosmwasm.std.types.ContractInfo
import io.eqoty.secret.std.contract.msg.shared.RoyaltyConfig
import io.eqoty.secret.std.types.Permit
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

object EqotyRoyaltyDealerMsgs {
    @Serializable
    data class InstantiateConfig(
        @SerialName("total_shares")
        val totalShares: UInt? = null,
        val recipients: List<TokensRecipient>,
        @SerialName("public_metadata")
        val publicMetadata: Snip721Msgs.Metadata? = null,
        @SerialName("private_metadata")
        val privateMetadata: Snip721Msgs.Metadata? = null,
        val entropy: String,
        @SerialName("royalty_config")
        val royaltyConfig: RoyaltyConfig? = null,
    )


    @Serializable
    data class TokensRecipient(
        val recipient: String,
        @SerialName("shares_per_token")
        val sharesPerToken: List<UInt>,
    )

    @Serializable
    data class Execute(
        val pay: Pay? = null,
        @SerialName("reconcile_unaccounted_revenue") val reconcileUnaccountedRevenue: ReconcileUnaccountedRevenue? = null,
        @SerialName("claim_available_balances") val claimAvailableBalances: ClaimAvailableBalances? = null,
        @SerialName("redistribute_shares") val redistributeShares: RedistributeShares? = null,
        @SerialName("register_snip20") val registerSnip20: RegisterSnip20? = null,
    ) {

        @Serializable
        data class Pay(
            val uri: String? = null,
            val memo: String? = null,
        )

        @Serializable
        class ReconcileUnaccountedRevenue

        @Serializable
        data class ClaimAvailableBalances(
            @SerialName("token_id") val tokenId: String,
            @SerialName("last_payment_id") val lastPaymentId: String? = null,
            val permit: Permit? = null
        )

        @Serializable
        data class RedistributeShares(
            @SerialName("token_ids")
            val tokenIds: List<String>,
            val recipients: List<TokensRecipient>,
            val permit: Permit? = null
        )

        @Serializable
        data class RegisterSnip20(
            val address: String,
            @SerialName("code_hash")
            val codeHash: String,
            val entropy: String,
            /// The migration secret provided by the sending contract.
            /// Users should always pass null.
            val secret: String? = null
        )

    }

    @Serializable
    data class Query(
        @SerialName("available_balances") val availableBalances: AvailableBalances? = null,
        @SerialName("get_child_snip721") val getChildSnip721: GetChildSnip721? = null,
        @SerialName("undistributable_funds") val undistributableFunds: UndistributableFunds? = null,
        @SerialName("total_revenue") val totalRevenue: TotalRevenue? = null,
        @SerialName("total_unaccounted_revenue") val totalUnaccountedRevenue: TotalUnaccountedRevenue? = null,
        @SerialName("total_shares") val totalShares: TotalShares? = null,
        @SerialName("token_shares") val tokenShares: TokenShares? = null,
    ) {
        @Serializable
        data class AvailableBalances(
            @SerialName("token_id") val tokenId: String,
            @SerialName("last_payment_id") val lastPaymentId: String? = null,
            val permit: Permit
        )

        @Serializable
        class GetChildSnip721

        @Serializable
        data class TotalRevenue(
            val permit: Permit
        )

        @Serializable
        data class TotalUnaccountedRevenue(
            val permit: Permit,
            val denom: String? = null,
        )

        @Serializable
        data class UndistributableFunds(
            val permit: Permit
        )

        @Serializable
        class TotalShares

        @Serializable
        data class TokenShares(@SerialName("token_id") val tokenId: String)
    }

    @Serializable
    data class ExecuteAnswer(
        @SerialName("claim_available_balances") val claimAvailableBalances: ClaimAvailableBalances? = null,
    ) {
        @Serializable
        data class ClaimAvailableBalances(val balances: List<Coin>)
    }


    @Serializable
    data class QueryAnswer(
        @SerialName("contract_info") val contractInfo: ContractInfo? = null,
        val funds: List<Coin>? = null,
        val shares: UInt? = null,
    )
}