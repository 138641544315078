package io.eqoty.shared.viewmodel.screens.scaffold

import io.eqoty.shared.viewmodel.Events
import io.eqoty.shared.viewmodel.HandlePlatformPushState
import io.eqoty.shared.viewmodel.screens.ScreenStack


fun Events.toggleWalletScreenExpanded() = inScreenScopeLaunch(ScreenStack.Scaffold) {
    stateManager.updateScreen(ScreenStack.Scaffold, ScaffoldScreenState::class) {
        val toggledValue = !it.walletScreenExpanded
        if (toggledValue) HandlePlatformPushState()
        it.copy(
            walletScreenExpanded = toggledValue
        )
    }
}

fun Events.setWalletScreenExpanded(expanded: Boolean) = inScreenScopeLaunch(ScreenStack.Scaffold) {
    stateManager.updateScreen(ScreenStack.Scaffold, ScaffoldScreenState::class) {
        if (expanded) HandlePlatformPushState()
        it.copy(
            walletScreenExpanded = expanded
        )
    }
}

fun Events.setSmallScaffoldActive(smallScaffoldActive: Boolean) = inScreenScopeLaunch(ScreenStack.Scaffold) {
    stateManager.updateScreen(ScreenStack.Scaffold, ScaffoldScreenState::class) {
        it.copy(
            smallScaffoldActive = smallScaffoldActive
        )
    }
}
