package io.eqoty.composables.screens.topbar


import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.Button
import app.softwork.bootstrapcompose.Column
import app.softwork.bootstrapcompose.Row
import app.softwork.bootstrapcompose.icons.ThreeDotsVertical
import app.softwork.bootstrapcompose.icons.WalletTwo
import io.eqoty.composables.resources.ImageResources
import io.eqoty.composables.styling.BackgroundDarker
import io.eqoty.composables.views.SocialMediaIconsRow
import io.eqoty.shared.ui.utils.ScreenSizeUtils
import io.eqoty.shared.ui.utils.smallScaffoldActive
import io.eqoty.shared.viewmodel.screens.topbar.DownloadIndicatorData
import io.eqoty.shared.viewmodel.screens.topbar.TopBarAccountItemData
import io.eqoty.shared.viewmodel.screens.topbar.TopBarState
import org.jetbrains.compose.web.ExperimentalComposeWebSvgApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import kotlin.math.max
import kotlin.math.min

@Composable
fun TopBar(
    state: TopBarState,
    onClickWalletAction: () -> Unit
) {
    Row(
        attrs = {
            style {
                maxHeight(100.percent)
                fontSize(1.2.cssRem)
                height(50.px)
                backgroundColor(BackgroundDarker)
                property("z-index", 2)
            }
            classes("sticky-top", "py-0", "d-flex", "align-items-center")
        },
    ) {
        Column(
            size = 5,
            attrs = {
                style {
                    paddingLeft(10.px)
                    overflow("hidden")
                    whiteSpace("nowrap")
                    property("text-overflow", "ellipsis")
                }
            },
        ) {
            if (ScreenSizeUtils.smallScaffoldActive) {
                A(
                    attrs = {
                        style {
                            display(DisplayStyle.Flex)
                            height(40.px)
                            width(40.px)
                            padding(5.px)
                        }
                    }
                ) {
                    Img(src = ImageResources.eqotyIconWhite)
                }
            }
        }

        Column(
            size = 7,
            attrs = {
                classes("d-flex", "justify-content-end")
                style { height(100.percent) }
            }
        ) {
            Div(
                attrs = {
                    style {
                        display(DisplayStyle.Flex)
                        flexFlow(FlexDirection.Row, FlexWrap.Nowrap)
                        alignItems("center")
                    }
                }
            ) {
                state.downloadIndicatorData?.let { DownloadProgress(it) }
                if (!ScreenSizeUtils.smallScaffoldActive) {
                    Column {
                        SocialMediaIconsRow(applyAttrs = { classes("me-2") })
                    }
                }
                TopBarAccountItem(
                    state.topBarAccountItemData,
                    onClickWalletAction
                )
            }
        }
    }
}

private val overflowAndTxtAttrs: AttrBuilderContext<HTMLDivElement> = {
    style {
        fontSize(12.px)
        // https://stackoverflow.com/questions/17779293/css-text-overflow-ellipsis-not-working
        overflow("hidden")
        whiteSpace("nowrap")
        property("text-overflow", "ellipsis")
    }
}

@Composable
fun DownloadProgress(data: DownloadIndicatorData) {
    Div(attrs = {
        classes("p-1")
        style {
            height(100.percent)
            width(225.px)
            display(DisplayStyle.Flex)
            flexFlow(FlexDirection.Row, FlexWrap.Nowrap)
        }
    }) {
        CircleProgressIndicator(data.progress, data.progressString)
        Div(
            attrs = {
                style {
                    height(100.percent)
                    width(100.percent)
                    display(DisplayStyle.Flex)
                    flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                    paddingLeft(5.px)
                    minWidth("0") // needed to enable text-overflow:ellipsis inside a flex
                }
                classes("text-nowrap")
            }
        ) {
            Div(attrs = overflowAndTxtAttrs) { Text(data.downloadProgressText) }
            Div(attrs = overflowAndTxtAttrs) { Text(data.downloadDescMessage) }
        }
    }
}

@Composable
fun CircleProgressIndicator(percentage: Float, percentageString: String) {

    Div(
        attrs = {
            style {
                height(100.percent)
                property("aspect-ratio", 1)
            }
        }
    ) {
        Div(
            attrs = {
                classes("circle-progress", "primary-border-color")
                style {
                    height(100.percent)
                    property("aspect-ratio", 1)
                }
            }
        ) {
            Span(
                attrs = {
                    classes("progress-left")
                    style {
                        property("--percentage", "${max(percentage.toDouble() - 0.5, 0.0) * 360.0}deg")
                    }
                }
            ) {
                Span(attrs = { classes("progress-bar") })
            }
            Span(
                attrs = {
                    classes("progress-right")
                    style {
                        property("--percentage", "${min(percentage.toDouble(), 0.5) * 360.0}deg")
                    }
                }) {
                Span(attrs = { classes("progress-bar") })
            }
            Div(attrs = { classes("progress-value") }) {
                Text(percentageString)
            }

        }

    }
}

@OptIn(ExperimentalComposeWebSvgApi::class)
@Composable
fun TopBarAccountItem(
    data: TopBarAccountItemData, onClick: () -> Unit
) {
    Button(
        outlined = true,
        content = {
            Div(
                attrs = {
                    style {
                        display(DisplayStyle.Flex)
                        flexFlow(FlexDirection.Row, FlexWrap.Nowrap)
                        justifyContent(JustifyContent.Center)
                        alignItems(AlignItems.Center)
                        fontSize(0.8.em)
                    }
                }
            ) {
                Div(attrs = {
                    style {
                        display(DisplayStyle.Flex)
                        flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                        justifyContent(JustifyContent.Center)
                    }
                    classes("text-nowrap")
                }) {
                    if (data.address != null) {
                        Div {
                            Text(data.networkName)
                        }
                        Div {
                            Text(
                                "Wallet: ...${
                                    data.address!!.subSequence(
                                        data.address!!.length - 5,
                                        data.address!!.length
                                    )
                                }"
                            )
                        }
                    } else {
                        Div {
                            Text("Connect Wallet")
                        }
                    }
                }
                WalletTwo(
                    attrs = { style { paddingLeft(10.px); height(35.px); width(35.px) } }
                )
                if (ScreenSizeUtils.smallScaffoldActive) {
                    ThreeDotsVertical(
                        attrs = { style { marginRight((-8).px); height(25.px); width(25.px) } }
                    )
                }
            }
        },
        attrs = {
            classes("py-0")
            style {
                property("border-color", Color.transparent)
            }
        }
    ) {
        onClick()
    }
}
