package io.eqoty.shared.viewmodel.screens.organizationregistrationinfo

import io.eqoty.secret.std.contract.msg.EqotyReleaseMsgs
import io.michaelrocks.libphonenumber.kotlin.PhoneNumberUtil

class OrganizationRegistrationInfoFormInput(
    private val phoneNumberUtil: PhoneNumberUtil,
    name: String,
    email: String,
    mailingAddress: String?,
    phone: String?,
    website: String?,
) {
    var name: String = name
        get() = field.trim()
    var email: String = email
        get() = field.trim()

    var phone: String? = phone
        get() = field?.trim()?.ifEmpty { null }

    var mailingAddress: String? = mailingAddress
        get() = field?.trim()?.ifEmpty { null }

    var website: String? = website
        get() = field?.trim()?.ifEmpty { null }


    fun validate(): OrganizationRegistrationInfoFormInputErrors {
        val inputErrors = OrganizationRegistrationInfoFormInputErrors()

        if (name.isBlank()) {
            inputErrors.nameError = "First name required"
        }

        if (!email.matches(
                Regex(
                    """(?:[a-z0-9!#${'$'}%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#${'$'}%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])"""
                )
            )
        ) {
            inputErrors.emailError = "Invalid email"
        }

        phone?.run {
            if (!this.contains("+")) {
                inputErrors.phoneError = "Phone number must contain country code (e.g. +1 for US)"
            } else {
                try {
                    phoneNumberUtil.parse(this, null)
                } catch (t: Throwable) {
                    inputErrors.phoneError = t.message
                }
            }
        }

        website?.run {
            if (!website!!.matches(
                    // https://stackoverflow.com/a/75967027/1363742
                    Regex(
                        """https?:\/\/([-a-zA-Z0-9\u1F60-\uFFFF\u1F60-\uFFFF\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F@:%._\+~#=]{1,256})\.([a-zA-Z][a-zA-Z0-9\u1F60-\uFFFF\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F\u0370-\u03ff\u1f00-\u1fff\u0400-\u04ff()-]{1,62})\b([\/#][-a-zA-Z0-9\u1F60-\uFFFF\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F\u0370-\u03ff\u1f00-\u1fff\u0400-\u04ff\u0900-\u097F\u0600-\u06FF\u0985-\u0994\u0995-\u09a7\u09a8-\u09ce\u0981\u0982\u0983\u09e6-\u09ef\u0750-\u077F\uFB50-\uFDFF\uFE70-\uFEFF\u4E00-\u9FFFẸɓɗẹỊỌịọṢỤṣụ()@:%_\+.~#?&//=\[\]!\${'$'}'*+,;]*)?"""
                    )
                )
            ) {
                inputErrors.websiteError = "Invalid url"
            }

        }

        return inputErrors
    }

    fun toOrganizationInfo(): EqotyReleaseMsgs.OrganizationInfo =
        EqotyReleaseMsgs.OrganizationInfo(
            name = name,
            email = email,
            phone = phone?.run {
                phoneNumberUtil.format(
                    phoneNumberUtil.parse(this, null),
                    PhoneNumberUtil.PhoneNumberFormat.E164
                )
            },
            mailingAddress = mailingAddress,
            website = website,
        )
}

data class OrganizationRegistrationInfoFormInputErrors(
    var nameError: String? = null,
    var emailError: String? = null,
    var phoneError: String? = null,
    var mailingAddressError: String? = null,
    var websiteError: String? = null,
) {
    fun hasNoErrors(): Boolean {
        return nameError == null &&
                emailError == null &&
                phoneError == null &&
                mailingAddressError == null &&
                websiteError == null
    }
}
