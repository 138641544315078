package io.eqoty.secret.std.contract.msg.shared

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RoyaltyConfig(
    /// decimal places in royalty rates
    @SerialName("decimal_places_in_rates")
    val decimalPlacesInRates: UByte,
    /// royalty rate
    val rate: UShort,
)