package io.eqoty.composables.screens.mintsong


import androidx.compose.runtime.*
import app.softwork.bootstrapcompose.*
import app.softwork.bootstrapcompose.icons.PlusLg
import dev.icerock.moko.resources.compose.stringResource
import io.eqoty.composables.views.FileInputValidation
import io.eqoty.composables.views.FormFloatingNumberInputValidation
import io.eqoty.composables.views.FormFloatingTextAreaValidation
import io.eqoty.composables.views.FormFloatingTextInputValidation
import io.eqoty.shared.MR
import io.eqoty.shared.viewmodel.screens.mintsong.MintSongScreenState
import io.eqoty.shared.viewmodel.screens.mintsong.MintTrackFormInput
import kotlinx.uuid.UUID
import org.jetbrains.compose.web.ExperimentalComposeWebSvgApi
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.builders.InputAttrsScope
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.attributes.forId
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement

@Composable
fun MintSongScreen(
    state: MintSongScreenState,
    onChoosePrivateAudioFileClick: () -> Unit,
    onChoosePublicAudioFileClick: () -> Unit,
    onChooseAlbumArtFileClick: () -> Unit,
    disableAutofillFromFile: () -> Unit,
    onMintClick: (MintTrackFormInput) -> Unit
) {
    MintSongForm(
        state,
        onChoosePrivateAudioFileClick,
        onChoosePublicAudioFileClick,
        onChooseAlbumArtFileClick,
        disableAutofillFromFile,
        onMintClick
    )
}

@Composable
private fun MintSongForm(
    state: MintSongScreenState,
    onChoosePrivateAudioFileClick: () -> Unit,
    onChoosePublicAudioFileClick: () -> Unit,
    onChooseAlbumArtFileClick: () -> Unit,
    disableAutofillFromFile: () -> Unit,
    onMintClick: (MintTrackFormInput) -> Unit
) {
    Fieldset(attrs = {
        if (!state.interactionEnabled) {
            attr("disabled", "")
        }
    }) {
        Div(attrs = {
            style {
                paddingTop(20.px)
                paddingBottom(40.px)
                display(DisplayStyle.Flex)
                flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                alignItems(AlignItems.Center)
            }
        }) {
            val advancedPubicAudioMode = remember { mutableStateOf(false) }
            val startAt = remember { mutableStateOf("0.0") }
            val endAt = remember { mutableStateOf("30.0") }
            var name by remember { mutableStateOf("") }
            var artist by remember { mutableStateOf(state.selectedOrganizationName) }
            var album by remember { mutableStateOf("") }
            var aboutTrack by remember { mutableStateOf("") }
            var price by remember { mutableStateOf("") }
            AddAlbumArtButton(state, onChooseAlbumArtFileClick)
            val widthStyle: StyleScope.() -> Unit = {
                minWidth("250px")
                width(25.percent)
            }
            val firstFileInputStyleOverride: StyleScope.() -> Unit = {
                marginTop(1.5.cssRem)
            }
            val lastFileInputStyleOverride: StyleScope.() -> Unit = {
                marginBottom(0.4.cssRem)
            }
            FileInputValidation(
                applyStyles = listOf(widthStyle, firstFileInputStyleOverride),
                selectedFile = state.privateAudioFile,
                topLabel = stringResource(MR.strings.audio_label),
                selectedContentText = state.privateAudioSizeLabel,
                placeholderText = stringResource(MR.strings.select_file),
                onChooseFileClick = onChoosePrivateAudioFileClick,
                error = state.validationResult.privateAudioFileError
            )
            val formAttrs: AttrBuilderContext<HTMLDivElement> = {
                style {
                    widthStyle()
                }
            }
            PublicAudioInput(
                state,
                formAttrs,
                listOf(lastFileInputStyleOverride),
                advancedPubicAudioMode,
                startAt,
                endAt,
                onChoosePublicAudioFileClick
            )
            if (state.autofillFromFileInfo) {
                name = state.privateFileName!!
                disableAutofillFromFile()
            }
            FormFloatingTextInputValidation(
                attrs = formAttrs,
                value = name,
                label = stringResource(MR.strings.track_name_input_label),
                onValueChange = {
                    name = it
                },
                error = state.validationResult.nameError
            )
            FormFloatingTextInputValidation(
                attrs = formAttrs,
                value = artist,
                label = stringResource(MR.strings.artist_input_label),
                onValueChange = {
                    artist = it
                },
                error = state.validationResult.artistError
            )
            FormFloatingTextInputValidation(
                attrs = formAttrs,
                value = album,
                label = stringResource(MR.strings.album_input_label),
                onValueChange = {
                    album = it
                },
                error = state.validationResult.albumError
            )
            FormFloatingTextAreaValidation(
                attrs = formAttrs,
                value = aboutTrack,
                label = stringResource(MR.strings.about_track_input_label),
                onValueChange = {
                    aboutTrack = it
                },
                error = state.validationResult.aboutTrackError
            )
            FormFloatingNumberInputValidation(
                attrs = formAttrs,
                value = price,
                label = "${state.currentNetwork?.currency} ${stringResource(MR.strings.price_input_label)}",
                onValueChange = {
                    price = it
                },
                error = state.validationResult.priceError
            )

            Div(attrs = {
                style {
                    paddingTop(20.px)
                    display(DisplayStyle.Flex)
                    flexFlow(FlexDirection.Row, FlexWrap.Nowrap)
                    alignItems(AlignItems.Start)
                    widthStyle()
                }
            }) {
                Button(
                    title = stringResource(MR.strings.mint_emark),
                ) {
                    onMintClick(
                        MintTrackFormInput(
                            state.selectedOrganizationId,
                            name,
                            artist,
                            album,
                            aboutTrack,
                            price,
                            state.privateAudioFile,
                            state.privateAudioDuration,
                            advancedPubicAudioMode.value,
                            state.publicAudioFile,
                            state.publicAudioDuration,
                            startAt.value,
                            endAt.value,
                            state.albumArtFile
                        )
                    )
                }
                Div(attrs = {
                    style {
                        display(DisplayStyle.Flex)
                        flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                        alignItems(AlignItems.Start)
                        justifyContent(JustifyContent.Center)
                        paddingLeft(10.px)
                    }
                }) {
                    state.mintErrorMessage?.let { errorMessage ->
                        Div(attrs = {
                            classes("text-danger")
                        }) {
                            Text(errorMessage)
                        }
                    }
                    state.mintProgress?.let { progress ->
                        Div {
                            Text(progress)
                        }
                    }
                    state.txHash?.let { txHash ->
                        Div {
                            Text(stringResource(MR.strings.success_emark))
                        }
                        Div {
                            Text(stringResource(MR.strings.tx_hash) + ": $txHash")
                        }
                    }
                }
            }
        }
    }
}

@Composable
private fun AddAlbumArtButton(state: MintSongScreenState, onChooseAlbumArtFileClick: () -> Unit) {
    val error = state.validationResult.trackArtFileError
    val hasError = state.validationResult.trackArtFileError != null
    Div(attrs = {
        style {
            flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
            display(DisplayStyle.Flex)
        }
    }) {
        Button(outlined = true, content = {
            Div(attrs = {
                style {
                    display(DisplayStyle.Flex)
                    flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                    alignItems(AlignItems.Center)
                    width(100.percent)
                    height(100.percent)
                    if (state.albumArtFile == null) {
                        border {
                            style(LineStyle.Dashed)
                        }
                    }
                    textAlign("center")
                }
                if (hasError) {
                    classes("border-danger")
                }
            }) {
                if (state.albumArtFile == null) {
                    AddAlbumArtButtonContentsNotChosen()
                } else {
                    Img(state.albumArtFile!!.dataUrl!!, attrs = {
                        style {
                            width(100.percent)
                            height(100.percent)
                        }
                    })
                }
            }
        }, attrs = {
            style {
                width(250.px)
                height(250.px)
                padding(0.px)
                property("border-color", Color.transparent)
            }
        }) {
            onChooseAlbumArtFileClick()
        }
        if (hasError) {
            Div(attrs = {
                classes("text-danger", "small")
                style {
                    marginTop(0.25.cssRem)
                }
                attr("role", "alert")
            }) {
                Text(error!!)
            }
        }
    }
}

@OptIn(ExperimentalComposeWebSvgApi::class)
@Composable
private fun AddAlbumArtButtonContentsNotChosen() {
    Div(attrs = {
        style {
            height((1.0 / 3.0 * 100.0).percent)
            display(DisplayStyle.Flex)
            flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
            justifyContent(JustifyContent.Center)
        }
    }) {
        Div { Text(stringResource(MR.strings.track_art_label)) }
    }
    PlusLg(attrs = {
        style {
            height((1.0 / 3.0 * 100.0).percent)
            width((1.0 / 3.0 * 100.0).percent)
        }
    })
    Div(attrs = {
        style {
            height((1.0 / 3.0 * 100.0).percent)
            display(DisplayStyle.Flex)
            flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
            justifyContent(JustifyContent.Center)
        }
    }) {
        Div { Text(stringResource(MR.strings.upload_track_art_res_reqs_l0)) }
        Div { Text(stringResource(MR.strings.upload_track_art_res_reqs_l1)) }
        Div { Text(stringResource(MR.strings.upload_track_art_file_reqs)) }
    }
}


@Composable
private fun PublicAudioInput(
    state: MintSongScreenState,
    formAttrs: AttrBuilderContext<HTMLDivElement>,
    fileInputApplyStyles: List<StyleScope.() -> Unit>,
    advancedPubicAudioMode: MutableState<Boolean>,
    startAt: MutableState<String>,
    endAt: MutableState<String>,
    onChoosePublicAudioFileClick: () -> Unit,
) {
    Column(attrs = formAttrs) {
        Row(attrs = { classes("flex-nowrap", "g-0") }) {
            if (advancedPubicAudioMode.value) {
                FileInputValidation(
                    applyStyles = fileInputApplyStyles,
                    selectedFile = state.publicAudioFile,
                    topLabel = stringResource(MR.strings.store_preview_audio_label),
                    selectedContentText = state.publicAudioSizeLabel,
                    placeholderText = stringResource(MR.strings.select_file),
                    onChooseFileClick = onChoosePublicAudioFileClick,
                    error = state.validationResult.publicAudioFileError
                )
            } else {
                Column {
                    Row { Div { Text(stringResource(MR.strings.store_preview_audio_label)) } }
                    Row(attrs = { classes("g-0") }) {
                        Column(attrs = { classes("col-6") }) {
                            FormFloatingTextInputValidation(
                                attrs = { },
                                value = startAt.value,
                                label = stringResource(MR.strings.start_at_sec),
                                onValueChange = {
                                    startAt.value = it
                                },
                                error = state.validationResult.publicAudioStartAtError
                            )
                        }
                        Column(attrs = { classes("col-6") }) {
                            FormFloatingTextInputValidation(
                                attrs = { },
                                value = endAt.value,
                                label = stringResource(MR.strings.end_at_sec),
                                onValueChange = {
                                    endAt.value = it
                                },
                                error = state.validationResult.publicAudioEndAtError
                            )
                        }
                    }
                }
            }
            Column(
                auto = true,
                attrs = { classes("d-flex", "justify-content-end") }
            ) {
                CheckboxColumn(
                    checked = advancedPubicAudioMode.value, label = "Advanced", switch = true
                ) {
                    advancedPubicAudioMode.value = it
                }
            }
        }
    }
}


@Composable
private fun CheckboxColumn(
    checked: Boolean,
    label: String,
    id: String = remember { UUID().toString() },
    disabled: Boolean = false,
    inline: Boolean = false,
    switch: Boolean = false,
    styling: (Styling.() -> Unit)? = null,
    attrs: (InputAttrsScope<Boolean>.() -> Unit)? = null,
    onClick: (Boolean) -> Unit
) {
    val classes = styling?.let {
        Styling().apply(it).generate()
    }

    Div({
        classes(BSClasses.formCheck)
        if (classes != null) {
            classes(classes = classes)
        }
        if (inline) {
            classes(BSClasses.formCheckInline)
        }
        if (switch) {
            classes(BSClasses.formSwitch)
        }
        classes("ps-0")
        style {
            display(DisplayStyle.Flex)
            flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
            justifyContent(JustifyContent.Start)
            alignItems(AlignItems.End)
        }
    }) {
        Label(attrs = {
            style {
                fontSize(0.75.cssRem)
            }
            classes(BSClasses.formCheckLabel)
            forId("_$id")
        }) {
            Text(label)
        }
        Input(attrs = {
            classes(BSClasses.formCheckInput, "my-3")
            id("_$id")
            checked(checked)
            onInput { event ->
                onClick(event.value)
            }
            if (disabled) {
                disabled()
            }
            attrs?.invoke(this)
        }, type = InputType.Checkbox)
    }
}
