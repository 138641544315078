package io.eqoty.shared.viewmodel.screens.mintsong

import io.eqoty.shared.datalayer.objects.*
import io.eqoty.shared.datalayer.sources.filesystem.CommonFile
import io.eqoty.shared.viewmodel.utils.roundToDecimals

class MintTrackFormInput(
    val organizationId: ULong? = null,
    name: String,
    artist: String,
    album: String,
    aboutTrack: String,
    price: String,
    val privateAudioFile: CommonFile?,
    val privateAudioDuration: Long?,
    val advancedPubicAudioMode: Boolean,
    val publicAudioAdvancedModeFile: CommonFile?,
    val publicAudioAdvancedModeDuration: Long?,
    val publicAudioStartAt: String,
    val publicAudioEndAt: String,
    val trackArtFile: CommonFile?,
) {
    var name: String = name
        get() = field.trim()

    var artist: String = artist
        get() = field.trim()

    var album: String = album
        get() = field.trim()

    var aboutTrack: String = aboutTrack
        get() = field.trim()

    var price: String = price
        get() = field.trim()

    val publicAudioClipRange: LongRange?
        get() {
            return if (advancedPubicAudioMode) null
            else {
                val startAt = publicAudioStartAt.toDouble().run { (this * 1000).toLong() }
                val endAt = publicAudioEndAt.toDouble().run { (this * 1000).toLong() }
                startAt..endAt
            }
        }

    fun validate(): MintSongFormInputErrors {
        val inputErrors = MintSongFormInputErrors()

        if (organizationId == null) {
            inputErrors.organizationIdError = "Select an organization"
        }

        if (name.isBlank()) {
            inputErrors.nameError = "Name required"
        }

        if (artist.isBlank()) {
            inputErrors.artistError = "Artist required"
        }

        if (album.isBlank()) {
            inputErrors.albumError = "Album name required"
        }

        if (price.isBlank()) {
            inputErrors.priceError = "Price required"
        } else if (price.toDoubleOrNull() == null) {
            inputErrors.priceError = "Invalid number"
        } else if (price.toDouble() < 0) {
            inputErrors.priceError = "Price must be >= 0"
        }

        if (privateAudioFile == null) {
            inputErrors.privateAudioFileError = "Audio file required"
        } else {
            if (privateAudioDuration == null) {
                inputErrors.privateAudioFileError = "Audio duration not set"
            } else if (privateAudioDuration <= 0) {
                inputErrors.privateAudioFileError =
                    "Audio duration: $privateAudioDuration is invalid. Audio may be corrupt or empty"
            }
        }

        when (advancedPubicAudioMode) {
            true -> if (publicAudioAdvancedModeFile == null) {
                inputErrors.publicAudioFileError = "A public preview audio file for the store is required"
            } else {
                if (publicAudioAdvancedModeDuration == null) {
                    inputErrors.publicAudioFileError = "Audio duration not set"
                } else if (publicAudioAdvancedModeDuration <= 0) {
                    inputErrors.publicAudioFileError =
                        "Audio duration: $publicAudioAdvancedModeDuration is invalid. Audio may be corrupt or empty"
                }
            }

            false -> {
                if (privateAudioFile == null) {
                    inputErrors.publicAudioFileError = "A private audio file was not selected"
                }
                val publicAudioStartAtMs = publicAudioStartAt.toDoubleOrNull()?.run { (this * 1000).toInt() }
                val publicAudioEndAtMs = publicAudioEndAt.toDoubleOrNull()?.run { (this * 1000).toInt() }

                if (publicAudioStartAtMs == null) {
                    inputErrors.publicAudioStartAtError = "Invalid number"
                } else if (publicAudioStartAtMs < 0) {
                    inputErrors.publicAudioStartAtError = "Start time must be >= 0"
                } else if (publicAudioEndAtMs != null && publicAudioStartAtMs >= publicAudioEndAtMs) {
                    inputErrors.publicAudioStartAtError = "Start time must be < end time"
                } else if (privateAudioDuration != null && publicAudioStartAtMs > privateAudioDuration) {
                    inputErrors.publicAudioStartAtError = "Start time must be <= private audio duration: ${
                        (privateAudioDuration.toDouble() / 1000.0).roundToDecimals(3)
                    } sec"
                }

                if (publicAudioEndAtMs == null) {
                    inputErrors.publicAudioEndAtError = "Invalid number"
                } else if (publicAudioEndAtMs < 0) {
                    inputErrors.publicAudioEndAtError = "End time must be >= 0"
                } else if (publicAudioStartAtMs != null && publicAudioStartAtMs >= publicAudioEndAtMs) {
                    inputErrors.publicAudioEndAtError = "End time must be > start time"
                } else if (privateAudioDuration != null && publicAudioEndAtMs > privateAudioDuration) {
                    inputErrors.publicAudioEndAtError = "End time must be <= private audio duration: ${
                        (privateAudioDuration.toDouble() / 1000.0).roundToDecimals(3)
                    } sec"
                }

            }
        }

        if (trackArtFile == null) {
            inputErrors.trackArtFileError = "Track Art required"
        }
        return inputErrors
    }

    fun toTrackProperties(
        privateAudioTracks: List<RemoteAudioResource>,
        publicAudioTracks: List<RemoteAudioResource>,
    ): ReleaseProperties = AudioReleaseProperties(
        attachments = listOf(
            AudioAttachmentMetadata(
                access = AttachmentAccess.PRIVATE,
                duration = privateAudioDuration!!,
                priority = 0,
                formats = privateAudioTracks
            ), AudioAttachmentMetadata(
                access = AttachmentAccess.PUBLIC,
                duration = if (advancedPubicAudioMode) publicAudioAdvancedModeDuration!! else publicAudioClipRange!!.last - publicAudioClipRange!!.first,
                priority = 0,
                formats = publicAudioTracks
            )
        ), listOf(artist), album
    )
}

data class MintSongFormInputErrors(
    var organizationIdError: String? = null,
    var nameError: String? = null,
    var artistError: String? = null,
    var albumError: String? = null,
    var aboutTrackError: String? = null,
    var priceError: String? = null,
    var privateAudioFileError: String? = null,
    var publicAudioFileError: String? = null,
    var publicAudioStartAtError: String? = null,
    var publicAudioEndAtError: String? = null,
    var trackArtFileError: String? = null,
) {
    fun hasNoErrors(): Boolean {
        return organizationIdError == null && nameError == null && artistError == null && albumError == null && aboutTrackError == null && priceError == null && privateAudioFileError == null && publicAudioFileError == null && publicAudioStartAtError == null && publicAudioEndAtError == null && trackArtFileError == null
    }
}
