package io.eqoty.shared.viewmodel.screens.leftnavigationrail

import io.eqoty.shared.viewmodel.StateManager
import io.eqoty.shared.viewmodel.screens.ScreenInitSettings


// INITIALIZATION settings for this screen
// to understand the initialization behaviour, read the comments in the ScreenInitSettings.kt file


fun StateManager.initLeftNavigationRail() = ScreenInitSettings(
    title = /*params.name ?:*/ "",
    initState = {
        LeftNavigationRailState()
    },
    callOnInit = {},
)