package io.eqoty.shared.viewmodel.screens.wallettxrequest

import io.eqoty.shared.datalayer.sources.web3.TxRequestInfo
import io.eqoty.shared.viewmodel.ScreenState
import io.eqoty.shared.viewmodel.utils.baseUnitToStdUnit
import io.eqoty.shared.viewmodel.utils.stdUnitDenom

// here is the data class defining the state for this screen

data class WalletTxRequestState(
    val txRequestInfo: TxRequestInfo? = null,
) : ScreenState {
    val estimatedGas: String = "Estimated Gas: ${txRequestInfo?.gas?.amount} ${txRequestInfo?.gas?.denom}"
    val amountSent: String = "Amount being sent: " +
            "${txRequestInfo?.amount?.baseUnitToStdUnit(10)} ${txRequestInfo?.amount?.stdUnitDenom}"
}