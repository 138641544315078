package io.eqoty.shared.datalayer.objects

import kotlinx.serialization.Serializable


@Serializable
data class OrganizationProfileMetadata(
    val id: String,
    val name: String,
    val description: String? = null,
    val bannerImage: String? = null,
)