package io.eqoty.composables.views

import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.FormFloating
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.StyleScope
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement


@Composable
fun FormFloatingTextAreaValidation(
    attrs: AttrBuilderContext<HTMLDivElement> = {},
    textAreaStyle: (StyleScope.() -> Unit)? = null,
    value: String,
    label: String,
    onValueChange: (String) -> Unit,
    error: String? = null,
) {
    val hasError = error != null
    FormFloating(
        attrs = attrs
    ) {
        TextArea(value = value) {
            style {
                textAreaStyle?.invoke(this)
            }
            classes("form-control")
            if (hasError) {
                classes("is-invalid")
            }
            id("floatingInput")
            placeholder(label)
            onInput {
                onValueChange(it.value)
            }
        }
        Label("floatingInput") {
            Text(label)
        }
        if (hasError) {
            Div(attrs = {
                classes("invalid-feedback")
            }) {
                Text(error!!)
            }
        }
    }
}