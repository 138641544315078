package io.eqoty.composables.screens.scaffold

import androidx.compose.runtime.Composable
import androidx.compose.runtime.saveable.SaveableStateHolder
import io.eqoty.shared.viewmodel.Navigation
import io.eqoty.shared.viewmodel.screens.scaffold.ScaffoldScreenState

@Composable
fun Navigation.Scaffold(
    state: ScaffoldScreenState,
    screenUIsStateHolder: SaveableStateHolder
) {
    if (state.smallScaffoldActive) {
        SmallScaffold(state, screenUIsStateHolder)
    } else {
        LargeScaffold(state, screenUIsStateHolder)
    }
}

