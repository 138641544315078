package io.eqoty.shared.datalayer.sources.filesystem

import kotlinx.coroutines.suspendCancellableCoroutine
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Int8Array
import org.w3c.dom.ErrorEvent
import org.w3c.files.File
import org.w3c.files.FileReader
import kotlin.coroutines.resumeWithException

actual class CommonFile(val file: File) {

    actual val name: String = file.name
    actual val size: Long = file.size.toLong()
    actual suspend fun getBytes(): ByteArray = getBase64(file)

    var dataUrl: String? = null
    suspend fun setDataUrl(): String? {
        dataUrl = getDataUrl(file)
        return dataUrl
    }

    private suspend fun getBase64(file: File): ByteArray = suspendCancellableCoroutine { continuation ->
        val reader = FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = {
            val arrayBuffer = reader.result as ArrayBuffer
            val bytes = Int8Array(arrayBuffer).unsafeCast<ByteArray>()
            continuation.resume(bytes, null)
        }
        reader.onerror = { error ->
            continuation.resumeWithException(Error((error as ErrorEvent).message))
        }
    }

    suspend fun getDataUrl(file: File): String = suspendCancellableCoroutine { continuation ->
        val reader = FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = {
            val dataUrl = reader.result as String
            continuation.resume(dataUrl, null)
        }
        reader.onerror = { error ->
            continuation.resumeWithException(Error((error as ErrorEvent).message))
        }
    }


    actual val path: String = file.name
    actual val platformFile: Any = file

}