package io.eqoty.shared.datalayer.sources.web3

import io.eqoty.secretk.utils.EncryptionUtils
import io.eqoty.secretk.wallet.DirectSigningWallet
import io.eqoty.secretk.wallet.Wallet


class DirectSigningSecretWeb3Provider(
) : InternalWeb3Provider<DirectSigningWallet>, CosmosWeb3Provider {

    private val hardcodedNetwork = Web3Network.SecretTestnet

    var chainId: String? = hardcodedNetwork.id

    override val wallet: Wallet?
        get() = internalWallet

    override val encryptionUtils: EncryptionUtils? = null

    override var internalWallet: DirectSigningWallet? = null

    override val activeAccountIndex: Int? get() = activeAccountIndexBacking
    private var activeAccountIndexBacking: Int? = null

    override suspend fun setActiveAccountIndex(accountIndex: Int?) {
        activeAccountIndexBacking = accountIndex
    }


    override fun addAccountToWallet(mnemonic: String): String {
        val address = if (wallet == null) {
            internalWallet = DirectSigningWallet(mnemonic)
            internalWallet!!.accounts[0].address
        } else {
            internalWallet!!.addAccount(mnemonic).publicData.address
        }
        return address
    }

    override fun addAccountToWallet(privkey: UByteArray): String {
        val address = if (wallet == null) {
            internalWallet = DirectSigningWallet(privkey)
            internalWallet!!.accounts[0].address
        } else {
            internalWallet!!.addAccount(privkey).publicData.address
        }
        return address
    }


    override suspend fun requestAccounts(): List<String> = wallet?.getAccounts()?.map { it.address } ?: emptyList()

    override suspend fun getAccounts(): List<String> = wallet?.getAccounts()?.map { it.address } ?: emptyList()

    override suspend fun getId(): String? = chainId

    override suspend fun disconnect() {
        setActiveAccountIndex(null)
    }

}
