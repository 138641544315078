import app.softwork.bootstrapcompose.require
import io.eqoty.composables.MainComposable
import io.eqoty.shared.ui.utils.ScreenSizeUtils
import io.eqoty.shared.ui.utils.smallScaffoldActive
import io.eqoty.shared.viewmodel.DKMPViewModel
import io.eqoty.shared.viewmodel.getWebInstance
import io.eqoty.shared.viewmodel.screens.scaffold.setSmallScaffoldActive
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.renderComposable


fun main() = application { model ->
    require("./custom.scss")
    window.addEventListener("resize", {
        model.stateManager.events.setSmallScaffoldActive(ScreenSizeUtils.smallScaffoldActive)
    }, false)
    renderComposable(rootElementId = "root") {
        MainComposable(model)
    }
}

// this coroutine mechanism is required, because SqlDelight for Web
// (which is part of the DKMPViewModel) needs to be instantiated asynchronously
fun application(block: suspend (DKMPViewModel) -> Unit) {
    MainScope().launch {
        val model = DKMPViewModel.getWebInstance()
        block(model)
    }
}
