package io.eqoty.shared.viewmodel.screens.leftnavigationrail

import io.eqoty.shared.viewmodel.ScreenState

// here is the data class defining the state for this screen

data class LeftNavigationRailState(
    val homeTab: HomeTab = HomeTab(),
    val storeTab: StoreTab = StoreTab(),
    val royaltiesTab: RoyaltiesTab = RoyaltiesTab(),
    val songsTab: SongsTab = SongsTab(),
    val releaserProfileTab: ReleaserProfileTab = ReleaserProfileTab(),
    val myArtistsTab: MyArtistsTab = MyArtistsTab(),
    val selectedTab: NavigationTab = homeTab,
) : ScreenState

/********** property classes **********/

sealed interface NavigationTab {
    var visible: Boolean
}

sealed interface NavigationTabFolder {
    var expanded: Boolean
}

data class HomeTab(
    override var visible: Boolean = true
) : NavigationTab

data class StoreTab(
    override var visible: Boolean = true
) : NavigationTab

sealed class LibraryTab : NavigationTab

data class SongsTab(
    override var visible: Boolean = true
) : LibraryTab()

data class RoyaltiesTab(
    override var visible: Boolean = true
) : LibraryTab()

sealed interface ReleaserTab : NavigationTab

data class ReleaserProfileTab(
    override var visible: Boolean = false
) : ReleaserTab

data class MyArtistsTab(
    override var visible: Boolean = false,
    override var expanded: Boolean = false
) : ReleaserTab, NavigationTabFolder
