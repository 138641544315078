package io.eqoty.shared.datalayer.sources.ipfs

import io.eqoty.kryptools.aes256gcm.Aes256Gcm
import io.eqoty.shared.datalayer.sources.filesystem.CommonFile
import io.ipfs.kotlin.IPFS
import io.ipfs.kotlin.commands.CatReadChannelHandler
import io.ipfs.kotlin.commands.UploadAndAddProgressListener
import io.ipfs.kotlin.defaults.InfuraIPFS
import io.ipfs.kotlin.model.NamedResponse

class IPFSHTTPClient {

    var ipfs: IPFS?

    init {
        try {
            ipfs = InfuraIPFS("2DHgYc4brIad4QUsCR0SoZbevvn", "01098080d890ec4338984894947c79ca")
        } catch (t: Throwable) {
            t.printStackTrace()
            ipfs = null
        }
    }

    suspend fun addFile(
        file: CommonFile,
        progressListener: UploadAndAddProgressListener? = null
    ): NamedResponse =
        ipfs!!.add.file(file.getBytes(), progressListener = progressListener)

    suspend fun encryptAndAddFile(
        aes256Gcm: Aes256Gcm,
        iv: UByteArray,
        key: UByteArray,
        file: CommonFile,
        progressListener: UploadAndAddProgressListener? = null
    ): NamedResponse {
        val encrypted = aes256Gcm.encrypt(iv, key, file.getBytes().toUByteArray())
        return ipfs!!.add.file(encrypted.toByteArray(), progressListener = progressListener)
    }


        suspend fun addText(text: String): NamedResponse = ipfs!!.add.string(text)
    suspend fun cat(hash: String): ByteArray = ipfs!!.get.catBytes(hash)

    suspend fun catStream(hash: String, handler: CatReadChannelHandler): Unit = ipfs!!.get.catReadChannel(hash, handler)
}
