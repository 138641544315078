package io.eqoty.shared.viewmodel.screens.organizationregistrationinfo

import co.touchlab.kermit.Logger
import io.eqoty.secret.std.contract.msg.EqotyReleaseMsgs
import io.eqoty.shared.datalayer.functions.getOrganizationBatch
import io.eqoty.shared.datalayer.functions.getWalletConnected
import io.eqoty.shared.datalayer.functions.registerOrganization
import io.eqoty.shared.datalayer.functions.setOrganizationInfo
import io.eqoty.shared.datalayer.sources.web3.ExternalWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.InternalWeb3Provider
import io.eqoty.shared.viewmodel.Events
import io.eqoty.shared.viewmodel.StateManager
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.scaffold.setWalletScreenExpanded
import io.eqoty.shared.viewmodel.screens.topbar.updateWalletBtnInfo
import io.eqoty.shared.viewmodel.screens.wallettxrequest.internalWalletTxRequestResultSharedFlow
import io.eqoty.shared.viewmodel.screens.wallettxrequest.setInternalWalletTxRequest
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.first

/********** EVENT functions, called directly by the UI layer **********/


fun Events.refreshOrganizationRegistrationInfoOnWalletChanges(organizationId: ULong?) =
    inScreenScopeLaunchInBackground(ScreenStack.Main) {
        dataRepository.walletAddressChangeFlow.collect { newWalletAddress ->
            Logger.d("refreshReleaserInfoOnWalletChanges: onCollect:$newWalletAddress")
            displayOrganizationRegistrationInfo(organizationId)
        }
    }

private var displayOrganizationInfoJob: Job? = null

fun Events.displayOrganizationRegistrationInfo(organizationId: ULong?) {
    // prevent multiple instances of refresh from being executed
    if (displayOrganizationInfoJob?.isActive == true) {
        return
    }
    displayOrganizationInfoJob = inScreenScopeLaunch(ScreenStack.Main) {
        if (!dataRepository.getWalletConnected()) {
            stateManager.updateScreen(ScreenStack.Main, OrganizationRegistrationInfoScreenState::class) {
                it.copy(
                    loading = false,
                    organization = null,
                    organizationId = organizationId,
                )
            }
            return@inScreenScopeLaunch
        }
        if (organizationId == null) {
            stateManager.updateScreen(ScreenStack.Main, OrganizationRegistrationInfoScreenState::class) {
                it.copy(
                    loading = false,
                    organization = null,
                    organizationId = null,
                )
            }
            return@inScreenScopeLaunch
        }

        stateManager.updateScreen(ScreenStack.Main, OrganizationRegistrationInfoScreenState::class) {
            it.copy(
                loading = true,
                organization = null,
                organizationId = organizationId,
                shouldReinitializeFormInputData = true,
            )
        }

        val organization = dataRepository.getOrganizationBatch(listOf(organizationId)).first()
        stateManager.updateScreen(ScreenStack.Main, OrganizationRegistrationInfoScreenState::class) {
            it.copy(
                organization = organization, loading = false, shouldReinitializeFormInputData = false,
            )
        }
        return@inScreenScopeLaunch
    }
}

fun Events.validateOrganizationRegistrationForm(
    formInput: OrganizationRegistrationInfoFormInput,
    onSuccess: (formInput: EqotyReleaseMsgs.OrganizationInfo) -> Unit
) = inScreenScopeLaunch(ScreenStack.Main) {
    stateManager.updateScreen(ScreenStack.Main, OrganizationRegistrationInfoScreenState::class) {
        it.copy(
            interactionEnabled = false, txErrorMessage = null
        )
    }
    val validationResult = formInput.validate()
    stateManager.updateScreen(ScreenStack.Main, OrganizationRegistrationInfoScreenState::class) {
        it.copy(
            validationResult = validationResult,
        )
    }
    if (!validationResult.hasNoErrors()) {
        stateManager.updateScreen(ScreenStack.Main, OrganizationRegistrationInfoScreenState::class) {
            it.copy(
                interactionEnabled = true
            )
        }
    } else {
        onSuccess(formInput.toOrganizationInfo())
    }
}

fun Events.registerOrganization(
    organizationInfo: EqotyReleaseMsgs.OrganizationInfo,
) = inScreenScopeLaunch(ScreenStack.Main) {
    stateManager.updateScreen(ScreenStack.Main, OrganizationRegistrationInfoScreenState::class) {
        it.copy(
            loading = true
        )
    }
    val txHash = when (val web3Provider = dataRepository.web3Provider) {
        is InternalWeb3Provider<*> -> {
            try {
                setWalletScreenExpanded(true)?.join()
                dataRepository.registerOrganization(organizationInfo) { txRequestInfo ->
                    setInternalWalletTxRequest(txRequestInfo)
                    val requestResult = internalWalletTxRequestResultSharedFlow.first()
                    require(txRequestInfo == requestResult.txRequestInfo)
                    requestResult.result
                }
            } catch (t: Throwable) {
                t.printStackTrace()
                setReleaserProfileScreenError(stateManager, t.message ?: "Register releasers failed")
                null
            } finally {
                setWalletScreenExpanded(false)?.join()
            }
        }

        is ExternalWeb3Provider -> {
            try {
                dataRepository.registerOrganization(organizationInfo, null)
            } catch (t: Throwable) {
                setReleaserProfileScreenError(stateManager, t.message ?: "Register releasers failed")
                null
            }
        }

        else -> throw NotImplementedError("web3Provider: $web3Provider not handled")
    }

    stateManager.updateScreen(ScreenStack.Main, OrganizationRegistrationInfoScreenState::class) {
        it.copy(
            loading = false, interactionEnabled = true, txHash = txHash?.transactionHash
        )
    }
    updateWalletBtnInfo()?.join()
}

fun Events.setOrganizationInfo(
    organizationId: ULong,
    organizationInfo: EqotyReleaseMsgs.OrganizationInfo,
) = inScreenScopeLaunch(ScreenStack.Main) {
    stateManager.updateScreen(ScreenStack.Main, OrganizationRegistrationInfoScreenState::class) {
        it.copy(
            loading = true
        )
    }
    val txHash = when (val web3Provider = dataRepository.web3Provider) {
        is InternalWeb3Provider<*> -> {
            try {
                setWalletScreenExpanded(true)?.join()
                dataRepository.setOrganizationInfo(organizationId, organizationInfo) { txRequestInfo ->
                    setInternalWalletTxRequest(txRequestInfo)
                    val requestResult = internalWalletTxRequestResultSharedFlow.first()
                    require(txRequestInfo == requestResult.txRequestInfo)
                    requestResult.result
                }
            } catch (t: Throwable) {
                t.printStackTrace()
                setReleaserProfileScreenError(stateManager, t.message ?: "Set releaser info failed")
                null
            } finally {
                setWalletScreenExpanded(false)?.join()
            }
        }

        is ExternalWeb3Provider -> {
            try {
                dataRepository.setOrganizationInfo(organizationId, organizationInfo, null)
            } catch (t: Throwable) {
                setReleaserProfileScreenError(stateManager, t.message ?: "Set releaser info failed")
                null
            }
        }

        else -> throw NotImplementedError("web3Provider: $web3Provider not handled")
    }

    stateManager.updateScreen(ScreenStack.Main, OrganizationRegistrationInfoScreenState::class) {
        it.copy(
            loading = false, interactionEnabled = true, txHash = txHash?.transactionHash
        )
    }
    updateWalletBtnInfo()?.join()
}

private fun setReleaserProfileScreenError(stateManager: StateManager, errorMessage: String) {
    stateManager.updateScreen(ScreenStack.Main, OrganizationRegistrationInfoScreenState::class) {
        it.copy(
            txErrorMessage = errorMessage, interactionEnabled = true
        )
    }
}