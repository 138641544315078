package mylocal.db

import app.cash.sqldelight.SuspendingTransacter
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import io.eqoty.shared.datalayer.sources.localdb.nfts.NftMetadataTable
import io.eqoty.shared.datalayer.sources.localdb.nfts.NftMetadataTableQueries
import io.eqoty.shared.datalayer.sources.localdb.nfts.ProductDealerContractTable
import io.eqoty.shared.datalayer.sources.localdb.nfts.ProductDealerContractTableQueries
import io.eqoty.shared.datalayer.sources.localdb.nfts.PurchaseNftTable
import io.eqoty.shared.datalayer.sources.localdb.nfts.PurchaseNftTableQueries
import io.eqoty.shared.datalayer.sources.localdb.nfts.ReleaseTable
import io.eqoty.shared.datalayer.sources.localdb.nfts.ReleaseTableQueries
import io.eqoty.shared.datalayer.sources.localdb.nfts.RemoteAudioResourceTable
import io.eqoty.shared.datalayer.sources.localdb.nfts.RemoteAudioResourceTableQueries
import io.eqoty.shared.datalayer.sources.localdb.nfts.RoyaltyNftTable
import io.eqoty.shared.datalayer.sources.localdb.nfts.RoyaltyNftTableQueries
import kotlin.Unit
import mylocal.db.shared.newInstance
import mylocal.db.shared.schema

public interface LocalDb : SuspendingTransacter {
  public val nftMetadataTableQueries: NftMetadataTableQueries

  public val productDealerContractTableQueries: ProductDealerContractTableQueries

  public val purchaseNftTableQueries: PurchaseNftTableQueries

  public val releaseTableQueries: ReleaseTableQueries

  public val remoteAudioResourceTableQueries: RemoteAudioResourceTableQueries

  public val royaltyNftTableQueries: RoyaltyNftTableQueries

  public companion object {
    public val Schema: SqlSchema<QueryResult.AsyncValue<Unit>>
      get() = LocalDb::class.schema

    public operator fun invoke(
      driver: SqlDriver,
      NftMetadataTableAdapter: NftMetadataTable.Adapter,
      ProductDealerContractTableAdapter: ProductDealerContractTable.Adapter,
      PurchaseNftTableAdapter: PurchaseNftTable.Adapter,
      ReleaseTableAdapter: ReleaseTable.Adapter,
      RemoteAudioResourceTableAdapter: RemoteAudioResourceTable.Adapter,
      RoyaltyNftTableAdapter: RoyaltyNftTable.Adapter,
    ): LocalDb = LocalDb::class.newInstance(driver, NftMetadataTableAdapter,
        ProductDealerContractTableAdapter, PurchaseNftTableAdapter, ReleaseTableAdapter,
        RemoteAudioResourceTableAdapter, RoyaltyNftTableAdapter)
  }
}
