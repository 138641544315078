package io.eqoty.shared.datalayer.functions

import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.PurchaseNft
import io.eqoty.shared.datalayer.objects.toPurchaseNft
import io.eqoty.shared.datalayer.sources.DataSrc
import io.eqoty.shared.datalayer.sources.LOCAL
import io.eqoty.shared.datalayer.sources.RemoteSrc
import io.eqoty.shared.datalayer.sources.localdb.nfts.getPurchaseNftById
import io.eqoty.shared.datalayer.sources.localdb.nfts.getPurchaseNftIdForRelease
import io.eqoty.shared.datalayer.sources.localdb.nfts.upsertPurchaseNft
import io.eqoty.shared.datalayer.sources.webservices.apis.fetchReleaseMetaData

suspend fun Repository.getPurchaseNft(
    releaseId: UInt, purchaseId: BigInteger, dataSrc: DataSrc = LOCAL
): PurchaseNft? = withRepoContext {
    if (dataSrc is RemoteSrc) {
        val childSnip721 = getProductDealerContract(releaseId).childSnip721
        val purchaseTokenUri = getPurchaseTokenUri(childSnip721, purchaseId)
        val releaseMetaData = purchaseTokenUri?.run { webservices.fetchReleaseMetaData(this) }
        val purchaseNft = releaseMetaData.toPurchaseNft(releaseId, purchaseId)
        localDb.upsertPurchaseNft(purchaseNft)
    }
    return@withRepoContext localDb.getPurchaseNftById(getPurchaseNftIdForRelease(releaseId, purchaseId))
}