package io.eqoty.composables.screens.scaffold

import androidx.compose.runtime.Composable
import androidx.compose.runtime.saveable.SaveableStateHolder
import app.softwork.bootstrapcompose.Color
import app.softwork.bootstrapcompose.Column
import app.softwork.bootstrapcompose.Container
import app.softwork.bootstrapcompose.Row
import io.eqoty.composables.navigation.ScreenPicker
import io.eqoty.composables.navigation.bars.LibraryTabRow
import io.eqoty.composables.navigation.bars.ReleaserTabRow
import io.eqoty.composables.navigation.bars.SidebarColumn
import io.eqoty.composables.styling.BackgroundDarkest
import io.eqoty.composables.styling.ColumnSize
import io.eqoty.composables.styling.ColumnSizes
import io.eqoty.composables.styling.ScrimOverlayBg
import io.eqoty.composables.views.SocialMediaIconsRow
import io.eqoty.shared.viewmodel.Navigation
import io.eqoty.shared.viewmodel.screens.MainStackL1ScreenTab
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.scaffold.ScaffoldScreenState
import io.eqoty.shared.viewmodel.screens.scaffold.setWalletScreenExpanded
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Main

@Composable
fun Navigation.SmallScaffold(
    state: ScaffoldScreenState,
    saveableStateHolder: SaveableStateHolder
) {
    val currentMainStackScreenId = screenStackToNavigationState[ScreenStack.Main]!!.topScreenIdentifier
    val currentMainStackL1ScreenId = screenStackToNavigationState[ScreenStack.Main]!!.currentLevel1ScreenIdentifier

    val walletSidebarId = "walletSideBar"
    Container(
        fluid = true,
        attrs = {
            classes("h-100")
        }
    ) {
        Column(
            attrs = {
                classes("h-100", "d-flex", "flex-column")
            }
        ) {
            Row(
                attrs = { classes("flex-grow-1", "overflow-scroll") }
            ) {
                Main(
                    attrs = {
                        classes("col")
                    }
                ) {
                    screenStackToNavigationState[ScreenStack.Header]!!.topScreenIdentifier.let { sId ->
                        saveableStateHolder.SaveableStateProvider(sId.URI) {
                            ScreenPicker(ScreenStack.Header, sId)
                        }
                    }
                    when (MainStackL1ScreenTab.getScreenTab(currentMainStackL1ScreenId.URI)) {
                        MainStackL1ScreenTab.Library -> LibraryTabRow()
                        MainStackL1ScreenTab.Releaser -> ReleaserTabRow()
                        else -> {}
                    }
                    Row {
                        screenStackToNavigationState[ScreenStack.Main]!!.topScreenIdentifier.let { sId ->
                            saveableStateHolder.SaveableStateProvider(sId.URI) {
                                ScreenPicker(ScreenStack.Main, sId)
                            }
                        }
                    }
                }
            }

            // Media player
            Row(
                attrs = {
                    style {
                        property("box-shadow", "rgba(0, 0, 0, 1.0)0px 5px 15px")
                    }
                },
                styling = {
                    Background {
                        color = Color.Body
                    }

                }
            ) {
                screenStackToNavigationState[ScreenStack.MPlayer]!!.topScreenIdentifier.let { sId ->
                    saveableStateHolder.SaveableStateProvider(sId.URI) {
                        ScreenPicker(ScreenStack.MPlayer, sId)
                    }
                }
            }

            // Menu
            screenStackToNavigationState[ScreenStack.BottomNavigationBar]!!.topScreenIdentifier.let { sId ->
                saveableStateHolder.SaveableStateProvider(sId.URI) {
                    ScreenPicker(ScreenStack.BottomNavigationBar, sId)
                }
            }
        }

    }
    if (state.walletScreenExpanded) {
        Container(
            fluid = true,
            attrs = {
                classes("absolute-full-overlay")
            }
        ) {
            Column(attrs = { classes("h-100") }) {
                Row(attrs = { classes("h-100") }) {
                    Column(
                        auto = true,
                        attrs = {
                            style {
                                backgroundColor(ScrimOverlayBg)
                                height(100.percent)
                            }
                            onClick {
                                stateManager.events.setWalletScreenExpanded(false)
                            }
                            classes("flex-grow-1")
                        }) {}
                    SidebarColumn(
                        walletSidebarId,
                        state.walletScreenExpanded,
                        sizes = ColumnSizes(ColumnSize.All.auto()),
                        applyStyle = {
                            height(100.percent)
                            paddingTop(40.px)
                            backgroundColor(BackgroundDarkest)
                        },
                        applyAttrs = { classes("d-flex", "flex-column", "px-4") },
                    ) {
                        screenStackToNavigationState[ScreenStack.Wallet]!!.topScreenIdentifier.let { sId ->
                            saveableStateHolder.SaveableStateProvider(sId.URI) {
                                ScreenPicker(ScreenStack.Wallet, sId)
                            }
                        }
                        SocialMediaIconsRow(applyAttrs = { classes("mt-auto", "mb-3") })
                    }
                }
            }
        }
    }
}