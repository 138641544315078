package io.eqoty.shared.datalayer.functions

import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.sources.web3.InternalWeb3Provider

suspend fun Repository.addAccountToWallet(hexPrivateKeyOrMnemonic: String) = withRepoContext {
    val address = when (val provider = web3Provider) {
        is InternalWeb3Provider<*> -> {
            provider.addAccountToWallet(hexPrivateKeyOrMnemonic)
        }

        else -> throw UnsupportedOperationException("addAccountToWallet with unhandled web3Provider: $web3Provider")
    }
    return@withRepoContext address
}

suspend fun Repository.addAccountToWallet(privKey: UByteArray) = withRepoContext {
    val address = when (val provider = web3Provider) {
        is InternalWeb3Provider<*> -> {
            provider.addAccountToWallet(privKey)
        }

        else -> throw UnsupportedOperationException("addAccountToWallet with unhandled web3Provider: $web3Provider")
    }
    return@withRepoContext address
}