package io.eqoty.shared.viewmodel.screens.myorganizations

import io.eqoty.secret.std.contract.msg.EqotyReleaseMsgs
import io.eqoty.shared.viewmodel.ScreenState

// here is the data class defining the state for this screen

data class MyOrganizationsScreenState(
    val loading: Boolean = true,
    val isConnected: Boolean = false,
    val organizations: List<EqotyReleaseMsgs.Organization> = emptyList(),
) : ScreenState

