package io.eqoty.composables.screens.home


import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.*
import io.eqoty.composables.resources.ImageResources
import io.eqoty.composables.styling.Background
import io.eqoty.composables.styling.BackgroundDarkest
import dev.icerock.moko.resources.compose.stringResource
import io.eqoty.composables.views.SocialMediaIconsRow
import io.eqoty.shared.ui.utils.BootstrapBreakpoint
import io.eqoty.shared.ui.utils.ScreenSizeUtils
import io.eqoty.shared.ui.utils.smallScaffoldActive
import io.eqoty.shared.viewmodel.screens.home.FeatureCard
import io.eqoty.shared.viewmodel.screens.home.HomeState
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement

private val circleCenterColor = Color("#1a1a1a")

@Composable
fun HomeScreen(
    state: HomeState,
    onClickOpenStore: () -> Unit
) {
    Column(attrs = { style { backgroundImage("linear-gradient(90deg, $Background, $BackgroundDarkest)") } }) {}
    Column(auto = true) {
        val contentMaxWidth = if (ScreenSizeUtils.smallScaffoldActive) {
            BootstrapBreakpoint.Sm.px.px
        } else BootstrapBreakpoint.Xxl.px.px
        TopBannerRow(contentMaxWidth, onClickOpenStore)
        BasicFeaturesRow("Our decentralized app puts artists in control with of their music with simple, powerful, and fair features", state.distributionFeatureCards, contentMaxWidth)
        BasicFeaturesRow("The first content protected marketplace for utility backed music NFTs", state.basicFeatureCards, contentMaxWidth)
        BlurbRow(contentMaxWidth)
        PerkFeaturesRow(state.perkFeatureCards, contentMaxWidth)
        PreviewVideoRow(contentMaxWidth)
        BottomRow(contentMaxWidth)
    }
    Column(attrs = { style { backgroundImage("linear-gradient(90deg, $BackgroundDarkest, $Background)") } }) {}
}


@Composable
private fun TopBannerRow(contentMaxWidth: CSSNumeric, onClickOpenStore: () -> Unit) {
    Row(attrs = { classes("justify-content-center", "px-0") }) {
        val homepageHeader = if (ScreenSizeUtils.smallScaffoldActive) {
            ImageResources.homeBannerSm
        } else ImageResources.homeBannerLg
        // prevent flickering while image loads by forcing the aspect ratio to match the image that will load
        val bannerAspectRatio = if (ScreenSizeUtils.smallScaffoldActive) 1080.0 / 1350.0 else 2112.0 / 1024
        Div(attrs = {
            classes("card", "border-0", "p-0")
            style {
                maxWidth(contentMaxWidth)
            }
        }) {
            Img(src = homepageHeader, attrs = {
                classes("card-img")
                style {
                    maxWidth(contentMaxWidth)
                    height(100.percent)
                    width(100.percent)
                    variable("aspect-ratio", bannerAspectRatio)
                    variable("object-fit", "contain")
                }
            })
            Div(attrs = { classes("card-img-overlay", "p-5") }) {
                Div(attrs = { classes("card-title") }) {
                    Img(
                        attrs = {
                            style { width(15.cssRem) }
                        },
                        src = ImageResources.eqotyLogoWhite
                    )
                }
                H3(attrs = {
                    classes("card-text", "px-2")
                    style {
                        width(20.cssRem)
                    }
                }
                ) {
                    Text("Distribute music anywhere, tokenize your royalties, and unlock new revenue paths.")
                }
                Button("Browse *Testnet* Store", attrs = { classes("ms-2", "mt-4") }) {
                    onClickOpenStore()
                }

            }
        }

    }
}


@Composable
private fun BasicFeaturesRow(heading: String, featureCards: List<FeatureCard>, contentMaxWidth: CSSNumeric) {
    Row(attrs = { classes("justify-content-center", "px-0") }) {
        Column(attrs = {
            style {
                maxWidth(contentMaxWidth)
                backgroundImage("radial-gradient(circle, $circleCenterColor, $BackgroundDarkest)")
            }
            classes("d-flex", "flex-column", "align-items-center", "justify-content-center")
        }) {
            Row(attrs = { style { height(150.px) } }) {}
            Row(attrs = {
                style { maxWidth(BootstrapBreakpoint.Lg.px.px) }
                classes("justify-content-center", "p-5")
            }) {
                H3(attrs = {
                    style {
                        textAlign("center")
                    }
                }) {
                    Text(heading)
                }
            }
            CardGridRow(featureCards)
            Row(attrs = { style { height(150.px) } }) {}
        }
    }
}

@Composable
private fun BlurbRow(contentMaxWidth: CSSNumeric) {
    Row(attrs = { classes("justify-content-center", "px-0") }) {
        Column(attrs = {
            style {
                maxWidth(contentMaxWidth)
                height(400.px)
                backgroundImage("radial-gradient(circle, $circleCenterColor, $BackgroundDarkest)")
            }
            classes("d-flex", "flex-column", "align-items-center", "justify-content-center")
        }) {
            Row(attrs = {
                style { maxWidth(BootstrapBreakpoint.Lg.px.px) }
                classes("justify-content-center", "pt-5", "fst-italic")
            }) {
                H3(attrs = {
                    style {
                        textAlign("center")
                    }
                }) {
                    Text("\"Music NFTs are just collectibles\"")
                }
            }
            Row(attrs = {
                style { maxWidth(BootstrapBreakpoint.Lg.px.px) }
                classes("justify-content-center", "p-5")
            }) {
                H4(attrs = {
                    style {
                        textAlign("center")
                    }
                }) {
                    Text("Think again. We make it easy for artists to release music NFTs with real tangible value for their fans.")
                    //  a digital music store for musicians to release high quality protected music, sell or lease music copyright licenses, and provide perks for fans. All verifiably tracked on-chain while maintaining users and artists privacy.
                }
            }
        }
    }
}


@Composable
private fun PerkFeaturesRow(featureCards: List<FeatureCard>, contentMaxWidth: CSSNumeric) {
    Row(attrs = { classes("justify-content-center", "px-0") }) {
        Column(attrs = {
            style {
                maxWidth(contentMaxWidth)
                backgroundImage("radial-gradient(circle, $circleCenterColor, $BackgroundDarkest)")
            }
            classes("d-flex", "flex-column", "align-items-center", "justify-content-center")
        }) {
            Row(attrs = {
                style { maxWidth(BootstrapBreakpoint.Lg.px.px) }
                classes("justify-content-center", "p-5")
            }) {
                H3(attrs = {
                    style {
                        textAlign("center")
                    }
                }) {
                    Text("Utility backed features:")
                }
            }
            CardGridRow(featureCards)
            Row(attrs = { style { height(50.px) } }) {}
        }
    }
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
private fun BottomRow(contentMaxWidth: CSSNumeric) {
    Row(attrs = { classes("justify-content-center", "px-0") }) {
        Column(attrs = {
            style {
                maxWidth(contentMaxWidth)
                backgroundImage("radial-gradient(circle, $circleCenterColor, $BackgroundDarkest)")
            }
            classes("d-flex", "flex-column", "align-items-center", "justify-content-center")
        }) {
            Row(attrs = { style { height(50.px) } }) {}
            Row(attrs = {
                style { maxWidth(BootstrapBreakpoint.Lg.px.px) }
                classes("justify-content-center", "p-5")
            }) {
                H3(attrs = {
                    style {
                        textAlign("center")
                    }
                }) {
                    Text("\uD83D\uDEA7 Under Construction \uD83D\uDEA7")
                }
            }
            Row(attrs = {
                style { maxWidth(BootstrapBreakpoint.Lg.px.px) }
                classes("justify-content-center", "p-5")
            }) {
                H4(attrs = {
                    style {
                        textAlign("center")
                    }
                }) {
                    Text("For More Info Contact us:")
                }
            }
            SocialMediaIconsRow(applyAttrs = {
                style {
                    transform {
                        scale(2)
                    }
                }
            })
            Row(attrs = { style { height(50.px) } }) {}
        }
    }
}

@Composable
private fun CardGridRow(
    featureCards: List<FeatureCard>,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
    val columnDisplayRules = arrayOf(
        "col-11",
        "col-md-6",
        "col-lg-4",
    )
    Row(attrs = {
        classes("justify-content-center", "w-100")
        attrs?.invoke(this)
    }) {
        featureCards.forEach { featureCard ->
            Column(attrs = {
                classes(*columnDisplayRules, "g-4")
            }) {
                Card(
                    attrs = { style { minHeight(200.px) }; classes("d-flex", "flex-column") },
                    bodyAttrs = { classes("d-flex", "flex-column") },
                ) {
                    H3(attrs = { classes("card-title") }) { Text(stringResource(featureCard.header)) }
                    H4(attrs = { classes("card-text", "my-auto") }) { Text(stringResource(featureCard.body)) }
                }
            }
        }
    }
}


@Composable
private fun PreviewVideoRow(contentMaxWidth: CSSNumeric) {
    Row(attrs = { classes("justify-content-center", "px-0") }) {
        Column(attrs = {
            style {
                maxWidth(contentMaxWidth)
                backgroundImage("radial-gradient(circle, $circleCenterColor, $BackgroundDarkest)")
            }
            classes("d-flex", "flex-column", "align-items-center", "justify-content-center")
        }) {
            Row(attrs = { style { height(50.px) } }) {}
            Row(attrs = {
                style { maxWidth(BootstrapBreakpoint.Lg.px.px) }
                classes("justify-content-center", "pt-5", "pb-1")
            }) {
                H3(attrs = {
                    style {
                        textAlign("center")
                    }
                }) {
                    Text("Want to learn more? Checkout our video featured in Secret Summit 2022:")
                }
            }
            Row(attrs = {
                style {
                    width(100.percent)
                    maxWidth(BootstrapBreakpoint.Lg.px.px)
                }
                classes("justify-content-center", "pt-5")
            }) {
                Video(attrs = {
                    attr("controls", "")
                    attr("crossorigin", "")
                    attr(
                        "src",
                        "https://bafybeic72tvd4jk7dyqm3xq5oyvhdk43jzcu63etdoys4bw63lsq6fthmq.ipfs.nftstorage.link"
                    )
                }) {}
            }
            Row(attrs = { style { height(50.px) } }) {}
        }
    }
}
