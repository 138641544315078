package io.eqoty.shared.datalayer

import app.cash.sqldelight.ColumnAdapter
import app.cash.sqldelight.db.SqlDriver
import com.ionspin.kotlin.bignum.integer.BigInteger
import com.russhwolf.settings.Settings
import io.eqoty.cosmwasm.std.types.Coin
import io.eqoty.kryptools.aes256gcm.Aes256Gcm
import io.eqoty.secretk.client.SigningCosmWasmClient
import io.eqoty.secretk.utils.ensureLibsodiumInitialized
import io.eqoty.shared.datalayer.objects.AttachmentAccess
import io.eqoty.shared.datalayer.sources.filesystem.SYSTEM
import io.eqoty.shared.datalayer.sources.ipfs.IPFSHTTPClient
import io.eqoty.shared.datalayer.sources.localdb.nfts.*
import io.eqoty.shared.datalayer.sources.localsettings.MySettings
import io.eqoty.shared.datalayer.sources.runtimecache.CacheObjects
import io.eqoty.shared.datalayer.sources.web3.*
import io.eqoty.shared.datalayer.sources.webservices.ApiClient
import io.eqoty.shared.datalayer.sources.webservices.DownloadManager
import io.eqoty.shared.datalayer.utils.bi10
import kotlinx.coroutines.*
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import mylocal.db.LocalDb

class Repository(
    val sqlDriver: SqlDriver,
    val settings: Settings = Settings(),
    internal val web3Providers: Map<WalletProvider, Web3Provider>,
    val useDefaultDispatcher: Boolean = true
) {
    suspend fun initialize() {
        ensureLibsodiumInitialized()
    }

    internal val webservices by lazy { ApiClient() }
    internal val downloadManager by lazy { DownloadManager() }
    internal val aes256Gcm by lazy { Aes256Gcm() }

    val secretNetworkToClient: Map<Web3Network, SigningCosmWasmClient> = mutableMapOf()
    fun getClient(network: Web3Network): SigningCosmWasmClient {
        val client = (secretNetworkToClient as MutableMap).getOrPut(network) {
            SigningCosmWasmClient(
                SecretEndpoints.scrtGrpcGatewayEndpoint, null, chainId = network.id
            )
        }
        (web3Provider as? CosmosWeb3Provider)?.let { cosmosWeb3Provider ->
            client.wallet = cosmosWeb3Provider.wallet
            cosmosWeb3Provider.encryptionUtils?.let {
                client.setEncryptionUtils(it)
            }
        }
        return client
    }

    internal var selectedWalletProvider: WalletProvider? = null
    internal val web3Provider: Web3Provider?
        get() = web3Providers[selectedWalletProvider]

    internal val walletAddressChangeFlow = MutableSharedFlow<String?>(0)

    internal val ipfsClient by lazy { IPFSHTTPClient() }

    internal val localDb by lazy {
        val bigIntegerColumnAdapter = object : ColumnAdapter<BigInteger, String> {
            override fun decode(databaseValue: String): BigInteger {
                return databaseValue.bi10
            }

            override fun encode(value: BigInteger): String {
                return value.toString(10)
            }
        }
        val intToLongColumnAdapter = object : ColumnAdapter<Int, Long> {
            override fun decode(databaseValue: Long): Int {
                return databaseValue.toInt()
            }

            override fun encode(value: Int): Long {
                return value.toLong()
            }
        }
        val uIntToLongColumnAdapter = object : ColumnAdapter<UInt, Long> {
            override fun decode(databaseValue: Long): UInt {
                return databaseValue.toUInt()
            }

            override fun encode(value: UInt): Long {
                return value.toLong()
            }
        }
        val uLongToLongColumnAdapter = object : ColumnAdapter<ULong, Long> {
            override fun decode(databaseValue: Long): ULong {
                return databaseValue.toULong()
            }

            override fun encode(value: ULong): Long {
                return value.toLong()
            }
        }
        val stringListAdapter = object : ColumnAdapter<List<String>, String> {
            val seperator = "|,|"
            override fun decode(databaseValue: String): List<String> {
                return databaseValue.split(seperator)
            }

            override fun encode(value: List<String>): String {
                return value.joinToString(seperator)
            }
        }
        val coinListAdapter = object : ColumnAdapter<List<Coin>, String> {
            override fun decode(databaseValue: String): List<Coin> {
                return Json.decodeFromString(databaseValue)
            }

            override fun encode(value: List<Coin>): String {
                return Json.encodeToString(value)
            }
        }
        val attachmentAccessColumnAdapter = object : ColumnAdapter<AttachmentAccess, String> {
            override fun decode(databaseValue: String): AttachmentAccess {
                val decoded: AttachmentAccess = Json.decodeFromString(databaseValue)
                return decoded
            }

            override fun encode(value: AttachmentAccess): String {
                val encoded = Json.encodeToString(value)
                return encoded
            }
        }
        LocalDb(
            sqlDriver, ReleaseTableAdapter = ReleaseTable.Adapter(
                uIntToLongColumnAdapter, uLongToLongColumnAdapter
            ), RoyaltyNftTableAdapter = RoyaltyNftTable.Adapter(
                bigIntegerColumnAdapter, coinListAdapter, uIntToLongColumnAdapter
            ), PurchaseNftTableAdapter = PurchaseNftTable.Adapter(
                purchaseCtrkReleaseIdAdapter = uIntToLongColumnAdapter,
            ), RemoteAudioResourceTableAdapter = RemoteAudioResourceTable.Adapter(
                attachmentAccessColumnAdapter,
                intToLongColumnAdapter,
                intToLongColumnAdapter,
                intToLongColumnAdapter,
                intToLongColumnAdapter,
                intToLongColumnAdapter,
            ), ProductDealerContractTableAdapter = ProductDealerContractTable.Adapter(
                releaseIdAdapter = uIntToLongColumnAdapter, pricesAdapter = coinListAdapter
            ), NftMetadataTableAdapter = NftMetadataTable.Adapter(
                artistsAdapter = stringListAdapter
            )
        )
    }
    internal val localSettings by lazy { MySettings(settings) }
    internal val runtimeCache by lazy { CacheObjects() }
    internal val fileSystem = SYSTEM

    // we run each repository function on a Dispatchers.Default coroutine
    // we pass useDefaultDispatcher=false just for the TestRepository instance
    // this executes in the background and waits to return result
    suspend fun <T> withRepoContext(block: suspend () -> T): T {
        return if (useDefaultDispatcher) {
            withContext(Dispatchers.Default) {
                block()
            }
        } else {
            block()
        }
    }

    // this executes in the background and returns immediately to with the created Job
    suspend fun <T> withBackgroundRepoContext(scope: CoroutineScope, block: suspend () -> T): Job =
        scope.launch(Dispatchers.Default) {
            block()
        }

}