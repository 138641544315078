package io.eqoty.shared.viewmodel.screens.mintsong

import io.eqoty.shared.datalayer.sources.filesystem.CommonFile
import io.eqoty.shared.datalayer.sources.web3.Web3Network
import io.eqoty.shared.viewmodel.ScreenState
import io.eqoty.shared.viewmodel.utils.bytesToMegabytes

// here is the data class defining the state for this screen

data class MintSongScreenState(
    val selectedOrganizationId: ULong,
    val selectedOrganizationName: String,
    val mintErrorMessage: String? = null,
    val privateAudioFile: CommonFile? = null,
    val privateAudioDuration: Long? = null,
    val publicAudioFile: CommonFile? = null,
    val publicAudioDuration: Long? = null,
    val albumArtFile: CommonFile? = null,
    val nftName: String? = null,
    val nftDesc: String? = null,
    val mintProgress: String? = null,
    val txHash: String? = null,
    val albumArtUrl: String? = null,
    val autofillFromFileInfo: Boolean = false,
    val validationResult: MintSongFormInputErrors = MintSongFormInputErrors(),
    val currentNetwork: Web3Network? = null,
    val interactionEnabled: Boolean = true,
) : ScreenState {
    val privateFileName = privateAudioFile?.name?.substringBeforeLast('.', "")
    val privateAudioSizeLabel = privateAudioFile?.let { "Size: ${privateAudioFile.size.bytesToMegabytes()} MB" }
    val publicAudioSizeLabel = publicAudioFile?.let { "Size: ${publicAudioFile.size.bytesToMegabytes()} MB" }
}