package io.eqoty.shared.viewmodel.screens.myroyalties

import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.objects.Release
import io.eqoty.shared.datalayer.objects.RoyaltyNft
import io.eqoty.shared.datalayer.utils.bi10
import io.eqoty.shared.viewmodel.ScreenState
import io.eqoty.shared.viewmodel.screens.generic.TrackRowData
import io.eqoty.shared.viewmodel.utils.baseUnitToStdUnit
import io.eqoty.shared.viewmodel.utils.stdUnitDenom

// here is the data class defining the state for this screen

data class MyRoyaltiesState(
    val isConnected: Boolean = false,
    val isLoading: Boolean = false,
    val listType: MyRoyaltiesType,
    val myRoyaltiesListItems: List<MyRoyaltiesListItem> = emptyList(),
) : ScreenState {
    init {
        myRoyaltiesListItems.forEach { releaseItem ->
            releaseItem.royaltySubListItems = releaseItem.royaltySubListItems.filter { sublistItem ->
                if (listType == MyRoyaltiesType.FOR_SALE) {
                    sublistItem.isForSale
                } else {
                    true
                }
            }
        }
    }
}

/********** property classes **********/

enum class MyRoyaltiesType { ALL, FOR_SALE, NOT_FOR_SALE }

data class MyRoyaltiesListItem(
    private val _release: Release,
    private val _royalties: List<RoyaltyNft>,
) {
    // in the ViewModel classes, our computed properties only do UI-formatting operations
    // (the arithmetical operations, such as calculating a percentage, should happen in the DataLayer classes)
    val id = _release.id
    val releaseIdDispTxt = "id: ${_release.id}"
    val trackRowData = TrackRowData(_release, null)
    var royaltySubListItems = _royalties.map {
        MyRoyaltiesNftRoyaltySubListItem(_release, it)
    }
}

data class MyRoyaltiesNftRoyaltySubListItem(
    private val _release: Release,
    private val _royalty: RoyaltyNft,
) {
    // in the ViewModel classes, our computed properties only do UI-formatting operations
    // (the arithmetical operations, such as calculating a percentage, should happen in the DataLayer classes)
    val id = _royalty.id
    val royaltyIdDispTxt = "rid: ${_royalty.id}"

    val isOwner = _royalty.isOwner
    val isForSale = true
    val tokenPercent = "${(_royalty.royaltyPercent.intValue().toDouble() / 1_000_000.0) * 100.0}"
    val tokenShares = _royalty.royaltyPercent.uintValue()
    val showClaimBalanceBtn = _royalty.claimableBalances.any { it.amount.bi10 > BigInteger.ZERO }
    val firstClaimableBalance = _royalty.claimableBalances.firstOrNull()
    val claimableBalanceBtnText =
        "Claim: ${firstClaimableBalance?.baseUnitToStdUnit()} ${firstClaimableBalance?.stdUnitDenom}"

    val rowText = "Royalty = $tokenPercent%"
}