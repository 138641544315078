package io.eqoty.shared.viewmodel

import io.eqoty.shared.viewmodel.screens.ScreenStack
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow

class StateProvider(val stateManager: StateManager) {

    fun getScreenStateFlow(screenStack: ScreenStack, screenIdentifier: ScreenIdentifier): StateFlow<ScreenState> {
        //debugLogger.log("getScreenStateFlow: "+screenIdentifier.URI)
        return stateManager.screenStackToScreenStatesMap[screenStack]!![screenIdentifier.URI]!!.asStateFlow()
    }

}



