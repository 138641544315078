package io.eqoty.shared.datalayer.functions

import CreateFFmpegOptionsInstance
import co.touchlab.kermit.Logger
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.AudioFileAndInfo
import io.eqoty.shared.datalayer.sources.filesystem.CommonFile
import io.eqoty.shared.datalayer.sources.filesystem.nameWithoutExtension
import jslib.ffmpeg.FFmpeg
import jslib.ffmpeg.createFFmpeg
import kotlinx.coroutines.await
import org.khronos.webgl.Uint8Array
import org.w3c.files.File
import org.w3c.files.FilePropertyBag


private var ffmpegBacking: FFmpeg? = null
private suspend fun getLoadedFfmpeg(): FFmpeg {
    if (ffmpegBacking == null) {
        ffmpegBacking = createFFmpeg(
            CreateFFmpegOptionsInstance(
                log = true, corePath = "https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js"
            )
        )
        ffmpegBacking!!.load().await()
    }
    return ffmpegBacking!!
}

actual suspend fun Repository.transcodeLosslessFileToAllFormats(
    inputAudioFileAndInfo: AudioFileAndInfo,
    clipRange: LongRange?,
    progress: (AudioTranscodeDefaults) -> Unit
): List<AudioFileAndInfo> =
    withRepoContext {
        // https://github.com/ffmpegwasm/ffmpeg.wasm/issues/202
        val inputFile = inputAudioFileAndInfo.file
        val inputAudioInfo = inputAudioFileAndInfo.info
        val ffmpeg = getLoadedFfmpeg()
        ffmpeg.FS(
            "writeFile", inputFile.name, inputFile.getBytes()
        )
        val outputs = mutableListOf<AudioFileAndInfo>()
        val includeInputAudioFormat = clipRange != null
        val filteredFormats = getFilteredTranscodeDefaults(inputAudioInfo, includeInputAudioFormat, true)
        if (!includeInputAudioFormat) {
            // Not need to transcode if a clipRange is not specified. Use the original lossless file.
            outputs.add(inputAudioFileAndInfo)
        }
        for (format in filteredFormats) {
            progress(format)
            val outputFileName = format.buildFileName(clipRange, inputFile.nameWithoutExtension)
            ffmpeg.run(
                *format.inputFfmpegArgs(clipRange),
                "-i", inputFile.name,
                *format.outputFfmpegArgs(clipRange),
                outputFileName
            ).await()
            /*
            * Run FS operations.
            * For input/output file of ffmpeg.wasm, it is required to save them to MEMFS
            * first so that ffmpeg.wasm is able to consume them. Here we rely on the FS
            * methods provided by Emscripten.
            */
            val bytes = (ffmpeg.FS("readFile", outputFileName) as Uint8Array) // readFile from MEMFS.
            val newFile = CommonFile(
                File(
                    arrayOf(bytes), outputFileName, FilePropertyBag(
                        type = "octet/stream"
                    )
                )
            )
            outputs.add(AudioFileAndInfo(newFile, getAudioInfo(newFile)))
            ffmpeg.FS("unlink", outputFileName) // delete file from MEMFS.

        }
        ffmpeg.FS("unlink", inputFile.name) // delete file from MEMFS.
        Logger.d(outputs.toString())
        return@withRepoContext outputs
    }

