package io.michaelrocks.libphonenumber

import dev.icerock.moko.graphics.Color
import dev.icerock.moko.resources.AssetResource
import dev.icerock.moko.resources.ColorResource
import dev.icerock.moko.resources.FileResource
import dev.icerock.moko.resources.FontResource
import dev.icerock.moko.resources.ImageResource
import dev.icerock.moko.resources.PluralsResource
import dev.icerock.moko.resources.ResourceContainer
import dev.icerock.moko.resources.StringResource
import kotlin.String
import kotlin.collections.List

public actual object MR {
  private val contentHash: String = "5a03d05c378352eb895788f199b39fb0"

  public actual object strings : ResourceContainer<StringResource> {
    public override fun values(): List<StringResource> = listOf()
  }

  public actual object plurals : ResourceContainer<PluralsResource> {
    public override fun values(): List<PluralsResource> = listOf()
  }

  public actual object images : ResourceContainer<ImageResource> {
    public override fun values(): List<ImageResource> = listOf()
  }

  public actual object fonts : ResourceContainer<FontResource> {
    public override fun values(): List<FontResource> = listOf()
  }

  public actual object files : ResourceContainer<FileResource> {
    public override fun values(): List<FileResource> = listOf()
  }

  public actual object colors : ResourceContainer<ColorResource> {
    public override fun values(): List<ColorResource> = listOf()
  }

  public actual object assets : ResourceContainer<AssetResource> {
    public override fun values(): List<AssetResource> =
        listOf(io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MA,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BE,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_TW,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BB,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MF,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_AM,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_GT,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_QA,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_NI,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_AD,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MO,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_NG,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_AC,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MH,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BL,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_NU,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_GH,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MZ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_TK,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_HK,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_NR,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BY,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_TL,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_RU,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_KM,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MT,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_GF,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_373,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MS,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_808,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BW,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_380,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_GA,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_AX,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_NF,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BM,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_KP,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_NA,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_AE,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MN,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_KW,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BJ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MG,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_RO,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_TV,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_DZ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_AL,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_GU,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BD,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_KY,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_GR,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_NO,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_TC,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_DO,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MR,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_381,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_375,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MU,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BQ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_TD,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_NZ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_372,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_GG,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_GN,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_AW,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_KE,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_TM,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_GI,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_972,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_RS,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_TJ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_800,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_UG,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CR,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_LV,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_JO,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_94,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CU,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SY,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_58,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_IN,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_FJ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_ZM,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_EE,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_FM,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_VA,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_YE,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CI,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SE,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_PM,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SB,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CN,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_LC,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SK,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CG,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SL,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_34,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_IR,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_PY,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SV,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_EC,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CZ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_VG,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_FK,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_IO,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_LY,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_66,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SX,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_UA,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_VN,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_PW,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_95,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_61,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_VI,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CA,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_855,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SM,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_IS,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SJ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_US,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CF,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_LB,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_ZW,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_PE,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_OM,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_IT,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_PL,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_VU,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_UZ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SC,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_255,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CO,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_LK,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_PK,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CH,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SD,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MA,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BE,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GT,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_AM,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_QA,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_NI,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_TW,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BB,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MF,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MO,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_AD,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MH,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BL,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_NG,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_AC,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MZ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_NU,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GH,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_43,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_676,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BY,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_RU,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_TL,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_44,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_NR,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_HK,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GF,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MT,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_KM,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_AX,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GA,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MS,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_81,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BW,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BM,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_KP,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_NF,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_KW,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MN,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BJ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_NA,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_AE,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_27,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GU,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_AL,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MG,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_TV,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_RO,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_DZ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GR,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_NO,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BD,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_KY,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_TC,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_DO,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MR,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_NZ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_679,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GG,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MU,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BQ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_TD,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_KE,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_TM,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_AW,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GN,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_TJ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_RS,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GI,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_UG,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CR,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_JO,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_LV,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_359,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CU,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SY,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_506,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_ZM,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_350,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_EE,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_IN,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_FJ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_995,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_FM,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_VA,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_YE,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CI,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SE,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_878,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SB,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CN,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_PM,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_LC,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SK,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CG,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_IR,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SL,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_882,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SV,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CZ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_EC,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_PY,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_351,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_LY,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_VG,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_FK,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_PW,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_VN,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_UA,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SX,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_358,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_VI,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_IS,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CA,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SM,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_883,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_PE,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_IT,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_OM,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_US,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SJ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CF,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_LB,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_ZW,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_870,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SC,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_UZ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CO,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_LK,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_VU,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_PL,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CH,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SD,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_PK,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_GL,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_AU,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BZ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_TO,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_KG,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_AR,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MY,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_TH,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MP,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_KI,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_TA,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BT,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_DM,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_GB,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_DJ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BS,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_KN,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MW,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_GE,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_TT,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BA,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_ME,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_GW,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BF,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_HT,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_GP,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_AI,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_GY,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_KR,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MK,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BO,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_TZ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_AG,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_NC,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BH,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_ML,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MV,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_DK,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BR,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_TG,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_GD,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_385,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MQ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_KH,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_AZ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_XK,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_AS,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_WF,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_971,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_HN,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_DE,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MX,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_NP,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_GM,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_AT,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_TN,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_RW,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_AF,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_WS,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MM,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_RE,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BI,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_NE,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BN,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_BG,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_TR,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MC,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_KZ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_HU,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_NL,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_MD,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_HR,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_AO,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_39,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_LI,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_JP,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SA,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CM,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_ET,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_YT,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_ES,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CC,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SO,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_IQ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SH,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CD,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_30,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_FR,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_PG,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_LR,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_90,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CV,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_64,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SZ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_ID,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_63,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_EH,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_LU,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_PR,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_55,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_ST,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CX,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_JE,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_IM,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_VE,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_FI,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_52,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SS,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SI,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_LA,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_31,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_PF,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_856,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SN,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_PA,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_36,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_PH,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_ER,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CK,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SG,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_UY,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CL,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_IL,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_EG,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_SR,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_FO,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_54,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_VC,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_979,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CY,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_LT,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_JM,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_ZA,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_62,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_PS,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_CW,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_91,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_LS,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_PT,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_IE,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BZ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_TO,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_KG,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_AU,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GL,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MY,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_TH,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_AR,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_675,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GB,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_49,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_KI,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MP,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_DM,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BT,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GE,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BS,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_DJ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_7,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MW,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_KN,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GW,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_TT,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BA,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_ME,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_HT,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_AI,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GP,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BF,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MK,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_KR,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BO,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_TZ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GY,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BH,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_ML,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_AG,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_NC,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GD,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_84,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MV,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BR,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_DK,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_TG,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_AZ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_XK,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_KH,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MQ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_DE,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MX,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_AS,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_WF,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_HN,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_RW,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_TN,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_NP,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_AT,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GM,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MM,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_RE,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BI,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_AF,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_WS,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BN,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_NE,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_GQ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_NL,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_HU,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_BG,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_TR,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_KZ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MC,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_HR,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_AO,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_MD,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_JP,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_LI,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SA,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_ET,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CM,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_ES,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_888,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_YT,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_IQ,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CC,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SO,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_881,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_FR,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_PG,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SH,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CD,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_ID,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_LR,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CV,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SZ,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_PR,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_EH,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_LU,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_ST,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CX,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_JE,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_505,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SS,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_IM,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_VE,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_FI,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_PF,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SI,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_LA,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_PA,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_595,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SN,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CK,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_ER,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SG,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_PH,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_UY,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CL,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_EG,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_SR,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberAlternateFormatsProto_352,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_IL,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CY,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_FO,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_VC,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_PS,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_JM,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_LT,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_ZA,
        io.michaelrocks.libphonenumber.android.data.PhoneNumberMetadataProto_PT,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_IE,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_CW,
        io.michaelrocks.libphonenumber.android.data.ShortNumberMetadataProto_LS)

    public actual object io {
      public actual object michaelrocks {
        public actual object libphonenumber {
          public actual object android {
            public actual object `data` {
              public actual val ShortNumberMetadataProto_MA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MA")

              public actual val ShortNumberMetadataProto_BE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BE")

              public actual val PhoneNumberMetadataProto_TW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TW")

              public actual val ShortNumberMetadataProto_BB: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BB\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BB")

              public actual val ShortNumberMetadataProto_MF: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MF\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MF")

              public actual val ShortNumberMetadataProto_AM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AM")

              public actual val ShortNumberMetadataProto_GT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GT")

              public actual val PhoneNumberMetadataProto_QA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_QA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_QA")

              public actual val ShortNumberMetadataProto_NI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NI")

              public actual val ShortNumberMetadataProto_AD: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AD\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AD")

              public actual val ShortNumberMetadataProto_MO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MO")

              public actual val ShortNumberMetadataProto_NG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NG")

              public actual val ShortNumberMetadataProto_AC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AC")

              public actual val ShortNumberMetadataProto_MH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MH")

              public actual val ShortNumberMetadataProto_BL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BL")

              public actual val ShortNumberMetadataProto_NU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NU")

              public actual val ShortNumberMetadataProto_GH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GH")

              public actual val ShortNumberMetadataProto_MZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MZ")

              public actual val PhoneNumberMetadataProto_TK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TK")

              public actual val ShortNumberMetadataProto_HK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_HK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_HK")

              public actual val ShortNumberMetadataProto_NR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NR")

              public actual val ShortNumberMetadataProto_BY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BY")

              public actual val PhoneNumberMetadataProto_TL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TL")

              public actual val PhoneNumberMetadataProto_RU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_RU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_RU")

              public actual val ShortNumberMetadataProto_KM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KM")

              public actual val ShortNumberMetadataProto_MT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MT")

              public actual val ShortNumberMetadataProto_GF: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GF\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GF")

              public actual val PhoneNumberAlternateFormatsProto_373: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_373\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_373")

              public actual val ShortNumberMetadataProto_MS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MS")

              public actual val PhoneNumberMetadataProto_808: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_808\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_808")

              public actual val ShortNumberMetadataProto_BW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BW")

              public actual val PhoneNumberAlternateFormatsProto_380: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_380\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_380")

              public actual val ShortNumberMetadataProto_GA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GA")

              public actual val ShortNumberMetadataProto_AX: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AX\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AX")

              public actual val ShortNumberMetadataProto_NF: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NF\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NF")

              public actual val ShortNumberMetadataProto_BM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BM")

              public actual val ShortNumberMetadataProto_KP: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KP\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KP")

              public actual val ShortNumberMetadataProto_NA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NA")

              public actual val ShortNumberMetadataProto_AE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AE")

              public actual val ShortNumberMetadataProto_MN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MN")

              public actual val ShortNumberMetadataProto_KW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KW")

              public actual val ShortNumberMetadataProto_BJ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BJ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BJ")

              public actual val ShortNumberMetadataProto_MG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MG")

              public actual val PhoneNumberMetadataProto_RO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_RO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_RO")

              public actual val PhoneNumberMetadataProto_TV: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TV\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TV")

              public actual val ShortNumberMetadataProto_DZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_DZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_DZ")

              public actual val ShortNumberMetadataProto_AL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AL")

              public actual val ShortNumberMetadataProto_GU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GU")

              public actual val ShortNumberMetadataProto_BD: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BD\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BD")

              public actual val ShortNumberMetadataProto_KY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KY")

              public actual val ShortNumberMetadataProto_GR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GR")

              public actual val ShortNumberMetadataProto_NO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NO")

              public actual val PhoneNumberMetadataProto_TC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TC")

              public actual val ShortNumberMetadataProto_DO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_DO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_DO")

              public actual val ShortNumberMetadataProto_MR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MR")

              public actual val PhoneNumberAlternateFormatsProto_381: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_381\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_381")

              public actual val PhoneNumberAlternateFormatsProto_375: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_375\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_375")

              public actual val ShortNumberMetadataProto_MU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MU")

              public actual val ShortNumberMetadataProto_BQ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BQ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BQ")

              public actual val PhoneNumberMetadataProto_TD: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TD\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TD")

              public actual val ShortNumberMetadataProto_NZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NZ")

              public actual val PhoneNumberAlternateFormatsProto_372: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_372\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_372")

              public actual val ShortNumberMetadataProto_GG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GG")

              public actual val ShortNumberMetadataProto_GN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GN")

              public actual val ShortNumberMetadataProto_AW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AW")

              public actual val ShortNumberMetadataProto_KE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KE")

              public actual val PhoneNumberMetadataProto_TM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TM")

              public actual val ShortNumberMetadataProto_GI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GI")

              public actual val PhoneNumberAlternateFormatsProto_972: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_972\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_972")

              public actual val PhoneNumberMetadataProto_RS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_RS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_RS")

              public actual val PhoneNumberMetadataProto_TJ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TJ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TJ")

              public actual val PhoneNumberMetadataProto_800: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_800\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_800")

              public actual val ShortNumberMetadataProto_UG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_UG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_UG")

              public actual val PhoneNumberMetadataProto_CR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CR")

              public actual val PhoneNumberMetadataProto_LV: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LV\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LV")

              public actual val PhoneNumberMetadataProto_JO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_JO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_JO")

              public actual val PhoneNumberAlternateFormatsProto_94: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_94\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_94")

              public actual val PhoneNumberMetadataProto_CU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CU")

              public actual val ShortNumberMetadataProto_SY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SY")

              public actual val PhoneNumberAlternateFormatsProto_58: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_58\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_58")

              public actual val PhoneNumberMetadataProto_IN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IN")

              public actual val PhoneNumberMetadataProto_FJ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_FJ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_FJ")

              public actual val ShortNumberMetadataProto_ZM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ZM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ZM")

              public actual val PhoneNumberMetadataProto_EE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_EE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_EE")

              public actual val PhoneNumberMetadataProto_FM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_FM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_FM")

              public actual val ShortNumberMetadataProto_VA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_VA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_VA")

              public actual val ShortNumberMetadataProto_YE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_YE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_YE")

              public actual val PhoneNumberMetadataProto_CI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CI")

              public actual val ShortNumberMetadataProto_SE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SE")

              public actual val ShortNumberMetadataProto_PM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PM")

              public actual val ShortNumberMetadataProto_SB: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SB\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SB")

              public actual val PhoneNumberMetadataProto_CN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CN")

              public actual val PhoneNumberMetadataProto_LC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LC")

              public actual val ShortNumberMetadataProto_SK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SK")

              public actual val PhoneNumberMetadataProto_CG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CG")

              public actual val ShortNumberMetadataProto_SL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SL")

              public actual val PhoneNumberAlternateFormatsProto_34: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_34\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_34")

              public actual val PhoneNumberMetadataProto_IR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IR")

              public actual val ShortNumberMetadataProto_PY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PY")

              public actual val ShortNumberMetadataProto_SV: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SV\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SV")

              public actual val PhoneNumberMetadataProto_EC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_EC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_EC")

              public actual val PhoneNumberMetadataProto_CZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CZ")

              public actual val ShortNumberMetadataProto_VG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_VG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_VG")

              public actual val PhoneNumberMetadataProto_FK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_FK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_FK")

              public actual val PhoneNumberMetadataProto_IO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IO")

              public actual val PhoneNumberMetadataProto_LY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LY")

              public actual val PhoneNumberAlternateFormatsProto_66: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_66\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_66")

              public actual val ShortNumberMetadataProto_SX: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SX\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SX")

              public actual val ShortNumberMetadataProto_UA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_UA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_UA")

              public actual val ShortNumberMetadataProto_VN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_VN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_VN")

              public actual val ShortNumberMetadataProto_PW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PW")

              public actual val PhoneNumberAlternateFormatsProto_95: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_95\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_95")

              public actual val PhoneNumberAlternateFormatsProto_61: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_61\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_61")

              public actual val ShortNumberMetadataProto_VI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_VI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_VI")

              public actual val PhoneNumberMetadataProto_CA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CA")

              public actual val PhoneNumberAlternateFormatsProto_855: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_855\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_855")

              public actual val ShortNumberMetadataProto_SM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SM")

              public actual val PhoneNumberMetadataProto_IS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IS")

              public actual val ShortNumberMetadataProto_SJ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SJ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SJ")

              public actual val ShortNumberMetadataProto_US: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_US\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_US")

              public actual val PhoneNumberMetadataProto_CF: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CF\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CF")

              public actual val PhoneNumberMetadataProto_LB: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LB\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LB")

              public actual val ShortNumberMetadataProto_ZW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ZW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ZW")

              public actual val ShortNumberMetadataProto_PE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PE")

              public actual val PhoneNumberMetadataProto_OM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_OM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_OM")

              public actual val PhoneNumberMetadataProto_IT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IT")

              public actual val ShortNumberMetadataProto_PL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PL")

              public actual val ShortNumberMetadataProto_VU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_VU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_VU")

              public actual val ShortNumberMetadataProto_UZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_UZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_UZ")

              public actual val ShortNumberMetadataProto_SC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SC")

              public actual val PhoneNumberAlternateFormatsProto_255: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_255\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_255")

              public actual val PhoneNumberMetadataProto_CO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CO")

              public actual val PhoneNumberMetadataProto_LK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LK")

              public actual val ShortNumberMetadataProto_PK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PK")

              public actual val PhoneNumberMetadataProto_CH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CH")

              public actual val ShortNumberMetadataProto_SD: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SD\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SD")

              public actual val PhoneNumberMetadataProto_MA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MA")

              public actual val PhoneNumberMetadataProto_BE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BE")

              public actual val PhoneNumberMetadataProto_GT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GT")

              public actual val PhoneNumberMetadataProto_AM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AM")

              public actual val ShortNumberMetadataProto_QA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_QA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_QA")

              public actual val PhoneNumberMetadataProto_NI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NI")

              public actual val ShortNumberMetadataProto_TW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TW")

              public actual val PhoneNumberMetadataProto_BB: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BB\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BB")

              public actual val PhoneNumberMetadataProto_MF: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MF\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MF")

              public actual val PhoneNumberMetadataProto_MO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MO")

              public actual val PhoneNumberMetadataProto_AD: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AD\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AD")

              public actual val PhoneNumberMetadataProto_MH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MH")

              public actual val PhoneNumberMetadataProto_BL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BL")

              public actual val PhoneNumberMetadataProto_NG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NG")

              public actual val PhoneNumberMetadataProto_AC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AC")

              public actual val PhoneNumberMetadataProto_MZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MZ")

              public actual val PhoneNumberMetadataProto_NU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NU")

              public actual val PhoneNumberMetadataProto_GH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GH")

              public actual val PhoneNumberAlternateFormatsProto_43: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_43\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_43")

              public actual val PhoneNumberAlternateFormatsProto_676: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_676\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_676")

              public actual val PhoneNumberMetadataProto_BY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BY")

              public actual val ShortNumberMetadataProto_RU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_RU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_RU")

              public actual val ShortNumberMetadataProto_TL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TL")

              public actual val PhoneNumberAlternateFormatsProto_44: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_44\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_44")

              public actual val PhoneNumberMetadataProto_NR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NR")

              public actual val PhoneNumberMetadataProto_HK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_HK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_HK")

              public actual val PhoneNumberMetadataProto_GF: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GF\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GF")

              public actual val PhoneNumberMetadataProto_MT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MT")

              public actual val PhoneNumberMetadataProto_KM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KM")

              public actual val PhoneNumberMetadataProto_AX: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AX\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AX")

              public actual val PhoneNumberMetadataProto_GA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GA")

              public actual val PhoneNumberMetadataProto_MS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MS")

              public actual val PhoneNumberAlternateFormatsProto_81: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_81\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_81")

              public actual val PhoneNumberMetadataProto_BW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BW")

              public actual val PhoneNumberMetadataProto_BM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BM")

              public actual val PhoneNumberMetadataProto_KP: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KP\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KP")

              public actual val PhoneNumberMetadataProto_NF: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NF\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NF")

              public actual val PhoneNumberMetadataProto_KW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KW")

              public actual val PhoneNumberMetadataProto_MN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MN")

              public actual val PhoneNumberMetadataProto_BJ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BJ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BJ")

              public actual val PhoneNumberMetadataProto_NA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NA")

              public actual val PhoneNumberMetadataProto_AE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AE")

              public actual val PhoneNumberAlternateFormatsProto_27: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_27\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_27")

              public actual val PhoneNumberMetadataProto_GU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GU")

              public actual val PhoneNumberMetadataProto_AL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AL")

              public actual val PhoneNumberMetadataProto_MG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MG")

              public actual val ShortNumberMetadataProto_TV: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TV\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TV")

              public actual val ShortNumberMetadataProto_RO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_RO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_RO")

              public actual val PhoneNumberMetadataProto_DZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_DZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_DZ")

              public actual val PhoneNumberMetadataProto_GR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GR")

              public actual val PhoneNumberMetadataProto_NO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NO")

              public actual val PhoneNumberMetadataProto_BD: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BD\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BD")

              public actual val PhoneNumberMetadataProto_KY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KY")

              public actual val ShortNumberMetadataProto_TC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TC")

              public actual val PhoneNumberMetadataProto_DO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_DO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_DO")

              public actual val PhoneNumberMetadataProto_MR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MR")

              public actual val PhoneNumberMetadataProto_NZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NZ")

              public actual val PhoneNumberAlternateFormatsProto_679: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_679\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_679")

              public actual val PhoneNumberMetadataProto_GG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GG")

              public actual val PhoneNumberMetadataProto_MU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MU")

              public actual val PhoneNumberMetadataProto_BQ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BQ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BQ")

              public actual val ShortNumberMetadataProto_TD: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TD\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TD")

              public actual val PhoneNumberMetadataProto_KE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KE")

              public actual val ShortNumberMetadataProto_TM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TM")

              public actual val PhoneNumberMetadataProto_AW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AW")

              public actual val PhoneNumberMetadataProto_GN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GN")

              public actual val ShortNumberMetadataProto_TJ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TJ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TJ")

              public actual val ShortNumberMetadataProto_RS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_RS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_RS")

              public actual val PhoneNumberMetadataProto_GI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GI")

              public actual val PhoneNumberMetadataProto_UG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_UG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_UG")

              public actual val ShortNumberMetadataProto_CR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CR")

              public actual val ShortNumberMetadataProto_JO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_JO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_JO")

              public actual val ShortNumberMetadataProto_LV: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LV\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LV")

              public actual val PhoneNumberAlternateFormatsProto_359: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_359\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_359")

              public actual val ShortNumberMetadataProto_CU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CU")

              public actual val PhoneNumberMetadataProto_SY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SY")

              public actual val PhoneNumberAlternateFormatsProto_506: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_506\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_506")

              public actual val PhoneNumberMetadataProto_ZM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ZM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ZM")

              public actual val PhoneNumberAlternateFormatsProto_350: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_350\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_350")

              public actual val ShortNumberMetadataProto_EE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_EE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_EE")

              public actual val ShortNumberMetadataProto_IN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_IN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_IN")

              public actual val ShortNumberMetadataProto_FJ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_FJ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_FJ")

              public actual val PhoneNumberAlternateFormatsProto_995: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_995\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_995")

              public actual val ShortNumberMetadataProto_FM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_FM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_FM")

              public actual val PhoneNumberMetadataProto_VA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_VA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_VA")

              public actual val PhoneNumberMetadataProto_YE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_YE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_YE")

              public actual val ShortNumberMetadataProto_CI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CI")

              public actual val PhoneNumberMetadataProto_SE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SE")

              public actual val PhoneNumberMetadataProto_878: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_878\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_878")

              public actual val PhoneNumberMetadataProto_SB: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SB\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SB")

              public actual val ShortNumberMetadataProto_CN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CN")

              public actual val PhoneNumberMetadataProto_PM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PM")

              public actual val ShortNumberMetadataProto_LC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LC")

              public actual val PhoneNumberMetadataProto_SK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SK")

              public actual val ShortNumberMetadataProto_CG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CG")

              public actual val ShortNumberMetadataProto_IR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_IR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_IR")

              public actual val PhoneNumberMetadataProto_SL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SL")

              public actual val PhoneNumberMetadataProto_882: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_882\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_882")

              public actual val PhoneNumberMetadataProto_SV: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SV\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SV")

              public actual val ShortNumberMetadataProto_CZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CZ")

              public actual val ShortNumberMetadataProto_EC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_EC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_EC")

              public actual val PhoneNumberMetadataProto_PY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PY")

              public actual val PhoneNumberAlternateFormatsProto_351: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_351\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_351")

              public actual val ShortNumberMetadataProto_LY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LY")

              public actual val PhoneNumberMetadataProto_VG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_VG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_VG")

              public actual val ShortNumberMetadataProto_FK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_FK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_FK")

              public actual val PhoneNumberMetadataProto_PW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PW")

              public actual val PhoneNumberMetadataProto_VN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_VN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_VN")

              public actual val PhoneNumberMetadataProto_UA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_UA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_UA")

              public actual val PhoneNumberMetadataProto_SX: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SX\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SX")

              public actual val PhoneNumberAlternateFormatsProto_358: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_358\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_358")

              public actual val PhoneNumberMetadataProto_VI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_VI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_VI")

              public actual val ShortNumberMetadataProto_IS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_IS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_IS")

              public actual val ShortNumberMetadataProto_CA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CA")

              public actual val PhoneNumberMetadataProto_SM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SM")

              public actual val PhoneNumberMetadataProto_883: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_883\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_883")

              public actual val PhoneNumberMetadataProto_PE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PE")

              public actual val ShortNumberMetadataProto_IT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_IT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_IT")

              public actual val ShortNumberMetadataProto_OM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_OM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_OM")

              public actual val PhoneNumberMetadataProto_US: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_US\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_US")

              public actual val PhoneNumberMetadataProto_SJ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SJ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SJ")

              public actual val ShortNumberMetadataProto_CF: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CF\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CF")

              public actual val ShortNumberMetadataProto_LB: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LB\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LB")

              public actual val PhoneNumberMetadataProto_ZW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ZW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ZW")

              public actual val PhoneNumberMetadataProto_870: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_870\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_870")

              public actual val PhoneNumberMetadataProto_SC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SC")

              public actual val PhoneNumberMetadataProto_UZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_UZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_UZ")

              public actual val ShortNumberMetadataProto_CO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CO")

              public actual val ShortNumberMetadataProto_LK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LK")

              public actual val PhoneNumberMetadataProto_VU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_VU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_VU")

              public actual val PhoneNumberMetadataProto_PL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PL")

              public actual val ShortNumberMetadataProto_CH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CH")

              public actual val PhoneNumberMetadataProto_SD: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SD\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SD")

              public actual val PhoneNumberMetadataProto_PK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PK")

              public actual val ShortNumberMetadataProto_GL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GL")

              public actual val ShortNumberMetadataProto_AU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AU")

              public actual val ShortNumberMetadataProto_BZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BZ")

              public actual val PhoneNumberMetadataProto_TO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TO")

              public actual val ShortNumberMetadataProto_KG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KG")

              public actual val ShortNumberMetadataProto_AR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AR")

              public actual val ShortNumberMetadataProto_MY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MY")

              public actual val PhoneNumberMetadataProto_TH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TH")

              public actual val ShortNumberMetadataProto_MP: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MP\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MP")

              public actual val ShortNumberMetadataProto_KI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KI")

              public actual val PhoneNumberMetadataProto_TA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TA")

              public actual val ShortNumberMetadataProto_BT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BT")

              public actual val ShortNumberMetadataProto_DM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_DM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_DM")

              public actual val ShortNumberMetadataProto_GB: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GB\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GB")

              public actual val ShortNumberMetadataProto_DJ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_DJ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_DJ")

              public actual val ShortNumberMetadataProto_BS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BS")

              public actual val ShortNumberMetadataProto_KN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KN")

              public actual val ShortNumberMetadataProto_MW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MW")

              public actual val ShortNumberMetadataProto_GE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GE")

              public actual val PhoneNumberMetadataProto_TT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TT")

              public actual val ShortNumberMetadataProto_BA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BA")

              public actual val ShortNumberMetadataProto_ME: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ME\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ME")

              public actual val ShortNumberMetadataProto_GW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GW")

              public actual val ShortNumberMetadataProto_BF: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BF\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BF")

              public actual val ShortNumberMetadataProto_HT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_HT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_HT")

              public actual val ShortNumberMetadataProto_GP: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GP\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GP")

              public actual val ShortNumberMetadataProto_AI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AI")

              public actual val ShortNumberMetadataProto_GY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GY")

              public actual val ShortNumberMetadataProto_KR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KR")

              public actual val ShortNumberMetadataProto_MK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MK")

              public actual val ShortNumberMetadataProto_BO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BO")

              public actual val PhoneNumberMetadataProto_TZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TZ")

              public actual val ShortNumberMetadataProto_AG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AG")

              public actual val ShortNumberMetadataProto_NC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NC")

              public actual val ShortNumberMetadataProto_BH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BH")

              public actual val ShortNumberMetadataProto_ML: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ML\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ML")

              public actual val ShortNumberMetadataProto_MV: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MV\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MV")

              public actual val ShortNumberMetadataProto_DK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_DK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_DK")

              public actual val ShortNumberMetadataProto_BR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BR")

              public actual val PhoneNumberMetadataProto_TG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TG")

              public actual val ShortNumberMetadataProto_GD: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GD\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GD")

              public actual val PhoneNumberAlternateFormatsProto_385: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_385\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_385")

              public actual val ShortNumberMetadataProto_MQ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MQ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MQ")

              public actual val ShortNumberMetadataProto_KH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KH")

              public actual val ShortNumberMetadataProto_AZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AZ")

              public actual val PhoneNumberMetadataProto_XK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_XK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_XK")

              public actual val ShortNumberMetadataProto_AS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AS")

              public actual val PhoneNumberMetadataProto_WF: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_WF\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_WF")

              public actual val PhoneNumberAlternateFormatsProto_971: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_971\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_971")

              public actual val ShortNumberMetadataProto_HN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_HN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_HN")

              public actual val ShortNumberMetadataProto_DE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_DE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_DE")

              public actual val ShortNumberMetadataProto_MX: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MX\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MX")

              public actual val ShortNumberMetadataProto_NP: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NP\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NP")

              public actual val ShortNumberMetadataProto_GM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_GM")

              public actual val ShortNumberMetadataProto_AT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AT")

              public actual val PhoneNumberMetadataProto_TN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TN")

              public actual val PhoneNumberMetadataProto_RW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_RW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_RW")

              public actual val ShortNumberMetadataProto_AF: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AF\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AF")

              public actual val PhoneNumberMetadataProto_WS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_WS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_WS")

              public actual val ShortNumberMetadataProto_MM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MM")

              public actual val PhoneNumberMetadataProto_RE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_RE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_RE")

              public actual val ShortNumberMetadataProto_BI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BI")

              public actual val ShortNumberMetadataProto_NE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NE")

              public actual val ShortNumberMetadataProto_BN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BN")

              public actual val ShortNumberMetadataProto_BG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_BG")

              public actual val PhoneNumberMetadataProto_TR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_TR")

              public actual val ShortNumberMetadataProto_MC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MC")

              public actual val ShortNumberMetadataProto_KZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_KZ")

              public actual val ShortNumberMetadataProto_HU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_HU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_HU")

              public actual val ShortNumberMetadataProto_NL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_NL")

              public actual val ShortNumberMetadataProto_MD: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MD\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_MD")

              public actual val ShortNumberMetadataProto_HR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_HR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_HR")

              public actual val ShortNumberMetadataProto_AO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_AO")

              public actual val PhoneNumberAlternateFormatsProto_39: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_39\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_39")

              public actual val PhoneNumberMetadataProto_LI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LI")

              public actual val PhoneNumberMetadataProto_JP: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_JP\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_JP")

              public actual val ShortNumberMetadataProto_SA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SA")

              public actual val PhoneNumberMetadataProto_CM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CM")

              public actual val PhoneNumberMetadataProto_ET: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ET\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ET")

              public actual val ShortNumberMetadataProto_YT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_YT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_YT")

              public actual val PhoneNumberMetadataProto_ES: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ES\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ES")

              public actual val PhoneNumberMetadataProto_CC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CC")

              public actual val ShortNumberMetadataProto_SO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SO")

              public actual val PhoneNumberMetadataProto_IQ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IQ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IQ")

              public actual val ShortNumberMetadataProto_SH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SH")

              public actual val PhoneNumberMetadataProto_CD: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CD\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CD")

              public actual val PhoneNumberAlternateFormatsProto_30: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_30\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_30")

              public actual val PhoneNumberMetadataProto_FR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_FR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_FR")

              public actual val ShortNumberMetadataProto_PG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PG")

              public actual val PhoneNumberMetadataProto_LR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LR")

              public actual val PhoneNumberAlternateFormatsProto_90: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_90\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_90")

              public actual val PhoneNumberMetadataProto_CV: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CV\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CV")

              public actual val PhoneNumberAlternateFormatsProto_64: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_64\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_64")

              public actual val ShortNumberMetadataProto_SZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SZ")

              public actual val PhoneNumberMetadataProto_ID: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ID\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ID")

              public actual val PhoneNumberAlternateFormatsProto_63: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_63\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_63")

              public actual val PhoneNumberMetadataProto_EH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_EH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_EH")

              public actual val PhoneNumberMetadataProto_LU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LU")

              public actual val ShortNumberMetadataProto_PR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PR")

              public actual val PhoneNumberAlternateFormatsProto_55: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_55\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_55")

              public actual val ShortNumberMetadataProto_ST: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ST\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ST")

              public actual val PhoneNumberMetadataProto_CX: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CX\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CX")

              public actual val PhoneNumberMetadataProto_JE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_JE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_JE")

              public actual val PhoneNumberMetadataProto_IM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IM")

              public actual val ShortNumberMetadataProto_VE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_VE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_VE")

              public actual val PhoneNumberMetadataProto_FI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_FI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_FI")

              public actual val PhoneNumberAlternateFormatsProto_52: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_52\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_52")

              public actual val ShortNumberMetadataProto_SS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SS")

              public actual val ShortNumberMetadataProto_SI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SI")

              public actual val PhoneNumberMetadataProto_LA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LA")

              public actual val PhoneNumberAlternateFormatsProto_31: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_31\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_31")

              public actual val ShortNumberMetadataProto_PF: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PF\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PF")

              public actual val PhoneNumberAlternateFormatsProto_856: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_856\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_856")

              public actual val ShortNumberMetadataProto_SN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SN")

              public actual val ShortNumberMetadataProto_PA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PA")

              public actual val PhoneNumberAlternateFormatsProto_36: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_36\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_36")

              public actual val ShortNumberMetadataProto_PH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PH")

              public actual val PhoneNumberMetadataProto_ER: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ER\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ER")

              public actual val PhoneNumberMetadataProto_CK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CK")

              public actual val ShortNumberMetadataProto_SG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SG")

              public actual val ShortNumberMetadataProto_UY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_UY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_UY")

              public actual val PhoneNumberMetadataProto_CL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CL")

              public actual val PhoneNumberMetadataProto_IL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IL")

              public actual val PhoneNumberMetadataProto_EG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_EG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_EG")

              public actual val ShortNumberMetadataProto_SR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_SR")

              public actual val PhoneNumberMetadataProto_FO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_FO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_FO")

              public actual val PhoneNumberAlternateFormatsProto_54: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_54\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_54")

              public actual val ShortNumberMetadataProto_VC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_VC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_VC")

              public actual val PhoneNumberMetadataProto_979: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_979\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_979")

              public actual val PhoneNumberMetadataProto_CY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CY")

              public actual val PhoneNumberMetadataProto_LT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LT")

              public actual val PhoneNumberMetadataProto_JM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_JM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_JM")

              public actual val ShortNumberMetadataProto_ZA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ZA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ZA")

              public actual val PhoneNumberAlternateFormatsProto_62: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_62\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_62")

              public actual val ShortNumberMetadataProto_PS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PS")

              public actual val PhoneNumberMetadataProto_CW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_CW")

              public actual val PhoneNumberAlternateFormatsProto_91: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_91\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_91")

              public actual val PhoneNumberMetadataProto_LS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_LS")

              public actual val ShortNumberMetadataProto_PT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_PT")

              public actual val PhoneNumberMetadataProto_IE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_IE")

              public actual val PhoneNumberMetadataProto_BZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BZ")

              public actual val ShortNumberMetadataProto_TO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TO")

              public actual val PhoneNumberMetadataProto_KG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KG")

              public actual val PhoneNumberMetadataProto_AU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AU")

              public actual val PhoneNumberMetadataProto_GL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GL")

              public actual val PhoneNumberMetadataProto_MY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MY")

              public actual val ShortNumberMetadataProto_TH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TH")

              public actual val PhoneNumberMetadataProto_AR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AR")

              public actual val PhoneNumberAlternateFormatsProto_675: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_675\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_675")

              public actual val PhoneNumberMetadataProto_GB: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GB\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GB")

              public actual val PhoneNumberAlternateFormatsProto_49: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_49\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_49")

              public actual val PhoneNumberMetadataProto_KI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KI")

              public actual val PhoneNumberMetadataProto_MP: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MP\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MP")

              public actual val PhoneNumberMetadataProto_DM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_DM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_DM")

              public actual val PhoneNumberMetadataProto_BT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BT")

              public actual val PhoneNumberMetadataProto_GE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GE")

              public actual val PhoneNumberMetadataProto_BS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BS")

              public actual val PhoneNumberMetadataProto_DJ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_DJ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_DJ")

              public actual val PhoneNumberAlternateFormatsProto_7: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_7\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_7")

              public actual val PhoneNumberMetadataProto_MW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MW")

              public actual val PhoneNumberMetadataProto_KN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KN")

              public actual val PhoneNumberMetadataProto_GW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GW")

              public actual val ShortNumberMetadataProto_TT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TT")

              public actual val PhoneNumberMetadataProto_BA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BA")

              public actual val PhoneNumberMetadataProto_ME: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ME\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ME")

              public actual val PhoneNumberMetadataProto_HT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_HT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_HT")

              public actual val PhoneNumberMetadataProto_AI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AI")

              public actual val PhoneNumberMetadataProto_GP: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GP\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GP")

              public actual val PhoneNumberMetadataProto_BF: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BF\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BF")

              public actual val PhoneNumberMetadataProto_MK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MK")

              public actual val PhoneNumberMetadataProto_KR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KR")

              public actual val PhoneNumberMetadataProto_BO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BO")

              public actual val ShortNumberMetadataProto_TZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TZ")

              public actual val PhoneNumberMetadataProto_GY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GY")

              public actual val PhoneNumberMetadataProto_BH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BH")

              public actual val PhoneNumberMetadataProto_ML: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ML\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ML")

              public actual val PhoneNumberMetadataProto_AG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AG")

              public actual val PhoneNumberMetadataProto_NC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NC")

              public actual val PhoneNumberMetadataProto_GD: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GD\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GD")

              public actual val PhoneNumberAlternateFormatsProto_84: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_84\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_84")

              public actual val PhoneNumberMetadataProto_MV: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MV\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MV")

              public actual val PhoneNumberMetadataProto_BR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BR")

              public actual val PhoneNumberMetadataProto_DK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_DK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_DK")

              public actual val ShortNumberMetadataProto_TG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TG")

              public actual val PhoneNumberMetadataProto_AZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AZ")

              public actual val ShortNumberMetadataProto_XK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_XK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_XK")

              public actual val PhoneNumberMetadataProto_KH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KH")

              public actual val PhoneNumberMetadataProto_MQ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MQ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MQ")

              public actual val PhoneNumberMetadataProto_DE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_DE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_DE")

              public actual val PhoneNumberMetadataProto_MX: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MX\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MX")

              public actual val PhoneNumberMetadataProto_AS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AS")

              public actual val ShortNumberMetadataProto_WF: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_WF\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_WF")

              public actual val PhoneNumberMetadataProto_HN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_HN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_HN")

              public actual val ShortNumberMetadataProto_RW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_RW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_RW")

              public actual val ShortNumberMetadataProto_TN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TN")

              public actual val PhoneNumberMetadataProto_NP: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NP\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NP")

              public actual val PhoneNumberMetadataProto_AT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AT")

              public actual val PhoneNumberMetadataProto_GM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GM")

              public actual val PhoneNumberMetadataProto_MM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MM")

              public actual val ShortNumberMetadataProto_RE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_RE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_RE")

              public actual val PhoneNumberMetadataProto_BI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BI")

              public actual val PhoneNumberMetadataProto_AF: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AF\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AF")

              public actual val ShortNumberMetadataProto_WS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_WS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_WS")

              public actual val PhoneNumberMetadataProto_BN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BN")

              public actual val PhoneNumberMetadataProto_NE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NE")

              public actual val PhoneNumberMetadataProto_GQ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GQ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_GQ")

              public actual val PhoneNumberMetadataProto_NL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_NL")

              public actual val PhoneNumberMetadataProto_HU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_HU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_HU")

              public actual val PhoneNumberMetadataProto_BG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_BG")

              public actual val ShortNumberMetadataProto_TR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_TR")

              public actual val PhoneNumberMetadataProto_KZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_KZ")

              public actual val PhoneNumberMetadataProto_MC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MC")

              public actual val PhoneNumberMetadataProto_HR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_HR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_HR")

              public actual val PhoneNumberMetadataProto_AO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_AO")

              public actual val PhoneNumberMetadataProto_MD: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MD\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_MD")

              public actual val ShortNumberMetadataProto_JP: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_JP\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_JP")

              public actual val ShortNumberMetadataProto_LI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LI")

              public actual val PhoneNumberMetadataProto_SA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SA")

              public actual val ShortNumberMetadataProto_ET: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ET\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ET")

              public actual val ShortNumberMetadataProto_CM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CM")

              public actual val ShortNumberMetadataProto_ES: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ES\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ES")

              public actual val PhoneNumberMetadataProto_888: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_888\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_888")

              public actual val PhoneNumberMetadataProto_YT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_YT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_YT")

              public actual val ShortNumberMetadataProto_IQ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_IQ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_IQ")

              public actual val ShortNumberMetadataProto_CC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CC")

              public actual val PhoneNumberMetadataProto_SO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SO")

              public actual val PhoneNumberMetadataProto_881: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_881\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_881")

              public actual val ShortNumberMetadataProto_FR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_FR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_FR")

              public actual val PhoneNumberMetadataProto_PG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PG")

              public actual val PhoneNumberMetadataProto_SH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SH")

              public actual val ShortNumberMetadataProto_CD: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CD\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CD")

              public actual val ShortNumberMetadataProto_ID: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ID\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ID")

              public actual val ShortNumberMetadataProto_LR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LR")

              public actual val ShortNumberMetadataProto_CV: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CV\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CV")

              public actual val PhoneNumberMetadataProto_SZ: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SZ\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SZ")

              public actual val PhoneNumberMetadataProto_PR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PR")

              public actual val ShortNumberMetadataProto_EH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_EH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_EH")

              public actual val ShortNumberMetadataProto_LU: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LU\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LU")

              public actual val PhoneNumberMetadataProto_ST: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ST\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ST")

              public actual val ShortNumberMetadataProto_CX: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CX\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CX")

              public actual val ShortNumberMetadataProto_JE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_JE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_JE")

              public actual val PhoneNumberAlternateFormatsProto_505: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_505\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_505")

              public actual val PhoneNumberMetadataProto_SS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SS")

              public actual val ShortNumberMetadataProto_IM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_IM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_IM")

              public actual val PhoneNumberMetadataProto_VE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_VE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_VE")

              public actual val ShortNumberMetadataProto_FI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_FI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_FI")

              public actual val PhoneNumberMetadataProto_PF: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PF\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PF")

              public actual val PhoneNumberMetadataProto_SI: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SI\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SI")

              public actual val ShortNumberMetadataProto_LA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LA")

              public actual val PhoneNumberMetadataProto_PA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PA")

              public actual val PhoneNumberAlternateFormatsProto_595: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_595\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_595")

              public actual val PhoneNumberMetadataProto_SN: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SN\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SN")

              public actual val ShortNumberMetadataProto_CK: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CK\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CK")

              public actual val ShortNumberMetadataProto_ER: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ER\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_ER")

              public actual val PhoneNumberMetadataProto_SG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SG")

              public actual val PhoneNumberMetadataProto_PH: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PH\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PH")

              public actual val PhoneNumberMetadataProto_UY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_UY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_UY")

              public actual val ShortNumberMetadataProto_CL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CL")

              public actual val ShortNumberMetadataProto_EG: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_EG\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_EG")

              public actual val PhoneNumberMetadataProto_SR: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SR\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_SR")

              public actual val PhoneNumberAlternateFormatsProto_352: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_352\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberAlternateFormatsProto_352")

              public actual val ShortNumberMetadataProto_IL: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_IL\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_IL")

              public actual val ShortNumberMetadataProto_CY: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CY\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CY")

              public actual val ShortNumberMetadataProto_FO: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_FO\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_FO")

              public actual val PhoneNumberMetadataProto_VC: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_VC\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_VC")

              public actual val PhoneNumberMetadataProto_PS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PS")

              public actual val ShortNumberMetadataProto_JM: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_JM\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_JM")

              public actual val ShortNumberMetadataProto_LT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LT")

              public actual val PhoneNumberMetadataProto_ZA: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ZA\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_ZA")

              public actual val PhoneNumberMetadataProto_PT: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PT\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/PhoneNumberMetadataProto_PT")

              public actual val ShortNumberMetadataProto_IE: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_IE\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_IE")

              public actual val ShortNumberMetadataProto_CW: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CW\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_CW")

              public actual val ShortNumberMetadataProto_LS: AssetResource =
                  AssetResource(originalPath =
                  js("require(\"files/io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LS\")")
                  as String, rawPath =
                  "io/michaelrocks/libphonenumber/android/data/ShortNumberMetadataProto_LS")
            }
          }
        }
      }
    }
  }
}
