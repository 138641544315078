package io.eqoty.shared.viewmodel.screens.mintsong

import co.touchlab.kermit.Logger
import io.eqoty.kryptools.aes256gcm.Aes256Gcm
import io.eqoty.shared.datalayer.functions.*
import io.eqoty.shared.datalayer.objects.AttachmentAccess
import io.eqoty.shared.datalayer.objects.AudioContainer
import io.eqoty.shared.datalayer.objects.AudioFileAndInfo
import io.eqoty.shared.datalayer.sources.filesystem.CommonFile
import io.eqoty.shared.datalayer.sources.web3.CosmosWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.EthWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.ExternalWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.InternalWeb3Provider
import io.eqoty.shared.devicelayer.functions.launchFileChooser
import io.eqoty.shared.viewmodel.Events
import io.eqoty.shared.viewmodel.StateManager
import io.eqoty.shared.viewmodel.debugLogger
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.scaffold.setWalletScreenExpanded
import io.eqoty.shared.viewmodel.screens.topbar.updateWalletBtnInfo
import io.eqoty.shared.viewmodel.screens.wallettxrequest.internalWalletTxRequestResultSharedFlow
import io.eqoty.shared.viewmodel.screens.wallettxrequest.setInternalWalletTxRequest
import io.eqoty.shared.viewmodel.utils.ethToWei
import io.eqoty.shared.viewmodel.utils.roundToDecimals
import io.eqoty.shared.viewmodel.utils.scrtToUscrt
import kotlinx.coroutines.flow.first

/********** EVENT functions, called directly by the UI layer **********/

expect object MimeTypes {
    val audio: List<String>
    val image: List<String>
}

fun Events.chooseAudioFile(access: AttachmentAccess) = inScreenScopeLaunch(ScreenStack.Main) {
    val fileList = try {
        launchFileChooser(MimeTypes.audio, saveMode = false, saveFileName = null)
    } catch (t: Throwable) {
        t.printStackTrace()
        emptyList()
    }
    if (fileList.isEmpty()) {
        return@inScreenScopeLaunch
    }
    val file = fileList[0]
    val audioDuration = try {
        dataRepository.getAudioDuration(file)
    } catch (t: Throwable) {
        setMintScreenError(stateManager, t.message ?: "getAudioDuration Error")
        return@inScreenScopeLaunch
    }
    stateManager.updateScreen(ScreenStack.Main, MintSongScreenState::class) {
        if (access == AttachmentAccess.PRIVATE) {
            it.copy(
                privateAudioFile = file,
                privateAudioDuration = audioDuration,
                autofillFromFileInfo = true
            )
        } else {
            it.copy(
                publicAudioFile = file,
                publicAudioDuration = audioDuration
            )
        }
    }
}

fun Events.disableAutofillFromFile() = inScreenScopeLaunch(ScreenStack.Main) {
    stateManager.updateScreen(ScreenStack.Main, MintSongScreenState::class) {
        it.copy(
            autofillFromFileInfo = false
        )
    }
}

fun Events.chooseAlbumArtFile() = inScreenScopeLaunch(ScreenStack.Main) {
    val fileList = try {
        launchFileChooser(MimeTypes.image, saveMode = false, saveFileName = null)
    } catch (t: Throwable) {
        t.printStackTrace()
        emptyList()
    }
    if (fileList.isNotEmpty()) {
        stateManager.updateScreen(ScreenStack.Main, MintSongScreenState::class) {
            it.copy(
                albumArtFile = fileList[0]
            )
        }
    }
}

/***
 * Used by iOS which needs to use its own file chooser that we can't spawn outside the view.
 */
fun Events.useFilesChosenInApp(fileList: List<CommonFile>) = inScreenScopeLaunch(ScreenStack.Main) {
    if (fileList.isNotEmpty()) {
        stateManager.updateScreen(ScreenStack.Main, MintSongScreenState::class) {
            it.copy(
                privateAudioFile = fileList[0]
            )
        }
    }
}

private fun setMintScreenError(stateManager: StateManager, errorMessage: String) {
    stateManager.updateScreen(ScreenStack.Main, MintSongScreenState::class) {
        it.copy(
            mintErrorMessage = errorMessage,
            interactionEnabled = true
        )
    }
}

private fun setMintProgressMessage(stateManager: StateManager, progressMessages: List<String?>) {
    stateManager.updateScreen(ScreenStack.Main, MintSongScreenState::class) {
        it.copy(
            mintProgress = progressMessages.filterNotNull().lastOrNull()
        )
    }
}

fun Events.setWeb3Network() = inScreenScopeLaunch(ScreenStack.Main) {
    val network = dataRepository.getWeb3Network()
    stateManager.updateScreen(ScreenStack.Main, MintSongScreenState::class) {
        it.copy(
            currentNetwork = network
        )
    }
    return@inScreenScopeLaunch
}

fun Events.validateMintTrackForm(
    formInput: MintTrackFormInput, onSuccess: (formInput: MintTrackFormInput) -> Unit
) = inScreenScopeLaunch(ScreenStack.Main) {
    stateManager.updateScreen(ScreenStack.Main, MintSongScreenState::class) {
        it.copy(
            interactionEnabled = false,
            mintErrorMessage = null
        )
    }
    val validationResult = formInput.validate()
    stateManager.updateScreen(ScreenStack.Main, MintSongScreenState::class) {
        it.copy(
            validationResult = validationResult,
        )
    }
    if (!validationResult.hasNoErrors()) {
        stateManager.updateScreen(ScreenStack.Main, MintSongScreenState::class) {
            it.copy(
                interactionEnabled = true
            )
        }
    } else {
        onSuccess(formInput)
    }
}

fun Events.mint(
    formInput: MintTrackFormInput,
) = inScreenScopeLaunch(ScreenStack.Main) {
    val validationResult = formInput.validate()
    if (!validationResult.hasNoErrors()) {
        debugLogger.w { "Events.mint called with a formInput that has errors: $validationResult" }
        return@inScreenScopeLaunch
    }
    val mintProgressMessages = MutableList<String?>(8) { null }
    val creds = dataRepository.getRandomAesCredentials()
    val ivKey = creds.iv + creds.key
    var privateMp3V0Url: String? = null
    val privateAudioUrlsAndInfo = try {
        mintProgressMessages[0] = "Transcoding Private Audio"
        setMintProgressMessage(stateManager, mintProgressMessages)
        val verifiedAudioAndInfo = dataRepository.verifyAudioMeetsReqs(formInput.privateAudioFile!!)
        val transcodedFiles = dataRepository.transcodeLosslessFileToAllFormats(
            verifiedAudioAndInfo,
            null,
        ) {
            mintProgressMessages[0] = "Transcoding Private Audio to ${it.name} format in progress..."
            setMintProgressMessage(stateManager, mintProgressMessages)
        }
        val mp3V0Audio = findMp3V0Audio(transcodedFiles)
        transcodedFiles.map { audioFileAndInfo ->
            val url = dataRepository.addEncryptedFileToIpfs(
                creds.iv, creds.key, audioFileAndInfo.file
            ) { uploadProgress, addProgress ->
                if (uploadProgress != null) {
                    mintProgressMessages[1] =
                        "Uploading Private Audio ${audioFileAndInfo.file.name}... ${
                            uploadProgress.percentage.roundToDecimals(1)
                        }%"
                }
                if (addProgress != null) {
                    mintProgressMessages[2] =
                        "Adding Private Audio ${audioFileAndInfo.file.name} To IPFS... ${
                            addProgress.percentage.roundToDecimals(1)
                        }%"
                }
                setMintProgressMessage(stateManager, mintProgressMessages)
            }
            mintProgressMessages[1] = null
            mintProgressMessages[2] = null
            if (audioFileAndInfo == mp3V0Audio) {
                privateMp3V0Url = url
            }
            audioFileAndInfo.info.toAudioFormatMetadata(url)
        }
    } catch (t: Throwable) {
        t.printStackTrace()
        setMintScreenError(stateManager, "Private Audio upload failed: ${t.message}")
        return@inScreenScopeLaunch
    }
    require(privateMp3V0Url != null) { "privateMp3V0Url not set" }
    val publicAudioUrlsAndInfo = try {
        mintProgressMessages[3] = "Transcoding Public Audio"
        setMintProgressMessage(stateManager, mintProgressMessages)
        val verifiedAudioAndInfo = if (formInput.advancedPubicAudioMode) {
            dataRepository.verifyAudioMeetsReqs(formInput.publicAudioAdvancedModeFile!!)
        } else {
            dataRepository.verifyAudioMeetsReqs(formInput.privateAudioFile)
        }
        val transcodedFiles = dataRepository.transcodeLosslessFileToAllFormats(
            verifiedAudioAndInfo,
            formInput.publicAudioClipRange
        ) {
            mintProgressMessages[3] = "Transcoding Public Audio to ${it.name} Format"
            setMintProgressMessage(stateManager, mintProgressMessages)
        }
        transcodedFiles.map { audioFileAndInfo ->
            val url = dataRepository.addFileToIpfs(audioFileAndInfo.file) { uploadProgress, addProgress ->
                if (uploadProgress != null) {
                    mintProgressMessages[4] =
                        "Uploading Public Audio ${audioFileAndInfo.file.name}... ${
                            uploadProgress.percentage.roundToDecimals(1)
                        }%"
                }
                if (addProgress != null) {
                    mintProgressMessages[5] =
                        "Adding Public Audio ${audioFileAndInfo.file.name} To IPFS... ${
                            addProgress.percentage.roundToDecimals(1)
                        }%"
                }
                setMintProgressMessage(stateManager, mintProgressMessages)
            }
            mintProgressMessages[4] = null
            mintProgressMessages[5] = null
            audioFileAndInfo.info.toAudioFormatMetadata(url)
        }
    } catch (t: Throwable) {
        setMintScreenError(stateManager, "Public Audio upload failed: ${t.message}")
        return@inScreenScopeLaunch
    }
    val albumArtUrl = try {
        dataRepository.addFileToIpfs(formInput.trackArtFile!!) { uploadProgress, addProgress ->
            if (uploadProgress != null) {
                mintProgressMessages[6] =
                    "Uploading Track Art... ${uploadProgress.percentage.roundToDecimals(1)}%"
            }
            if (addProgress != null) {
                mintProgressMessages[7] =
                    "Adding Track Art To IPFS... ${addProgress.percentage.roundToDecimals(1)}%"
            }
            setMintProgressMessage(stateManager, mintProgressMessages)
        }
    } catch (t: Throwable) {
        setMintScreenError(stateManager, "Track art upload failed: ${t.message}")
        return@inScreenScopeLaunch
    }
    setMintProgressMessage(stateManager, emptyList())
    val metadataUrl = try {
        dataRepository.createAndAdd721MetadataToIpfs(
            formInput = formInput,
            privateAudioFormats = privateAudioUrlsAndInfo,
            publicAudioFormats = publicAudioUrlsAndInfo,
            imageUrl = albumArtUrl
        )
    } catch (t: Throwable) {
        t.printStackTrace()
        setMintScreenError(stateManager, "Metadata upload failed: ${t.message}")
        return@inScreenScopeLaunch
    }
    Logger.d("Metadata published to ipfs: $metadataUrl")
    val priceBaseUnit = when (dataRepository.web3Provider!!) {
        is EthWeb3Provider -> formInput.price.ethToWei()
        is CosmosWeb3Provider -> formInput.price.scrtToUscrt()
    }

    val txHash = when (val web3Provider = dataRepository.web3Provider) {
        is InternalWeb3Provider<*> -> {
            try {
                setWalletScreenExpanded(true)?.join()
                dataRepository.mintRelease(
                    formInput.organizationId!!,
                    metadataUrl,
                    privateMp3V0Url!!,
                    priceBaseUnit,
                    ivKey
                ) { txRequestInfo ->
                    setInternalWalletTxRequest(txRequestInfo)
                    val requestResult = internalWalletTxRequestResultSharedFlow.first()
                    require(txRequestInfo == requestResult.txRequestInfo)
                    requestResult.result
                }.transactionHash
            } catch (t: Throwable) {
                t.printStackTrace()
                setMintScreenError(stateManager, t.message ?: "Mint failed.")
                null
            } finally {
                setWalletScreenExpanded(false)?.join()
            }
        }

        is ExternalWeb3Provider -> {
            try {
                dataRepository.mintRelease(
                    formInput.organizationId!!,
                    metadataUrl,
                    privateMp3V0Url!!,
                    priceBaseUnit,
                    ivKey,
                    null
                ).transactionHash
            } catch (t: Throwable) {
                setMintScreenError(stateManager, t.message ?: "Mint failed.")
                null
            }
        }

        else -> throw NotImplementedError("web3Provider: $web3Provider not handled")
    }

    stateManager.updateScreen(ScreenStack.Main, MintSongScreenState::class) {
        MintSongScreenState(
            selectedOrganizationId = it.selectedOrganizationId,
            selectedOrganizationName = it.selectedOrganizationName,
            currentNetwork = it.currentNetwork,
            txHash = txHash
        )
    }
    updateWalletBtnInfo()?.join()
}

fun findMp3V0Audio(audioFiles: List<AudioFileAndInfo>): AudioFileAndInfo =
    audioFiles.filter { it.info.container == AudioContainer.MP3 }.minByOrNull { it.info.size }!!
