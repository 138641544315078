package io.eqoty.composables.screens.mediaplayer


import androidx.compose.runtime.*
import app.softwork.bootstrapcompose.Button
import app.softwork.bootstrapcompose.Column
import app.softwork.bootstrapcompose.Layout.Display
import app.softwork.bootstrapcompose.Row
import app.softwork.bootstrapcompose.icons.Pause
import app.softwork.bootstrapcompose.icons.Play
import app.softwork.bootstrapcompose.icons.XLg
import io.eqoty.composables.styles.overflowTxtToEllipsisAttrs
import io.eqoty.composables.styles.overflowTxtToEllipsisStyle
import io.eqoty.shared.ui.utils.ScreenSizeUtils
import io.eqoty.shared.ui.utils.smallScaffoldActive
import io.eqoty.shared.viewmodel.screens.generic.TrackRowData
import io.eqoty.shared.viewmodel.screens.mediaplayer.MediaPlayerWidgetState
import io.eqoty.shared.viewmodel.screens.mediaplayer.MediaPlayerWidgetTrack
import io.eqoty.shared.viewmodel.screens.mediaplayer.PlaybackState
import org.jetbrains.compose.web.ExperimentalComposeWebSvgApi
import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.svg.SVGElement

@Composable
fun MediaPlayerWidget(
    state: MediaPlayerWidgetState,
    onPlayClick: (track: MediaPlayerWidgetTrack?) -> Unit,
    onCloseClick: () -> Unit,
    onPauseClick: () -> Unit,
    onClickOrganization: (TrackRowData) -> Unit,
) {
    state.track?.apply {
        when (ScreenSizeUtils.smallScaffoldActive) {
            true -> {
                MediaPlayerWidgetSmall(state, this, onPlayClick, onCloseClick, onPauseClick, onClickOrganization)
            }

            false -> {
                MediaPlayerWidgetLarge(state, this, onPlayClick, onCloseClick, onPauseClick, onClickOrganization)
            }
        }
    }
}

@Composable
private fun MediaPlayerWidgetSmall(
    state: MediaPlayerWidgetState,
    track: MediaPlayerWidgetTrack,
    onPlayClick: (track: MediaPlayerWidgetTrack?) -> Unit,
    onCloseClick: () -> Unit,
    onPauseClick: () -> Unit,
    onClickOrganization: (TrackRowData) -> Unit,
) {
    Column(attrs = { classes("px-3", "pt-2", "pb-1") }) {
        Row(attrs = {
            style {
                flexWrap(FlexWrap.Nowrap)
            }
        }) {
            MediaPlayerWidgetCurrentTrackColumns(track.displayData, onClickOrganization)

            MediaPlayerWidgetSmallTrackPlayerRightColumn(
                state,
                onPlayClick,
                onPauseClick,
                onCloseClick,
            )
        }
        Row(attrs = { classes("pt-2") }) {
            // todo: replace https://github.com/hfhbd/bootstrap-compose/issues/206
            Div(attrs = {
                classes("progress", "p-0")
                style {
                    height(4.px)
                    flexGrow(1)
                }
            }) {
                Div(attrs = {
                    classes("progress-bar")
                    attr("role", "progressbar")
                    attr("aria-valuenow", state.currentPositionPercentOf100.toString())
                    attr("aria-valuemin", "0")
                    attr("aria-valuemin", "100")
                    style {
                        width(state.currentPositionPercentOf100?.percent ?: 0.percent)
                    }
                }) {}
            }
        }
    }
}

@Composable
private fun MediaPlayerWidgetLarge(
    state: MediaPlayerWidgetState,
    track: MediaPlayerWidgetTrack,
    onPlayClick: (track: MediaPlayerWidgetTrack?) -> Unit,
    onCloseClick: () -> Unit,
    onPauseClick: () -> Unit,
    onClickOrganization: (TrackRowData) -> Unit,
) {
    Column(
        attrs = {
            style {
                height(80.px)
            }
            classes("px-3", "py-2")
        }
    ) {
        Row(attrs = {
            style {
                height(100.percent)
                flexWrap(FlexWrap.Nowrap)
            }
        }) {
            Column(
                styling = {
                    Layout {
                        display(Display.Flex)
                    }
                },
                attrs = {
                    classes("justify-content-center", "align-items-center", "col-3")
                    style { height(100.percent) }
                }
            ) {
                MediaPlayerWidgetCurrentTrackColumns(track.displayData, onClickOrganization)
            }
            Column(
                styling = {
                    Layout {
                        display(Display.Flex)
                    }
                },
                attrs = {
                    classes("justify-content-center", "align-items-center", "p-0", "col-6")
                    style { height(100.percent) }
                }
            ) {
                MediaPlayerWidgetTrackPlayer(
                    state,
                    onPlayClick,
                    onPauseClick
                )
            }
            Column(
                styling = {
                    Layout {
                        display(Display.Flex)
                    }
                },
                attrs = {
                    classes("justify-content-end", "col-3")
                    style { height(100.percent) }
                }
            ) {
                MediaPlayerWidgetLargeTrackPlayerRight(
                    onCloseClick,
                )
            }
        }
    }
}


@Composable
private fun MediaPlayerWidgetCurrentTrackColumns(
    trackRowData: TrackRowData, onClickOrganization: (TrackRowData) -> Unit,
) {
    Column(
        auto = true,
        attrs = {
            style { height(65.px) }
        }
    ) {
        Img(trackRowData.trackArtUrl, attrs = {
            style {
                height(100.percent)
            }
            attr("crossorigin", "")
        })
    }

    Column(
        attrs = {
            classes("d-flex", "flex-column", "justify-content-center", "p-0", "ps-2")
            style {
                minWidth("0") // needed to enable text-overflow:ellipsis inside a flex
            }
        }
    ) {
        Row {
            Div(attrs = overflowTxtToEllipsisAttrs) { Text(value = trackRowData.name) }
        }
        Row {
            var cursorHoveredOverOrganization by remember { mutableStateOf(false) }
            Div(attrs = {
                onClick { event ->
                    event.stopPropagation()
                    onClickOrganization(trackRowData)
                }
                onMouseOver { event ->
                    cursorHoveredOverOrganization = true
                }
                onMouseOut { event ->
                    cursorHoveredOverOrganization = false
                }
                style {
                    overflowTxtToEllipsisStyle()
                    cursor(if (cursorHoveredOverOrganization) "pointer" else "default")
                }
                if (cursorHoveredOverOrganization) {
                    classes("text-decoration-underline")
                }
            }) { Text(value = trackRowData.artistsDisplay) }
        }
    }
}

@OptIn(ExperimentalComposeWebSvgApi::class)
@Composable
private fun MediaPlayerWidgetTrackPlayer(
    state: MediaPlayerWidgetState,
    onPlayClick: (track: MediaPlayerWidgetTrack?) -> Unit,
    onPauseClick: () -> Unit
) {
    Div(
        attrs = {
            style {
                display(DisplayStyle.Flex)
                flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                width(100.percent)
            }
        }
    ) {
        Div(
            attrs = {
                style {
                    display(DisplayStyle.Flex)
                    flexFlow(FlexDirection.Row, FlexWrap.Nowrap)
                    justifyContent(JustifyContent.Center)
                }
            }
        ) {
            val btnAttrs: AttrBuilderContext<HTMLButtonElement> = {
                style {
                    property("border-color", Color.transparent)
                    padding(0.1.cssRem)
                }
            }
            val iconAttrs: AttrBuilderContext<SVGElement> = {
                style {
                    height(35.px)
                    width(35.px)
                    color(Color.white)
                }
            }
            if (state.playbackState != PlaybackState.PLAYING) {
                Button(
                    content = {
                        Play(attrs = iconAttrs)
                    },
                    outlined = true,
                    attrs = btnAttrs
                ) {
                    onPlayClick(state.track)
                }
            } else {
                Button(
                    content = {
                        Pause(attrs = iconAttrs)
                    },
                    outlined = true,
                    attrs = btnAttrs
                ) {
                    onPauseClick()
                }
            }
        }
        Div(
            attrs = {
                style {
                    display(DisplayStyle.Flex)
                    flexFlow(FlexDirection.Row, FlexWrap.Nowrap)
                    alignItems(AlignItems.Center)
                    width(100.percent)
                }
            }
        ) {
            Div(attrs = { style { paddingRight(4.px) } }) { Text(state.currentPositionFormatted) }
            // todo: replace https://github.com/hfhbd/bootstrap-compose/issues/206
            Div(attrs = {
                classes("progress")
                style {
                    height(8.px)
                    flexGrow(1)
                }
            }) {
                Div(attrs = {
                    classes("progress-bar")
                    attr("role", "progressbar")
                    attr("aria-valuenow", state.currentPositionPercentOf100.toString())
                    attr("aria-valuemin", "0")
                    attr("aria-valuemin", "100")
                    style {
                        width(state.currentPositionPercentOf100?.percent ?: 0.percent)
                    }
                }) {}
            }
            Div(attrs = { style { paddingLeft(4.px) } }) { Text(state.durationFormatted) }
        }
    }
}


@OptIn(ExperimentalComposeWebSvgApi::class)
@Composable
private fun MediaPlayerWidgetSmallTrackPlayerRightColumn(
    state: MediaPlayerWidgetState,
    onPlayClick: (track: MediaPlayerWidgetTrack?) -> Unit,
    onPauseClick: () -> Unit,
    onCloseClick: () -> Unit,
) {
    Column(
        auto = true,
        attrs = {
            classes("d-flex", "flex-column", "justify-content-center", "ps-0")
        }
    ) {
        Row(
            attrs = {
                style {
                    display(DisplayStyle.Flex)
                    flexFlow(FlexDirection.Row, FlexWrap.Nowrap)
                }
            }
        ) {
            val btnAttrs: AttrBuilderContext<HTMLButtonElement> = {
                style {
                    property("border-color", Color.transparent)
                    padding(0.1.cssRem)
                }
            }
            val iconAttrs: AttrsScope<SVGElement>.() -> Unit = {
                style {
                    height(40.px)
                    width(40.px)
                    color(Color.white)
                }
            }
            Column {
                Button(
                    content = {
                        XLg(attrs = {
                            iconAttrs()
                            style { padding(5.px) }
                        })
                    },
                    outlined = true,
                    attrs = btnAttrs
                ) {
                    onCloseClick()
                }
            }
            Column {
                if (state.playbackState != PlaybackState.PLAYING) {
                    Button(
                        content = {
                            Play(attrs = iconAttrs)
                        },
                        outlined = true,
                        attrs = btnAttrs
                    ) {
                        onPlayClick(state.track)
                    }
                } else {
                    Button(
                        content = {
                            Pause(attrs = { iconAttrs() })
                        },
                        outlined = true,
                        attrs = btnAttrs
                    ) {
                        onPauseClick()
                    }
                }
            }
        }
    }
}

@OptIn(ExperimentalComposeWebSvgApi::class)
@Composable
private fun MediaPlayerWidgetLargeTrackPlayerRight(
    onCloseClick: () -> Unit,
) {
    Div(
        attrs = {
            style {
                display(DisplayStyle.Flex)
                flexFlow(FlexDirection.Row, FlexWrap.Nowrap)
                height(100.percent)
                alignContent(AlignContent.Start)
            }
        }
    ) {
        val btnAttrs: AttrBuilderContext<HTMLButtonElement> = {
            style {
                property("border-color", Color.transparent)
                alignSelf(AlignSelf.SelfStart)
                padding(0.1.cssRem)
            }
        }
        val iconAttrs: AttrBuilderContext<SVGElement> = {
            style {
                height(15.px)
                width(15.px)
                color(Color.white)
            }
        }
        Button(
            content = {
                XLg(attrs = iconAttrs)
            },
            outlined = true,
            attrs = btnAttrs
        ) {
            onCloseClick()
        }
    }
}
