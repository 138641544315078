package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.cosmwasm.std.types.Coin
import kotlin.Any
import kotlin.Boolean
import kotlin.String
import kotlin.UInt
import kotlin.collections.List

public class RoyaltyNftTableQueries(
  driver: SqlDriver,
  private val RoyaltyNftTableAdapter: RoyaltyNftTable.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getRoyaltyNft(id: String, mapper: (
    id: String,
    royaltyPercent: BigInteger,
    isOwner: Boolean,
    claimableBalances: List<Coin>,
    releaseId: UInt,
  ) -> T): Query<T> = GetRoyaltyNftQuery(id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      RoyaltyNftTableAdapter.royaltyPercentAdapter.decode(cursor.getString(1)!!),
      cursor.getBoolean(2)!!,
      RoyaltyNftTableAdapter.claimableBalancesAdapter.decode(cursor.getString(3)!!),
      RoyaltyNftTableAdapter.releaseIdAdapter.decode(cursor.getLong(4)!!)
    )
  }

  public fun getRoyaltyNft(id: String): Query<RoyaltyNftTable> = getRoyaltyNft(id) { id_,
      royaltyPercent, isOwner, claimableBalances, releaseId ->
    RoyaltyNftTable(
      id_,
      royaltyPercent,
      isOwner,
      claimableBalances,
      releaseId
    )
  }

  public fun <T : Any> getRoyaltyNftListForReleaseId(releaseId: UInt, mapper: (
    id: String,
    royaltyPercent: BigInteger,
    isOwner: Boolean,
    claimableBalances: List<Coin>,
    releaseId: UInt,
  ) -> T): Query<T> = GetRoyaltyNftListForReleaseIdQuery(releaseId) { cursor ->
    mapper(
      cursor.getString(0)!!,
      RoyaltyNftTableAdapter.royaltyPercentAdapter.decode(cursor.getString(1)!!),
      cursor.getBoolean(2)!!,
      RoyaltyNftTableAdapter.claimableBalancesAdapter.decode(cursor.getString(3)!!),
      RoyaltyNftTableAdapter.releaseIdAdapter.decode(cursor.getLong(4)!!)
    )
  }

  public fun getRoyaltyNftListForReleaseId(releaseId: UInt): Query<RoyaltyNftTable> =
      getRoyaltyNftListForReleaseId(releaseId) { id, royaltyPercent, isOwner, claimableBalances,
      releaseId_ ->
    RoyaltyNftTable(
      id,
      royaltyPercent,
      isOwner,
      claimableBalances,
      releaseId_
    )
  }

  public fun <T : Any> getRoyaltyNftList(mapper: (
    id: String,
    royaltyPercent: BigInteger,
    isOwner: Boolean,
    claimableBalances: List<Coin>,
    releaseId: UInt,
  ) -> T): Query<T> = Query(2_125_347_038, arrayOf("RoyaltyNftTable"), driver, "RoyaltyNftTable.sq",
      "getRoyaltyNftList",
      "SELECT id,royaltyPercent, isOwner, claimableBalances, releaseId FROM RoyaltyNftTable") {
      cursor ->
    mapper(
      cursor.getString(0)!!,
      RoyaltyNftTableAdapter.royaltyPercentAdapter.decode(cursor.getString(1)!!),
      cursor.getBoolean(2)!!,
      RoyaltyNftTableAdapter.claimableBalancesAdapter.decode(cursor.getString(3)!!),
      RoyaltyNftTableAdapter.releaseIdAdapter.decode(cursor.getLong(4)!!)
    )
  }

  public fun getRoyaltyNftList(): Query<RoyaltyNftTable> = getRoyaltyNftList { id, royaltyPercent,
      isOwner, claimableBalances, releaseId ->
    RoyaltyNftTable(
      id,
      royaltyPercent,
      isOwner,
      claimableBalances,
      releaseId
    )
  }

  public fun getFavorites(): Query<String> = Query(-373_771_641, arrayOf("RoyaltyNftTable"), driver,
      "RoyaltyNftTable.sq", "getFavorites", "SELECT id FROM RoyaltyNftTable WHERE isOwner = 1") {
      cursor ->
    cursor.getString(0)!!
  }

  public suspend fun deleteTable() {
    driver.execute(1_545_686_333, """DELETE FROM RoyaltyNftTable""", 0).await()
    notifyQueries(1_545_686_333) { emit ->
      emit("RoyaltyNftTable")
    }
  }

  public suspend fun deleteRoyaltyNftsByReleaseId(releaseId: UInt) {
    driver.execute(1_729_219_385, """DELETE FROM RoyaltyNftTable WHERE releaseId = ?""", 1) {
          bindLong(0, RoyaltyNftTableAdapter.releaseIdAdapter.encode(releaseId))
        }.await()
    notifyQueries(1_729_219_385) { emit ->
      emit("RoyaltyNftTable")
    }
  }

  public suspend fun upsertRoyaltyNft(
    id: String,
    royaltyPercent: BigInteger,
    isOwner: Boolean,
    claimableBalance: List<Coin>,
    releaseId: UInt,
  ) {
    transaction {
      driver.execute(-1_727_475_914, """
          |INSERT OR REPLACE INTO RoyaltyNftTable(id,royaltyPercent,isOwner,claimableBalances,releaseId)
          |    VALUES(?, ?, ?, ?, ?)
          """.trimMargin(), 5) {
            bindString(0, id)
            bindString(1, RoyaltyNftTableAdapter.royaltyPercentAdapter.encode(royaltyPercent))
            bindBoolean(2, isOwner)
            bindString(3, RoyaltyNftTableAdapter.claimableBalancesAdapter.encode(claimableBalance))
            bindLong(4, RoyaltyNftTableAdapter.releaseIdAdapter.encode(releaseId))
          }.await()
    }
    notifyQueries(-520_232_763) { emit ->
      emit("RoyaltyNftTable")
    }
  }

  private inner class GetRoyaltyNftQuery<out T : Any>(
    public val id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("RoyaltyNftTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("RoyaltyNftTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(637_084_704,
        """SELECT id,royaltyPercent,isOwner,claimableBalances,releaseId FROM RoyaltyNftTable WHERE id = ?""",
        mapper, 1) {
      bindString(0, id)
    }

    override fun toString(): String = "RoyaltyNftTable.sq:getRoyaltyNft"
  }

  private inner class GetRoyaltyNftListForReleaseIdQuery<out T : Any>(
    public val releaseId: UInt,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("RoyaltyNftTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("RoyaltyNftTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_475_633_335,
        """SELECT id,royaltyPercent, isOwner, claimableBalances, releaseId FROM RoyaltyNftTable WHERE releaseId = ?""",
        mapper, 1) {
      bindLong(0, RoyaltyNftTableAdapter.releaseIdAdapter.encode(releaseId))
    }

    override fun toString(): String = "RoyaltyNftTable.sq:getRoyaltyNftListForReleaseId"
  }
}
