package io.eqoty.shared.viewmodel.screens


// DEFINITION OF ALL SCREEN STACKS IN THE APP

enum class ScreenStack {
    Scaffold,
    Header,
    LeftNavigationRail,
    BottomNavigationBar,
    Wallet,
    Main,
    MPlayer
}