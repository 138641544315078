package io.eqoty.shared.datalayer.functions

import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.sources.filesystem.CommonFile
import io.eqoty.shared.datalayer.sources.webservices.ipfsProtocol
import io.ipfs.kotlin.commands.UploadAndAddProgressListener

suspend fun Repository.addFileToIpfs(
    file: CommonFile,
    uploadAndAddProgressListener: UploadAndAddProgressListener? = null
): String = withRepoContext {
    val addedImageHash = ipfsClient.addFile(file, uploadAndAddProgressListener).hash
    val fileUrl = ipfsProtocol + addedImageHash
    return@withRepoContext fileUrl
}
