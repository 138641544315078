package io.eqoty.shared.viewmodel.screens.releaserprofile

import io.eqoty.shared.viewmodel.StateManager
import io.eqoty.shared.viewmodel.screens.CallOnInitValues
import io.eqoty.shared.viewmodel.screens.ScreenInitSettings

// INITIALIZATION settings for this screen
// this is what should be implemented:
// - a data class implementing the ScreenParams interface, which defines the parameters to the passed to the screen
// - Navigation extension function taking the ScreenParams class as an argument, return the ScreenInitSettings for this screen
// to understand the initialization behaviour, read the comments in the ScreenInitSettings.kt file

fun StateManager.initReleaserProfile() = ScreenInitSettings(
    title = "Releaser Profile",
    initState = { ReleaserProfileScreenState() },
    callOnInit = {
        events.displayReleaserInfo()
        events.refreshReleaserInfoOnWalletChanges()
    },
    callOnInitAtEachNavigation = CallOnInitValues.CALL_AFTER_SHOWING_SCREEN
)
