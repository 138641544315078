package io.eqoty.composables.navigation.bars

import androidx.compose.runtime.*
import io.eqoty.composables.styling.ColumnSizes
import kotlinx.browser.document
import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.css.StyleScope
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement

/**
 * Used to specify the location of the Offcanvas component.
 */


@Composable
fun SidebarColumn(
    id: String = "sidebar",
    show: Boolean = true,
    sizes: ColumnSizes? = null,
    applyStyle: (StyleScope.() -> Unit)? = null,
    applyAttrs: (AttrsScope<HTMLDivElement>.() -> Unit)? = null,
    content: ContentBuilder<HTMLDivElement>
) {
    var initialComposition: Boolean by remember { mutableStateOf(true) }

    @Composable
    fun Collapse(content: ContentBuilder<HTMLDivElement>) {
        Div(
            {
                style {
                    applyStyle?.let { it() }
                }
                if (sizes == null) {
                    classes("col")
                } else {
                    classes(*sizes.classes)
                }
                classes("collapse", "collapse-horizontal")
//                if (initialComposition) {
                    //  in the initial composition document.getElementById(id) returns null, so the show hide js code
                    //  doesn't run. Set here the first time instead
                    // update on ^ for some reason this isn't the case anymore after updating bootstrap-compose
                    classes(if (show) "show" else "hide")
                    initialComposition = false
//                }
                id(id)
                applyAttrs?.let { it() }
            },
            content
        )
    }

    Collapse(content)

//    val myCollapse = document.getElementById(id)
//    myCollapse?.apply {
//        val bootstrap = io.eqoty.composables.modules.bootstrap
//        val bsCollapse = js("bootstrap.Collapse.getOrCreateInstance(myCollapse)")
//        if (show) {
//            bsCollapse.show()
//        } else {
//            bsCollapse.hide()
//        }
//    }
// ^ having show hide issues on the latest bootstrap compose. Just disable programmatic show/hide
}