package io.eqoty.shared.viewmodel

import co.touchlab.kermit.Logger
import io.eqoty.shared.datalayer.Repository

val debugLogger by lazy {
    val logger = Logger
    logger.setTag("eqoty")
    logger
}

class DKMPViewModel(val repo: Repository) {

    companion object Factory {
        // factory methods are defined in the platform-specific shared code (androidMain and iosMain)
    }

    val stateManager by lazy { StateManager(repo) }

}