package io.eqoty.shared.datalayer.functions

import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.ProductDealerContract
import io.eqoty.shared.datalayer.sources.LOCAL
import io.eqoty.shared.datalayer.sources.REMOTE
import io.eqoty.shared.datalayer.sources.localdb.nfts.getProductDealerContract
import io.eqoty.shared.datalayer.sources.localdb.nfts.releaseExists
import io.eqoty.shared.datalayer.sources.localdb.nfts.upsertProductDealerContract

suspend fun Repository.getProductDealerContract(releaseId: UInt): ProductDealerContract = withRepoContext {
    // ProductDealerContractTable has a foreign key constraint on a releaseId from ReleaseTable
    // So ensure a row with releaseId exists in ReleaseTable before calling upsertProductDealerContract
    val release = if (!localDb.releaseExists(releaseId)) {
        getRelease(releaseId, REMOTE)!!
    } else {
        getRelease(releaseId, LOCAL)!!
    }

    val productDealerContract = localDb.getProductDealerContract(releaseId) ?: run {
        val childSnip721 = queryProductDealerChildSnip721Contract(release.productDealer)
        localDb.upsertProductDealerContract(ProductDealerContract(releaseId, childSnip721, null))
        localDb.getProductDealerContract(releaseId)!!
    }
    return@withRepoContext productDealerContract
}