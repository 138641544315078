package io.eqoty.shared.datalayer.functions

import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.AudioFileAndInfo
import io.eqoty.shared.datalayer.sources.filesystem.CommonFile


suspend fun Repository.verifyAudioMeetsReqs(inFile: CommonFile): AudioFileAndInfo = withRepoContext {
    val inputAudioFileAndInfo = getAudioInfo(inFile)
    require(inputAudioFileAndInfo.lossless) { "Selected Audio must be lossless" }
    require(inputAudioFileAndInfo.bitDepth == 16 || inputAudioFileAndInfo.bitDepth == 24) { "Selected Audio must be 16 or 24 bit" }
    return@withRepoContext AudioFileAndInfo(inFile, inputAudioFileAndInfo)
}