package io.eqoty.shared.viewmodel.screens.nftpurchaselibrary

import io.eqoty.shared.datalayer.objects.PurchaseNft
import io.eqoty.shared.datalayer.objects.Release
import io.eqoty.shared.viewmodel.ScreenState
import io.eqoty.shared.viewmodel.screens.generic.TrackRowData

// here is the data class defining the state for this screen

data class NftPurchaseLibraryState(
    val isLoading: Boolean = false,
    val nftReleaseListItems: List<NftPurchaseLibraryListItem> = emptyList(),
) : ScreenState

/********** property classes **********/


data class NftPurchaseLibraryListItem(
    private val _release: Release,
    val purchase: PurchaseNft
) {
    // in the ViewModel classes, our computed properties only do UI-formatting operations
    // (the arithmetical operations, such as calculating a percentage, should happen in the DataLayer classes)
    val trackRowData = TrackRowData(_release, purchase)
}