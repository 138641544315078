package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import io.eqoty.cosmwasm.std.types.Coin
import kotlin.Any
import kotlin.String
import kotlin.UInt
import kotlin.collections.List

public class ProductDealerContractTableQueries(
  driver: SqlDriver,
  private val ProductDealerContractTableAdapter: ProductDealerContractTable.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getById(releaseId: UInt, mapper: (
    releaseId: UInt,
    childSnip721Address: String,
    childSnip721CodeHash: String,
    prices: List<Coin>?,
  ) -> T): Query<T> = GetByIdQuery(releaseId) { cursor ->
    mapper(
      ProductDealerContractTableAdapter.releaseIdAdapter.decode(cursor.getLong(0)!!),
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)?.let { ProductDealerContractTableAdapter.pricesAdapter.decode(it) }
    )
  }

  public fun getById(releaseId: UInt): Query<ProductDealerContractTable> = getById(releaseId) {
      releaseId_, childSnip721Address, childSnip721CodeHash, prices ->
    ProductDealerContractTable(
      releaseId_,
      childSnip721Address,
      childSnip721CodeHash,
      prices
    )
  }

  public fun <T : Any> getAll(mapper: (
    releaseId: UInt,
    childSnip721Address: String,
    childSnip721CodeHash: String,
    prices: List<Coin>?,
  ) -> T): Query<T> = Query(1_231_880_915, arrayOf("ProductDealerContractTable"), driver,
      "ProductDealerContractTable.sq", "getAll", "SELECT * FROM ProductDealerContractTable") {
      cursor ->
    mapper(
      ProductDealerContractTableAdapter.releaseIdAdapter.decode(cursor.getLong(0)!!),
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)?.let { ProductDealerContractTableAdapter.pricesAdapter.decode(it) }
    )
  }

  public fun getAll(): Query<ProductDealerContractTable> = getAll { releaseId, childSnip721Address,
      childSnip721CodeHash, prices ->
    ProductDealerContractTable(
      releaseId,
      childSnip721Address,
      childSnip721CodeHash,
      prices
    )
  }

  public suspend fun deleteTable() {
    driver.execute(1_062_781_307, """DELETE FROM ProductDealerContractTable""", 0).await()
    notifyQueries(1_062_781_307) { emit ->
      emit("ProductDealerContractTable")
    }
  }

  public suspend fun deleteByReleaseId(releaseId: UInt) {
    driver.execute(-768_401_192, """DELETE FROM ProductDealerContractTable WHERE releaseId = ?""",
        1) {
          bindLong(0, ProductDealerContractTableAdapter.releaseIdAdapter.encode(releaseId))
        }.await()
    notifyQueries(-768_401_192) { emit ->
      emit("ProductDealerContractTable")
    }
  }

  public suspend fun upsert(
    releaseId: UInt,
    childSnip721Address: String,
    childSnip721CodeHash: String,
    prices: List<Coin>?,
  ) {
    transaction {
      driver.execute(-1_766_460_536, """
          |INSERT OR REPLACE INTO ProductDealerContractTable(releaseId, childSnip721Address, childSnip721CodeHash, prices)
          |    VALUES(?, ?, ?, ?)
          """.trimMargin(), 4) {
            bindLong(0, ProductDealerContractTableAdapter.releaseIdAdapter.encode(releaseId))
            bindString(1, childSnip721Address)
            bindString(2, childSnip721CodeHash)
            bindString(3, prices?.let { ProductDealerContractTableAdapter.pricesAdapter.encode(it)
                })
          }.await()
    }
    notifyQueries(1_642_852_759) { emit ->
      emit("ProductDealerContractTable")
    }
  }

  private inner class GetByIdQuery<out T : Any>(
    public val releaseId: UInt,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ProductDealerContractTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ProductDealerContractTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-466_356_000,
        """SELECT * FROM ProductDealerContractTable WHERE releaseId = ?""", mapper, 1) {
      bindLong(0, ProductDealerContractTableAdapter.releaseIdAdapter.encode(releaseId))
    }

    override fun toString(): String = "ProductDealerContractTable.sq:getById"
  }
}
