package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.objects.RoyaltyNft
import io.eqoty.shared.datalayer.utils.bi10
import mylocal.db.LocalDb

fun RoyaltyNftTable.toRoyaltyNft() =
    RoyaltyNft(
        id = id.split(':')[1].bi10,
        royaltyPercent = royaltyPercent,
        isOwner = isOwner,
        claimableBalances = claimableBalances,
        releaseId = releaseId
    )

suspend fun LocalDb.getRoyaltyNft(releaseId: UInt, id: BigInteger): RoyaltyNft? {
    return royaltyNftTableQueries.getRoyaltyNft("${releaseId}:${id}").awaitAsOneOrNull()?.toRoyaltyNft()
}

suspend fun LocalDb.getRoyaltyNftList(): List<RoyaltyNft> {
    return royaltyNftTableQueries.getRoyaltyNftList().awaitAsList().map { it.toRoyaltyNft() }
}

suspend fun LocalDb.getRoyaltyNftsByReleaseId(releaseId: UInt): List<RoyaltyNft> {
    return royaltyNftTableQueries.getRoyaltyNftListForReleaseId(releaseId).awaitAsList().map { it.toRoyaltyNft() }
}

suspend fun LocalDb.upsertRoyaltyNft(releaseId: UInt, royaltyNft: RoyaltyNft) {
    royaltyNftTableQueries.upsertRoyaltyNft(
        id = "${releaseId}:${royaltyNft.id.toString(10)}",
        royaltyPercent = royaltyNft.royaltyPercent,
        claimableBalance = royaltyNft.claimableBalances,
        isOwner = royaltyNft.isOwner,
        releaseId = royaltyNft.releaseId,
    )
}

suspend fun LocalDb.setRoyaltyNftList(releaseId: UInt, list: List<RoyaltyNft>) {
    royaltyNftTableQueries.transaction {
        list.forEach { nft ->
            royaltyNftTableQueries.upsertRoyaltyNft(
                id = "${releaseId}:${nft.id.toString(10)}",
                royaltyPercent = nft.royaltyPercent,
                claimableBalance = nft.claimableBalances,
                isOwner = nft.isOwner,
                releaseId = nft.releaseId,
            )
        }
    }
}

suspend fun LocalDb.deleteRoyaltyNftsByReleaseId(releaseId: UInt) {
    return royaltyNftTableQueries.deleteRoyaltyNftsByReleaseId(releaseId)
}

suspend fun LocalDb.deleteAllRoyaltyNfts() {
    return royaltyNftTableQueries.deleteTable()
}
