package io.eqoty.shared.devicelayer.functions

import AudioWorkerMessage
import co.touchlab.kermit.Logger
import io.eqoty.kryptools.aes256gcm.Aes256Gcm
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.AttachmentEndpoint
import io.eqoty.shared.datalayer.sources.ipfs.IPFSHTTPClient
import io.eqoty.shared.devicelayer.DeviceAudioHandler
import io.eqoty.shared.devicelayer.PlaybackProgressListener
import jslib.webapi.AudioContext
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import okio.Buffer
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Float32Array
import org.khronos.webgl.Int8Array
import org.w3c.dom.Audio
import org.w3c.dom.MessageEvent
import org.w3c.dom.Worker
import org.w3c.dom.mediasource.AppendMode
import org.w3c.dom.mediasource.MediaSource
import org.w3c.dom.mediasource.SEQUENCE
import org.w3c.dom.mediasource.SourceBuffer
import org.w3c.dom.url.URL.Companion.createObjectURL


private fun ByteArray.asArrayBuffer(): ArrayBuffer = this.unsafeCast<Int8Array>().buffer

fun entriesOf(jsObject: dynamic): List<Pair<String, dynamic>> =
    (js("Object.entries") as (dynamic) -> Array<Array<dynamic>>).invoke(jsObject)
        .map { entry -> entry[0] as String to entry[1] }

actual suspend fun Repository.playAudio(
    endpoint: AttachmentEndpoint, playbackProgressListener: PlaybackProgressListener
) {
    val playWithMediaSource = false
    if (DeviceAudioHandler.audio == null) {
        DeviceAudioHandler.audio = Audio()
        DeviceAudioHandler.play(playbackProgressListener)
        if (playWithMediaSource) {
            playWithMediaSource(endpoint, ipfsClient, aes256Gcm)
        } else {
            playWithOpusStreamDecoder(endpoint, ipfsClient, aes256Gcm)
        }
    } else {
        DeviceAudioHandler.play(playbackProgressListener)
    }
}


/***
 * Implementation based on:
 * https://github.com/kmoskwiak/node-tcp-streaming-server/blob/master/client/js/app.js
 */
suspend fun playWithMediaSource(
    endpoint: AttachmentEndpoint,
    ipfsClient: IPFSHTTPClient,
    aes256Gcm: Aes256Gcm,
) {
    val dataQueue = Buffer()

    val mediaSource = MediaSource()
    DeviceAudioHandler.audio!!.setAttribute("src", createObjectURL(mediaSource))
    var buffer: SourceBuffer? = null

    fun tryUpdateBuffer() {
        if (dataQueue.size > 0 && !buffer!!.updating) {
            val bytesToAttemptToWrite = dataQueue.readByteArray()
            try {
                buffer!!.appendBuffer(bytesToAttemptToWrite.asArrayBuffer())
            } catch (t: Throwable) {
                dataQueue.write(bytesToAttemptToWrite)
                console.log(
                    "Tried and failed to add ${bytesToAttemptToWrite.size} bytes to audio buffer. " + "Bytes were added back into queue"
                )
            }
        }
    }

    suspend fun startBuffering() {
        var startPlay = true
        catStream(endpoint, ipfsClient, aes256Gcm) { decryptedBytes ->
            dataQueue.write(decryptedBytes)
            tryUpdateBuffer()
            if (startPlay) {
                DeviceAudioHandler.audio!!.play()
                console.log("Play")
                startPlay = false
            }
        }
    }


    mediaSource.addEventListener("sourceopen", {
//                console.log(MediaSource.isTypeSupported("""audio/mpeg"""))
        buffer = mediaSource.addSourceBuffer("""audio/mpeg""")
        buffer!!.mode = AppendMode.Companion.SEQUENCE

        buffer!!.addEventListener("update", { // Note: Have tried 'updateend'
            tryUpdateBuffer()
        })

        buffer!!.addEventListener("updateend", {
            tryUpdateBuffer()
        })
        MainScope().launch {
            startBuffering()
        }
    })
}

/***
 * Implementation based on:
 * https://github.com/AnthumChris/fetch-stream-audio/blob/cefcb23f1516f80a09c1d42e203379aa85acc2e4/src/js/modules/audio-stream-player.mjs#L144
 */
suspend fun playWithOpusStreamDecoder(
    endpoint: AttachmentEndpoint,
    ipfsClient: IPFSHTTPClient,
    aes256Gcm: Aes256Gcm,
) {
    val sessionId = window.performance.now()
    val worker = Worker("appWeb-worker.js")

    val audioCtx = AudioContext()
    val audio = DeviceAudioHandler.audio!!
    audioCtx.createMediaElementSource(audio)
    // https://stackoverflow.com/questions/57302931/make-audiobuffersourcenode-the-audio-source-of-an-audio-tag
    val streamNode = audioCtx.createMediaStreamDestination()
    audio.srcObject = streamNode.stream
    DeviceAudioHandler.audio = audio

    var playStartedAt = 0.0
    var totalTimeScheduled = 0.0
    var audioBufsCreated = 0
    var audioBufsEnded = 0


    fun play(completedWork: AudioWorkerMessage) = with(completedWork) {
        val audioSrc = audioCtx.createBufferSource()
        val audioBuffer = audioCtx.createBuffer(numberOfChannels, length.toLong(), sampleRate)

        audioSrc.addEventListener("ended") {
            if (++audioBufsEnded == audioBufsCreated) {
                DeviceAudioHandler.stop()
            }
        }
        audioBufsCreated++

        for (c in 0 until numberOfChannels) {
            audioBuffer.copyToChannel(channelData[c], c)
        }

        var startDelay = 0.0
        // initialize first play position.  initial clipping/choppiness sometimes occurs and intentional start latency needed
        // read more: https://github.com/WebAudio/web-audio-api/issues/296#issuecomment-257100626
        if (playStartedAt == 0.0) {/* this clips in Firefox, plays */
            // const startDelay = audioCtx.baseLatency || (128 / audioCtx.sampleRate);

            /* this doesn't clip in Firefox (256 value), plays */
            // startDelay = this._audioCtx.baseLatency || (256 / this._audioCtx.sampleRate);

            // 100ms allows enough time for largest 60ms Opus frame to decode
            startDelay = 0.1

            /* this could be useful for firefox but outputLatency is about 250ms in FF. too long */
            // const startDelay = audioCtx.outputLatency || audioCtx.baseLatency || (128 / audioCtx.sampleRate);

            playStartedAt = audioCtx.currentTime + startDelay
            // this._updateState({ latency: performance.now()-this._getDownloadStartTime()+startDelay*1000 });
        }

        audioSrc.buffer = audioBuffer
        // connect it to the Audio object
        audioSrc.connect(streamNode)

        val startAt = playStartedAt + totalTimeScheduled
        if (audioCtx.currentTime >= startAt) {
            Logger.w("Audio Skip detected: audioCtx.currentTime >= startAt")
        }
        audioSrc.start(startAt)
        totalTimeScheduled += audioBuffer.duration
    }


    worker.onmessage = { m: MessageEvent ->
        // mangled types issue: https://youtrack.jetbrains.com/issue/KT-44944/KJS-Non-mangled-types
        val dataEntries = entriesOf(m.data)
        val completedWork = AudioWorkerMessage(
            channelData = dataEntries[0].second as Array<Float32Array>,
            length = dataEntries[1].second as Int,
            numberOfChannels = dataEntries[2].second as Int,
            sampleRate = dataEntries[3].second as Int,
        )
        play(completedWork)
    }
    worker.onerror = {
        console.log("worker.onerror")
        console.log(it)
    }


    catStream(endpoint, ipfsClient, aes256Gcm) { decryptedBytes ->
        var msg = Unit.asDynamic()
        msg.type = "decodeOpus"
        msg.decode = decryptedBytes.asArrayBuffer()
        msg.sessionId = sessionId
        worker.postMessage(msg, arrayOf(msg.decode))
    }
}
