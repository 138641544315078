package io.eqoty.shared.viewmodel.screens.walletInfo

import io.eqoty.shared.datalayer.functions.getWalletAddress
import io.eqoty.shared.datalayer.functions.getWeb3Network
import io.eqoty.shared.isSupported
import io.eqoty.shared.viewmodel.Events
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.scaffold.setWalletScreenExpanded
import io.eqoty.shared.viewmodel.screens.topbar.updateWalletBtnInfo

/********** EVENT functions, called directly by the UI layer **********/
fun Events.walletInfoAndCollapseWalletOnSuccess() =
    inScreenScopeLaunch(ScreenStack.Wallet) {
        val walletAddress = dataRepository.getWalletAddress()
        val network = dataRepository.getWeb3Network()
        stateManager.updateScreen(ScreenStack.Wallet, WalletInfoScreenState::class) {
            it.copy(
                formatted = it.formatted.copy(
                    displayMessage = "Connected to ${network?.name}!",
                    walletAddress = walletAddress,
                    network = network,
                ),
                networkSupported = network.isSupported(),
            )
        }
        updateWalletBtnInfo()?.join()
        if (network.isSupported()) {
            setWalletScreenExpanded(false)?.join()
        }
    }