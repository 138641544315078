package io.eqoty.shared.datalayer.functions

import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.Release
import io.eqoty.shared.datalayer.sources.DataSrc
import io.eqoty.shared.datalayer.sources.LOCAL
import io.eqoty.shared.datalayer.sources.RemoteSrc
import io.eqoty.shared.datalayer.sources.localdb.nfts.getRelease

suspend fun Repository.getReleases(releaseIds: List<UInt>, dataSrc: DataSrc = LOCAL): List<Release?> = withRepoContext {
    return@withRepoContext if (dataSrc is RemoteSrc) {
        val releases = getReleasesBatch(releaseIds)
        releases.map { updateRelease(it) }
    } else {
        releaseIds.map { localDb.getRelease(it) }
    }
}

suspend fun Repository.getRelease(releaseId: UInt, dataSrc: DataSrc = LOCAL): Release? = withRepoContext {
    return@withRepoContext getReleases(listOf(releaseId), dataSrc).first()
}