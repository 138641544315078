package io.eqoty.shared.datalayer.functions

import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.sources.ALL
import io.eqoty.shared.datalayer.sources.DataSrc
import io.eqoty.shared.datalayer.sources.LOCAL
import io.eqoty.shared.datalayer.sources.REMOTE
import io.eqoty.shared.datalayer.sources.localdb.nfts.getPurchaseNftsByReleaseId
import io.eqoty.shared.datalayer.sources.web3.CosmosWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.EthWeb3Provider


suspend fun Repository.getOwnedPurchaseTokenIds(
    releaseId: UInt, address: String, src: DataSrc
): List<BigInteger> = withRepoContext {
    return@withRepoContext when (web3Provider!!) {
        is EthWeb3Provider -> throw NotImplementedError()
        is CosmosWeb3Provider -> getOwnedPurchaseTokenIdsScrt(releaseId, address, src)
    }
}


private suspend fun Repository.getOwnedPurchaseTokenIdsScrt(
    releaseId: UInt, ownerAddr: String, src: DataSrc
): List<BigInteger> =
    withRepoContext {
        val childSnip721 = getProductDealerContract(releaseId).childSnip721
        return@withRepoContext when (src) {
            is LOCAL -> {
                localDb.getPurchaseNftsByReleaseId(releaseId).map { it.id }
            }

            is ALL, REMOTE -> {
                try {
                    getOwnedPurchaseTokens(childSnip721, ownerAddr, false)
                } catch (t: Throwable) {
                    // todo: report this online
                    t.printStackTrace()
                    getOwnedPurchaseTokens(childSnip721, ownerAddr, true)
                    emptyList()
                }
            }
        }
    }