package io.eqoty.shared.viewmodel.screens.releaserprofile

import io.eqoty.secret.std.contract.msg.EqotyReleaseMsgs
import io.michaelrocks.libphonenumber.kotlin.PhoneNumberUtil

class ReleaserProfileFormInput(
    private val phoneNumberUtil: PhoneNumberUtil,
    firstName: String,
    lastName: String,
    email: String,
    phone: String?,
    mailingAddress: String?,
    govId: String?,
    selfie: String?,
) {
    var firstName: String = firstName
        get() = field.trim()

    var lastName: String = lastName
        get() = field.trim()

    var email: String = email
        get() = field.trim()

    var phone: String? = phone
        get() = field?.trim()?.ifEmpty { null }

    var mailingAddress: String? = mailingAddress
        get() = field?.trim()?.ifEmpty { null }

    var govId: String? = govId
        get() = field?.trim()?.ifEmpty { null }

    var selfie: String? = selfie
        get() = field?.trim()?.ifEmpty { null }

    fun validate(): ReleaserProfileFormInputErrors {
        val inputErrors = ReleaserProfileFormInputErrors()

        if (firstName.isBlank()) {
            inputErrors.firstNameError = "First name required"
        }

        if (lastName.isBlank()) {
            inputErrors.lastNameError = "Last name required"
        }

        if (!email.matches(
                Regex(
                    """(?:[a-z0-9!#${'$'}%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#${'$'}%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])"""
                )
            )
        ) {
            inputErrors.emailError = "Invalid email"
        }

        phone?.run {
            if (!this.contains("+")) {
                inputErrors.phoneError = "Phone number must contain country code (e.g. +1 for US)"
            } else {
                try {
                    phoneNumberUtil.parse(this, null)
                } catch (t: Throwable) {
                    inputErrors.phoneError = t.message
                }
            }
        }

        if (!mailingAddress.isNullOrBlank()) {
            TODO()
        }

        if (!govId.isNullOrBlank()) {
            TODO()
        }

        if (!selfie.isNullOrBlank()) {
            TODO()
        }


        return inputErrors
    }

    fun toReleaserInfo(): EqotyReleaseMsgs.ReleaserInfo =
        EqotyReleaseMsgs.ReleaserInfo(
            firstName = firstName,
            lastName = lastName,
            email = email,
            phone = phone?.run {
                phoneNumberUtil.format(
                    phoneNumberUtil.parse(this, null),
                    PhoneNumberUtil.PhoneNumberFormat.E164
                )
            },
            mailingAddress = mailingAddress,
            govId = govId,
            selfie = selfie,
        )
}

data class ReleaserProfileFormInputErrors(
    var firstNameError: String? = null,
    var lastNameError: String? = null,
    var emailError: String? = null,
    var phoneError: String? = null,
    var mailingAddressError: String? = null,
    var govIdError: String? = null,
    var selfieError: String? = null,
) {
    fun hasNoErrors(): Boolean {
        return firstNameError == null &&
                lastNameError == null &&
                emailError == null &&
                phoneError == null &&
                mailingAddressError == null &&
                govIdError == null &&
                selfieError == null
    }
}
