package io.eqoty.shared.viewmodel.screens.store

import io.eqoty.shared.datalayer.sources.ALL
import io.eqoty.shared.viewmodel.StateManager
import io.eqoty.shared.viewmodel.screens.CallOnInitValues
import io.eqoty.shared.viewmodel.screens.ScreenInitSettings
import io.eqoty.shared.viewmodel.screens.StoreParams

// INITIALIZATION settings for this screen
// this is what should be implemented:
// - a data class implementing the ScreenParams interface, which defines the parameters to the passed to the screen
// - Navigation extension function taking the ScreenParams class as an argument, return the ScreenInitSettings for this screen
// to understand the initialization behaviour, read the comments in the ScreenInitSettings.kt file


fun StateManager.initStore(params: StoreParams) = ScreenInitSettings(
    title = "Store: " + params.listType.name,
    initState = { StoreState(isLoading = true, listType = params.listType) },
    callOnInit = {
        events.refreshStoreScreen(ALL)
        events.refreshStoreScreenOnEthWalletChanges()
    },
    callOnInitAtEachNavigation = CallOnInitValues.CALL_AFTER_SHOWING_SCREEN, // in this way favourites can refresh
)