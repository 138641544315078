package io.eqoty.shared.datalayer.functions

import io.eqoty.secretk.wallet.Wallet
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.jsKeplrWeb3Provider
import io.eqoty.shared.datalayer.jsWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.SecretEndpoints
import io.eqoty.shared.datalayer.sources.web3.WalletProvider
import io.eqoty.utils.KeplrEnigmaUtils
import io.eqoty.wallet.MetaMaskWalletWrapper
import io.eqoty.wallet.OfflineSignerOnlyAminoWalletWrapper
import jslib.walletconnect.IWalletConnectProviderOptionsInstance
import jslib.walletconnect.WalletConnectProvider
import jslibs.secretjs.MetaMaskWallet
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.coroutines.delay
import org.w3c.dom.get
import web3.Web3
import kotlin.js.Promise

actual suspend fun Repository.injectWeb3ProviderWithWindowProvider(selectedWallet: WalletProvider) = withRepoContext {
    when (selectedWallet) {
        WalletProvider.Keplr -> {
            val maxTries = 1000
            var tries = 0
            while (
                window.asDynamic().keplr == null ||
                window.asDynamic().getOfflineSignerOnlyAmino == null ||
                window.asDynamic().getEnigmaUtils == null
            ) {
                delay(10)
                if (++tries == maxTries) {
                    throw UnsupportedOperationException("Couldn't get Keplr wallet")
                }
            }

            jsKeplrWeb3Provider.chainId = "pulsar-3"
            val suggestChainPromise: Promise<dynamic> = window.asDynamic().keplr
                .experimentalSuggestChain(
                    getPulsarSuggestion(
                        SecretEndpoints.scrtGrpcGatewayEndpoint,
                        SecretEndpoints.scrtRpcEndpoint,
                        jsKeplrWeb3Provider.chainId!!

                    )
                ) as Promise<dynamic>
            suggestChainPromise.await()
            val enablePromise: Promise<dynamic> =
                window.asDynamic().keplr.enable(jsKeplrWeb3Provider.chainId!!) as Promise<dynamic>
            enablePromise.await()
            jsKeplrWeb3Provider.walletBacking =
                OfflineSignerOnlyAminoWalletWrapper(window.asDynamic().keplr, jsKeplrWeb3Provider.chainId!!)
            jsKeplrWeb3Provider.externalEncryptionUtils =
                KeplrEnigmaUtils(window.asDynamic().keplr, jsKeplrWeb3Provider.chainId!!)
        }

        WalletProvider.MetaMask -> {
            //https://medium.com/valist/how-to-connect-web3-js-to-metamask-in-2020-fee2b2edf58a
            val provider = window["ethereum"]
            val web3 = Web3(provider).apply {
                eth.handleRevert = true
            }
            val account = web3.eth.requestAccounts().await().first()
            jsKeplrWeb3Provider.chainId = "pulsar-3"
            jsKeplrWeb3Provider.walletBacking = MetaMaskWalletWrapper(MetaMaskWallet.create(provider as Any, account).await())
        }

        WalletProvider.WalletConnect -> {
            val provider = WalletConnectProvider(
                IWalletConnectProviderOptionsInstance(
                    infuraId = "049fe990ec7c49daa50ed4009d20d590",
                )
            )
            jsWeb3Provider.web3 = Web3(provider).apply {
                eth.handleRevert = true
            }
        }

        WalletProvider.Internal, WalletProvider.InternalRandom -> {}
    }
}


fun getPulsarSuggestion(lcdUrl: String, rpcUrl: String, chainId: String): dynamic {
    val CHAIN_NAME = "Local Testnet"  //Anything you want
    val DENOM = "SCRT"
    val MINIMAL_DENOM = "uscrt"
    val suggestion: dynamic = JSON.parse(
        """{
            "chainId": "$chainId",
            "chainName": "$CHAIN_NAME",
            "rpc": "$rpcUrl",
            "rest": "$lcdUrl",
            "bip44": { "coinType": 529 },
            "coinType": 529,
            "stakeCurrency": { "coinDenom": "$DENOM",
                             "coinMinimalDenom": "$MINIMAL_DENOM",
                             "coinDecimals": 6
                             },
            "bech32Config": {
                "bech32PrefixAccAddr": "secret",
                "bech32PrefixAccPub": "secretpub",
                "bech32PrefixValAddr": "secretvaloper",
                "bech32PrefixValPub": "secretvaloperpub",
                "bech32PrefixConsAddr": "secretvalcons",
                "bech32PrefixConsPub": "secretvalconspub"
            },
            "currencies": [
                { "coinDenom": "$DENOM",
                  "coinMinimalDenom": "$MINIMAL_DENOM",
                  "coinDecimals": 6
                }
            ],
            "feeCurrencies": [
                { "coinDenom": "$DENOM",
                  "coinMinimalDenom": "$MINIMAL_DENOM",
                  "coinDecimals": 6,
                  "gasPriceStep": { 
                        "low": 0.1,
                        "average": 0.25,
                        "high": 0.4
                   }
                }
            ],
            "features": ["secretwasm"]
        }"""
    )
    return suggestion
}