package io.eqoty.composables.screens.walletinfo

import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.*
import app.softwork.bootstrapcompose.SpacingSpecs.SpacingSize.Medium
import io.eqoty.shared.viewmodel.screens.walletInfo.WalletInfoScreenState
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text


@Composable
fun WalletInfoScreen(
    state: WalletInfoScreenState,
    onDisconnectWallet: () -> Unit,
    onReleaserModeCheckedChange: (Boolean) -> Unit,
) {
    Row(attrs = {
        style {
            width(200.px)
            alignItems(AlignItems.Center)
        }
        classes("d-flex", "flex-column", "h-100")
    }) {
        if (state.networkSupported) {
            ConnectedScreen(state, onDisconnectWallet, onReleaserModeCheckedChange)
        } else if (!state.networkSupported) {
            WalletInfoScreenUnsupportedNetwork(state, onDisconnectWallet)
        }
    }
}

@Composable
fun WalletInfoScreenUnsupportedNetwork(
    state: WalletInfoScreenState, onDisconnectWallet: () -> Unit
) {
    Row {
        Column {
            Text(state.formatted.unsupportedNetworkInfo)
        }
    }
    Row {
        Column {
            Button("Disconnect") {
                onDisconnectWallet()
            }
        }
    }
}


@Composable
fun ConnectedScreen(
    state: WalletInfoScreenState,
    onDisconnectWallet: () -> Unit,
    onArtistModeCheckedChange: (Boolean) -> Unit,
) {
    val rowStyling: RowStyling.() -> Unit = { Margins { Top { size = Medium } } }
    Row({ rowStyling() }) {
        Text(state.formatted.supportedNetworkInfo)
    }
    Row({ rowStyling() }) {
        Div(attrs = {
            classes("text-break")
        }) {
            Text(state.formatted.walletAddressInfo)
        }
    }
    Row({ rowStyling() }) {
        state.formatted.displayMessage?.let {
            Text(it)
        }
    }
    Row({ rowStyling() }) {
        Column {
            Button("Disconnect") {
                onDisconnectWallet()
            }
        }
    }
    Row({ rowStyling() }) {
        state.errorMessage?.let {
            Text(it)
        }
    }
    state.releaserMode?.let {
        Row(attrs = { classes("mt-auto") }) {
            NavigationRailSwitch(
                label = "Releaser Mode", checked = it, onArtistModeCheckedChange
            )
        }
    }
}

@Composable
private fun NavigationRailSwitch(
    label: String,
    checked: Boolean,
    onCheckedChange: (Boolean) -> Unit,
) {
    ListItem(attrs = {
        classes("list-group-item-action", "py-3")
    }) {
        Checkbox(checked, label = label, switch = true) {
            onCheckedChange(it)
        }
    }
}

