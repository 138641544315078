package io.eqoty.shared.viewmodel.screens.leftnavigationrail

import io.eqoty.shared.datalayer.functions.getReleaserId
import io.eqoty.shared.viewmodel.Events
import io.eqoty.shared.viewmodel.screens.Level1Navigation
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.bottomnavigationbar.BottomNavigationBarState
import io.eqoty.shared.viewmodel.screens.walletInfo.WalletInfoScreenState


/********** NO EVENT FUNCTION IS DEFINED ON THIS SCREEN **********/

fun Events.selectNavigationTab(selectedTab: NavigationTab) {
    stateManager.updateScreen(ScreenStack.LeftNavigationRail, LeftNavigationRailState::class) {
        it.copy(
            selectedTab = selectedTab
        )
    }
    stateManager.updateScreen(ScreenStack.BottomNavigationBar, BottomNavigationBarState::class) {
        it.copy(
            selectedTab = selectedTab
        )
    }
    stateManager.navigation.run {
        when (selectedTab) {
            is HomeTab -> selectLevel1Navigation(
                ScreenStack.Main,
                Level1Navigation.HomeProvider.screenIdentifier
            )

            is StoreTab -> selectLevel1Navigation(
                ScreenStack.Main,
                Level1Navigation.StoreProvider.screenIdentifier
            )

            is SongsTab -> selectLevel1Navigation(
                ScreenStack.Main,
                Level1Navigation.NftPurchaseLibraryProvider.screenIdentifier
            )

            is RoyaltiesTab -> selectLevel1Navigation(
                ScreenStack.Main,
                Level1Navigation.MyRoyaltiesProvider.screenIdentifier
            )

            is MyArtistsTab -> selectLevel1Navigation(
                ScreenStack.Main,
                Level1Navigation.MyOrganizationsProvider.screenIdentifier
            )

            is ReleaserProfileTab -> selectLevel1Navigation(
                ScreenStack.Main,
                Level1Navigation.ReleaserProfileProvider.screenIdentifier
            )
        }
    }
}

fun Events.toggleExpandTabFolder(tabFolder: NavigationTabFolder) {
    when (tabFolder) {
        is MyArtistsTab -> {
            stateManager.updateScreen(ScreenStack.LeftNavigationRail, LeftNavigationRailState::class) {
                it.copy(
                    myArtistsTab = it.myArtistsTab.copy(
                        expanded = !it.myArtistsTab.expanded
                    )
                )
            }
        }
    }
}

fun Events.setReleaserMode(releaserModeEnabledUpdate: Boolean) {
    dataRepository.localSettings.releaserMode = releaserModeEnabledUpdate
    displayReleaserMode()
}

/***
 * Will update the ui to display the releaser mode or regular mode according to the saved releaserMode setting
 */
fun Events.displayReleaserMode() =
    inScreenScopeLaunch(ScreenStack.LeftNavigationRail) {
        val releaserMode = dataRepository.localSettings.releaserMode
        stateManager.updateScreen(ScreenStack.Wallet, WalletInfoScreenState::class) {
            it.copy(
                releaserMode = releaserMode,
            )
        }
        stateManager.updateScreen(ScreenStack.LeftNavigationRail, LeftNavigationRailState::class) {
            it.copy(
                releaserProfileTab = it.releaserProfileTab.copy(
                    visible = releaserMode
                )
            )
        }
        stateManager.updateScreen(ScreenStack.BottomNavigationBar, BottomNavigationBarState::class) {
            it.copy(
                // todo: I don't like this. Figure out a way to not have to use when....
                //  using it.releaserTab.apply don't trigger a recompose for some reason
                releaserTab = when (it.releaserTab) {
                    is ReleaserProfileTab -> it.releaserTab.copy(
                        visible = releaserMode
                    )

                    is MyArtistsTab -> it.releaserTab.copy(
                        visible = releaserMode
                    )

                }
            )
        }
        val showMyArtistsRailTab = if (releaserMode) {
            dataRepository.getReleaserId() != null
        } else {
            false
        }
        stateManager.updateScreen(ScreenStack.LeftNavigationRail, LeftNavigationRailState::class) {
            it.copy(
                myArtistsTab = it.myArtistsTab.copy(
                    visible = showMyArtistsRailTab
                )
            )
        }
        val releaserModeScreens = listOf(
            Level1Navigation.ReleaserProfileProvider.screenIdentifier.screen,
            Level1Navigation.MyOrganizationsProvider.screenIdentifier.screen
        )
        if (!releaserMode &&
            releaserModeScreens.contains(stateManager.currentLevel1ScreenIdentifier(ScreenStack.Main)?.screen)
        ) {
            stateManager.navigation.selectLevel1Navigation(
                ScreenStack.Main,
                Level1Navigation.HomeProvider.screenIdentifier
            )
        }
    }
