package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.ColumnAdapter
import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.cosmwasm.std.types.Coin
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.UInt
import kotlin.collections.List

public data class RoyaltyNftTable(
  public val id: String,
  public val royaltyPercent: BigInteger,
  public val isOwner: Boolean,
  public val claimableBalances: List<Coin>,
  public val releaseId: UInt,
) {
  public class Adapter(
    public val royaltyPercentAdapter: ColumnAdapter<BigInteger, String>,
    public val claimableBalancesAdapter: ColumnAdapter<List<Coin>, String>,
    public val releaseIdAdapter: ColumnAdapter<UInt, Long>,
  )
}
