package io.eqoty.shared.datalayer.functions

import co.touchlab.kermit.Logger
import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.Release
import io.eqoty.shared.datalayer.objects.RoyaltyNft
import io.eqoty.shared.datalayer.sources.LOCAL
import io.eqoty.shared.datalayer.sources.REMOTE
import io.eqoty.shared.datalayer.sources.localdb.nfts.deleteRoyaltyNftsByReleaseId
import io.eqoty.shared.datalayer.sources.localdb.nfts.getRelease
import io.eqoty.shared.datalayer.sources.localdb.nfts.getRoyaltyNftsByReleaseId
import io.eqoty.shared.datalayer.sources.localdb.nfts.setRoyaltyNftList

data class ReleaseAndRoyaltyNfts(val release: Release, val royaltyNfts: List<RoyaltyNft>)

suspend fun Repository.getReleaseWithRoyaltyNfts(releaseId: UInt): ReleaseAndRoyaltyNfts = withRepoContext {

    val nftRoyaltyNfts: List<RoyaltyNft> = try {
        getOwnedRoyaltyTokens(releaseId)
    } catch (t: Throwable) {
        Logger.d("getRoyaltyNftList ERROR MESSAGE:", t)
        emptyList()
    }

    localDb.deleteRoyaltyNftsByReleaseId(releaseId)
    localDb.setRoyaltyNftList(releaseId, nftRoyaltyNfts)

    // RETURN a list where:
    //  the datalayer list object (NftMetaData) has been mapped to the viewmodel list object (StoreItem)
    val release = localDb.getRelease(releaseId)!!
    ReleaseAndRoyaltyNfts(release, localDb.getRoyaltyNftsByReleaseId(releaseId))
}

private suspend fun Repository.getOwnedRoyaltyTokens(releaseId: UInt): List<RoyaltyNft> {
    val senderAddress = getWalletAddress()!!
    return getOwnedRoyaltyNftIds(releaseId, senderAddress, REMOTE).map { id ->
        val release = getRelease(releaseId, LOCAL)!!
        val royaltyDealer = release.royaltyDealer
        // todo save the childSnip721 info to the db and get from there
        val childSnip721 = queryRoyaltyDealerChildSnip721Contract(release.royaltyDealer)
        val claimableBalance = getClaimableRoyaltyBalance(royaltyDealer, childSnip721, id)
        val shares =
            getRoyaltyTokenRoyaltyShares(royaltyDealer, id)
        RoyaltyNft(
            id = id,
            isOwner = true,
            royaltyPercent = shares,
            releaseId = releaseId,
            claimableBalances = claimableBalance
        )
    }
}
