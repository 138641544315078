package io.eqoty.composables

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.saveable.rememberSaveableStateHolder
import io.eqoty.composables.navigation.HandleBackButton
import io.eqoty.composables.navigation.Router
import io.eqoty.shared.ui.utils.ScreenSizeUtils
import io.eqoty.shared.ui.utils.smallScaffoldActive
import io.eqoty.shared.viewmodel.DKMPViewModel
import io.eqoty.shared.viewmodel.screens.scaffold.setSmallScaffoldActive

@Composable
fun MainComposable(model: DKMPViewModel) {
    val dkmpNav = model.stateManager.navigation
    val screenUIsStateHolder = rememberSaveableStateHolder()
    dkmpNav.Router(screenUIsStateHolder)

    LaunchedEffect(Unit) {
        // run once on first composition
        dkmpNav.HandleBackButton(screenUIsStateHolder)
        model.stateManager.events.setSmallScaffoldActive(ScreenSizeUtils.smallScaffoldActive)
    }
}

