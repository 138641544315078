package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.ColumnAdapter
import kotlin.Long
import kotlin.String
import kotlin.UInt

public data class PurchaseNftTable(
  public val id: String,
  public val purchaseCtrkReleaseId: UInt,
  public val metadataId: String?,
) {
  public class Adapter(
    public val purchaseCtrkReleaseIdAdapter: ColumnAdapter<UInt, Long>,
  )
}
