package io.eqoty.shared.datalayer.sources.web3


enum class Web3Network(val id: String, val description: String, val currency: String) {
    EthereumMainnet("1", "Ethereum Mainnet", "ETH"),
    RopstenTestnet("3", "Ropsten Testnet", "ROP"),
    RinkebyTestnet("4", "Rinkeby Testnet", "RIN"),
    GoerliTestnet("5", "Goerli Testnet", "GOR"),
    BinanceMainnet("56", "Binance Smart Chain Mainnet", "BNB"),
    PolygonMainnet("137", "Polygon Mainnet", "MATIC"),
    SepoliaTestnet("11155111", "Sepolia Testnet", "SEP"),
    PrivateTestnet("1337", "Private Testnet", "ETH"),
    SecretMainnet("secret-4", "Secret Mainnet", "SCRT"),
    SecretTestnet("pulsar-3", "Secret Testnet", "SCRT");


    companion object {
        private val map = Web3Network.values().associateBy(Web3Network::id)
        fun valueOf(id: String): Web3Network? = map[id]
    }
}

val Int?.network: Web3Network?
    get() = Web3Network.valueOf(id = this.toString())
val String?.network: Web3Network?
    get() = Web3Network.valueOf(id = this.toString())
