package io.eqoty.shared.devicelayer.functions

import io.eqoty.shared.datalayer.sources.filesystem.CommonFile
import kotlinx.browser.document
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.coroutines.suspendCancellableCoroutine
import org.w3c.dom.asList
import org.w3c.dom.events.MouseEvent
import org.w3c.files.FileList

/***
 * @property accept ex. [".txt","text/plain"]
 * @property multiSelect enables picking multiple files
 */
actual suspend fun launchFileChooser(
    accept: List<String>,
    multiSelect: Boolean,
    saveMode: Boolean,
    saveFileName: String?
): List<CommonFile> =
    suspendCancellableCoroutine { continuation ->
        val inputElement = document.createElement("input")
        inputElement.asDynamic().type = "file"
        inputElement.asDynamic().accept = accept.joinToString(separator = ",")

        if (multiSelect) {
            inputElement.asDynamic().multiple = multiSelect
        }
        inputElement.addEventListener("change", { event ->
            MainScope().launch {
                val fileList = (event.target.asDynamic().files as FileList).asList()
                    .map {
                        CommonFile(it).apply {
                            setDataUrl()
                        }
                    }
                continuation.resume(fileList, null)
            }
        })
        inputElement.dispatchEvent(MouseEvent("click"))
    }
