package mylocal.db.shared

import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.AfterVersion
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import io.eqoty.shared.datalayer.sources.localdb.nfts.NftMetadataTable
import io.eqoty.shared.datalayer.sources.localdb.nfts.NftMetadataTableQueries
import io.eqoty.shared.datalayer.sources.localdb.nfts.ProductDealerContractTable
import io.eqoty.shared.datalayer.sources.localdb.nfts.ProductDealerContractTableQueries
import io.eqoty.shared.datalayer.sources.localdb.nfts.PurchaseNftTable
import io.eqoty.shared.datalayer.sources.localdb.nfts.PurchaseNftTableQueries
import io.eqoty.shared.datalayer.sources.localdb.nfts.ReleaseTable
import io.eqoty.shared.datalayer.sources.localdb.nfts.ReleaseTableQueries
import io.eqoty.shared.datalayer.sources.localdb.nfts.RemoteAudioResourceTable
import io.eqoty.shared.datalayer.sources.localdb.nfts.RemoteAudioResourceTableQueries
import io.eqoty.shared.datalayer.sources.localdb.nfts.RoyaltyNftTable
import io.eqoty.shared.datalayer.sources.localdb.nfts.RoyaltyNftTableQueries
import kotlin.Long
import kotlin.Unit
import kotlin.reflect.KClass
import mylocal.db.LocalDb

internal val KClass<LocalDb>.schema: SqlSchema<QueryResult.AsyncValue<Unit>>
  get() = LocalDbImpl.Schema

internal fun KClass<LocalDb>.newInstance(
  driver: SqlDriver,
  NftMetadataTableAdapter: NftMetadataTable.Adapter,
  ProductDealerContractTableAdapter: ProductDealerContractTable.Adapter,
  PurchaseNftTableAdapter: PurchaseNftTable.Adapter,
  ReleaseTableAdapter: ReleaseTable.Adapter,
  RemoteAudioResourceTableAdapter: RemoteAudioResourceTable.Adapter,
  RoyaltyNftTableAdapter: RoyaltyNftTable.Adapter,
): LocalDb = LocalDbImpl(driver, NftMetadataTableAdapter, ProductDealerContractTableAdapter,
    PurchaseNftTableAdapter, ReleaseTableAdapter, RemoteAudioResourceTableAdapter,
    RoyaltyNftTableAdapter)

private class LocalDbImpl(
  driver: SqlDriver,
  NftMetadataTableAdapter: NftMetadataTable.Adapter,
  ProductDealerContractTableAdapter: ProductDealerContractTable.Adapter,
  PurchaseNftTableAdapter: PurchaseNftTable.Adapter,
  ReleaseTableAdapter: ReleaseTable.Adapter,
  RemoteAudioResourceTableAdapter: RemoteAudioResourceTable.Adapter,
  RoyaltyNftTableAdapter: RoyaltyNftTable.Adapter,
) : SuspendingTransacterImpl(driver), LocalDb {
  override val nftMetadataTableQueries: NftMetadataTableQueries = NftMetadataTableQueries(driver,
      NftMetadataTableAdapter)

  override val productDealerContractTableQueries: ProductDealerContractTableQueries =
      ProductDealerContractTableQueries(driver, ProductDealerContractTableAdapter)

  override val purchaseNftTableQueries: PurchaseNftTableQueries = PurchaseNftTableQueries(driver,
      PurchaseNftTableAdapter, ReleaseTableAdapter)

  override val releaseTableQueries: ReleaseTableQueries = ReleaseTableQueries(driver,
      ReleaseTableAdapter)

  override val remoteAudioResourceTableQueries: RemoteAudioResourceTableQueries =
      RemoteAudioResourceTableQueries(driver, RemoteAudioResourceTableAdapter)

  override val royaltyNftTableQueries: RoyaltyNftTableQueries = RoyaltyNftTableQueries(driver,
      RoyaltyNftTableAdapter)

  public object Schema : SqlSchema<QueryResult.AsyncValue<Unit>> {
    override val version: Long
      get() = 1

    override fun create(driver: SqlDriver): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
      driver.execute(null, """
          |CREATE TABLE NftMetadataTable (
          |    id TEXT NOT NULL PRIMARY KEY,
          |    name TEXT NOT NULL,
          |    description TEXT NOT NULL,
          |    image TEXT NOT NULL,
          |    animationUrl TEXT NOT NULL,
          |    privateAudioDuration INTEGER NOT NULL,
          |    publicAudioDuration INTEGER NOT NULL,
          |    artists TEXT NOT NULL,
          |    album TEXT NOT NULL
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE ProductDealerContractTable (
          |    releaseId INTEGER NOT NULL PRIMARY KEY,
          |    childSnip721Address TEXT NOT NULL,
          |    childSnip721CodeHash TEXT NOT NULL,
          |    prices TEXT,
          |
          |    FOREIGN KEY (releaseId) REFERENCES ReleaseTable (id)
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE PurchaseNftTable (
          |    id TEXT NOT NULL PRIMARY KEY,
          |    purchaseCtrkReleaseId INTEGER  NOT NULL,
          |    metadataId TEXT,
          |
          |    FOREIGN KEY (metadataId) REFERENCES NftMetadataTable (id),
          |    FOREIGN KEY (purchaseCtrkReleaseId) REFERENCES ProductDealerContractTable(releaseId)
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE ReleaseTable (
          |    id INTEGER NOT NULL PRIMARY KEY,
          |    organizationId INTEGER NOT NULL,
          |    listed INTEGER NOT NULL,
          |    productDealerAddress TEXT NOT NULL,
          |    productDealerCodeHash TEXT NOT NULL,
          |    royaltyDealerAddress TEXT NOT NULL,
          |    royaltyDealerCodeHash TEXT NOT NULL,
          |    nftMetadataId TEXT NOT NULL,
          |    privateAuthenticationKey TEXT,
          |    FOREIGN KEY (nftMetadataId) REFERENCES NftMetadataTable (id)
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE RemoteAudioResourceTable (
          |    id TEXT NOT NULL PRIMARY KEY,
          |    url TEXT NOT NULL,
          |    access TEXT NOT NULL,
          |    priority INTEGER NOT NULL,
          |    size INTEGER NOT NULL,
          |    container TEXT NOT NULL,
          |    codec TEXT NOT NULL,
          |    bitDepth INTEGER,
          |    bitRate INTEGER NOT NULL,
          |    sampleRate INTEGER NOT NULL,
          |    channels INTEGER NOT NULL,
          |    lossless INTEGER NOT NULL,
          |    nftMetadataId TEXT NOT NULL,
          |    FOREIGN KEY (nftMetadataId)
          |       REFERENCES NftMetadataTable(id)
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE RoyaltyNftTable (
          |    id TEXT NOT NULL PRIMARY KEY,
          |    royaltyPercent TEXT NOT NULL,
          |    isOwner INTEGER NOT NULL DEFAULT 0,
          |    claimableBalances TEXT  NOT NULL,
          |    releaseId INTEGER  NOT NULL,
          |        FOREIGN KEY (releaseId)
          |           REFERENCES ReleaseTable (id)
          |)
          """.trimMargin(), 0).await()
    }

    override fun migrate(
      driver: SqlDriver,
      oldVersion: Long,
      newVersion: Long,
      vararg callbacks: AfterVersion,
    ): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
    }
  }
}
