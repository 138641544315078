package io.eqoty.shared.viewmodel.utils

import com.ionspin.kotlin.bignum.integer.BigInteger
import kotlin.math.roundToInt


fun Double.roundToDecimals(decimals: Int): Double {
    var dotAt = 1
    repeat(decimals) { dotAt *= 10 }
    val roundedValue = (this * dotAt).roundToInt()
    return (roundedValue / dotAt) + (roundedValue % dotAt).toDouble() / dotAt
}

// use commas as thousands delimiter
fun Int.toCommaThousandString(): String {
    val str = this.toString()
    val length = str.length
    var out = ""
    for (i in 0 until length) { // not including (=until) length
        val thousandIndex = (length - i) % 3
        if (thousandIndex == 0 && i > 0) {
            out += ","
        }
        out += str.substring(i, i + 1)
    }
    return out
}

// format a float number into a percentage string, with 1 decimal
fun Float.toPercentageString(): String {
    if (this == 0f) {
        return ""
    }
    return ((this * 10f).roundToInt() / 10f).toString() + "%"
}

fun BigInteger.toHexString(): String {
    val nonPrefixedAddress = toString(16)
    return if (nonPrefixedAddress.length % 2 == 0) {
        "0x$nonPrefixedAddress"
    } else {
        // force addresses to have even digit length
        "0x0$nonPrefixedAddress"
    }
}

fun Int.bytesToMegabytes(decimals: Int): String = "${(this.toDouble() * 1e-6).roundToDecimals(decimals)}"
fun Long.bytesToMegabytes(): String = "${(this.toDouble() * 1e-6).roundToDecimals(1)}"
