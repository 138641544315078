package io.eqoty.shared.datalayer.functions

import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.secret.std.contract.msg.EqotyRoyaltyDealerMsgs
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.RoyaltyNft
import io.eqoty.shared.datalayer.sources.DataSrc
import io.eqoty.shared.datalayer.sources.LOCAL
import io.eqoty.shared.datalayer.sources.RemoteSrc
import io.eqoty.shared.datalayer.sources.localdb.nfts.getRoyaltyNft
import io.eqoty.shared.datalayer.sources.localdb.nfts.upsertRoyaltyNft
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json


suspend fun Repository.getRoyaltyNft(
    releaseId: UInt,
    royaltyId: BigInteger,
    dataSrc: DataSrc = LOCAL
): RoyaltyNft? = withRepoContext {
    if (dataSrc is RemoteSrc) {
        val senderAddress = getWalletAddress()!!
        val release = getRelease(releaseId)!!
        val royaltyDealer = release.royaltyDealer
        // todo save the childSnip721 info to the db and get from there
        val childSnip721 = queryRoyaltyDealerChildSnip721Contract(release.royaltyDealer)
        val contractInfoQuery = EqotyRoyaltyDealerMsgs.Query(
            availableBalances = EqotyRoyaltyDealerMsgs.Query.AvailableBalances(
                permit = getPermit(senderAddress, childSnip721.address),
                tokenId = royaltyId.toString()
            )
        )
        val network = getWeb3Network() ?: return@withRepoContext null
        val claimableBalance = Json.decodeFromString<EqotyRoyaltyDealerMsgs.QueryAnswer>(
            getClient(network).queryContractSmart(
                royaltyDealer.address,
                Json.encodeToString(contractInfoQuery),
                royaltyDealer.codeHash
            )
        ).funds!!
        val shares =
            getRoyaltyTokenRoyaltyShares(royaltyDealer, royaltyId)
        val royaltyNft = RoyaltyNft(
            id = royaltyId,
            isOwner = true,
            royaltyPercent = shares,
            releaseId = releaseId,
            claimableBalances = claimableBalance
        )
        localDb.upsertRoyaltyNft(releaseId, royaltyNft)
    }
    return@withRepoContext localDb.getRoyaltyNft(releaseId, royaltyId)
}