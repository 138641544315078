package io.eqoty.composables.navigation.bars

import androidx.compose.runtime.*
import app.softwork.bootstrapcompose.Button
import app.softwork.bootstrapcompose.Column
import app.softwork.bootstrapcompose.Layout.Display
import app.softwork.bootstrapcompose.Row
import io.eqoty.composables.styles.overflowTxtToEllipsisAttrs
import io.eqoty.composables.styling.BackgroundDarker
import io.eqoty.shared.viewmodel.Navigation
import io.eqoty.shared.viewmodel.screens.Level1Navigation
import io.eqoty.shared.viewmodel.screens.ScreenStack
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text


fun StyleScope.tabStyle(
    isSelected: Boolean
) {
    width(100.percent)
    height(100.percent)
    border {
        borderRadius(0.px)
    }
    property("border-top-style", "hidden")
    property("border-left-style", "hidden")
    property("border-right-style", "hidden")
    if (isSelected) {
        property("border-bottom-style", "solid")
        property("border-bottom-width", "2px")
    } else {
        property("border-bottom-style", "hidden")
    }
}

@Composable
fun Navigation.LibraryTabRow() {
    var activeTabIndex by remember { mutableStateOf(0) }
    Row(
        attrs = {
            style {
                flexWrap(FlexWrap.Nowrap)
                height(50.px)
                backgroundColor(BackgroundDarker)
            }
        },
    ) {
        Column(
            styling = { Layout { display(Display.Flex) } },
            attrs = { classes("p-0") }
        ) {
            Button(
                outlined = true,
                attrs = {
                    style {
                        tabStyle(activeTabIndex == 0)
                    }
                },
                content = {
                    Div(attrs = overflowTxtToEllipsisAttrs) { Text("Songs") }
                }
            ) {
                activeTabIndex = 0
                selectLevel1Navigation(
                    ScreenStack.Main,
                    Level1Navigation.NftPurchaseLibraryProvider.screenIdentifier
                )
            }
        }
        Column(
            styling = { Layout { display(Display.Flex) } },
            attrs = { classes("p-0") }
        ) {
            Button(
                outlined = true,
                attrs = {
                    style {
                        tabStyle(activeTabIndex == 1)
                    }
                },
                content = {
                    Div(attrs = overflowTxtToEllipsisAttrs) { Text("Royalties") }
                }
            ) {
                activeTabIndex = 1
                selectLevel1Navigation(
                    ScreenStack.Main,
                    Level1Navigation.MyRoyaltiesProvider.screenIdentifier
                )
            }
        }
    }
}