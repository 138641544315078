package io.eqoty.shared.devicelayer

import io.eqoty.shared.viewmodel.screens.mediaplayer.PlaybackState
import org.w3c.dom.Audio
import org.w3c.dom.events.Event
import kotlin.math.floor
import kotlin.math.roundToInt


actual object DeviceAudioHandler {

    var audio: Audio? = null
    private var playbackProgressListener: PlaybackProgressListener? = null

    /**
     * After play starts on Safari, audio.currentTime is not zero like other browsers. It's some large number.
     * So to calculate current playback time on all browsers. Just save that timestamp and subtract it from
     * audio.currentTime, to get the actual playback time
     */
    private var playStartedTimeStamp: Double? = null

    private val loadedDataListener: (Event) -> Unit = {
        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
        if (audio?.readyState != 0.toShort()) {
            audio?.play()
        }
    }

    private val timeupdateListener: (Event) -> Unit = {
        if (playStartedTimeStamp == null) {
            // by this time a milliseconds of audio might have already played.
            // so just floor audio.currentTime to get the starting second
            playStartedTimeStamp = floor(audio?.currentTime ?: 0.0)
        }
        val playbackTime = audio!!.currentTime - playStartedTimeStamp!!
        val playbackState = if (audio?.paused == true) PlaybackState.PAUSED else PlaybackState.PLAYING
        playbackProgressListener?.invoke((playbackTime * 1000.0).roundToInt(), playbackState)
    }

    actual suspend fun play(playbackProgressListener: PlaybackProgressListener) {
        this.playbackProgressListener = playbackProgressListener
        if (playStartedTimeStamp == null) {
            audio!!.addEventListener("loadeddata", loadedDataListener)
            audio!!.addEventListener("timeupdate", timeupdateListener)
        } else {
            audio!!.play()
        }
    }

    actual fun pause() {
        audio?.pause()
    }

    actual fun stop() {
        audio?.pause()
        audio?.currentTime = 0.0
        playbackProgressListener?.invoke(0, PlaybackState.STOPPED)
        audio?.remove() // not sure if needed
        audio?.removeEventListener("loadeddata", loadedDataListener)
        audio?.removeEventListener("timeupdate", timeupdateListener)
        playbackProgressListener = null
        playStartedTimeStamp = null
        audio = null
    }
}