package io.eqoty.composables.styling


sealed class ColumnSize(
    val size: Int?,
    val auto: Boolean
) {

    abstract val breakPointPrefix: String?
    val cssClass: String get() = "col${breakPointPrefix?.let { "-$breakPointPrefix" } ?: ""}-${if (auto) "auto" else size}"

    class All : ColumnSize {
        override val breakPointPrefix: String? = null

        constructor(size: Int) : super(size, false)
        private constructor(auto: Boolean) : super(null, auto)

        companion object {
            fun auto() = All(auto = true)
        }
    }

    // Xs is the identical to all
    class Xs : ColumnSize {
        override val breakPointPrefix: String? = null

        constructor(size: Int) : super(size, false)
        private constructor(auto: Boolean) : super(null, auto)

        companion object {
            fun auto() = Xs(auto = true)
        }
    }

    class Sm : ColumnSize {
        override val breakPointPrefix: String = "sm"

        constructor(size: Int) : super(size, false)
        private constructor(auto: Boolean) : super(null, auto)

        companion object {
            fun auto() = Sm(auto = true)
        }
    }

    class Md : ColumnSize {
        override val breakPointPrefix: String = "md"

        constructor(size: Int) : super(size, false)
        private constructor(auto: Boolean) : super(null, auto)

        companion object {
            fun auto() = Md(auto = true)
        }
    }

    class Lg : ColumnSize {
        override val breakPointPrefix: String = "lg"

        constructor(size: Int) : super(size, false)
        private constructor(auto: Boolean) : super(null, auto)

        companion object {
            fun auto() = Lg(auto = true)
        }
    }

    class Xl : ColumnSize {
        override val breakPointPrefix: String = "xl"

        constructor(size: Int) : super(size, false)
        private constructor(auto: Boolean) : super(null, auto)

        companion object {
            fun auto() = Xl(auto = true)
        }
    }

    class Xxl : ColumnSize {
        override val breakPointPrefix: String = "xxl"

        constructor(size: Int) : super(size, false)
        private constructor(auto: Boolean) : super(null, auto)

        companion object {
            fun auto() = Xxl(auto = true)
        }
    }
}

class ColumnSizes(vararg sizes: ColumnSize) {

    val classes = sizes.map { it.cssClass }.toTypedArray()

}