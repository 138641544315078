package io.eqoty.shared.viewmodel.screens.royaltynftdetail

import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.functions.splitRoyaltyToken
import io.eqoty.shared.datalayer.sources.web3.ExternalWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.InternalWeb3Provider
import io.eqoty.shared.viewmodel.Events
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.scaffold.setWalletScreenExpanded
import io.eqoty.shared.viewmodel.screens.topbar.updateWalletBtnInfo
import io.eqoty.shared.viewmodel.screens.wallettxrequest.internalWalletTxRequestResultSharedFlow
import io.eqoty.shared.viewmodel.screens.wallettxrequest.setInternalWalletTxRequest
import kotlinx.coroutines.flow.first


/********** NO EVENT FUNCTION IS DEFINED ON THIS SCREEN **********/

fun Events.splitRoyalty(
    releaseId: UInt,
    tokenId: BigInteger,
    splitCount: Int,
) = inScreenScopeLaunch(ScreenStack.Main) {
    when (val web3Provider = dataRepository.web3Provider) {
        is InternalWeb3Provider<*> -> {
            try {
                setWalletScreenExpanded(true)?.join()
                dataRepository.splitRoyaltyToken(releaseId, tokenId, splitCount) { txRequestInfo ->
                    setInternalWalletTxRequest(txRequestInfo)
                    val requestResult = internalWalletTxRequestResultSharedFlow.first()
                    require(txRequestInfo == requestResult.txRequestInfo)
                    requestResult.result
                }
            } catch (t: Throwable) {
                t.printStackTrace()
            } finally {
                setWalletScreenExpanded(false)?.join()
            }
        }

        is ExternalWeb3Provider -> {
            try {
                dataRepository.splitRoyaltyToken(releaseId, tokenId, splitCount, null)
            } catch (t: Throwable) {
                t.printStackTrace()
            }
        }

        else -> throw NotImplementedError("web3Provider: $web3Provider not handled")
    }

    updateWalletBtnInfo()?.join()
}