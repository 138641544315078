package io.eqoty.shared.viewmodel.screens.wallettxrequest

import co.touchlab.kermit.Logger
import io.eqoty.shared.datalayer.sources.web3.TxRequestInfo
import io.eqoty.shared.datalayer.sources.web3.TxRequestResult
import io.eqoty.shared.viewmodel.Events
import io.eqoty.shared.viewmodel.Navigation
import io.eqoty.shared.viewmodel.ScreenIdentifier
import io.eqoty.shared.viewmodel.screens.Screen
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.WalletTxRequestScreenParams
import io.eqoty.shared.viewmodel.screens.connectwallet.navigateToScreenIfWalletConnected
import kotlinx.coroutines.flow.*


/********** EVENT functions, called directly by the UI layer **********/


val Events.internalWalletTxRequestStateFlow: StateFlow<TxRequestInfo?>
    get() = inAppWalletTxRequestMutableStateFlow.asStateFlow()
private val inAppWalletTxRequestMutableStateFlow: MutableStateFlow<TxRequestInfo?> = MutableStateFlow(null)

val Events.internalWalletTxRequestResultSharedFlow: SharedFlow<TxRequestResult>
    get() = internalWalletTxRequestResultMutableSharedFlow.asSharedFlow()
private val internalWalletTxRequestResultMutableSharedFlow: MutableSharedFlow<TxRequestResult> = MutableSharedFlow()

fun Events.setInternalWalletTxRequest(txRequestInfo: TxRequestInfo?) {
    inAppWalletTxRequestMutableStateFlow.value = txRequestInfo
}

suspend fun Events.broadcastInternalWalletTxRequestResult(txRequestResult: TxRequestResult) {
    internalWalletTxRequestResultMutableSharedFlow.emit(txRequestResult)
}


fun Events.broadcastWalletTxRequestResultAndExitScreen(
    txRequestInfo: TxRequestInfo, result: Boolean
) = inScreenScopeLaunch(ScreenStack.Wallet) {
    broadcastInternalWalletTxRequestResult(
        TxRequestResult(
            txRequestInfo, result
        )
    )
    setInternalWalletTxRequest(null)
    stateManager.navigation.exitScreen(ScreenStack.Wallet, stateManager.currentScreenIdentifier(ScreenStack.Wallet))
}


suspend fun Events.listenForInternalWalletTxRequestsAndLaunchRequestScreen(
) {
    val startedFlow = MutableSharedFlow<Unit>()
    inScreenScopeLaunchInBackground(ScreenStack.Wallet) {
        internalWalletTxRequestStateFlow.filterNotNull().onStart {
            startedFlow.emit(Unit)
        }.onCompletion {
            Logger.i("listenForInternalWalletTxRequestsAndLaunchRequestScreen was cancelled.")
        }.collect { txRequestInfo ->
            navigateToScreenIfWalletConnected(
                ScreenIdentifier.get(Screen.WalletTxRequest, WalletTxRequestScreenParams(txRequestInfo))
            )
        }
    }
    startedFlow.first()
}
