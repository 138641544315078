package io.eqoty.shared.datalayer.functions

import io.eqoty.cosmwasm.std.types.Coin
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.sources.web3.InternalWalletTxRequest


suspend fun Repository.makePurchase(
    releaseId: UInt,
    price: Coin,
    inAppTxRequest: InternalWalletTxRequest?
): String = withRepoContext {
    val productDealer = getRelease(releaseId)!!.productDealer
    val receipt = purchase(productDealer, price, inAppTxRequest)
    return@withRepoContext receipt.transactionHash
}