package io.eqoty.shared

import dev.icerock.moko.graphics.Color
import dev.icerock.moko.resources.AssetResource
import dev.icerock.moko.resources.ColorResource
import dev.icerock.moko.resources.FileResource
import dev.icerock.moko.resources.FontResource
import dev.icerock.moko.resources.ImageResource
import dev.icerock.moko.resources.PluralsResource
import dev.icerock.moko.resources.ResourceContainer
import dev.icerock.moko.resources.StringResource
import dev.icerock.moko.resources.`internal`.RemoteJsStringLoaderHolder
import dev.icerock.moko.resources.`internal`.SupportedLocales
import dev.icerock.moko.resources.provider.RemoteJsStringLoader
import kotlin.String
import kotlin.collections.List

public actual object MR {
  private val contentHash: String = "ab036a1c40c83999c3cdadf214bc3082"

  public val stringsLoader: RemoteJsStringLoader = strings.stringsLoader

  public actual object strings : ResourceContainer<StringResource>, RemoteJsStringLoaderHolder {
    private val stringsFallbackFileUrl: String =
        js("require(\"localization/ioeqotyshared_stringsJson.json\")") as String

    private val supportedLocales: SupportedLocales = SupportedLocales(listOf(
        ))

    override val stringsLoader: RemoteJsStringLoader = RemoteJsStringLoader.Impl(supportedLocales =
        supportedLocales, fallbackFileUri = stringsFallbackFileUrl)

    public actual val track_art: StringResource = StringResource(key = "track_art", loader =
        stringsLoader)

    public actual val track_art_label: StringResource = StringResource(key = "track_art_label",
        loader = stringsLoader)

    public actual val upload_track_art_res_reqs_l0: StringResource = StringResource(key =
        "upload_track_art_res_reqs_l0", loader = stringsLoader)

    public actual val upload_track_art_res_reqs_l1: StringResource = StringResource(key =
        "upload_track_art_res_reqs_l1", loader = stringsLoader)

    public actual val upload_track_art_file_reqs: StringResource = StringResource(key =
        "upload_track_art_file_reqs", loader = stringsLoader)

    public actual val track_name_input_label: StringResource = StringResource(key =
        "track_name_input_label", loader = stringsLoader)

    public actual val about_track_input_label: StringResource = StringResource(key =
        "about_track_input_label", loader = stringsLoader)

    public actual val name: StringResource = StringResource(key = "name", loader = stringsLoader)

    public actual val first_name: StringResource = StringResource(key = "first_name", loader =
        stringsLoader)

    public actual val last_name: StringResource = StringResource(key = "last_name", loader =
        stringsLoader)

    public actual val email: StringResource = StringResource(key = "email", loader = stringsLoader)

    public actual val phone: StringResource = StringResource(key = "phone", loader = stringsLoader)

    public actual val website: StringResource = StringResource(key = "website", loader =
        stringsLoader)

    public actual val artist_input_label: StringResource = StringResource(key =
        "artist_input_label", loader = stringsLoader)

    public actual val album_input_label: StringResource = StringResource(key = "album_input_label",
        loader = stringsLoader)

    public actual val price_input_label: StringResource = StringResource(key = "price_input_label",
        loader = stringsLoader)

    public actual val loading: StringResource = StringResource(key = "loading", loader =
        stringsLoader)

    public actual val browse: StringResource = StringResource(key = "browse", loader =
        stringsLoader)

    public actual val audio_label: StringResource = StringResource(key = "audio_label", loader =
        stringsLoader)

    public actual val store_preview_audio_label: StringResource = StringResource(key =
        "store_preview_audio_label", loader = stringsLoader)

    public actual val start_at_sec: StringResource = StringResource(key = "start_at_sec", loader =
        stringsLoader)

    public actual val end_at_sec: StringResource = StringResource(key = "end_at_sec", loader =
        stringsLoader)

    public actual val select_file: StringResource = StringResource(key = "select_file", loader =
        stringsLoader)

    public actual val mint_emark: StringResource = StringResource(key = "mint_emark", loader =
        stringsLoader)

    public actual val success_emark: StringResource = StringResource(key = "success_emark", loader =
        stringsLoader)

    public actual val submit: StringResource = StringResource(key = "submit", loader =
        stringsLoader)

    public actual val tx_hash: StringResource = StringResource(key = "tx_hash", loader =
        stringsLoader)

    public actual val nothing_here: StringResource = StringResource(key = "nothing_here", loader =
        stringsLoader)

    public actual val purchase_a_song_in_store: StringResource = StringResource(key =
        "purchase_a_song_in_store", loader = stringsLoader)

    public actual val connect_wallet: StringResource = StringResource(key = "connect_wallet", loader
        = stringsLoader)

    public actual val wallet_recommendations: StringResource = StringResource(key =
        "wallet_recommendations", loader = stringsLoader)

    public actual val connect_wallet_my_releases: StringResource = StringResource(key =
        "connect_wallet_my_releases", loader = stringsLoader)

    public actual val my_releases_placeholder_1: StringResource = StringResource(key =
        "my_releases_placeholder_1", loader = stringsLoader)

    public actual val my_releases_placeholder_2: StringResource = StringResource(key =
        "my_releases_placeholder_2", loader = stringsLoader)

    public actual val team_at_eqoty_io: StringResource = StringResource(key = "team_at_eqoty_io",
        loader = stringsLoader)

    public actual val not_a_registered_releaser: StringResource = StringResource(key =
        "not_a_registered_releaser", loader = stringsLoader)

    public actual val request_access_to_release: StringResource = StringResource(key =
        "request_access_to_release", loader = stringsLoader)

    public actual val releaser_registration_on_waitlist: StringResource = StringResource(key =
        "releaser_registration_on_waitlist", loader = stringsLoader)

    public actual val request_access_to_release_pending: StringResource = StringResource(key =
        "request_access_to_release_pending", loader = stringsLoader)

    public actual val releaser_registration_enabled: StringResource = StringResource(key =
        "releaser_registration_enabled", loader = stringsLoader)

    public actual val releaser_registration_enabled_details: StringResource = StringResource(key =
        "releaser_registration_enabled_details", loader = stringsLoader)

    public actual val releaser_registration_disabled: StringResource = StringResource(key =
        "releaser_registration_disabled", loader = stringsLoader)

    public actual val releaser_registration_disabled_details: StringResource = StringResource(key =
        "releaser_registration_disabled_details", loader = stringsLoader)

    public actual val organization_registration_pending: StringResource = StringResource(key =
        "organization_registration_pending", loader = stringsLoader)

    public actual val organization_registration_pending_details: StringResource = StringResource(key
        = "organization_registration_pending_details", loader = stringsLoader)

    public actual val organization_registration_enabled: StringResource = StringResource(key =
        "organization_registration_enabled", loader = stringsLoader)

    public actual val organization_registration_enabled_details: StringResource = StringResource(key
        = "organization_registration_enabled_details", loader = stringsLoader)

    public actual val organization_registration_disabled: StringResource = StringResource(key =
        "organization_registration_disabled", loader = stringsLoader)

    public actual val organization_registration_disabled_details: StringResource =
        StringResource(key = "organization_registration_disabled_details", loader = stringsLoader)

    public actual val mint_new_track: StringResource = StringResource(key = "mint_new_track", loader
        = stringsLoader)

    public actual val my_royalties: StringResource = StringResource(key = "my_royalties", loader =
        stringsLoader)

    public actual val url_discord: StringResource = StringResource(key = "url_discord", loader =
        stringsLoader)

    public actual val url_instagram: StringResource = StringResource(key = "url_instagram", loader =
        stringsLoader)

    public actual val url_twitter: StringResource = StringResource(key = "url_twitter", loader =
        stringsLoader)

    public actual val url_telegram: StringResource = StringResource(key = "url_telegram", loader =
        stringsLoader)

    public actual val my_artists: StringResource = StringResource(key = "my_artists", loader =
        stringsLoader)

    public actual val create_new_artist: StringResource = StringResource(key = "create_new_artist",
        loader = stringsLoader)

    public actual val connect_wallet_my_artists: StringResource = StringResource(key =
        "connect_wallet_my_artists", loader = stringsLoader)

    public actual val my_artists_placeholder_1: StringResource = StringResource(key =
        "my_artists_placeholder_1", loader = stringsLoader)

    public actual val my_artists_placeholder_2: StringResource = StringResource(key =
        "my_artists_placeholder_2", loader = stringsLoader)

    public actual val home_distro_feature_card_0_title: StringResource = StringResource(key =
        "home_distro_feature_card_0_title", loader = stringsLoader)

    public actual val home_distro_feature_card_0_body: StringResource = StringResource(key =
        "home_distro_feature_card_0_body", loader = stringsLoader)

    public actual val home_distro_feature_card_1_title: StringResource = StringResource(key =
        "home_distro_feature_card_1_title", loader = stringsLoader)

    public actual val home_distro_feature_card_1_body: StringResource = StringResource(key =
        "home_distro_feature_card_1_body", loader = stringsLoader)

    public actual val home_distro_feature_card_2_title: StringResource = StringResource(key =
        "home_distro_feature_card_2_title", loader = stringsLoader)

    public actual val home_distro_feature_card_2_body: StringResource = StringResource(key =
        "home_distro_feature_card_2_body", loader = stringsLoader)

    public actual val home_feature_card_0_title: StringResource = StringResource(key =
        "home_feature_card_0_title", loader = stringsLoader)

    public actual val home_feature_card_0_body: StringResource = StringResource(key =
        "home_feature_card_0_body", loader = stringsLoader)

    public actual val home_feature_card_1_title: StringResource = StringResource(key =
        "home_feature_card_1_title", loader = stringsLoader)

    public actual val home_feature_card_1_body: StringResource = StringResource(key =
        "home_feature_card_1_body", loader = stringsLoader)

    public actual val home_feature_card_2_title: StringResource = StringResource(key =
        "home_feature_card_2_title", loader = stringsLoader)

    public actual val home_feature_card_2_body: StringResource = StringResource(key =
        "home_feature_card_2_body", loader = stringsLoader)

    public actual val home_feature_card_3_title: StringResource = StringResource(key =
        "home_feature_card_3_title", loader = stringsLoader)

    public actual val home_feature_card_3_body: StringResource = StringResource(key =
        "home_feature_card_3_body", loader = stringsLoader)

    public actual val home_feature_card_4_title: StringResource = StringResource(key =
        "home_feature_card_4_title", loader = stringsLoader)

    public actual val home_feature_card_4_body: StringResource = StringResource(key =
        "home_feature_card_4_body", loader = stringsLoader)

    public actual val home_feature_card_5_title: StringResource = StringResource(key =
        "home_feature_card_5_title", loader = stringsLoader)

    public actual val home_feature_card_5_body: StringResource = StringResource(key =
        "home_feature_card_5_body", loader = stringsLoader)

    public actual val home_feature_card_6_title: StringResource = StringResource(key =
        "home_feature_card_6_title", loader = stringsLoader)

    public actual val home_feature_card_6_body: StringResource = StringResource(key =
        "home_feature_card_6_body", loader = stringsLoader)

    public actual val edit_profile: StringResource = StringResource(key = "edit_profile", loader =
        stringsLoader)

    public actual val banner_image: StringResource = StringResource(key = "banner_image", loader =
        stringsLoader)

    public actual val upload_banner_image_res_reqs_l0: StringResource = StringResource(key =
        "upload_banner_image_res_reqs_l0", loader = stringsLoader)

    public actual val upload_banner_image_res_reqs_l1: StringResource = StringResource(key =
        "upload_banner_image_res_reqs_l1", loader = stringsLoader)

    public actual val upload_banner_image_file_reqs: StringResource = StringResource(key =
        "upload_banner_image_file_reqs", loader = stringsLoader)

    public actual val releases: StringResource = StringResource(key = "releases", loader =
        stringsLoader)

    public actual val about: StringResource = StringResource(key = "about", loader = stringsLoader)

    override fun values(): List<StringResource> = listOf(track_art, track_art_label,
        upload_track_art_res_reqs_l0, upload_track_art_res_reqs_l1, upload_track_art_file_reqs,
        track_name_input_label, about_track_input_label, name, first_name, last_name, email, phone,
        website, artist_input_label, album_input_label, price_input_label, loading, browse,
        audio_label, store_preview_audio_label, start_at_sec, end_at_sec, select_file, mint_emark,
        success_emark, submit, tx_hash, nothing_here, purchase_a_song_in_store, connect_wallet,
        wallet_recommendations, connect_wallet_my_releases, my_releases_placeholder_1,
        my_releases_placeholder_2, team_at_eqoty_io, not_a_registered_releaser,
        request_access_to_release, releaser_registration_on_waitlist,
        request_access_to_release_pending, releaser_registration_enabled,
        releaser_registration_enabled_details, releaser_registration_disabled,
        releaser_registration_disabled_details, organization_registration_pending,
        organization_registration_pending_details, organization_registration_enabled,
        organization_registration_enabled_details, organization_registration_disabled,
        organization_registration_disabled_details, mint_new_track, my_royalties, url_discord,
        url_instagram, url_twitter, url_telegram, my_artists, create_new_artist,
        connect_wallet_my_artists, my_artists_placeholder_1, my_artists_placeholder_2,
        home_distro_feature_card_0_title, home_distro_feature_card_0_body,
        home_distro_feature_card_1_title, home_distro_feature_card_1_body,
        home_distro_feature_card_2_title, home_distro_feature_card_2_body,
        home_feature_card_0_title, home_feature_card_0_body, home_feature_card_1_title,
        home_feature_card_1_body, home_feature_card_2_title, home_feature_card_2_body,
        home_feature_card_3_title, home_feature_card_3_body, home_feature_card_4_title,
        home_feature_card_4_body, home_feature_card_5_title, home_feature_card_5_body,
        home_feature_card_6_title, home_feature_card_6_body, edit_profile, banner_image,
        upload_banner_image_res_reqs_l0, upload_banner_image_res_reqs_l1,
        upload_banner_image_file_reqs, releases, about)
  }

  public actual object plurals : ResourceContainer<PluralsResource> {
    override fun values(): List<PluralsResource> = listOf()
  }

  public actual object images : ResourceContainer<ImageResource> {
    override fun values(): List<ImageResource> = listOf()
  }

  public actual object fonts : ResourceContainer<FontResource> {
    override fun values(): List<FontResource> = listOf()
  }

  public actual object files : ResourceContainer<FileResource> {
    public actual val eqoty_icon_white: FileResource = FileResource(fileUrl =
        js("require(\"files/eqoty-icon-white.svg\")") as String)

    override fun values(): List<FileResource> = listOf(eqoty_icon_white)
  }

  public actual object colors : ResourceContainer<ColorResource> {
    override fun values(): List<ColorResource> = listOf()
  }

  public actual object assets : ResourceContainer<AssetResource> {
    override fun values(): List<AssetResource> = listOf()
  }
}
