package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Boolean
import kotlin.String
import kotlin.UInt
import kotlin.ULong

public class PurchaseNftTableQueries(
  driver: SqlDriver,
  private val PurchaseNftTableAdapter: PurchaseNftTable.Adapter,
  private val ReleaseTableAdapter: ReleaseTable.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getById(id: String, mapper: (
    id: String,
    purchaseCtrkReleaseId: UInt,
    metadataId: String?,
  ) -> T): Query<T> = GetByIdQuery(id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      PurchaseNftTableAdapter.purchaseCtrkReleaseIdAdapter.decode(cursor.getLong(1)!!),
      cursor.getString(2)
    )
  }

  public fun getById(id: String): Query<PurchaseNftTable> = getById(id) { id_,
      purchaseCtrkReleaseId, metadataId ->
    PurchaseNftTable(
      id_,
      purchaseCtrkReleaseId,
      metadataId
    )
  }

  public fun <T : Any> getByReleaseId(purchaseCtrkReleaseId: UInt, mapper: (
    id: String,
    purchaseCtrkReleaseId: UInt,
    metadataId: String?,
  ) -> T): Query<T> = GetByReleaseIdQuery(purchaseCtrkReleaseId) { cursor ->
    mapper(
      cursor.getString(0)!!,
      PurchaseNftTableAdapter.purchaseCtrkReleaseIdAdapter.decode(cursor.getLong(1)!!),
      cursor.getString(2)
    )
  }

  public fun getByReleaseId(purchaseCtrkReleaseId: UInt): Query<PurchaseNftTable> =
      getByReleaseId(purchaseCtrkReleaseId) { id, purchaseCtrkReleaseId_, metadataId ->
    PurchaseNftTable(
      id,
      purchaseCtrkReleaseId_,
      metadataId
    )
  }

  public fun <T : Any> getAll(mapper: (
    id: UInt,
    organizationId: ULong,
    listed: Boolean,
    productDealerAddress: String,
    productDealerCodeHash: String,
    royaltyDealerAddress: String,
    royaltyDealerCodeHash: String,
    nftMetadataId: String,
    privateAuthenticationKey: String?,
  ) -> T): Query<T> = Query(-1_252_360_110, arrayOf("ReleaseTable"), driver, "PurchaseNftTable.sq",
      "getAll", "SELECT * FROM ReleaseTable") { cursor ->
    mapper(
      ReleaseTableAdapter.idAdapter.decode(cursor.getLong(0)!!),
      ReleaseTableAdapter.organizationIdAdapter.decode(cursor.getLong(1)!!),
      cursor.getBoolean(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7)!!,
      cursor.getString(8)
    )
  }

  public fun getAll(): Query<ReleaseTable> = getAll { id, organizationId, listed,
      productDealerAddress, productDealerCodeHash, royaltyDealerAddress, royaltyDealerCodeHash,
      nftMetadataId, privateAuthenticationKey ->
    ReleaseTable(
      id,
      organizationId,
      listed,
      productDealerAddress,
      productDealerCodeHash,
      royaltyDealerAddress,
      royaltyDealerCodeHash,
      nftMetadataId,
      privateAuthenticationKey
    )
  }

  public suspend fun deleteByReleaseId(purchaseCtrkReleaseId: UInt) {
    driver.execute(788_958_713, """DELETE FROM PurchaseNftTable WHERE purchaseCtrkReleaseId = ?""",
        1) {
          bindLong(0,
              PurchaseNftTableAdapter.purchaseCtrkReleaseIdAdapter.encode(purchaseCtrkReleaseId))
        }.await()
    notifyQueries(788_958_713) { emit ->
      emit("PurchaseNftTable")
    }
  }

  public suspend fun deleteTable() {
    driver.execute(1_711_856_476, """DELETE FROM PurchaseNftTable""", 0).await()
    notifyQueries(1_711_856_476) { emit ->
      emit("PurchaseNftTable")
    }
  }

  public suspend fun upsert(
    id: String,
    metadataId: String?,
    purchaseCtrkReleaseId: UInt,
  ) {
    transaction {
      driver.execute(-1_120_268_985, """
          |INSERT OR REPLACE INTO PurchaseNftTable(id,metadataId, purchaseCtrkReleaseId)
          |    VALUES(?, ?, ?)
          """.trimMargin(), 3) {
            bindString(0, id)
            bindString(1, metadataId)
            bindLong(2,
                PurchaseNftTableAdapter.purchaseCtrkReleaseIdAdapter.encode(purchaseCtrkReleaseId))
          }.await()
    }
    notifyQueries(-841_388_266) { emit ->
      emit("PurchaseNftTable")
    }
  }

  private inner class GetByIdQuery<out T : Any>(
    public val id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("PurchaseNftTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("PurchaseNftTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-168_416_447, """SELECT * FROM PurchaseNftTable  WHERE id = ?""",
        mapper, 1) {
      bindString(0, id)
    }

    override fun toString(): String = "PurchaseNftTable.sq:getById"
  }

  private inner class GetByReleaseIdQuery<out T : Any>(
    public val purchaseCtrkReleaseId: UInt,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("PurchaseNftTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("PurchaseNftTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_565_529_756,
        """SELECT * FROM PurchaseNftTable  WHERE purchaseCtrkReleaseId = ?""", mapper, 1) {
      bindLong(0,
          PurchaseNftTableAdapter.purchaseCtrkReleaseIdAdapter.encode(purchaseCtrkReleaseId))
    }

    override fun toString(): String = "PurchaseNftTable.sq:getByReleaseId"
  }
}
