package io.eqoty.composables.navigation.bars

import androidx.compose.runtime.*
import app.softwork.bootstrapcompose.Button
import app.softwork.bootstrapcompose.Column
import app.softwork.bootstrapcompose.Layout.Display
import app.softwork.bootstrapcompose.Row
import io.eqoty.composables.styles.overflowTxtToEllipsisAttrs
import io.eqoty.composables.styling.BackgroundDarker
import io.eqoty.shared.viewmodel.Navigation
import io.eqoty.shared.viewmodel.screens.Level1Navigation
import io.eqoty.shared.viewmodel.screens.ScreenStack
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text


@Composable
fun Navigation.ReleaserTabRow() {
    var activeTabIndex by remember { mutableStateOf(0) }
    Row(
        attrs = {
            style {
                flexWrap(FlexWrap.Nowrap)
                height(50.px)
                backgroundColor(BackgroundDarker)
            }
        },
    ) {
        Column(
            styling = { Layout { display(Display.Flex) } },
            attrs = { classes("p-0") }
        ) {
            Button(
                outlined = true,
                attrs = {
                    style {
                        tabStyle(activeTabIndex == 0)
                    }
                },
                content = {
                    Div(attrs = overflowTxtToEllipsisAttrs) { Text("Releaser Profile") }
                }
            ) {
                activeTabIndex = 0
                selectLevel1Navigation(
                    ScreenStack.Main,
                    Level1Navigation.ReleaserProfileProvider.screenIdentifier
                )
            }
        }
        Column(
            styling = { Layout { display(Display.Flex) } },
            attrs = { classes("p-0") }
        ) {
            Button(
                outlined = true,
                attrs = {
                    style {
                        tabStyle(activeTabIndex == 1)
                    }
                },
                content = {
                    Div(attrs = overflowTxtToEllipsisAttrs) { Text("My Artists") }
                }
            ) {
                activeTabIndex = 1
                selectLevel1Navigation(
                    ScreenStack.Main,
                    Level1Navigation.MyOrganizationsProvider.screenIdentifier
                )
            }
        }
    }
}