package io.eqoty.composables.screens.royaltynftdetail

import androidx.compose.runtime.*
import app.softwork.bootstrapcompose.Column
import app.softwork.bootstrapcompose.Row
import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.composables.screens.LoadingRow
import io.eqoty.shared.viewmodel.screens.royaltynftdetail.RoyaltyNftDetailState
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Text

@Composable
fun RoyaltyNftDetailScreen(
    state: RoyaltyNftDetailState,
    onSplitRoyalty: (UInt, BigInteger, Int) -> Unit
) {
    if (state.isLoading) {

        LoadingRow("royalties")

    } else {
        state.royaltyNftDetailDisplayFormatted?.apply {
            Column {

                DataElement("name:", name)
                DataElement("description:", description)
                DataElement("animation_url:", animationUrl)
                var splitCount by remember { mutableStateOf(0) }
                Input(InputType.Number) {
                    onChange {
                        splitCount = it.value?.toInt() ?: 0
                    }
                }
                Button(attrs = {
                    onClick { onSplitRoyalty(releaseId, id, splitCount) }
                }) {
                    Text("Split")
                }
            }
        }

    }
}


@Composable
fun DataElement(label: String, value: String = "") {
    Row {
        Text("$label: ")
        Text(value)
    }
}