package io.eqoty.shared.viewmodel.screens.store

import co.touchlab.kermit.Logger
import io.eqoty.shared.datalayer.functions.getPurchasePrice
import io.eqoty.shared.datalayer.functions.getReleaseList
import io.eqoty.shared.datalayer.functions.getWalletConnected
import io.eqoty.shared.datalayer.functions.makePurchase
import io.eqoty.shared.datalayer.sources.DataSrc
import io.eqoty.shared.datalayer.sources.REMOTE
import io.eqoty.shared.datalayer.sources.web3.ExternalWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.InternalWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.WalletProvider
import io.eqoty.shared.viewmodel.Events
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.scaffold.setWalletScreenExpanded
import io.eqoty.shared.viewmodel.screens.topbar.updateWalletBtnInfo
import io.eqoty.shared.viewmodel.screens.wallettxrequest.internalWalletTxRequestResultSharedFlow
import io.eqoty.shared.viewmodel.screens.wallettxrequest.setInternalWalletTxRequest
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.launch


/********** EVENT functions, called directly by the UI layer **********/

fun Events.refreshStoreScreenOnEthWalletChanges() = inScreenScopeLaunchInBackground(ScreenStack.Main) {
    dataRepository.walletAddressChangeFlow.collect { newWalletAddress ->
        Logger.d("refreshStoreScreenOnEthWalletChanges: onCollect:$newWalletAddress")
        refreshStoreScreen(REMOTE)
    }
}

private var refreshStoreScreenJob: Job? = null

fun Events.refreshStoreScreen(dataSrc: DataSrc) {
    // prevent multiple instances of refresh from being executed
    if (refreshStoreScreenJob?.isActive == true) {
        return
    }
    refreshStoreScreenJob = inScreenScopeLaunchInBackground(ScreenStack.Main) {
        val isConnected = dataRepository.getWalletConnected()
        val currentScreenScope = this
        if (!isConnected) {
            stateManager.updateScreen(ScreenStack.Main, StoreState::class) {
                it.copy(
                    isLoading = false,
                    storeListItems = emptyList(),
                )
            }
        } else {
            stateManager.updateScreen(ScreenStack.Main, StoreState::class) {
                it.copy(
                    isLoading = true,
                )
            }
            val releaseListResults = dataRepository.getReleaseList(currentScreenScope, dataSrc)
            releaseListResults.collect { releaseList ->
                val storeListItems = releaseList.map { r -> StoreListItem(r, null) }.toMutableList()
                stateManager.updateScreen(ScreenStack.Main, StoreState::class) {
                    it.copy(
                        storeListItems = ArrayList(storeListItems),
                    )
                }
                // concurrently get all prices
                coroutineScope {
                    releaseList.forEachIndexed { i, release ->
                        launch(Dispatchers.Default) {
                            val prices = dataRepository.getPurchasePrice(release.id).first()
                            storeListItems[i] = StoreListItem(release, prices)
                            stateManager.updateScreen(ScreenStack.Main, StoreState::class) {
                                // copy the lists so that ScreenStates to make sure the state hash changes
                                // and the StateFlow emits the new state
                                it.copy(
                                    storeListItems = ArrayList(storeListItems),
                                )
                            }
                        }
                    }
                }
            }
            // update state, after retrieving data from the repository
            stateManager.updateScreen(ScreenStack.Main, StoreState::class) {
                it.copy(
                    isLoading = false,
                )
            }
        }
    }
}

fun Events.makePurchase(releaseId: UInt) =
    inScreenScopeLaunch(ScreenStack.Main) {
        if (dataRepository.selectedWalletProvider == WalletProvider.InternalRandom) {
            setWalletScreenExpanded(true)?.join()
            return@inScreenScopeLaunch
        }
        val price = dataRepository.getPurchasePrice(releaseId).first()
        when (val web3Provider = dataRepository.web3Provider) {
            is InternalWeb3Provider<*> -> {
                try {
                    setWalletScreenExpanded(true)?.join()
                    dataRepository.makePurchase(releaseId, price) { txRequestInfo ->
                        setInternalWalletTxRequest(txRequestInfo)
                        val requestResult = internalWalletTxRequestResultSharedFlow.first()
                        require(txRequestInfo == requestResult.txRequestInfo)
                        requestResult.result
                    }
                } catch (t: Throwable) {
                    t.printStackTrace()
                } finally {
                    setWalletScreenExpanded(false)?.join()
                }
            }

            is ExternalWeb3Provider -> {
                try {
                    dataRepository.makePurchase(releaseId, price, null)
                } catch (t: Throwable) {
                    t.printStackTrace()
                }
            }

            else -> throw NotImplementedError("web3Provider: $web3Provider not handled")
        }

        updateWalletBtnInfo()?.join()

        // update state, after retrieving data from the repository
//        stateManager.updateScreen(StoreState::class) {
//            val modifiedListData = it.nftReleaseListItems.map { releaseListItem ->
//                if (releaseListItem.id == releaseId) {
//                    NftReleaseListItem(updatedReleaseListItem.release)
//                } else releaseListItem
//            }
//            it.copy(
//                isLoading = false,
//                nftReleaseListItems = modifiedListData,
//            )
//        }

    }