package io.eqoty.shared.datalayer.objects

import kotlinx.serialization.Serializable


@Serializable
data class NftMetadata(
    val id: String,
    val name: String,
    val description: String,
    val image: String,
    val animationUrl: String,
    val privateAudioResources: List<RemoteAudioResource>,
    val privateAudioDuration: Long,
    val publicAudioResources: List<RemoteAudioResource>,
    val publicAudioDuration: Long,
    val artists: List<String>,
    val album: String
)