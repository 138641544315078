package io.eqoty.shared.datalayer.sources.web3


enum class WalletProvider {
    Keplr,
    MetaMask,
    WalletConnect,
    Internal,
    /*
    InternalRandom:
    An internal wallet with a random address used to
    load the store when the user hasn't connected their own wallet
    */
    InternalRandom
}
