package io.eqoty.shared.viewmodel.screens.connectwallet

import co.touchlab.kermit.Logger
import io.eqoty.shared.datalayer.functions.*
import io.eqoty.shared.datalayer.sources.localdb.nfts.*
import io.eqoty.shared.datalayer.sources.web3.WalletProvider
import io.eqoty.shared.viewmodel.Events
import io.eqoty.shared.viewmodel.ScreenIdentifier
import io.eqoty.shared.viewmodel.screens.Screen
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.leftnavigationrail.displayReleaserMode
import io.eqoty.shared.viewmodel.screens.leftnavigationrail.setReleaserMode
import io.eqoty.shared.viewmodel.screens.topbar.updateWalletBtnInfo
import io.eqoty.shared.viewmodel.screens.walletInfo.WalletInfoScreenState
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.onStart


/********** EVENT functions, called directly by the UI layer **********/
fun Events.displayCurrentWalletStatus() = inScreenScopeLaunch(ScreenStack.Wallet) {
    val injectedEthProviderAvailable = dataRepository.getInjectedEthProviderAvailable()
    val injectedKeplrProviderAvailable = dataRepository.getInjectedKeplrProviderAvailable()
    val ethWalletConnected = dataRepository.getWalletConnected()
    val network = dataRepository.getWeb3Network()

    // getWalletAddress triggers connection request only get if wallet is already connected
    // otherwise the user should click connect in the ui to trigger connection request
    val walletAddress = if (ethWalletConnected) {
        dataRepository.getWalletAddress()
    } else null
    // update state, after retrieving data from the repository
    stateManager.updateScreen(ScreenStack.Wallet, ConnectWalletScreenState::class) {
        it.copy(
            injectedEthProviderAvailable = injectedEthProviderAvailable,
            injectedKeplrProviderAvailable = injectedKeplrProviderAvailable,
            walletConnected = ethWalletConnected,
            network = network,
            walletAddress = walletAddress
        )
    }
}

suspend fun Events.navigateToScreenIfWalletConnected(
    screenIdentifier: ScreenIdentifier,
) {
    val walletConnected = dataRepository.getWalletConnected()
    if (walletConnected) {
        stateManager.navigation.navigateToScreen(ScreenStack.Wallet, screenIdentifier)
    }
}


fun <K> Events.startConnectSequence(
    privateKeyOrMnemonic: K?,
    selectedWallet: WalletProvider,
) = inScreenScopeLaunch(ScreenStack.Wallet) {
    with(dataRepository) {
        selectedWalletProvider = selectedWallet
        val addedAccount = when (privateKeyOrMnemonic) {
            is String -> {
                addAccountToWallet(privateKeyOrMnemonic)
            }

            is UByteArray -> {
                addAccountToWallet(privateKeyOrMnemonic)
            }

            else -> null
        }
        injectWeb3ProviderWithWindowProvider(selectedWallet)
        listenForNetworkChangesAndResetEthContractsRepo()
        listenForWalletAddressChangesSetForSessionAndNavToWalletConnectedOrDisconnected()
        requestWalletAccountForSession(selectedWallet, addedAccount)
        displayReleaserMode()
    }
}

fun <K> Events.changeAccount(
    privateKeyOrMnemonic: K?,
    selectedWallet: WalletProvider,
) = inScreenScopeLaunch(ScreenStack.Wallet) {
    with(dataRepository) {
        selectedWalletProvider = selectedWallet
        val addedAccount = when (privateKeyOrMnemonic) {
            is String -> {
                addAccountToWallet(privateKeyOrMnemonic)
            }

            is UByteArray -> {
                addAccountToWallet(privateKeyOrMnemonic)
            }

            else -> null
        }
        injectWeb3ProviderWithWindowProvider(selectedWallet)
        requestWalletAccountForSession(selectedWallet, addedAccount)
    }
}

suspend fun Events.listenForNetworkChangesAndResetEthContractsRepo() {
    val startedFlow = MutableSharedFlow<Unit>()
    inAppScopeLaunchInBackground(ScreenStack.Wallet) {
        dataRepository.startNetworkChangeListener().onStart {
            startedFlow.emit(Unit)
        }.collect { chainId ->
            Logger.d("chainChanged: chainId=$chainId")
            clearAllData()
            displayCurrentWalletStatus()?.join()
            updateWalletBtnInfo()?.join()
            navigateToScreenIfWalletConnected(ScreenIdentifier.get(Screen.WalletInfo, null))
        }
    }
    startedFlow.first()
}

suspend fun Events.listenForWalletAddressChangesSetForSessionAndNavToWalletConnectedOrDisconnected() {
    val startedFlow = MutableSharedFlow<Unit>()
    inAppScopeLaunchInBackground(ScreenStack.Wallet) {
        dataRepository.startWalletAddressChangeListener()
        dataRepository.walletAddressChangeFlow.onStart {
            startedFlow.emit(Unit)
        }.collect { newWalletAddress ->
            Logger.d("chosen wallet address changed: address=$newWalletAddress")
            dataRepository.localDb.royaltyNftTableQueries.deleteTable()
            val walletConnected = newWalletAddress != null
            stateManager.updateScreen(ScreenStack.Wallet, ConnectWalletScreenState::class) {
                it.copy(
                    walletConnected = walletConnected, walletAddress = newWalletAddress
                )
            }
            updateWalletBtnInfo()?.join()
            if (walletConnected) {
                displayCurrentWalletStatus()?.join()
                if (dataRepository.selectedWalletProvider != WalletProvider.InternalRandom) {
                    navigateToScreenIfWalletConnected(ScreenIdentifier.get(Screen.WalletInfo, null))
                }
            } else {
                dataRepository.selectedWalletProvider = WalletProvider.InternalRandom
                while (stateManager.currentScreenIdentifier(ScreenStack.Wallet).screen != Screen.ConnectWallet) {
                    stateManager.navigation.exitScreen(
                        ScreenStack.Wallet, stateManager.currentScreenIdentifier(ScreenStack.Wallet)
                    )
                }
            }
        }
    }
    startedFlow.first()
}

fun Events.disconnectWallet() = inScreenScopeLaunch(ScreenStack.Wallet) {
    setReleaserMode(false)
    try {
        dataRepository.disconnectWallet()
        clearAllData()
    } catch (t: Throwable) {
        stateManager.updateScreen(ScreenStack.Wallet, WalletInfoScreenState::class) {
            it.copy(
                errorMessage = t.message
            )
        }
    }
}

private suspend fun Events.clearAllData() {
    dataRepository.localDb.deleteAllAudioTracks()
    dataRepository.localDb.deleteAllNftMetadata()
    dataRepository.localDb.deleteAllPurchaseNfts()
    dataRepository.localDb.deleteAllProductDealerContracts()
    dataRepository.localDb.deleteAllRoyaltyNfts()
    dataRepository.localDb.deleteAllReleases()
}
