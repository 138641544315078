package io.eqoty.shared.viewmodel.screens.myroyalties

import co.touchlab.kermit.Logger
import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.functions.*
import io.eqoty.shared.datalayer.sources.DataSrc
import io.eqoty.shared.datalayer.sources.REMOTE
import io.eqoty.shared.datalayer.sources.web3.ExternalWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.InternalWeb3Provider
import io.eqoty.shared.viewmodel.Events
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.scaffold.setWalletScreenExpanded
import io.eqoty.shared.viewmodel.screens.topbar.updateWalletBtnInfo
import io.eqoty.shared.viewmodel.screens.wallettxrequest.internalWalletTxRequestResultSharedFlow
import io.eqoty.shared.viewmodel.screens.wallettxrequest.setInternalWalletTxRequest
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.first

/********** EVENT functions, called directly by the UI layer **********/

fun Events.refreshMyRoyaltiesScreenOnEthWalletChanges() = inScreenScopeLaunchInBackground(ScreenStack.Main) {
    dataRepository.walletAddressChangeFlow.collect { newWalletAddress ->
        Logger.d("refreshMyRoyaltiesScreenOnEthWalletChanges: onCollect:$newWalletAddress")
        refreshMyRoyaltiesScreen(REMOTE)
    }
}

private var refreshMyRoyaltiesScreenJob: Job? = null

fun Events.refreshMyRoyaltiesScreen(dataSrc: DataSrc) {
    if (refreshMyRoyaltiesScreenJob?.isActive == true) {
        return
    }
    refreshMyRoyaltiesScreenJob = inScreenScopeLaunchInBackground(ScreenStack.Main) {
        val isConnected = dataRepository.getWalletConnected()
        val currentScreenScope = this
        if (!isConnected) {
            stateManager.updateScreen(ScreenStack.Main, MyRoyaltiesState::class) {
                it.copy(
                    isConnected = false, isLoading = false, myRoyaltiesListItems = emptyList()
                )
            }
        } else {
            stateManager.updateScreen(ScreenStack.Main, MyRoyaltiesState::class) {
                it.copy(
                    isConnected = true,
                    isLoading = true,
                )
            }
            val releaseListResults =
                dataRepository.getOwnedRoyaltyNftsAndTheirReleases(currentScreenScope, dataSrc)
            releaseListResults.collect { ownedRoyaltyNftsAndTheirReleases ->
                val listData = ownedRoyaltyNftsAndTheirReleases.map { ownedRoyaltyNftsAndRelease ->
                    MyRoyaltiesListItem(
                        _release = ownedRoyaltyNftsAndRelease.release,
                        _royalties = ownedRoyaltyNftsAndRelease.royaltyNfts
                    )
                }
                // update state, after retrieving data from the repository
                stateManager.updateScreen(ScreenStack.Main, MyRoyaltiesState::class) {
                    it.copy(
                        myRoyaltiesListItems = listData,
                    )
                }
            }
            stateManager.updateScreen(ScreenStack.Main, MyRoyaltiesState::class) {
                it.copy(
                    isLoading = false,
                )
            }
        }
    }
}

fun Events.mergeRoyaltyTokens(
    releaseId: UInt,
    tokenIds: List<BigInteger>,
    sharesPerToken: List<UInt>,
) = inScreenScopeLaunch(ScreenStack.Main) {
    when (val web3Provider = dataRepository.web3Provider) {
        is InternalWeb3Provider<*> -> {
            try {
                setWalletScreenExpanded(true)?.join()
                dataRepository.mergeRoyaltyTokens(releaseId, tokenIds, sharesPerToken) { txRequestInfo ->
                    setInternalWalletTxRequest(txRequestInfo)
                    val requestResult = internalWalletTxRequestResultSharedFlow.first()
                    require(txRequestInfo == requestResult.txRequestInfo)
                    requestResult.result
                }
            } catch (t: Throwable) {
                t.printStackTrace()
            } finally {
                setWalletScreenExpanded(false)?.join()
            }
        }

        is ExternalWeb3Provider -> {
            try {
                dataRepository.mergeRoyaltyTokens(releaseId, tokenIds, sharesPerToken, null)
            } catch (t: Throwable) {
                t.printStackTrace()
            }
        }

        else -> throw NotImplementedError("web3Provider: $web3Provider not handled")
    }


    try {
        val updatedReleaseListItem = dataRepository.getReleaseWithRoyaltyNfts(releaseId)

        // update state, after retrieving data from the repository
        stateManager.updateScreen(ScreenStack.Main, MyRoyaltiesState::class) {
            val modifiedListData = it.myRoyaltiesListItems.map { releaseListItem ->
                if (releaseListItem.trackRowData.releaseId == releaseId) {
                    MyRoyaltiesListItem(
                        updatedReleaseListItem.release,
                        updatedReleaseListItem.royaltyNfts,
                    )
                } else releaseListItem
            }
            it.copy(
                isLoading = false,
                myRoyaltiesListItems = modifiedListData,
            )
        }
    } catch (t: Throwable) {
        t.printStackTrace()
    }

}

fun Events.claimRoyaltyBalance(
    releaseId: UInt, tokenId: BigInteger,
) = inScreenScopeLaunch(ScreenStack.Main) {
    val royaltyDealer = dataRepository.getRelease(releaseId)!!.royaltyDealer
    when (val web3Provider = dataRepository.web3Provider) {
        is InternalWeb3Provider<*> -> {
            try {
                setWalletScreenExpanded(true)?.join()
                dataRepository.claimRoyaltyBalance(royaltyDealer, tokenId) { txRequestInfo ->
                    setInternalWalletTxRequest(txRequestInfo)
                    val requestResult = internalWalletTxRequestResultSharedFlow.first()
                    require(txRequestInfo == requestResult.txRequestInfo)
                    requestResult.result
                }
            } catch (t: Throwable) {
                t.printStackTrace()
            } finally {
                setWalletScreenExpanded(false)?.join()
            }
        }

        is ExternalWeb3Provider -> {
            try {
                dataRepository.claimRoyaltyBalance(royaltyDealer, tokenId, null)
            } catch (t: Throwable) {
                t.printStackTrace()
            }
        }

        else -> throw NotImplementedError("web3Provider $web3Provider not handled")
    }


    try {
        val updatedReleaseListItem = dataRepository.getReleaseWithRoyaltyNfts(releaseId)
        // update state, after retrieving data from the repository
        stateManager.updateScreen(ScreenStack.Main, MyRoyaltiesState::class) {
            val modifiedListData = it.myRoyaltiesListItems.map { releaseListItem ->
                if (releaseListItem.trackRowData.releaseId == releaseId) {
                    MyRoyaltiesListItem(updatedReleaseListItem.release, updatedReleaseListItem.royaltyNfts)
                } else releaseListItem
            }
            it.copy(
                isLoading = false,
                myRoyaltiesListItems = modifiedListData,
            )
        }
        updateWalletBtnInfo()?.join()
    } catch (t: Throwable) {
        t.printStackTrace()
    }

}