package io.eqoty.shared.datalayer.functions

import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.AudioContainer
import io.eqoty.shared.datalayer.objects.AudioFileAndInfo
import io.eqoty.shared.datalayer.objects.AudioInfo
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds


enum class AudioTranscodeDefaults(
    private val ffmpegArgs: Array<String>,
    val fileNameExtra: String?,
    val audioContainer: AudioContainer,
    val isReleaseFormat: Boolean,
    val fixedLosslessBitDepth: Int? = null,
) {
    //https://trac.ffmpeg.org/wiki/Encode/MP3
    MP3_320(
        ffmpegArgs = arrayOf("-acodec", "libmp3lame", "-b:a", "320k"), " [320]", AudioContainer.MP3, true
    ),
    MP3_V0(
        ffmpegArgs = arrayOf("-acodec", "libmp3lame", "-q:a", "0"), " [V0]", AudioContainer.MP3, true
    ),

    //https://artists.spotify.com/en/help/article/audio-file-formats
    //https://wiki.hydrogenaud.io/index.php?title=Recommended_Ogg_Vorbis#Recommended_Encoder_Settings
    OGG_VORBIS_96(
        ffmpegArgs = arrayOf("-acodec", "libvorbis", "-q", "2"), " [96]", AudioContainer.OGG, false
    ),
    OGG_VORBIS_160(
        ffmpegArgs = arrayOf("-acodec", "libvorbis", "-q", "5"), " [160]", AudioContainer.OGG, false
    ),
    OGG_VORBIS_320(
        ffmpegArgs = arrayOf("-acodec", "libvorbis", "-q", "9"), " [320]", AudioContainer.OGG, false
    ),

    //https://wiki.xiph.org/Opus_Recommended_Settings
    OGG_OPUS_64(
        ffmpegArgs = arrayOf("-acodec", "libopus", "-b:a", "64k"), " [64]", AudioContainer.OGG, true
    ),
    OGG_OPUS_96(
        ffmpegArgs = arrayOf("-acodec", "libopus", "-b:a", "96k"), " [96]", AudioContainer.OGG, true
    ),
    OGG_OPUS_128(
        ffmpegArgs = arrayOf("-acodec", "libopus", "-b:a", "128k"), " [128]", AudioContainer.OGG, true
    ),

    //https://superuser.com/questions/746969/ffmpeg-to-convert-from-flac-to-wav
    WAV_16(
        ffmpegArgs = arrayOf("-acodec", "pcm_s16le"), null, AudioContainer.WAV, true, fixedLosslessBitDepth = 16
    ),
    WAV_24(
        ffmpegArgs = arrayOf("-acodec", "pcm_s24le"), " [24bit]", AudioContainer.WAV, true, fixedLosslessBitDepth = 24
    ),

    //https://stackoverflow.com/questions/40561505/convert-wav-to-aiff-with-ffmpeg
    AIFF_16(
        ffmpegArgs = arrayOf("-acodec", "pcm_s16be"), null, AudioContainer.AIFF, true, fixedLosslessBitDepth = 16
    ),
    AIFF_24(
        ffmpegArgs = arrayOf("-acodec", "pcm_s24be"), " [24bit]", AudioContainer.AIFF, true, fixedLosslessBitDepth = 24
    ),

    FLAC(
        ffmpegArgs = arrayOf("-acodec", "flac"), null, audioContainer = AudioContainer.FLAC, true
    ),

    ALAC(
        ffmpegArgs = arrayOf("-acodec", "alac"), null, audioContainer = AudioContainer.ALAC, false
    )
    ;

    private fun millisecondsToFfmpegTimeFormat(ms: Long): String {
        val duration = ms.milliseconds

        val hours = duration.inWholeHours
        val minutes = (duration - hours.hours).inWholeMinutes
        val seconds = (duration - hours.hours - minutes.minutes).inWholeSeconds
        val milliseconds = (duration - hours.hours - minutes.minutes - seconds.seconds).inWholeMilliseconds

        return "${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${
            seconds.toString().padStart(2, '0')
        }.${milliseconds.toString().padStart(3, '0')}"
    }

    fun inputFfmpegArgs(clipRange: LongRange?): Array<String> {
        return clipRange?.let { arrayOf("-ss", millisecondsToFfmpegTimeFormat(it.first)) } ?: emptyArray()
    }

    fun outputFfmpegArgs(clipRange: LongRange?): Array<String> {
        val outputTimeLength =
            clipRange?.let { arrayOf("-t", millisecondsToFfmpegTimeFormat(it.last - it.first)) } ?: emptyArray()
        return outputTimeLength + ffmpegArgs + arrayOf("-vn")
    }


    fun buildFileName(clipRange: LongRange?, nameWithoutExtension: String): String {
        val clipRangeFileNameSuffix = clipRange?.run {
            "_${this.first.milliseconds.inWholeSeconds}-${this.last.milliseconds.inWholeSeconds}"
        } ?: ""
        return nameWithoutExtension + clipRangeFileNameSuffix + (fileNameExtra ?: "") + audioContainer.fileExtension
    }

    fun buildFileNameWithOutExtension(clipRange: LongRange?, nameWithoutExtension: String): String {
        val clipRangeFileNameSuffix = clipRange?.run {
            "_${this.first.milliseconds.inWholeSeconds}-${this.last.milliseconds.inWholeSeconds}"
        } ?: ""
        return nameWithoutExtension + clipRangeFileNameSuffix + (fileNameExtra ?: "")
    }
}

fun getFilteredTranscodeDefaults(
    inputAudioInfo: AudioInfo,
    includeInputAudioFormat: Boolean,
    releaseFormatsOnly: Boolean
): List<AudioTranscodeDefaults> {
    val filteredFormats = AudioTranscodeDefaults.entries.filter { format ->
        (includeInputAudioFormat || inputAudioInfo.container != format.audioContainer) &&
                (format.fixedLosslessBitDepth == null || format.fixedLosslessBitDepth == inputAudioInfo.bitDepth) &&
                if (releaseFormatsOnly) format.isReleaseFormat else true
    }
    return filteredFormats
}

expect suspend fun Repository.transcodeLosslessFileToAllFormats(
    inputAudioFileAndInfo: AudioFileAndInfo,
    clipRange: LongRange?,
    progress: (AudioTranscodeDefaults) -> Unit
): List<AudioFileAndInfo>