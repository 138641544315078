package io.eqoty.shared.datalayer.functions

import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.viewmodel.screens.royaltynftdetail.RoyaltyNftDetailDisplayFormatted

suspend fun Repository.getRoyaltyNftDetailDisplayFormatted(
    releaseId: UInt,
    id: BigInteger
): RoyaltyNftDetailDisplayFormatted = withRepoContext {

    // RETURN a CountryInfo object, whose constructor takes 2 datalayer objects:
    //  - CountriesListData (read from the localDb)
    //  - CountriesExtraData (read from the runtimeCache)
    RoyaltyNftDetailDisplayFormatted(
        getRelease(releaseId)!!,
        getRoyaltyNft(releaseId, id)!!,
    )
}
