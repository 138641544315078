package io.eqoty.shared.viewmodel.screens.organizationprofile


import io.eqoty.shared.datalayer.objects.FormInputImage
import io.eqoty.shared.datalayer.objects.FormInputImageFile
import io.eqoty.shared.datalayer.objects.FormInputImageUrl
import io.eqoty.shared.datalayer.sources.filesystem.CommonFile
import korlibs.image.format.readBitmapNative
import korlibs.io.file.std.VfsFileFromData


class EditOrganizationProfileFormInput(
    private val newOrganizationBannerImgFile: CommonFile?,
    private val existingOrganizationBannerImgUrl: String?,
    val description: String?,
) {
    val organizationBannerImage: FormInputImage
        get() = if (newOrganizationBannerImgFile != null) {
            FormInputImageFile(newOrganizationBannerImgFile)
        } else {
            FormInputImageUrl(existingOrganizationBannerImgUrl!!)
        }

    suspend fun validate(): EditOrganizationProfileFormInputErrors {
        val inputErrors = EditOrganizationProfileFormInputErrors()

        if (existingOrganizationBannerImgUrl == null && newOrganizationBannerImgFile == null) {
            inputErrors.organizationBannerFileError = "Banner Image required"
        }
        newOrganizationBannerImgFile?.let {
            val bytes = it.getBytes()
            val info = try {
                VfsFileFromData(bytes).readBitmapNative()
            } catch (e: Throwable) {
                inputErrors.organizationBannerFileError = "Banner image must be a valid image file"
                return inputErrors
            }
            if (info.width < 2660 || info.height < 1140) {
                inputErrors.organizationBannerFileError = "Banner images must be at least 2660px x 1140px"
            }
            if (info.width == 2660 && info.height == 1140 && bytes.size > 10_485_760) {
                inputErrors.organizationBannerFileError = "Banner images can’t be larger than 10MiB"
            }

        }
        return inputErrors
    }


}

data class EditOrganizationProfileFormInputErrors(
    var organizationBannerFileError: String? = null,
) {
    fun hasNoErrors(): Boolean {
        return organizationBannerFileError == null
    }
}
