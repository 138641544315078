package io.eqoty.shared.datalayer.functions

import com.ionspin.kotlin.bignum.integer.BigInteger
import com.ionspin.kotlin.bignum.integer.toBigInteger
import io.eqoty.cosmwasm.std.types.Coin
import io.eqoty.cosmwasm.std.types.ContractInfo
import io.eqoty.secret.std.contract.msg.EqotyProductDealerMsgs
import io.eqoty.secret.std.contract.msg.EqotyReleaseMsgs
import io.eqoty.secret.std.contract.msg.EqotyRoyaltyDealerMsgs
import io.eqoty.secret.std.contract.msg.Snip721Msgs
import io.eqoty.secret.std.types.Permission
import io.eqoty.secret.std.types.Permit
import io.eqoty.secretk.extensions.accesscontrol.PermitFactory
import io.eqoty.secretk.types.MsgExecuteContract
import io.eqoty.secretk.types.TxOptions
import io.eqoty.secretk.utils.toByteString
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.sources.web3.InternalWalletTxRequest
import io.eqoty.shared.datalayer.sources.web3.SecretEndpoints.releaseAddr
import io.eqoty.shared.datalayer.sources.web3.SecretEndpoints.releaseCodeHash
import io.eqoty.shared.datalayer.sources.web3.TxRequestInfo
import io.eqoty.shared.datalayer.utils.bi
import io.eqoty.shared.datalayer.utils.bi10
import kotlinx.datetime.Clock
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import okio.ByteString.Companion.toByteString
import web3.eth.TransactionReceipt
import kotlin.random.Random


private val json = Json {
    ignoreUnknownKeys = true
}

private val jsonPretty = Json {
    prettyPrint = true
}

suspend fun Repository.getPermit(senderAddress: String, contractAddress: String): Permit {
    return runtimeCache.senderToAddressPermitMap.getOrPut(senderAddress) {
        mutableMapOf()
    }.getOrPut(contractAddress) {
        makePermit(contractAddress, senderAddress)
    }
}


private suspend fun Repository.makePermit(contractAddress: String, senderAddress: String): Permit = withRepoContext {
    val network = getWeb3Network()!!
    val client = getClient(network)
    return@withRepoContext PermitFactory.newPermit(
        client.wallet!!,
        senderAddress,
        client.getChainId(),
        "Permit",
        listOf(contractAddress),
        listOf(Permission.Owner),
    )
}

suspend fun Repository.getReleaseTokenCount(): BigInteger {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val query = Snip721Msgs.Query(
        numTokens = Snip721Msgs.Query.NumTokens()
    )
    val res = client.queryContractSmart(releaseAddr, Json.encodeToString(query), releaseCodeHash)
    val numTokens = Json.decodeFromString<EqotyReleaseMsgs.QueryAnswer>(res).numTokens!!
    return numTokens.count.bi
}

suspend fun Repository.getAllReleases(): List<EqotyReleaseMsgs.Release> {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val query =
        EqotyReleaseMsgs.Query(releases = EqotyReleaseMsgs.Query.Releases(getPermit(senderAddress, releaseAddr)))
    val res = client.queryContractSmart(releaseAddr, Json.encodeToString(query), releaseCodeHash)
    return Json.decodeFromString<EqotyReleaseMsgs.QueryAnswer>(res).releases!!
}


suspend fun Repository.getReleaseIdsByOrganization(id: ULong): List<UInt> {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val query =
        EqotyReleaseMsgs.Query(organizationReleases = EqotyReleaseMsgs.Query.OrganizationReleases(id))
    val res = client.queryContractSmart(releaseAddr, Json.encodeToString(query), releaseCodeHash)
    return Json.decodeFromString<EqotyReleaseMsgs.QueryAnswer>(res).organizationReleases!!
}


suspend fun Repository.getReleasesBatch(ids: List<UInt>): List<EqotyReleaseMsgs.Release> {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val query =
        EqotyReleaseMsgs.Query(
            releasesBatch = EqotyReleaseMsgs.Query.ReleasesBatch(
                getPermit(
                    senderAddress,
                    releaseAddr
                ), ids
            )
        )
    val res = client.queryContractSmart(releaseAddr, Json.encodeToString(query), releaseCodeHash)
    return Json.decodeFromString<EqotyReleaseMsgs.QueryAnswer>(res).releases!!
}

suspend fun Repository.getClaimableRoyaltyBalance(
    royaltyDealer: ContractInfo,
    snip721Contract: ContractInfo,
    royaltyId: BigInteger
): List<Coin> {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val query = EqotyRoyaltyDealerMsgs.Query(
        availableBalances = EqotyRoyaltyDealerMsgs.Query.AvailableBalances(
            royaltyId.toString(),
            permit = getPermit(senderAddress, snip721Contract.address)
        )
    )

    val res = client.queryContractSmart(royaltyDealer.address, Json.encodeToString(query), royaltyDealer.codeHash)
    return Json.decodeFromString<EqotyRoyaltyDealerMsgs.QueryAnswer>(res).funds!!
}

suspend fun Repository.getRoyaltyTokenRoyaltyShares(
    royaltyDealer: ContractInfo,
    royaltyId: BigInteger,
): BigInteger = withRepoContext {
    val contractInfoQuery = EqotyRoyaltyDealerMsgs.Query(
        tokenShares = EqotyRoyaltyDealerMsgs.Query.TokenShares(royaltyId.toString())
    )
    return@withRepoContext Json.decodeFromString<EqotyRoyaltyDealerMsgs.QueryAnswer>(
        getClient(getWeb3Network()!!).queryContractSmart(
            royaltyDealer.address, Json.encodeToString(contractInfoQuery), royaltyDealer.codeHash
        )
    ).shares!!.toBigInteger()
}

suspend fun Repository.getReleaseTokenUri(id: UInt): String {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val query = Snip721Msgs.Query(
        nftInfo = Snip721Msgs.Query.NftInfo(tokenId = id.toString())
    )
    val res = client.queryContractSmart(releaseAddr, Json.encodeToString(query), releaseCodeHash)
    return Json.decodeFromString<Snip721Msgs.QueryAnswer>(res).nftInfo?.tokenUri!!
}

suspend fun Repository.mintRelease(
    organizationId: ULong,
    metaDataUri: String,
    onChainPrivateMediaUrl: String,
    purchasePrice: BigInteger,
    ivKey: UByteArray,
    internalWltTxRequest: InternalWalletTxRequest?
): TransactionReceipt {
    val senderAddress = getWalletAddress()!!
    val network = getWeb3Network()!!
    val client = getClient(network)
    val purchasePrices = listOf(Coin(amount = purchasePrice, denom = "uscrt"))
    val totalShares = 1_000_000u
    val adminAddress = "secret1ykvqksy3kwus7fepdcj9tsuvzagp586ws5x6mu"
    val eqRoyaltyInitMsg = EqotyRoyaltyDealerMsgs.InstantiateConfig(
        recipients = listOf(
            EqotyRoyaltyDealerMsgs.TokensRecipient(
                recipient = senderAddress, sharesPerToken = listOf((totalShares * 95u) / 100u)
            ),
            EqotyRoyaltyDealerMsgs.TokensRecipient(
                recipient = adminAddress, sharesPerToken = listOf((totalShares * 5u) / 100u)
            ),
        ),
        totalShares = totalShares,
        entropy = Random.nextBytes(32).toByteString().hex()
    )
    val publicMetadata = Snip721Msgs.Metadata(metaDataUri)
    val privateMetadata = Snip721Msgs.Metadata(
        extension = Snip721Msgs.Metadata.Extension(
            media = listOf(
                Snip721Msgs.Metadata.MediaFile(
                    url = onChainPrivateMediaUrl, authentication = Snip721Msgs.Metadata.MediaFile.Authentication(
                        key = ivKey.toByteString().base64()
                    )
                )
            )
        )
    )
    val eqPurchaseInitMsg = EqotyProductDealerMsgs.InstantiateConfig(
        prices = purchasePrices,
        publicMetadata = null,
        privateMetadata = null,
        entropy = Random.nextBytes(32).toByteString().hex()
    )
    val msg = EqotyReleaseMsgs.Execute(
        mintRelease = EqotyReleaseMsgs.Execute.MintRelease(
            eqRoyaltyDealerInitConfig = eqRoyaltyInitMsg,
            eqProductDealerInitConfig = eqPurchaseInitMsg,
            mintReleaseConfig = EqotyReleaseMsgs.Execute.MintRelease.MintReleaseConfig(
                organizationId,
                publicMetadata = publicMetadata,
                privateMetadata = privateMetadata,
            )
        )
    )
    val msgPretty = jsonPretty.encodeToString(msg)
    val msgs = listOf(
        MsgExecuteContract(
            sender = senderAddress,
            contractAddress = releaseAddr,
            codeHash = releaseCodeHash,
            msg = msgPretty,
        )
    )
    val gasLimit = 550_000
    val txOptions = if (internalWltTxRequest != null) {
        val requestedGasLimit = if (client.chainId == "secretdev-1") {
            try {
                val simulate = client.simulate(msgs)
                (simulate.gasUsed.toDouble() * 1.1).toInt()
            } catch (t: Throwable) {
                throw Error("Simulate Failed: ${t.message}")
            }
        } else {
            gasLimit
        }
        val approved = internalWltTxRequest.invoke(
            TxRequestInfo(null, Coin(requestedGasLimit, "uscrt"), releaseAddr, msgPretty)
        )
        if (!approved) {
            throw Error("Tx Declined")
        }
        TxOptions(gasLimit = requestedGasLimit)
    } else {
        TxOptions(1_000_000)
    }

    val res = client.execute(
        msgs, txOptions = txOptions
    )

    return TransactionReceipt(res.txhash)
}


suspend fun Repository.getRoyaltyTokenShares(
    royaltyDealer: ContractInfo,
    royaltyId: BigInteger
): BigInteger {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val query =
        EqotyRoyaltyDealerMsgs.Query(tokenShares = EqotyRoyaltyDealerMsgs.Query.TokenShares(royaltyId.toString()))
    return Json.decodeFromString<EqotyRoyaltyDealerMsgs.QueryAnswer>(
        client.queryContractSmart(
            royaltyDealer.address, Json.encodeToString(query), royaltyDealer.codeHash
        )
    ).shares!!.toBigInteger()
}

suspend fun Repository.queryBatchNftDossier(
    contractAddr: String, codeHash: String, ids: List<String>
): List<Snip721Msgs.QueryAnswer.NftDossier> {
    val network = getWeb3Network()!!
    val client = getClient(network)
    if (ids.isEmpty()) return emptyList()
    val query = Snip721Msgs.Query(
        batchNftDossier = Snip721Msgs.Query.BatchNftDossier(tokenIds = ids)
    )
    val res = client.queryContractSmart(contractAddr, Json.encodeToString(query), codeHash)
    return json.decodeFromString<Snip721Msgs.QueryAnswer>(res).batchNftDossier!!.nftDossiers
}

suspend fun Repository.fragmentRoyaltyToken(
    royaltyDealer: ContractInfo,
    tokenId: BigInteger,
    splitCount: BigInteger,
    internalWltTxRequest: InternalWalletTxRequest?
): TransactionReceipt {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val totalShares = getRoyaltyTokenShares(royaltyDealer, tokenId)
    val splitSharesAmount = (totalShares / splitCount).uintValue()
    val msg = EqotyRoyaltyDealerMsgs.Execute(
        redistributeShares = EqotyRoyaltyDealerMsgs.Execute.RedistributeShares(
            tokenIds = listOf(tokenId.toString()), recipients = listOf(
                EqotyRoyaltyDealerMsgs.TokensRecipient(
                    recipient = senderAddress,
                    sharesPerToken = List(splitCount.intValue()) { splitSharesAmount }),
            )
        )
    )
    val msgPretty = jsonPretty.encodeToString(msg)
    val msgs = listOf(
        MsgExecuteContract(
            sender = senderAddress,
            contractAddress = royaltyDealer.address,
            codeHash = royaltyDealer.codeHash,
            msg = msgPretty,
        )
    )
    val gasLimit = 120_000 + splitCount.intValue() * 60_000
    val txOptions = if (internalWltTxRequest != null) {
        val requestedGasLimit = if (client.chainId == "secretdev-1") {
            try {
                val simulate = client.simulate(msgs)
                (simulate.gasUsed.toDouble() * 1.1).toInt()
            } catch (t: Throwable) {
                throw Error("Simulate Failed: ${t.message}")
            }
        } else {
            gasLimit
        }
        val approved = internalWltTxRequest.invoke(
            TxRequestInfo(null, Coin(requestedGasLimit, "uscrt"), releaseAddr, msgPretty)
        )
        if (!approved) {
            throw Error("Tx Declined")
        }
        TxOptions(gasLimit = requestedGasLimit)
    } else {
        TxOptions(gasLimit)
    }
    val res = client.execute(
        msgs, txOptions = txOptions
    )
    return TransactionReceipt(res.txhash)
}

suspend fun Repository.mergeRoyalties(
    royaltyDealer: ContractInfo,
    snip721Contract: ContractInfo,
    tokenIds: MutableList<BigInteger>,
    sharesPerToken: List<UInt>,
    internalWltTxRequest: InternalWalletTxRequest?
): TransactionReceipt {
    tokenIds.sort()
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    // The royalty contract needs permission to transfer extra tokens to itself when merging. So give it permission
    // to transfer all tokens for a short time 60 seconds.
    val transferPermissionMsg = Snip721Msgs.Execute(
        setWhitelistedApproval = Snip721Msgs.Execute.SetWhitelistedApproval(
            address = royaltyDealer.address,
            expires = Snip721Msgs.Expiration(atTime = Clock.System.now().epochSeconds.toULong() + 60u),
            transfer = Snip721Msgs.Execute.AccessLevel.All
        )
    )
    val mergeMsg = EqotyRoyaltyDealerMsgs.Execute(
        redistributeShares = EqotyRoyaltyDealerMsgs.Execute.RedistributeShares(
            tokenIds = tokenIds.map { it.toString() },
            recipients = listOf(
                EqotyRoyaltyDealerMsgs.TokensRecipient(
                    recipient = senderAddress, sharesPerToken = sharesPerToken
                ),
            ),
            permit = getPermit(senderAddress, snip721Contract.address)
        )
    )
    val msgs = listOf(
        MsgExecuteContract(
            sender = senderAddress,
            contractAddress = snip721Contract.address,
            codeHash = snip721Contract.codeHash,
            msg = Json.encodeToString(transferPermissionMsg),
        ),
        MsgExecuteContract(
            sender = senderAddress,
            contractAddress = royaltyDealer.address,
            codeHash = royaltyDealer.codeHash,
            msg = Json.encodeToString(mergeMsg),
        )
    )
    val msgsPretty = jsonPretty.encodeToString(listOf(transferPermissionMsg, mergeMsg))
    val gasLimit = 120_000 + tokenIds.size * 60_000
    val txOptions = if (internalWltTxRequest != null) {
        val requestedGasLimit = if (client.chainId == "secretdev-1") {
            try {
                val simulate = client.simulate(msgs)
                (simulate.gasUsed.toDouble() * 1.1).toInt()
            } catch (t: Throwable) {
                throw Error("Simulate Failed: ${t.message}")
            }
        } else {
            gasLimit
        }
        val msgName = "MergeRoyaltyTokens"
        val approved = internalWltTxRequest.invoke(
            TxRequestInfo(null, Coin(requestedGasLimit, "uscrt"), releaseAddr, msgsPretty)
        )
        if (!approved) {
            throw Error("Tx Declined")
        }
        TxOptions(gasLimit = requestedGasLimit)
    } else {
        TxOptions(gasLimit)
    }
    val res = client.execute(
        msgs, txOptions = txOptions
    )
    return TransactionReceipt(res.txhash)
}

suspend fun Repository.claimRoyaltyBalance(
    royaltyDealer: ContractInfo,
    royaltyId: BigInteger,
    internalWltTxRequest: InternalWalletTxRequest?
): TransactionReceipt {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val msg = EqotyRoyaltyDealerMsgs.Execute(
        claimAvailableBalances = EqotyRoyaltyDealerMsgs.Execute.ClaimAvailableBalances(royaltyId.toString())
    )
    val msgPretty = jsonPretty.encodeToString(msg)
    val msgs = listOf(
        MsgExecuteContract(
            sender = senderAddress,
            contractAddress = royaltyDealer.address,
            codeHash = royaltyDealer.codeHash,
            msg = msgPretty,
        )
    )
    val txOptions = if (internalWltTxRequest != null) {
        val requestedGasLimit = try {
            val simulate = client.simulate(msgs)
            (simulate.gasUsed.toDouble() * 1.1).toInt()
        } catch (t: Throwable) {
            throw Error("Simulate Failed: ${t.message}")
        }
        val approved = internalWltTxRequest.invoke(
            TxRequestInfo(null, Coin(requestedGasLimit, "uscrt"), releaseAddr, msgPretty)
        )
        if (!approved) {
            throw Error("Tx Declined")
        }
        TxOptions(gasLimit = requestedGasLimit)
    } else {
        TxOptions(150_000)
    }
    val res = client.execute(
        msgs, txOptions = txOptions
    )
    return TransactionReceipt(res.txhash)
}


suspend fun Repository.getOwnedRoyaltyTokens(
    snip721Contract: ContractInfo,
    ownerAddr: String,
    withPermit: Boolean
): List<BigInteger> {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val query = when (withPermit) {
        true -> Snip721Msgs.Query(
            withPermit = Snip721Msgs.Query.WithPermit(
                permit = getPermit(senderAddress, snip721Contract.address),
                query = Snip721Msgs.QueryWithPermit(
                    tokens = Snip721Msgs.QueryWithPermit.Tokens(ownerAddr)
                )
            )
        )

        false -> Snip721Msgs.Query(
            tokens = Snip721Msgs.Query.Tokens(ownerAddr)
        )
    }
    val res = client.queryContractSmart(snip721Contract.address, Json.encodeToString(query), snip721Contract.codeHash)
    return Json.decodeFromString<Snip721Msgs.QueryAnswer>(res).tokenList!!.tokens.map { it.bi10 }
}


suspend fun Repository.queryProductDealerChildSnip721Contract(productDealer: ContractInfo): ContractInfo {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val query = EqotyProductDealerMsgs.Query(
        getChildSnip721 = EqotyProductDealerMsgs.Query.GetChildSnip721()
    )
    val res = client.queryContractSmart(productDealer.address, Json.encodeToString(query), productDealer.codeHash)
    return Json.decodeFromString<EqotyProductDealerMsgs.QueryAnswer>(res).contractInfo!!
}

suspend fun Repository.queryRoyaltyDealerChildSnip721Contract(royaltyDealer: ContractInfo): ContractInfo {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val query = EqotyRoyaltyDealerMsgs.Query(
        getChildSnip721 = EqotyRoyaltyDealerMsgs.Query.GetChildSnip721()
    )
    val res = client.queryContractSmart(royaltyDealer.address, Json.encodeToString(query), royaltyDealer.codeHash)
    return Json.decodeFromString<EqotyRoyaltyDealerMsgs.QueryAnswer>(res).contractInfo!!
}

suspend fun Repository.purchase(
    productDealer: ContractInfo,
    price: Coin,
    internalWltTxRequest: InternalWalletTxRequest?
): TransactionReceipt {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val msg = EqotyProductDealerMsgs.Execute(
        purchaseMint = EqotyProductDealerMsgs.Execute.PurchaseMint()
    )

    val msgPretty = jsonPretty.encodeToString(msg)
    val msgs = listOf(
        MsgExecuteContract(
            sender = senderAddress,
            contractAddress = productDealer.address,
            codeHash = productDealer.codeHash,
            msg = msgPretty,
            sentFunds = listOf(price)
        )
    )

    val gasLimit = 120_000
    val txOptions = if (internalWltTxRequest != null) {
        val requestedGasLimit = if (client.chainId == "secretdev-1") {
            try {
                val simulate = client.simulate(msgs)
                (simulate.gasUsed.toDouble() * 1.1).toInt()
            } catch (t: Throwable) {
                throw Error("Simulate Failed: ${t.message}")
            }
        } else {
            gasLimit
        }
        val approved = internalWltTxRequest.invoke(
            TxRequestInfo(price, Coin(requestedGasLimit, "uscrt"), releaseAddr, msgPretty)
        )
        if (!approved) {
            throw Error("Tx Declined")
        }
        TxOptions(gasLimit = requestedGasLimit)
    } else {
        TxOptions(gasLimit = gasLimit)
    }

    val res = client.execute(
        msgs, txOptions = txOptions
    )

    return TransactionReceipt(res.txhash)
}

suspend fun Repository.getOwnedPurchaseTokens(
    snip721Contract: ContractInfo,
    ownerAddr: String, withPermit: Boolean
): List<BigInteger> {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val query = when (withPermit) {
        true -> Snip721Msgs.Query(
            withPermit = Snip721Msgs.Query.WithPermit(
                permit = getPermit(senderAddress, snip721Contract.address),
                query = Snip721Msgs.QueryWithPermit(
                    tokens = Snip721Msgs.QueryWithPermit.Tokens(ownerAddr)
                )
            )
        )

        false -> Snip721Msgs.Query(
            tokens = Snip721Msgs.Query.Tokens(ownerAddr)
        )
    }
    val res = client.queryContractSmart(snip721Contract.address, Json.encodeToString(query), snip721Contract.codeHash)
    return Json.decodeFromString<Snip721Msgs.QueryAnswer>(res).tokenList!!.tokens.map { it.bi10 }
}

suspend fun Repository.getPurchaseTokenUri(
    productDealer: ContractInfo,
    id: BigInteger
): String? {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val query = Snip721Msgs.Query(
        nftInfo = Snip721Msgs.Query.NftInfo(tokenId = id.toString())
    )
    val res = client.queryContractSmart(productDealer.address, Json.encodeToString(query), productDealer.codeHash)
    return Json.decodeFromString<Snip721Msgs.QueryAnswer>(res).nftInfo!!.tokenUri
}

suspend fun Repository.getPurchasePrice(
    productDealer: ContractInfo,
): List<Coin> {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val query = EqotyProductDealerMsgs.Query(getPrices = EqotyProductDealerMsgs.Query.GetPrices())
    val res = client.queryContractSmart(productDealer.address, Json.encodeToString(query), productDealer.codeHash)
    return Json.decodeFromString<EqotyProductDealerMsgs.QueryAnswer>(res).getPrices!!.prices
}

suspend fun Repository.getOrganizationMemberships(contractAddr: String): List<ULong> {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val query = EqotyReleaseMsgs.Query(
        organizationMemberships = EqotyReleaseMsgs.Query.OrganizationMemberships(
            permit = getPermit(senderAddress, releaseAddr),
            releaser = EqotyReleaseMsgs.ReleaserBy(address = contractAddr)
        )
    )
    val res = client.queryContractSmart(releaseAddr, Json.encodeToString(query), releaseCodeHash)
    return Json.decodeFromString<EqotyReleaseMsgs.QueryAnswer>(res).organizationMemberships!!
}

suspend fun Repository.registerOrganization(
    info: EqotyReleaseMsgs.OrganizationInfo, internalWltTxRequest: InternalWalletTxRequest?
): TransactionReceipt {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val msg = EqotyReleaseMsgs.Execute(
        registerOrganization = EqotyReleaseMsgs.Execute.RegisterOrganization(
            organization = info, releasers = listOf(EqotyReleaseMsgs.ReleaserBy(address = senderAddress))
        )
    )

    val msgPretty = jsonPretty.encodeToString(msg)
    val msgs = listOf(
        MsgExecuteContract(
            sender = senderAddress,
            contractAddress = releaseAddr,
            codeHash = releaseCodeHash,
            msg = msgPretty,
        )
    )
    val gasLimit = 90_000
    val txOptions = if (internalWltTxRequest != null) {
        val requestedGasLimit = if (client.chainId == "secretdev-1") {
            try {
                val simulate = client.simulate(msgs)
                (simulate.gasUsed.toDouble() * 1.1).toInt()
            } catch (t: Throwable) {
                throw Error("Simulate Failed: ${t.message}")
            }
        } else {
            gasLimit
        }
        val approved = internalWltTxRequest.invoke(
            TxRequestInfo(null, Coin(requestedGasLimit, "uscrt"), releaseAddr, msgPretty)
        )
        if (!approved) {
            throw Error("Tx Declined")
        }
        TxOptions(gasLimit = requestedGasLimit)
    } else {
        TxOptions(gasLimit)
    }

    val res = client.execute(
        msgs, txOptions = txOptions
    )

    return TransactionReceipt(res.txhash)
}

suspend fun Repository.setOrganizationInfo(
    organizationId: ULong, info: EqotyReleaseMsgs.OrganizationInfo, internalWltTxRequest: InternalWalletTxRequest?
): TransactionReceipt {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val msg = EqotyReleaseMsgs.Execute(
        setOrganizationInfo = EqotyReleaseMsgs.Execute.SetOrganizationInfo(
            id = organizationId,
            info = info,
        )
    )
    val msgPretty = jsonPretty.encodeToString(msg)
    val msgs = listOf(
        MsgExecuteContract(
            sender = senderAddress,
            contractAddress = releaseAddr,
            codeHash = releaseCodeHash,
            msg = msgPretty,
        )
    )
    val gasLimit = 90_000
    val txOptions = if (internalWltTxRequest != null) {
        val requestedGasLimit = if (client.chainId == "secretdev-1") {
            try {
                val simulate = client.simulate(msgs)
                (simulate.gasUsed.toDouble() * 1.1).toInt()
            } catch (t: Throwable) {
                throw Error("Simulate Failed: ${t.message}")
            }
        } else {
            gasLimit
        }
        val approved = internalWltTxRequest.invoke(
            TxRequestInfo(null, Coin(requestedGasLimit, "uscrt"), releaseAddr, msgPretty)
        )
        if (!approved) {
            throw Error("Tx Declined")
        }
        TxOptions(gasLimit = requestedGasLimit)
    } else {
        TxOptions(gasLimit)
    }

    val res = client.execute(
        msgs, txOptions = txOptions
    )

    return TransactionReceipt(res.txhash)
}

suspend fun Repository.setOrganizationProfileUri(
    organizationId: ULong, uri: String?, internalWltTxRequest: InternalWalletTxRequest?
): TransactionReceipt {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val msg = EqotyReleaseMsgs.Execute(
        setOrganizationProfileUri = EqotyReleaseMsgs.Execute.SetOrganizationProfileUri(
            id = organizationId,
            uri = uri,
        )
    )

    val msgPretty = jsonPretty.encodeToString(msg)
    val msgs = listOf(
        MsgExecuteContract(
            sender = senderAddress,
            contractAddress = releaseAddr,
            codeHash = releaseCodeHash,
            msg = msgPretty,
        )
    )
    val txOptions = TxOptions(50_000)
    if (internalWltTxRequest != null) {
        val approved = internalWltTxRequest.invoke(
            TxRequestInfo(
                null, Coin(txOptions.gasLimit, "uscrt"), releaseAddr, msgPretty
            )
        )
        if (!approved) {
            throw Error("Tx Declined")
        }

    }

    val res = client.execute(
        msgs, txOptions = txOptions
    )

    return TransactionReceipt(res.txhash)
}

suspend fun Repository.registerReleasers(
    infos: List<EqotyReleaseMsgs.ReleaserInfo>, internalWltTxRequest: InternalWalletTxRequest?
): TransactionReceipt {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val msg =
        EqotyReleaseMsgs.Execute(registerReleasers = EqotyReleaseMsgs.Execute.RegisterReleasers(registrationForms = infos.map { info ->
            EqotyReleaseMsgs.ReleaserRegistrationForm(
                info, senderAddress
            )
        }))

    val msgPretty = jsonPretty.encodeToString(msg)
    val msgs = listOf(
        MsgExecuteContract(
            sender = senderAddress,
            contractAddress = releaseAddr,
            codeHash = releaseCodeHash,
            msg = msgPretty,
        )
    )
    val gasLimit = 90_000
    val txOptions = if (internalWltTxRequest != null) {
        val requestedGasLimit = if (client.chainId == "secretdev-1") {
            try {
                val simulate = client.simulate(msgs)
                (simulate.gasUsed.toDouble() * 1.1).toInt()
            } catch (t: Throwable) {
                throw Error("Simulate Failed: ${t.message}")
            }
        } else {
            gasLimit
        }
        val approved = internalWltTxRequest.invoke(
            TxRequestInfo(null, Coin(requestedGasLimit, "uscrt"), releaseAddr, msgPretty)
        )
        if (!approved) {
            throw Error("Tx Declined")
        }
        TxOptions(gasLimit = requestedGasLimit)
    } else {
        TxOptions(gasLimit)
    }

    val res = client.execute(
        msgs, txOptions = txOptions
    )

    return TransactionReceipt(res.txhash)
}

suspend fun Repository.setReleaserInfo(
    releaserId: ULong, info: EqotyReleaseMsgs.ReleaserInfo, internalWltTxRequest: InternalWalletTxRequest?
): TransactionReceipt {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val msg = EqotyReleaseMsgs.Execute(
        setReleaserInfo = EqotyReleaseMsgs.Execute.SetReleaserInfo(
            releaser = EqotyReleaseMsgs.ReleaserBy(id = releaserId), info
        )
    )

    val msgPretty = jsonPretty.encodeToString(msg)
    val msgs = listOf(
        MsgExecuteContract(
            sender = senderAddress,
            contractAddress = releaseAddr,
            codeHash = releaseCodeHash,
            msg = msgPretty,
        )
    )
    val gasLimit = 90_000
    val txOptions = if (internalWltTxRequest != null) {
        val requestedGasLimit = if (client.chainId == "secretdev-1") {
            try {
                val simulate = client.simulate(msgs)
                (simulate.gasUsed.toDouble() * 1.1).toInt()
            } catch (t: Throwable) {
                throw Error("Simulate Failed: ${t.message}")
            }
        } else {
            gasLimit
        }
        val msgName = "RegisterReleasers"
        val approved = internalWltTxRequest.invoke(
            TxRequestInfo(null, Coin(requestedGasLimit, "uscrt"), releaseAddr, msgPretty)
        )
        if (!approved) {
            throw Error("Tx Declined")
        }
        TxOptions(gasLimit = requestedGasLimit)
    } else {
        TxOptions(gasLimit)
    }

    val res = client.execute(
        msgs, txOptions = txOptions
    )

    return TransactionReceipt(res.txhash)
}

suspend fun Repository.getReleaserId(): ULong? {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val query = EqotyReleaseMsgs.Query(
        releaserId = EqotyReleaseMsgs.Query.ReleaserId(
            permit = getPermit(senderAddress, releaseAddr), address = senderAddress
        )
    )
    val res = client.queryContractSmart(releaseAddr, Json.encodeToString(query), releaseCodeHash)
    return Json.decodeFromString<EqotyReleaseMsgs.QueryAnswer>(res).releaserId
}

suspend fun Repository.getReleasersBatch(ids: List<ULong>): List<EqotyReleaseMsgs.Releaser> {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val query = EqotyReleaseMsgs.Query(
        releasersBatch = EqotyReleaseMsgs.Query.ReleasersBatch(
            permit = getPermit(senderAddress, releaseAddr),
            ids,
        )
    )
    val res = client.queryContractSmart(releaseAddr, Json.encodeToString(query), releaseCodeHash)
    return Json.decodeFromString<EqotyReleaseMsgs.QueryAnswer>(res).releasers ?: listOf()
}

suspend fun Repository.getOrganizationBatch(ids: List<ULong>): List<EqotyReleaseMsgs.Organization> {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val query = EqotyReleaseMsgs.Query(
        organizationsBatch = EqotyReleaseMsgs.Query.OrganizationsBatch(
            permit = getPermit(senderAddress, releaseAddr),
            ids,
        )
    )
    val res = client.queryContractSmart(releaseAddr, Json.encodeToString(query), releaseCodeHash)
    return Json.decodeFromString<EqotyReleaseMsgs.QueryAnswer>(res).organizations ?: listOf()
}

suspend fun Repository.getOrganizationMemberships(): List<ULong> {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val query = EqotyReleaseMsgs.Query(
        organizationMemberships = EqotyReleaseMsgs.Query.OrganizationMemberships(
            permit = getPermit(senderAddress, releaseAddr),
            releaser = EqotyReleaseMsgs.ReleaserBy(address = senderAddress)
        )
    )
    val res = client.queryContractSmart(releaseAddr, Json.encodeToString(query), releaseCodeHash)
    return Json.decodeFromString<EqotyReleaseMsgs.QueryAnswer>(res).organizationMemberships ?: listOf()
}

suspend fun Repository.getPurchasePrivateMetadata(
    productDealer: ContractInfo,
    purchaseId: BigInteger
): Snip721Msgs.Metadata? {
    val network = getWeb3Network()!!
    val client = getClient(network)
    val senderAddress = getWalletAddress()!!
    val query = Snip721Msgs.Query(
        withPermit = Snip721Msgs.Query.WithPermit(
            permit = getPermit(senderAddress, productDealer.address),
            query = Snip721Msgs.QueryWithPermit(
                privateMetadata = Snip721Msgs.QueryWithPermit.PrivateMetadata(tokenId = purchaseId.toString())
            )
        )
    )
    val res = client.queryContractSmart(productDealer.address, Json.encodeToString(query), productDealer.codeHash)
    return Json.decodeFromString<Snip721Msgs.QueryAnswer>(res).privateMetadata
}
