package io.eqoty.shared.datalayer.functions

import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.jsWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.JsKeplrWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.JsWeb3Provider

// TODO add tests for disconnect with a higher priority
actual suspend fun Repository.disconnectWallet() {
    when (val provider = web3Provider) {
        is JsWeb3Provider -> {

            //https://stackoverflow.com/questions/66866817/is-there-any-way-to-initiate-a-disconnect-request-to-the-metamask-wallet/69145553#69145553
            //https://github.com/MetaMask/metamask-extension/issues/8990
            //https://github.com/MetaMask/metamask-extension/issues/10353
            var success = false
            try {
                provider.disconnect()
                walletAddressChangeFlow.emit(null)
                success = true
            } catch (_: Throwable) {
            }
            if (!success) {
                throw (Error("To disconnect: Open metamask -> Click Connected Sites in the dropdown next to account's name, and disconnect eqoty.io"))
            }
        }

        is JsKeplrWeb3Provider -> {
            provider.disconnect()
            walletAddressChangeFlow.emit(null)
//            throw (Error("To disconnect: Open keplr -> Navigate to Settings -> Security & Privacy -> Wallet Access Permissions -> click the `x` next to eqoty.io"))
        }

        else -> throw Error("disconnectWallet called on an unsupported Web3Provider: $provider")
    }

}