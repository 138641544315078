package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.async.coroutines.awaitAsList
import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.objects.AttachmentAccess
import io.eqoty.shared.datalayer.objects.RemoteAudioResource
import mylocal.db.LocalDb

enum class TrackType

suspend fun LocalDb.getPublicAudioTracksById(metadataId: String): List<RemoteAudioResource> {
    return remoteAudioResourceTableQueries.getAllForNftMetadataIdAndVisibility(
        metadataId,
        AttachmentAccess.PUBLIC,
        mapper = ::RemoteAudioResource
    ).awaitAsList()
}

suspend fun LocalDb.getPrivateAudioTracksById(metadataId: String): List<RemoteAudioResource> {
    return remoteAudioResourceTableQueries.getAllForNftMetadataIdAndVisibility(
        metadataId,
        AttachmentAccess.PRIVATE,
        mapper = ::RemoteAudioResource
    ).awaitAsList()
}

suspend fun LocalDb.upsertAudioTrackList(
    nftMetadataId: String,
    list: List<RemoteAudioResource>,
    access: AttachmentAccess,
    priority: Int = 0
) {
    remoteAudioResourceTableQueries.transaction {
        list.forEach { track ->
            remoteAudioResourceTableQueries.upsertAudioTrack(
                // We can't just use url as a primary key because a different tokens could have the same audio url
                // For example a release can have the same audio urls as a purchaseNft.
                id = "$nftMetadataId:${track.url}",
                url = track.url,
                access = access,
                priority = priority,
                size = track.size,
                container = track.container,
                codec = track.codec,
                bitDepth = track.bitDepth,
                bitRate = track.bitRate,
                sampleRate = track.sampleRate,
                channels = track.channels,
                lossless = track.lossless,
                nftMetadataId = nftMetadataId
            )
        }
    }
}

suspend fun LocalDb.deleteAudioTracksForReleaseId(releaseId: UInt) {
    return remoteAudioResourceTableQueries.deleteByNftMetadataId(getNftMetadataIdForReleaseId(releaseId))
}

suspend fun LocalDb.deleteAllAudioTracks() {
    return remoteAudioResourceTableQueries.deleteTable()
}
