package web3.eth

import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json

@Serializable
class TransactionReceipt(val transactionHash: String)

@Serializable
class TransactionError(
    val message: String
)


expect interface SendOptions {
    var from: String

    //    var gasPrice: String?
//    var gas: Number?
    var value: String?
//    var nonce: Number?
}

@Serializable
class SendOptionsImpl(
    override var from: String,
//    override var gasPrice: String? = definedExternallyCommon,
//    override var gas: Number? = definedExternallyCommon,
    override var value: String? = null,
    /* Number? | String? | BN? */
//    override var nonce: Number?= definedExternallyCommon,
) : SendOptions

expect fun SendOptionsImpl.buildJsonObject(json: Json): Any
