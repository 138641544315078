package io.eqoty.shared.viewmodel.screens.wallettxrequest

import io.eqoty.shared.viewmodel.StateManager
import io.eqoty.shared.viewmodel.screens.CallOnInitValues
import io.eqoty.shared.viewmodel.screens.ScreenInitSettings
import io.eqoty.shared.viewmodel.screens.WalletTxRequestScreenParams

// INITIALIZATION settings for this screen
// this is what should be implemented:
// - a data class implementing the ScreenParams interface, which defines the parameters to the passed to the screen
// - Navigation extension function taking the ScreenParams class as an argument, return the ScreenInitSettings for this screen
// to understand the initialization behaviour, read the comments in the ScreenInitSettings.kt file


fun StateManager.initWalletTxRequestScreen(params: WalletTxRequestScreenParams) = ScreenInitSettings(
    title = "Tx Request",
    initState = { WalletTxRequestState(params.txRequestInfo) },
    callOnInit = {
    },
    callOnInitAtEachNavigation = CallOnInitValues.CALL_AFTER_SHOWING_SCREEN
)