package io.eqoty.shared.datalayer.functions

import io.eqoty.secret.std.contract.msg.EqotyReleaseMsgs
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.Release
import io.eqoty.shared.datalayer.objects.toRelease
import io.eqoty.shared.datalayer.sources.localdb.nfts.getRelease
import io.eqoty.shared.datalayer.sources.localdb.nfts.upsertRelease
import io.eqoty.shared.datalayer.sources.webservices.apis.fetchReleaseMetaData


suspend fun Repository.updateRelease(releaseMsg: EqotyReleaseMsgs.Release): Release? = withRepoContext {
    val publicReleaseMetaData = releaseMsg.publicMetadata?.tokenUri
        ?.let { webservices.fetchReleaseMetaData(it) }
        ?: return@withRepoContext null
    val release = releaseMsg.toRelease(
        releaseMsg.privateMetadata?.extension?.media?.firstOrNull()?.authentication?.key,
        publicReleaseMetaData
    )
    localDb.upsertRelease(release)
    return@withRepoContext localDb.getRelease(release.id)
}