package io.eqoty.shared.datalayer.sources.runtimecache

import io.eqoty.secret.std.types.Permit

class CacheObjects {
    // here is the repository data we decide to just cache temporarily (for the runtime session),
    // rather than caching it permanently in the local db or local settings
    val senderToAddressPermitMap: MutableMap<String, MutableMap<String, Permit>> = mutableMapOf()


}
