package io.eqoty.composables.views

import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.Column
import app.softwork.bootstrapcompose.Row
import app.softwork.bootstrapcompose.icons.Discord
import app.softwork.bootstrapcompose.icons.Instagram
import app.softwork.bootstrapcompose.icons.Telegram
import app.softwork.bootstrapcompose.icons.Twitter
import dev.icerock.moko.resources.compose.stringResource
import io.eqoty.shared.MR
import org.jetbrains.compose.web.ExperimentalComposeWebSvgApi
import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.w3c.dom.HTMLDivElement

@OptIn(ExperimentalComposeWebSvgApi::class)
@Composable
fun SocialMediaIconsRow(applyAttrs: (AttrsScope<HTMLDivElement>.() -> Unit)? = null) {
    Row(attrs = { classes("align-items-center", "flex-nowrap"); applyAttrs?.let { it() } }) {
        Column {
            A(href = stringResource(MR.strings.url_twitter), attrs = {
                classes("d-flex", "nav-link", "ms-auto", "link-secondary")
            }) {
                Twitter(attrs = { style { height(20.px); width(20.px) } })
            }
        }
        Column {
            A(href = stringResource(MR.strings.url_instagram), attrs = {
                classes("d-flex", "nav-link", "ms-auto", "link-secondary")
            }) {
                Instagram(attrs = { style { height(20.px); width(20.px) } })
            }
        }
        Column {

            A(href = stringResource(MR.strings.url_telegram), attrs = {
                classes("d-flex", "nav-link", "ms-auto", "link-secondary")
            }) {
                Telegram(attrs = { style { height(20.px); width(20.px) } })
            }
        }
        Column {

            A(href = stringResource(MR.strings.url_discord), attrs = {
                classes("d-flex", "nav-link", "ms-auto", "link-secondary")
            }) {
                Discord(attrs = { style { height(20.px); width(20.px) } })
            }
        }
    }
}
