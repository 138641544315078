package io.eqoty.composables.navigation.bars

import androidx.compose.runtime.Composable
import androidx.compose.runtime.NonRestartableComposable
import app.softwork.bootstrapcompose.Column
import app.softwork.bootstrapcompose.Layout.Display
import app.softwork.bootstrapcompose.Row
import app.softwork.bootstrapcompose.icons.CollectionFill
import app.softwork.bootstrapcompose.icons.HouseFill
import app.softwork.bootstrapcompose.icons.VinylFill
import io.eqoty.composables.styles.overflowTxtToEllipsisAttrs
import io.eqoty.composables.styling.BackgroundDarker
import io.eqoty.composables.styling.PrimaryOpaque
import io.eqoty.shared.viewmodel.Navigation
import io.eqoty.shared.viewmodel.screens.bottomnavigationbar.BottomNavigationBarState
import io.eqoty.shared.viewmodel.screens.leftnavigationrail.*
import org.jetbrains.compose.web.ExperimentalComposeWebSvgApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.svg.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.svg.SVGElement

private val iconStyle: StyleScope.() -> Unit = {
    height(20.px)
    width(20.px)
}

private val tabStyle: StyleScope.() -> Unit = {
    borderWidth(1.px)
    justifyContent(JustifyContent.Center)
    alignItems(AlignItems.Center)
}

private fun buildTabItemContentAttrs(
    selected: Boolean,
): AttrBuilderContext<HTMLDivElement> {
    return {
        style {
            display(DisplayStyle.Flex)
            flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
            justifyContent(JustifyContent.Center)
            alignItems(AlignItems.Center)
            if (selected) {
                color(PrimaryOpaque)
            }
        }
    }
}

//private fun buildTabItemAttrs(currentUri: URI, selectedUri: URI): AttrBuilderContext<HTMLDivElement> {

// this is the bottom horizontal navigation bar for 1-Pane visualization
// (used by small devices and in Portrait mode)
@OptIn(ExperimentalComposeWebSvgApi::class)
@Composable
fun Navigation.BottomNavigationBar(
    state: BottomNavigationBarState,
    selectTab: (NavigationTab) -> Unit,
) {
    Row(
        attrs = {
            style {
                flexWrap(FlexWrap.Nowrap)
                height(50.px)
                // for some reason when switching to the home tab the bar bottom bar slightly shrinks
                // specifying a min-height prevents it from shrinking
                minHeight(50.px)
                paddingTop(4.px)
                backgroundColor(BackgroundDarker)
            }
        },
    ) {
        Column(styling = {
            Layout {
                display(Display.Flex)
            }
        }, attrs = {
            classes("justify-content-center", "align-items-center")
        }) {
            Div(attrs = {
                onClick { _ ->
                    selectTab(state.homeTab)
                }
                style {
                    tabStyle()
                }
            }, content = {
                Div(
                    attrs = buildTabItemContentAttrs(
                        selected = state.selectedTab is HomeTab
                    )
                ) {
                    HouseFill {
                        style {
                            iconStyle()
                        }
                    }
                    Div(attrs = overflowTxtToEllipsisAttrs) { Text("Home") }
                }
            })
        }
        Column(styling = {
            Layout {
                display(Display.Flex)
            }
        }, attrs = {
            classes("justify-content-center", "align-items-center")
        }) {
            Div(attrs = {
                onClick {
                    selectTab(state.storeTab)
                }
                style {
                    tabStyle()
                }
            }, content = {
                Div(
                    attrs = buildTabItemContentAttrs(
                        selected = state.selectedTab is StoreTab
                    )
                ) {
                    VinylFill {
                        style {
                            iconStyle()
                        }
                    }
                    Div(attrs = overflowTxtToEllipsisAttrs) { Text("Store") }
                }
            })
        }
        Column(styling = {
            Layout {
                display(Display.Flex)
            }
        }, attrs = {
            classes("justify-content-center", "align-items-center")
        }) {
            Div(attrs = {
                onClick {
                    selectTab(state.libraryTab)
                }
                style {
                    tabStyle()
                }
            }, content = {
                Div(
                    attrs = buildTabItemContentAttrs(
                        selected = state.selectedTab is LibraryTab
                    )
                ) {
                    CollectionFill {
                        style {
                            iconStyle()
                        }
                    }
                    Div(attrs = overflowTxtToEllipsisAttrs) { Text("Library") }
                }
            })
        }
        if (state.releaserTab.visible) {
            Column(styling = {
                Layout {
                    display(Display.Flex)
                }
            }, attrs = {
                classes("justify-content-center", "align-items-center")
            }) {
                Div(attrs = {
                    onClick { event ->
                        selectTab(state.releaserTab)
                    }
                    style {
                        tabStyle()
                    }
                }, content = {
                    Div(
                        attrs = buildTabItemContentAttrs(
                            selected = state.selectedTab is ReleaserTab
                        )
                    ) {
                        PersonFillGear {
                            style {
                                iconStyle()
                            }
                        }
                        Div(attrs = overflowTxtToEllipsisAttrs) { Text("Release") }
                    }
                })
            }
        }
    }
}

@ExperimentalComposeWebSvgApi
@Composable
@NonRestartableComposable
fun PersonFillGear(attrs: AttrBuilderContext<SVGElement>? = null) {
    Svg(viewBox = "0 0 16 16", attrs = {
        width(16)
        height(16)
        xmlns("http://www.w3.org/2000/svg")
        fill("currentColor")
        classes("bi", "bi-person-fill-gear")
        attrs?.invoke(this)
    }) {
        Path("M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Zm9.886-3.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z")
    }
}
