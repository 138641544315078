package io.eqoty.shared.viewmodel.screens.royaltynftdetail

import io.eqoty.shared.datalayer.functions.getRoyaltyNftDetailDisplayFormatted
import io.eqoty.shared.viewmodel.StateManager
import io.eqoty.shared.viewmodel.screens.RoyaltyNftDetailParams
import io.eqoty.shared.viewmodel.screens.ScreenInitSettings
import io.eqoty.shared.viewmodel.screens.ScreenStack


// INITIALIZATION settings for this screen
// this is what should be implemented:
// - a data class implementing the ScreenParams interface, which defines the parameters to the passed to the screen
// - Navigation extension function taking the ScreenParams class as an argument, return the ScreenInitSettings for this screen
// to understand the initialization behaviour, read the comments in the ScreenInitSettings.kt file

fun StateManager.initRoyaltyNftDetail(params: RoyaltyNftDetailParams) = ScreenInitSettings(
    title = params.name,
    initState = { RoyaltyNftDetailState(isLoading = true) },
    callOnInit = {
        val nftMetaDataHelper = dataRepository.getRoyaltyNftDetailDisplayFormatted(params.releaseId, params.id)
        // update state, after retrieving data from the repository
        updateScreen(ScreenStack.Main, RoyaltyNftDetailState::class) {
            it.copy(
                isLoading = false,
                royaltyNftDetailDisplayFormatted = nftMetaDataHelper,
            )
        }
    }
)