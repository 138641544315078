package io.eqoty.shared.viewmodel.screens.mediaplayer


import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.functions.getAudioAttachmentEndpoint
import io.eqoty.shared.datalayer.functions.getAudioStreamingFormatPreference
import io.eqoty.shared.datalayer.functions.getPurchaseNft
import io.eqoty.shared.datalayer.functions.getRelease
import io.eqoty.shared.datalayer.objects.AttachmentAccess
import io.eqoty.shared.devicelayer.functions.pauseAudio
import io.eqoty.shared.devicelayer.functions.playAudio
import io.eqoty.shared.devicelayer.functions.stopAudio
import io.eqoty.shared.viewmodel.Events
import io.eqoty.shared.viewmodel.screens.ScreenStack
import kotlinx.coroutines.coroutineScope


/********** NO EVENT FUNCTION IS DEFINED ON THIS SCREEN **********/


fun Events.loadTrackInMediaPlayer(
    releaseId: UInt,
    purchaseId: BigInteger?
) =
    inScreenScopeLaunch(ScreenStack.MPlayer) {
        var playing = PlaybackState.STOPPED
        val access = if (purchaseId == null) AttachmentAccess.PUBLIC else AttachmentAccess.PRIVATE
        val release = dataRepository.getRelease(releaseId)!!
        val purchaseNft = purchaseId?.let { dataRepository.getPurchaseNft(releaseId, it) }
        val track = MediaPlayerWidgetTrack(release, purchaseNft, access)
        stateManager.updateScreen(ScreenStack.MPlayer, MediaPlayerWidgetState::class) { state ->
            playing = state.playbackState
            if (playing != PlaybackState.STOPPED) {
                stopSongAndCloseMediaPlayer()?.join()
            }
            state.copy(track = track)
        }
        if (playing == PlaybackState.PLAYING) {
            playSong(track)
        }
    }

fun Events.playSong(track: MediaPlayerWidgetTrack) = inScreenScopeLaunch(ScreenStack.MPlayer) {
    inScreenScopeLaunchInBackground(ScreenStack.MPlayer) {
        val formatPref = dataRepository.getAudioStreamingFormatPreference()
        val endpoint =
            dataRepository.getAudioAttachmentEndpoint(
                track.releaseId,
                track.purchaseId,
                formatPref,
                track.access
            )

        coroutineScope {
            dataRepository.playAudio(endpoint) { currentPosition, playbackState ->
                try {
                    stateManager.updateScreen(ScreenStack.MPlayer, MediaPlayerWidgetState::class) {
                        it.copy(
                            currentPosition = currentPosition,
                            playbackState = playbackState ?: it.playbackState
                        )
                    }
                } catch (t: Throwable) {
                    t.printStackTrace()
                }
            }
        }
    }
    stateManager.updateScreen(ScreenStack.MPlayer, MediaPlayerWidgetState::class) {
        it.copy(
            playbackState = PlaybackState.PLAYING,
        )
    }
}

fun Events.pauseSong() = inScreenScopeLaunch(ScreenStack.MPlayer) {
    pauseAudio()
    stateManager.updateScreen(ScreenStack.MPlayer, MediaPlayerWidgetState::class) {
        it.copy(
            playbackState = PlaybackState.PAUSED
        )
    }
}

fun Events.stopSongAndCloseMediaPlayer() = inScreenScopeLaunch(ScreenStack.MPlayer) {
    stopAudio()
    return@inScreenScopeLaunch stateManager.updateScreen(ScreenStack.MPlayer, MediaPlayerWidgetState::class) {
        it.copy(
            track = null,
            playbackState = PlaybackState.STOPPED
        )
    }
}
