package io.eqoty.shared.datalayer.functions

import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.sources.filesystem.CommonFile
import co.touchlab.kermit.Logger
import jslib.webapi.AudioContext
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.suspendCancellableCoroutine
import org.khronos.webgl.ArrayBuffer
import org.w3c.dom.ErrorEvent
import org.w3c.files.FileReader
import kotlin.coroutines.resumeWithException
import kotlin.math.roundToInt
import kotlin.math.roundToLong

@OptIn(ExperimentalCoroutinesApi::class)
actual suspend fun Repository.getAudioDuration(commonFile: CommonFile): Long =
    suspendCancellableCoroutine { continuation ->
        val reader = FileReader()
        reader.onloadend = { _ ->
            val audioContext = js("""new (window.AudioContext || window.webkitAudioContext)();""") as AudioContext
            val arrayBuffer = reader.result as ArrayBuffer
            audioContext.decodeAudioData(arrayBuffer).then { audioBuffer ->
                Logger.d("audioBuffer.duration: ${audioBuffer.duration}")
                val durationMs = (audioBuffer.duration * 1000.0).roundToLong()
                Logger.d("durationMs: $durationMs")
                continuation.resume(durationMs, null)
            }.catch {
                continuation.resumeWithException(it)
            }
        }
        reader.onerror = { error ->
            continuation.resumeWithException(Error((error as ErrorEvent).message))
        }
        reader.readAsArrayBuffer(commonFile.file)
    }