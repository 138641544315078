package io.eqoty.shared.datalayer.functions

import co.touchlab.kermit.Logger
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.Release
import io.eqoty.shared.datalayer.sources.ALL
import io.eqoty.shared.datalayer.sources.DataSrc
import io.eqoty.shared.datalayer.sources.LocalSrc
import io.eqoty.shared.datalayer.sources.RemoteSrc
import io.eqoty.shared.datalayer.sources.localdb.nfts.getReleasesByOrganization
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.receiveAsFlow


suspend fun Repository.getReleasesByOrganization(
    organizationId: ULong,
    screenScope: CoroutineScope,
    src: DataSrc = ALL
): Flow<List<Release>> = withRepoContext {
    val results = Channel<List<Release>>()
    try {
        if (src is LocalSrc) {
            withBackgroundRepoContext(screenScope) {
                results.send(localDb.getReleasesByOrganization(organizationId))
                if (src !is ALL) {
                    results.close()
                }
            }
        }
        if (src is RemoteSrc) {
            withBackgroundRepoContext(screenScope) {
                if (!getWalletConnected()) return@withBackgroundRepoContext
                val organizationsReleaseIds = getReleaseIdsByOrganization(organizationId)
                val releases = getReleasesBatch(organizationsReleaseIds).mapNotNull { r ->
                    val release = updateRelease(r) ?: return@mapNotNull null
                    release
                }
                results.send(releases)
                results.close()
            }
        }
    } catch (t: Throwable) {
        Logger.d("getReleaseList ERROR MESSAGE: ${t.message}")
    }
    results.receiveAsFlow()
}