package io.eqoty.composables.screens.connectwallet

import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.Button
import app.softwork.bootstrapcompose.Column
import app.softwork.bootstrapcompose.Row
import co.touchlab.kermit.Logger
import io.eqoty.composables.resources.ImageResources.keplr
import io.eqoty.shared.datalayer.sources.web3.WalletProvider
import io.eqoty.shared.viewmodel.screens.connectwallet.ConnectWalletScreenState
import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement

@Composable
fun ConnectWalletScreen(
    state: ConnectWalletScreenState,
    onClickConnect: (WalletProvider) -> Unit
) {
    Row(
        attrs = {
            style {
                width(200.px)
                alignItems(AlignItems.Center)
            }
            classes("d-flex", "flex-column")
        }
    ) {
        if (!state.networkSupported) {
            ConnectWalletScreenUnsupportedNetwork(state)
        } else {
            ConnectWalletScreenWalletNotConnected(state, onClickConnect)
        }
    }
}


@Composable
fun ConnectWalletScreenWalletNotConnected(
    state: ConnectWalletScreenState,
    onClickConnect: (WalletProvider) -> Unit
) {
    val applyAttrs: AttrsScope<HTMLDivElement>.() -> Unit = {
        style {
            width(100.percent)
            paddingBottom(10.px)
        }
    }
    Row(
        attrs = {
            applyAttrs()
            classes("g-0")
        }
    ) {

        Div(
            attrs = {
                style {
                    fontSize(1.8.em)
                }
            }
        ) {
            Text("Choose Wallet Provider")
        }
    }
    Logger.d("state: $state")
    Row(attrs = { applyAttrs() }) {
        WalletProviderButton(
            src = keplr,
            text = "Keplr",
            disabled = !state.injectedKeplrProviderAvailable,
        ) {
            onClickConnect(WalletProvider.Keplr)
        }
    }
    /*    Row(attrs = { applyAttrs() }) {
            WalletProviderButton(
                src = metamaskFox,
                text = "Metamask",
                disabled = !state.injectedEthProviderAvailable,
            ) {
                onClickConnect(WalletProvider.MetaMask)
            }
        }
        Row(attrs = { applyAttrs() }) {
            WalletProviderButton(
                src = walletConnect,
                text = "WalletConnect"
            ) {
                onClickConnect(WalletProvider.WalletConnect)

            }
        }*/
}

@Composable
fun ConnectWalletScreenValid(
    state: ConnectWalletScreenState
) {
    Row {
        Text(state.supportedNetworkInfo)
    }
    Row {
        Text(state.walletAddressInfo)
    }
    Row {
        state.displayMessage?.let {
            Text(it)
        }
    }
}

@Composable
fun ConnectWalletScreenUnsupportedNetwork(state: ConnectWalletScreenState) {
    Row {
        Column {
            Text(state.unsupportedNetworkInfo)
        }
    }
}


@Composable
fun WalletProviderButton(
    src: String, text: String, disabled: Boolean = false,
    onClick: () -> Unit
) {
    Button(
        outlined = true,
        disabled = disabled,
        content = {
            Div(
                attrs = {
                    style {
                        display(DisplayStyle.Flex)
                        flexFlow(FlexDirection.Row, FlexWrap.Nowrap)
                        justifyContent(JustifyContent.Start)
                        alignItems(AlignItems.Center)
                        fontSize(1.2.em)
                    }
                }
            ) {
                A(
                    attrs = {
                        style {
                            display(DisplayStyle.Flex)
                            height(40.px)
                            width(40.px)
                            padding(5.px)
                        }
                    }
                ) {
                    Img(src = src)
                }
                Div {
                    Text(text)
                }
            }
        },
        attrs = {
            classes("py-0")
        }
    ) {
        onClick()
    }
}


