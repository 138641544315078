package cash.z.ecc.android.bip39

import cash.z.ecc.android.bip39.Mnemonics.DEFAULT_LANGUAGE_CODE

/**
 * A Cached list of words. This serves as an abstraction, allowing collaborators to be agnostic
 * about the source of words. Right now, words are kept in memory since only english is supported
 * but, eventually, they will come from the file system and library users should not have to change
 * any code.
 */
class WordList internal constructor(val languageCode: String = DEFAULT_LANGUAGE_CODE) {

    init {
        validate(languageCode)
    }

    private val _words by lazy { fetchWords(languageCode) }

    /**
     * Get the words in the cache or fetch them if they are not yet loaded.
     *
     * @return the list of words matching the given language code
     */
    val words get() = _words

    companion object {

        private const val WORD_LIST_ELEMENT_COUNT = 2048

        /**
         * Returns true when the given language code (like "en") is supported. Currently, only
         * English is supported but this will change in future versions.
         */
        fun isSupported(languageCode: String): Boolean = languageCode == DEFAULT_LANGUAGE_CODE

        /**
         * Throws an error when the given language code is not supported.
         */
        fun validate(languageCode: String) {
            if (!isSupported(languageCode)) {
                throw UnsupportedOperationException(
                    "The language <$languageCode> is not currently supported"
                )
            }
        }

        /**
         * Fetch words for the given language.
         *
         * @param languageCode the language code corresponding to the words to fetch. Currently,
         * only english is supported.
         *
         * @return a list of words matching the given language code.
         */
        @Suppress("LongMethod")
        private fun fetchWords(languageCode: String): List<String> {
            validate(languageCode)
            return buildList<String>(WORD_LIST_ELEMENT_COUNT) {
                add("abandon")
                add("ability")
                add("able")
                add("about")
                add("above")
                add("absent")
                add("absorb")
                add("abstract")
                add("absurd")
                add("abuse")
                add("access")
                add("accident")
                add("account")
                add("accuse")
                add("achieve")
                add("acid")
                add("acoustic")
                add("acquire")
                add("across")
                add("act")
                add("action")
                add("actor")
                add("actress")
                add("actual")
                add("adapt")
                add("add")
                add("addict")
                add("address")
                add("adjust")
                add("admit")
                add("adult")
                add("advance")
                add("advice")
                add("aerobic")
                add("affair")
                add("afford")
                add("afraid")
                add("again")
                add("age")
                add("agent")
                add("agree")
                add("ahead")
                add("aim")
                add("air")
                add("airport")
                add("aisle")
                add("alarm")
                add("album")
                add("alcohol")
                add("alert")
                add("alien")
                add("all")
                add("alley")
                add("allow")
                add("almost")
                add("alone")
                add("alpha")
                add("already")
                add("also")
                add("alter")
                add("always")
                add("amateur")
                add("amazing")
                add("among")
                add("amount")
                add("amused")
                add("analyst")
                add("anchor")
                add("ancient")
                add("anger")
                add("angle")
                add("angry")
                add("animal")
                add("ankle")
                add("announce")
                add("annual")
                add("another")
                add("answer")
                add("antenna")
                add("antique")
                add("anxiety")
                add("any")
                add("apart")
                add("apology")
                add("appear")
                add("apple")
                add("approve")
                add("april")
                add("arch")
                add("arctic")
                add("area")
                add("arena")
                add("argue")
                add("arm")
                add("armed")
                add("armor")
                add("army")
                add("around")
                add("arrange")
                add("arrest")
                add("arrive")
                add("arrow")
                add("art")
                add("artefact")
                add("artist")
                add("artwork")
                add("ask")
                add("aspect")
                add("assault")
                add("asset")
                add("assist")
                add("assume")
                add("asthma")
                add("athlete")
                add("atom")
                add("attack")
                add("attend")
                add("attitude")
                add("attract")
                add("auction")
                add("audit")
                add("august")
                add("aunt")
                add("author")
                add("auto")
                add("autumn")
                add("average")
                add("avocado")
                add("avoid")
                add("awake")
                add("aware")
                add("away")
                add("awesome")
                add("awful")
                add("awkward")
                add("axis")
                add("baby")
                add("bachelor")
                add("bacon")
                add("badge")
                add("bag")
                add("balance")
                add("balcony")
                add("ball")
                add("bamboo")
                add("banana")
                add("banner")
                add("bar")
                add("barely")
                add("bargain")
                add("barrel")
                add("base")
                add("basic")
                add("basket")
                add("battle")
                add("beach")
                add("bean")
                add("beauty")
                add("because")
                add("become")
                add("beef")
                add("before")
                add("begin")
                add("behave")
                add("behind")
                add("believe")
                add("below")
                add("belt")
                add("bench")
                add("benefit")
                add("best")
                add("betray")
                add("better")
                add("between")
                add("beyond")
                add("bicycle")
                add("bid")
                add("bike")
                add("bind")
                add("biology")
                add("bird")
                add("birth")
                add("bitter")
                add("black")
                add("blade")
                add("blame")
                add("blanket")
                add("blast")
                add("bleak")
                add("bless")
                add("blind")
                add("blood")
                add("blossom")
                add("blouse")
                add("blue")
                add("blur")
                add("blush")
                add("board")
                add("boat")
                add("body")
                add("boil")
                add("bomb")
                add("bone")
                add("bonus")
                add("book")
                add("boost")
                add("border")
                add("boring")
                add("borrow")
                add("boss")
                add("bottom")
                add("bounce")
                add("box")
                add("boy")
                add("bracket")
                add("brain")
                add("brand")
                add("brass")
                add("brave")
                add("bread")
                add("breeze")
                add("brick")
                add("bridge")
                add("brief")
                add("bright")
                add("bring")
                add("brisk")
                add("broccoli")
                add("broken")
                add("bronze")
                add("broom")
                add("brother")
                add("brown")
                add("brush")
                add("bubble")
                add("buddy")
                add("budget")
                add("buffalo")
                add("build")
                add("bulb")
                add("bulk")
                add("bullet")
                add("bundle")
                add("bunker")
                add("burden")
                add("burger")
                add("burst")
                add("bus")
                add("business")
                add("busy")
                add("butter")
                add("buyer")
                add("buzz")
                add("cabbage")
                add("cabin")
                add("cable")
                add("cactus")
                add("cage")
                add("cake")
                add("call")
                add("calm")
                add("camera")
                add("camp")
                add("can")
                add("canal")
                add("cancel")
                add("candy")
                add("cannon")
                add("canoe")
                add("canvas")
                add("canyon")
                add("capable")
                add("capital")
                add("captain")
                add("car")
                add("carbon")
                add("card")
                add("cargo")
                add("carpet")
                add("carry")
                add("cart")
                add("case")
                add("cash")
                add("casino")
                add("castle")
                add("casual")
                add("cat")
                add("catalog")
                add("catch")
                add("category")
                add("cattle")
                add("caught")
                add("cause")
                add("caution")
                add("cave")
                add("ceiling")
                add("celery")
                add("cement")
                add("census")
                add("century")
                add("cereal")
                add("certain")
                add("chair")
                add("chalk")
                add("champion")
                add("change")
                add("chaos")
                add("chapter")
                add("charge")
                add("chase")
                add("chat")
                add("cheap")
                add("check")
                add("cheese")
                add("chef")
                add("cherry")
                add("chest")
                add("chicken")
                add("chief")
                add("child")
                add("chimney")
                add("choice")
                add("choose")
                add("chronic")
                add("chuckle")
                add("chunk")
                add("churn")
                add("cigar")
                add("cinnamon")
                add("circle")
                add("citizen")
                add("city")
                add("civil")
                add("claim")
                add("clap")
                add("clarify")
                add("claw")
                add("clay")
                add("clean")
                add("clerk")
                add("clever")
                add("click")
                add("client")
                add("cliff")
                add("climb")
                add("clinic")
                add("clip")
                add("clock")
                add("clog")
                add("close")
                add("cloth")
                add("cloud")
                add("clown")
                add("club")
                add("clump")
                add("cluster")
                add("clutch")
                add("coach")
                add("coast")
                add("coconut")
                add("code")
                add("coffee")
                add("coil")
                add("coin")
                add("collect")
                add("color")
                add("column")
                add("combine")
                add("come")
                add("comfort")
                add("comic")
                add("common")
                add("company")
                add("concert")
                add("conduct")
                add("confirm")
                add("congress")
                add("connect")
                add("consider")
                add("control")
                add("convince")
                add("cook")
                add("cool")
                add("copper")
                add("copy")
                add("coral")
                add("core")
                add("corn")
                add("correct")
                add("cost")
                add("cotton")
                add("couch")
                add("country")
                add("couple")
                add("course")
                add("cousin")
                add("cover")
                add("coyote")
                add("crack")
                add("cradle")
                add("craft")
                add("cram")
                add("crane")
                add("crash")
                add("crater")
                add("crawl")
                add("crazy")
                add("cream")
                add("credit")
                add("creek")
                add("crew")
                add("cricket")
                add("crime")
                add("crisp")
                add("critic")
                add("crop")
                add("cross")
                add("crouch")
                add("crowd")
                add("crucial")
                add("cruel")
                add("cruise")
                add("crumble")
                add("crunch")
                add("crush")
                add("cry")
                add("crystal")
                add("cube")
                add("culture")
                add("cup")
                add("cupboard")
                add("curious")
                add("current")
                add("curtain")
                add("curve")
                add("cushion")
                add("custom")
                add("cute")
                add("cycle")
                add("dad")
                add("damage")
                add("damp")
                add("dance")
                add("danger")
                add("daring")
                add("dash")
                add("daughter")
                add("dawn")
                add("day")
                add("deal")
                add("debate")
                add("debris")
                add("decade")
                add("december")
                add("decide")
                add("decline")
                add("decorate")
                add("decrease")
                add("deer")
                add("defense")
                add("define")
                add("defy")
                add("degree")
                add("delay")
                add("deliver")
                add("demand")
                add("demise")
                add("denial")
                add("dentist")
                add("deny")
                add("depart")
                add("depend")
                add("deposit")
                add("depth")
                add("deputy")
                add("derive")
                add("describe")
                add("desert")
                add("design")
                add("desk")
                add("despair")
                add("destroy")
                add("detail")
                add("detect")
                add("develop")
                add("device")
                add("devote")
                add("diagram")
                add("dial")
                add("diamond")
                add("diary")
                add("dice")
                add("diesel")
                add("diet")
                add("differ")
                add("digital")
                add("dignity")
                add("dilemma")
                add("dinner")
                add("dinosaur")
                add("direct")
                add("dirt")
                add("disagree")
                add("discover")
                add("disease")
                add("dish")
                add("dismiss")
                add("disorder")
                add("display")
                add("distance")
                add("divert")
                add("divide")
                add("divorce")
                add("dizzy")
                add("doctor")
                add("document")
                add("dog")
                add("doll")
                add("dolphin")
                add("domain")
                add("donate")
                add("donkey")
                add("donor")
                add("door")
                add("dose")
                add("double")
                add("dove")
                add("draft")
                add("dragon")
                add("drama")
                add("drastic")
                add("draw")
                add("dream")
                add("dress")
                add("drift")
                add("drill")
                add("drink")
                add("drip")
                add("drive")
                add("drop")
                add("drum")
                add("dry")
                add("duck")
                add("dumb")
                add("dune")
                add("during")
                add("dust")
                add("dutch")
                add("duty")
                add("dwarf")
                add("dynamic")
                add("eager")
                add("eagle")
                add("early")
                add("earn")
                add("earth")
                add("easily")
                add("east")
                add("easy")
                add("echo")
                add("ecology")
                add("economy")
                add("edge")
                add("edit")
                add("educate")
                add("effort")
                add("egg")
                add("eight")
                add("either")
                add("elbow")
                add("elder")
                add("electric")
                add("elegant")
                add("element")
                add("elephant")
                add("elevator")
                add("elite")
                add("else")
                add("embark")
                add("embody")
                add("embrace")
                add("emerge")
                add("emotion")
                add("employ")
                add("empower")
                add("empty")
                add("enable")
                add("enact")
                add("end")
                add("endless")
                add("endorse")
                add("enemy")
                add("energy")
                add("enforce")
                add("engage")
                add("engine")
                add("enhance")
                add("enjoy")
                add("enlist")
                add("enough")
                add("enrich")
                add("enroll")
                add("ensure")
                add("enter")
                add("entire")
                add("entry")
                add("envelope")
                add("episode")
                add("equal")
                add("equip")
                add("era")
                add("erase")
                add("erode")
                add("erosion")
                add("error")
                add("erupt")
                add("escape")
                add("essay")
                add("essence")
                add("estate")
                add("eternal")
                add("ethics")
                add("evidence")
                add("evil")
                add("evoke")
                add("evolve")
                add("exact")
                add("example")
                add("excess")
                add("exchange")
                add("excite")
                add("exclude")
                add("excuse")
                add("execute")
                add("exercise")
                add("exhaust")
                add("exhibit")
                add("exile")
                add("exist")
                add("exit")
                add("exotic")
                add("expand")
                add("expect")
                add("expire")
                add("explain")
                add("expose")
                add("express")
                add("extend")
                add("extra")
                add("eye")
                add("eyebrow")
                add("fabric")
                add("face")
                add("faculty")
                add("fade")
                add("faint")
                add("faith")
                add("fall")
                add("false")
                add("fame")
                add("family")
                add("famous")
                add("fan")
                add("fancy")
                add("fantasy")
                add("farm")
                add("fashion")
                add("fat")
                add("fatal")
                add("father")
                add("fatigue")
                add("fault")
                add("favorite")
                add("feature")
                add("february")
                add("federal")
                add("fee")
                add("feed")
                add("feel")
                add("female")
                add("fence")
                add("festival")
                add("fetch")
                add("fever")
                add("few")
                add("fiber")
                add("fiction")
                add("field")
                add("figure")
                add("file")
                add("film")
                add("filter")
                add("final")
                add("find")
                add("fine")
                add("finger")
                add("finish")
                add("fire")
                add("firm")
                add("first")
                add("fiscal")
                add("fish")
                add("fit")
                add("fitness")
                add("fix")
                add("flag")
                add("flame")
                add("flash")
                add("flat")
                add("flavor")
                add("flee")
                add("flight")
                add("flip")
                add("float")
                add("flock")
                add("floor")
                add("flower")
                add("fluid")
                add("flush")
                add("fly")
                add("foam")
                add("focus")
                add("fog")
                add("foil")
                add("fold")
                add("follow")
                add("food")
                add("foot")
                add("force")
                add("forest")
                add("forget")
                add("fork")
                add("fortune")
                add("forum")
                add("forward")
                add("fossil")
                add("foster")
                add("found")
                add("fox")
                add("fragile")
                add("frame")
                add("frequent")
                add("fresh")
                add("friend")
                add("fringe")
                add("frog")
                add("front")
                add("frost")
                add("frown")
                add("frozen")
                add("fruit")
                add("fuel")
                add("fun")
                add("funny")
                add("furnace")
                add("fury")
                add("future")
                add("gadget")
                add("gain")
                add("galaxy")
                add("gallery")
                add("game")
                add("gap")
                add("garage")
                add("garbage")
                add("garden")
                add("garlic")
                add("garment")
                add("gas")
                add("gasp")
                add("gate")
                add("gather")
                add("gauge")
                add("gaze")
                add("general")
                add("genius")
                add("genre")
                add("gentle")
                add("genuine")
                add("gesture")
                add("ghost")
                add("giant")
                add("gift")
                add("giggle")
                add("ginger")
                add("giraffe")
                add("girl")
                add("give")
                add("glad")
                add("glance")
                add("glare")
                add("glass")
                add("glide")
                add("glimpse")
                add("globe")
                add("gloom")
                add("glory")
                add("glove")
                add("glow")
                add("glue")
                add("goat")
                add("goddess")
                add("gold")
                add("good")
                add("goose")
                add("gorilla")
                add("gospel")
                add("gossip")
                add("govern")
                add("gown")
                add("grab")
                add("grace")
                add("grain")
                add("grant")
                add("grape")
                add("grass")
                add("gravity")
                add("great")
                add("green")
                add("grid")
                add("grief")
                add("grit")
                add("grocery")
                add("group")
                add("grow")
                add("grunt")
                add("guard")
                add("guess")
                add("guide")
                add("guilt")
                add("guitar")
                add("gun")
                add("gym")
                add("habit")
                add("hair")
                add("half")
                add("hammer")
                add("hamster")
                add("hand")
                add("happy")
                add("harbor")
                add("hard")
                add("harsh")
                add("harvest")
                add("hat")
                add("have")
                add("hawk")
                add("hazard")
                add("head")
                add("health")
                add("heart")
                add("heavy")
                add("hedgehog")
                add("height")
                add("hello")
                add("helmet")
                add("help")
                add("hen")
                add("hero")
                add("hidden")
                add("high")
                add("hill")
                add("hint")
                add("hip")
                add("hire")
                add("history")
                add("hobby")
                add("hockey")
                add("hold")
                add("hole")
                add("holiday")
                add("hollow")
                add("home")
                add("honey")
                add("hood")
                add("hope")
                add("horn")
                add("horror")
                add("horse")
                add("hospital")
                add("host")
                add("hotel")
                add("hour")
                add("hover")
                add("hub")
                add("huge")
                add("human")
                add("humble")
                add("humor")
                add("hundred")
                add("hungry")
                add("hunt")
                add("hurdle")
                add("hurry")
                add("hurt")
                add("husband")
                add("hybrid")
                add("ice")
                add("icon")
                add("idea")
                add("identify")
                add("idle")
                add("ignore")
                add("ill")
                add("illegal")
                add("illness")
                add("image")
                add("imitate")
                add("immense")
                add("immune")
                add("impact")
                add("impose")
                add("improve")
                add("impulse")
                add("inch")
                add("include")
                add("income")
                add("increase")
                add("index")
                add("indicate")
                add("indoor")
                add("industry")
                add("infant")
                add("inflict")
                add("inform")
                add("inhale")
                add("inherit")
                add("initial")
                add("inject")
                add("injury")
                add("inmate")
                add("inner")
                add("innocent")
                add("input")
                add("inquiry")
                add("insane")
                add("insect")
                add("inside")
                add("inspire")
                add("install")
                add("intact")
                add("interest")
                add("into")
                add("invest")
                add("invite")
                add("involve")
                add("iron")
                add("island")
                add("isolate")
                add("issue")
                add("item")
                add("ivory")
                add("jacket")
                add("jaguar")
                add("jar")
                add("jazz")
                add("jealous")
                add("jeans")
                add("jelly")
                add("jewel")
                add("job")
                add("join")
                add("joke")
                add("journey")
                add("joy")
                add("judge")
                add("juice")
                add("jump")
                add("jungle")
                add("junior")
                add("junk")
                add("just")
                add("kangaroo")
                add("keen")
                add("keep")
                add("ketchup")
                add("key")
                add("kick")
                add("kid")
                add("kidney")
                add("kind")
                add("kingdom")
                add("kiss")
                add("kit")
                add("kitchen")
                add("kite")
                add("kitten")
                add("kiwi")
                add("knee")
                add("knife")
                add("knock")
                add("know")
                add("lab")
                add("label")
                add("labor")
                add("ladder")
                add("lady")
                add("lake")
                add("lamp")
                add("language")
                add("laptop")
                add("large")
                add("later")
                add("latin")
                add("laugh")
                add("laundry")
                add("lava")
                add("law")
                add("lawn")
                add("lawsuit")
                add("layer")
                add("lazy")
                add("leader")
                add("leaf")
                add("learn")
                add("leave")
                add("lecture")
                add("left")
                add("leg")
                add("legal")
                add("legend")
                add("leisure")
                add("lemon")
                add("lend")
                add("length")
                add("lens")
                add("leopard")
                add("lesson")
                add("letter")
                add("level")
                add("liar")
                add("liberty")
                add("library")
                add("license")
                add("life")
                add("lift")
                add("light")
                add("like")
                add("limb")
                add("limit")
                add("link")
                add("lion")
                add("liquid")
                add("list")
                add("little")
                add("live")
                add("lizard")
                add("load")
                add("loan")
                add("lobster")
                add("local")
                add("lock")
                add("logic")
                add("lonely")
                add("long")
                add("loop")
                add("lottery")
                add("loud")
                add("lounge")
                add("love")
                add("loyal")
                add("lucky")
                add("luggage")
                add("lumber")
                add("lunar")
                add("lunch")
                add("luxury")
                add("lyrics")
                add("machine")
                add("mad")
                add("magic")
                add("magnet")
                add("maid")
                add("mail")
                add("main")
                add("major")
                add("make")
                add("mammal")
                add("man")
                add("manage")
                add("mandate")
                add("mango")
                add("mansion")
                add("manual")
                add("maple")
                add("marble")
                add("march")
                add("margin")
                add("marine")
                add("market")
                add("marriage")
                add("mask")
                add("mass")
                add("master")
                add("match")
                add("material")
                add("math")
                add("matrix")
                add("matter")
                add("maximum")
                add("maze")
                add("meadow")
                add("mean")
                add("measure")
                add("meat")
                add("mechanic")
                add("medal")
                add("media")
                add("melody")
                add("melt")
                add("member")
                add("memory")
                add("mention")
                add("menu")
                add("mercy")
                add("merge")
                add("merit")
                add("merry")
                add("mesh")
                add("message")
                add("metal")
                add("method")
                add("middle")
                add("midnight")
                add("milk")
                add("million")
                add("mimic")
                add("mind")
                add("minimum")
                add("minor")
                add("minute")
                add("miracle")
                add("mirror")
                add("misery")
                add("miss")
                add("mistake")
                add("mix")
                add("mixed")
                add("mixture")
                add("mobile")
                add("model")
                add("modify")
                add("mom")
                add("moment")
                add("monitor")
                add("monkey")
                add("monster")
                add("month")
                add("moon")
                add("moral")
                add("more")
                add("morning")
                add("mosquito")
                add("mother")
                add("motion")
                add("motor")
                add("mountain")
                add("mouse")
                add("move")
                add("movie")
                add("much")
                add("muffin")
                add("mule")
                add("multiply")
                add("muscle")
                add("museum")
                add("mushroom")
                add("music")
                add("must")
                add("mutual")
                add("myself")
                add("mystery")
                add("myth")
                add("naive")
                add("name")
                add("napkin")
                add("narrow")
                add("nasty")
                add("nation")
                add("nature")
                add("near")
                add("neck")
                add("need")
                add("negative")
                add("neglect")
                add("neither")
                add("nephew")
                add("nerve")
                add("nest")
                add("net")
                add("network")
                add("neutral")
                add("never")
                add("news")
                add("next")
                add("nice")
                add("night")
                add("noble")
                add("noise")
                add("nominee")
                add("noodle")
                add("normal")
                add("north")
                add("nose")
                add("notable")
                add("note")
                add("nothing")
                add("notice")
                add("novel")
                add("now")
                add("nuclear")
                add("number")
                add("nurse")
                add("nut")
                add("oak")
                add("obey")
                add("object")
                add("oblige")
                add("obscure")
                add("observe")
                add("obtain")
                add("obvious")
                add("occur")
                add("ocean")
                add("october")
                add("odor")
                add("off")
                add("offer")
                add("office")
                add("often")
                add("oil")
                add("okay")
                add("old")
                add("olive")
                add("olympic")
                add("omit")
                add("once")
                add("one")
                add("onion")
                add("online")
                add("only")
                add("open")
                add("opera")
                add("opinion")
                add("oppose")
                add("option")
                add("orange")
                add("orbit")
                add("orchard")
                add("order")
                add("ordinary")
                add("organ")
                add("orient")
                add("original")
                add("orphan")
                add("ostrich")
                add("other")
                add("outdoor")
                add("outer")
                add("output")
                add("outside")
                add("oval")
                add("oven")
                add("over")
                add("own")
                add("owner")
                add("oxygen")
                add("oyster")
                add("ozone")
                add("pact")
                add("paddle")
                add("page")
                add("pair")
                add("palace")
                add("palm")
                add("panda")
                add("panel")
                add("panic")
                add("panther")
                add("paper")
                add("parade")
                add("parent")
                add("park")
                add("parrot")
                add("party")
                add("pass")
                add("patch")
                add("path")
                add("patient")
                add("patrol")
                add("pattern")
                add("pause")
                add("pave")
                add("payment")
                add("peace")
                add("peanut")
                add("pear")
                add("peasant")
                add("pelican")
                add("pen")
                add("penalty")
                add("pencil")
                add("people")
                add("pepper")
                add("perfect")
                add("permit")
                add("person")
                add("pet")
                add("phone")
                add("photo")
                add("phrase")
                add("physical")
                add("piano")
                add("picnic")
                add("picture")
                add("piece")
                add("pig")
                add("pigeon")
                add("pill")
                add("pilot")
                add("pink")
                add("pioneer")
                add("pipe")
                add("pistol")
                add("pitch")
                add("pizza")
                add("place")
                add("planet")
                add("plastic")
                add("plate")
                add("play")
                add("please")
                add("pledge")
                add("pluck")
                add("plug")
                add("plunge")
                add("poem")
                add("poet")
                add("point")
                add("polar")
                add("pole")
                add("police")
                add("pond")
                add("pony")
                add("pool")
                add("popular")
                add("portion")
                add("position")
                add("possible")
                add("post")
                add("potato")
                add("pottery")
                add("poverty")
                add("powder")
                add("power")
                add("practice")
                add("praise")
                add("predict")
                add("prefer")
                add("prepare")
                add("present")
                add("pretty")
                add("prevent")
                add("price")
                add("pride")
                add("primary")
                add("print")
                add("priority")
                add("prison")
                add("private")
                add("prize")
                add("problem")
                add("process")
                add("produce")
                add("profit")
                add("program")
                add("project")
                add("promote")
                add("proof")
                add("property")
                add("prosper")
                add("protect")
                add("proud")
                add("provide")
                add("public")
                add("pudding")
                add("pull")
                add("pulp")
                add("pulse")
                add("pumpkin")
                add("punch")
                add("pupil")
                add("puppy")
                add("purchase")
                add("purity")
                add("purpose")
                add("purse")
                add("push")
                add("put")
                add("puzzle")
                add("pyramid")
                add("quality")
                add("quantum")
                add("quarter")
                add("question")
                add("quick")
                add("quit")
                add("quiz")
                add("quote")
                add("rabbit")
                add("raccoon")
                add("race")
                add("rack")
                add("radar")
                add("radio")
                add("rail")
                add("rain")
                add("raise")
                add("rally")
                add("ramp")
                add("ranch")
                add("random")
                add("range")
                add("rapid")
                add("rare")
                add("rate")
                add("rather")
                add("raven")
                add("raw")
                add("razor")
                add("ready")
                add("real")
                add("reason")
                add("rebel")
                add("rebuild")
                add("recall")
                add("receive")
                add("recipe")
                add("record")
                add("recycle")
                add("reduce")
                add("reflect")
                add("reform")
                add("refuse")
                add("region")
                add("regret")
                add("regular")
                add("reject")
                add("relax")
                add("release")
                add("relief")
                add("rely")
                add("remain")
                add("remember")
                add("remind")
                add("remove")
                add("render")
                add("renew")
                add("rent")
                add("reopen")
                add("repair")
                add("repeat")
                add("replace")
                add("report")
                add("require")
                add("rescue")
                add("resemble")
                add("resist")
                add("resource")
                add("response")
                add("result")
                add("retire")
                add("retreat")
                add("return")
                add("reunion")
                add("reveal")
                add("review")
                add("reward")
                add("rhythm")
                add("rib")
                add("ribbon")
                add("rice")
                add("rich")
                add("ride")
                add("ridge")
                add("rifle")
                add("right")
                add("rigid")
                add("ring")
                add("riot")
                add("ripple")
                add("risk")
                add("ritual")
                add("rival")
                add("river")
                add("road")
                add("roast")
                add("robot")
                add("robust")
                add("rocket")
                add("romance")
                add("roof")
                add("rookie")
                add("room")
                add("rose")
                add("rotate")
                add("rough")
                add("round")
                add("route")
                add("royal")
                add("rubber")
                add("rude")
                add("rug")
                add("rule")
                add("run")
                add("runway")
                add("rural")
                add("sad")
                add("saddle")
                add("sadness")
                add("safe")
                add("sail")
                add("salad")
                add("salmon")
                add("salon")
                add("salt")
                add("salute")
                add("same")
                add("sample")
                add("sand")
                add("satisfy")
                add("satoshi")
                add("sauce")
                add("sausage")
                add("save")
                add("say")
                add("scale")
                add("scan")
                add("scare")
                add("scatter")
                add("scene")
                add("scheme")
                add("school")
                add("science")
                add("scissors")
                add("scorpion")
                add("scout")
                add("scrap")
                add("screen")
                add("script")
                add("scrub")
                add("sea")
                add("search")
                add("season")
                add("seat")
                add("second")
                add("secret")
                add("section")
                add("security")
                add("seed")
                add("seek")
                add("segment")
                add("select")
                add("sell")
                add("seminar")
                add("senior")
                add("sense")
                add("sentence")
                add("series")
                add("service")
                add("session")
                add("settle")
                add("setup")
                add("seven")
                add("shadow")
                add("shaft")
                add("shallow")
                add("share")
                add("shed")
                add("shell")
                add("sheriff")
                add("shield")
                add("shift")
                add("shine")
                add("ship")
                add("shiver")
                add("shock")
                add("shoe")
                add("shoot")
                add("shop")
                add("short")
                add("shoulder")
                add("shove")
                add("shrimp")
                add("shrug")
                add("shuffle")
                add("shy")
                add("sibling")
                add("sick")
                add("side")
                add("siege")
                add("sight")
                add("sign")
                add("silent")
                add("silk")
                add("silly")
                add("silver")
                add("similar")
                add("simple")
                add("since")
                add("sing")
                add("siren")
                add("sister")
                add("situate")
                add("six")
                add("size")
                add("skate")
                add("sketch")
                add("ski")
                add("skill")
                add("skin")
                add("skirt")
                add("skull")
                add("slab")
                add("slam")
                add("sleep")
                add("slender")
                add("slice")
                add("slide")
                add("slight")
                add("slim")
                add("slogan")
                add("slot")
                add("slow")
                add("slush")
                add("small")
                add("smart")
                add("smile")
                add("smoke")
                add("smooth")
                add("snack")
                add("snake")
                add("snap")
                add("sniff")
                add("snow")
                add("soap")
                add("soccer")
                add("social")
                add("sock")
                add("soda")
                add("soft")
                add("solar")
                add("soldier")
                add("solid")
                add("solution")
                add("solve")
                add("someone")
                add("song")
                add("soon")
                add("sorry")
                add("sort")
                add("soul")
                add("sound")
                add("soup")
                add("source")
                add("south")
                add("space")
                add("spare")
                add("spatial")
                add("spawn")
                add("speak")
                add("special")
                add("speed")
                add("spell")
                add("spend")
                add("sphere")
                add("spice")
                add("spider")
                add("spike")
                add("spin")
                add("spirit")
                add("split")
                add("spoil")
                add("sponsor")
                add("spoon")
                add("sport")
                add("spot")
                add("spray")
                add("spread")
                add("spring")
                add("spy")
                add("square")
                add("squeeze")
                add("squirrel")
                add("stable")
                add("stadium")
                add("staff")
                add("stage")
                add("stairs")
                add("stamp")
                add("stand")
                add("start")
                add("state")
                add("stay")
                add("steak")
                add("steel")
                add("stem")
                add("step")
                add("stereo")
                add("stick")
                add("still")
                add("sting")
                add("stock")
                add("stomach")
                add("stone")
                add("stool")
                add("story")
                add("stove")
                add("strategy")
                add("street")
                add("strike")
                add("strong")
                add("struggle")
                add("student")
                add("stuff")
                add("stumble")
                add("style")
                add("subject")
                add("submit")
                add("subway")
                add("success")
                add("such")
                add("sudden")
                add("suffer")
                add("sugar")
                add("suggest")
                add("suit")
                add("summer")
                add("sun")
                add("sunny")
                add("sunset")
                add("super")
                add("supply")
                add("supreme")
                add("sure")
                add("surface")
                add("surge")
                add("surprise")
                add("surround")
                add("survey")
                add("suspect")
                add("sustain")
                add("swallow")
                add("swamp")
                add("swap")
                add("swarm")
                add("swear")
                add("sweet")
                add("swift")
                add("swim")
                add("swing")
                add("switch")
                add("sword")
                add("symbol")
                add("symptom")
                add("syrup")
                add("system")
                add("table")
                add("tackle")
                add("tag")
                add("tail")
                add("talent")
                add("talk")
                add("tank")
                add("tape")
                add("target")
                add("task")
                add("taste")
                add("tattoo")
                add("taxi")
                add("teach")
                add("team")
                add("tell")
                add("ten")
                add("tenant")
                add("tennis")
                add("tent")
                add("term")
                add("test")
                add("text")
                add("thank")
                add("that")
                add("theme")
                add("then")
                add("theory")
                add("there")
                add("they")
                add("thing")
                add("this")
                add("thought")
                add("three")
                add("thrive")
                add("throw")
                add("thumb")
                add("thunder")
                add("ticket")
                add("tide")
                add("tiger")
                add("tilt")
                add("timber")
                add("time")
                add("tiny")
                add("tip")
                add("tired")
                add("tissue")
                add("title")
                add("toast")
                add("tobacco")
                add("today")
                add("toddler")
                add("toe")
                add("together")
                add("toilet")
                add("token")
                add("tomato")
                add("tomorrow")
                add("tone")
                add("tongue")
                add("tonight")
                add("tool")
                add("tooth")
                add("top")
                add("topic")
                add("topple")
                add("torch")
                add("tornado")
                add("tortoise")
                add("toss")
                add("total")
                add("tourist")
                add("toward")
                add("tower")
                add("town")
                add("toy")
                add("track")
                add("trade")
                add("traffic")
                add("tragic")
                add("train")
                add("transfer")
                add("trap")
                add("trash")
                add("travel")
                add("tray")
                add("treat")
                add("tree")
                add("trend")
                add("trial")
                add("tribe")
                add("trick")
                add("trigger")
                add("trim")
                add("trip")
                add("trophy")
                add("trouble")
                add("truck")
                add("true")
                add("truly")
                add("trumpet")
                add("trust")
                add("truth")
                add("try")
                add("tube")
                add("tuition")
                add("tumble")
                add("tuna")
                add("tunnel")
                add("turkey")
                add("turn")
                add("turtle")
                add("twelve")
                add("twenty")
                add("twice")
                add("twin")
                add("twist")
                add("two")
                add("type")
                add("typical")
                add("ugly")
                add("umbrella")
                add("unable")
                add("unaware")
                add("uncle")
                add("uncover")
                add("under")
                add("undo")
                add("unfair")
                add("unfold")
                add("unhappy")
                add("uniform")
                add("unique")
                add("unit")
                add("universe")
                add("unknown")
                add("unlock")
                add("until")
                add("unusual")
                add("unveil")
                add("update")
                add("upgrade")
                add("uphold")
                add("upon")
                add("upper")
                add("upset")
                add("urban")
                add("urge")
                add("usage")
                add("use")
                add("used")
                add("useful")
                add("useless")
                add("usual")
                add("utility")
                add("vacant")
                add("vacuum")
                add("vague")
                add("valid")
                add("valley")
                add("valve")
                add("van")
                add("vanish")
                add("vapor")
                add("various")
                add("vast")
                add("vault")
                add("vehicle")
                add("velvet")
                add("vendor")
                add("venture")
                add("venue")
                add("verb")
                add("verify")
                add("version")
                add("very")
                add("vessel")
                add("veteran")
                add("viable")
                add("vibrant")
                add("vicious")
                add("victory")
                add("video")
                add("view")
                add("village")
                add("vintage")
                add("violin")
                add("virtual")
                add("virus")
                add("visa")
                add("visit")
                add("visual")
                add("vital")
                add("vivid")
                add("vocal")
                add("voice")
                add("void")
                add("volcano")
                add("volume")
                add("vote")
                add("voyage")
                add("wage")
                add("wagon")
                add("wait")
                add("walk")
                add("wall")
                add("walnut")
                add("want")
                add("warfare")
                add("warm")
                add("warrior")
                add("wash")
                add("wasp")
                add("waste")
                add("water")
                add("wave")
                add("way")
                add("wealth")
                add("weapon")
                add("wear")
                add("weasel")
                add("weather")
                add("web")
                add("wedding")
                add("weekend")
                add("weird")
                add("welcome")
                add("west")
                add("wet")
                add("whale")
                add("what")
                add("wheat")
                add("wheel")
                add("when")
                add("where")
                add("whip")
                add("whisper")
                add("wide")
                add("width")
                add("wife")
                add("wild")
                add("will")
                add("win")
                add("window")
                add("wine")
                add("wing")
                add("wink")
                add("winner")
                add("winter")
                add("wire")
                add("wisdom")
                add("wise")
                add("wish")
                add("witness")
                add("wolf")
                add("woman")
                add("wonder")
                add("wood")
                add("wool")
                add("word")
                add("work")
                add("world")
                add("worry")
                add("worth")
                add("wrap")
                add("wreck")
                add("wrestle")
                add("wrist")
                add("write")
                add("wrong")
                add("yard")
                add("year")
                add("yellow")
                add("you")
                add("young")
                add("youth")
                add("zebra")
                add("zero")
                add("zone")
                add("zoo")
            }
        }
    }
}
