package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import io.eqoty.shared.datalayer.objects.AttachmentAccess
import kotlin.Any
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.Collection

public class RemoteAudioResourceTableQueries(
  driver: SqlDriver,
  private val RemoteAudioResourceTableAdapter: RemoteAudioResourceTable.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getByUrl(url: String, mapper: (
    id: String,
    url: String,
    access: AttachmentAccess,
    priority: Int,
    size: Long,
    container: String,
    codec: String,
    bitDepth: Int?,
    bitRate: Int,
    sampleRate: Int,
    channels: Int,
    lossless: Boolean,
  ) -> T): Query<T> = GetByUrlQuery(url) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      RemoteAudioResourceTableAdapter.accessAdapter.decode(cursor.getString(2)!!),
      RemoteAudioResourceTableAdapter.priorityAdapter.decode(cursor.getLong(3)!!),
      cursor.getLong(4)!!,
      cursor.getString(5)!!,
      cursor.getString(6)!!,
      cursor.getLong(7)?.let { RemoteAudioResourceTableAdapter.bitDepthAdapter.decode(it) },
      RemoteAudioResourceTableAdapter.bitRateAdapter.decode(cursor.getLong(8)!!),
      RemoteAudioResourceTableAdapter.sampleRateAdapter.decode(cursor.getLong(9)!!),
      RemoteAudioResourceTableAdapter.channelsAdapter.decode(cursor.getLong(10)!!),
      cursor.getBoolean(11)!!
    )
  }

  public fun getByUrl(url: String): Query<GetByUrl> = getByUrl(url) { id, url_, access, priority,
      size, container, codec, bitDepth, bitRate, sampleRate, channels, lossless ->
    GetByUrl(
      id,
      url_,
      access,
      priority,
      size,
      container,
      codec,
      bitDepth,
      bitRate,
      sampleRate,
      channels,
      lossless
    )
  }

  public fun <T : Any> getAll(mapper: (
    url: String,
    access: AttachmentAccess,
    priority: Int,
    size: Long,
    container: String,
    codec: String,
    bitDepth: Int?,
    bitRate: Int,
    sampleRate: Int,
    channels: Int,
    lossless: Boolean,
  ) -> T): Query<T> = Query(357_949_839, arrayOf("RemoteAudioResourceTable"), driver,
      "RemoteAudioResourceTable.sq", "getAll",
      "SELECT url, access, priority, size, container, codec, bitDepth, bitRate, sampleRate, channels, lossless FROM RemoteAudioResourceTable") {
      cursor ->
    mapper(
      cursor.getString(0)!!,
      RemoteAudioResourceTableAdapter.accessAdapter.decode(cursor.getString(1)!!),
      RemoteAudioResourceTableAdapter.priorityAdapter.decode(cursor.getLong(2)!!),
      cursor.getLong(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5)!!,
      cursor.getLong(6)?.let { RemoteAudioResourceTableAdapter.bitDepthAdapter.decode(it) },
      RemoteAudioResourceTableAdapter.bitRateAdapter.decode(cursor.getLong(7)!!),
      RemoteAudioResourceTableAdapter.sampleRateAdapter.decode(cursor.getLong(8)!!),
      RemoteAudioResourceTableAdapter.channelsAdapter.decode(cursor.getLong(9)!!),
      cursor.getBoolean(10)!!
    )
  }

  public fun getAll(): Query<GetAll> = getAll { url, access, priority, size, container, codec,
      bitDepth, bitRate, sampleRate, channels, lossless ->
    GetAll(
      url,
      access,
      priority,
      size,
      container,
      codec,
      bitDepth,
      bitRate,
      sampleRate,
      channels,
      lossless
    )
  }

  public fun countAudioMetadata(): Query<Long> = Query(-192_825_990,
      arrayOf("RemoteAudioResourceTable"), driver, "RemoteAudioResourceTable.sq",
      "countAudioMetadata", "SELECT COUNT(*) FROM RemoteAudioResourceTable") { cursor ->
    cursor.getLong(0)!!
  }

  public fun <T : Any> getAllForNftMetadataId(nftMetadataId: String, mapper: (
    url: String,
    size: Long,
    container: String,
    codec: String,
    bitDepth: Int?,
    bitRate: Int,
    sampleRate: Int,
    channels: Int,
    lossless: Boolean,
  ) -> T): Query<T> = GetAllForNftMetadataIdQuery(nftMetadataId) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)?.let { RemoteAudioResourceTableAdapter.bitDepthAdapter.decode(it) },
      RemoteAudioResourceTableAdapter.bitRateAdapter.decode(cursor.getLong(5)!!),
      RemoteAudioResourceTableAdapter.sampleRateAdapter.decode(cursor.getLong(6)!!),
      RemoteAudioResourceTableAdapter.channelsAdapter.decode(cursor.getLong(7)!!),
      cursor.getBoolean(8)!!
    )
  }

  public fun getAllForNftMetadataId(nftMetadataId: String): Query<GetAllForNftMetadataId> =
      getAllForNftMetadataId(nftMetadataId) { url, size, container, codec, bitDepth, bitRate,
      sampleRate, channels, lossless ->
    GetAllForNftMetadataId(
      url,
      size,
      container,
      codec,
      bitDepth,
      bitRate,
      sampleRate,
      channels,
      lossless
    )
  }

  public fun <T : Any> getAllForNftMetadataIdAndVisibility(
    nftMetadataId: String,
    access: AttachmentAccess,
    mapper: (
      url: String,
      size: Long,
      container: String,
      codec: String,
      bitDepth: Int?,
      bitRate: Int,
      sampleRate: Int,
      channels: Int,
      lossless: Boolean,
    ) -> T,
  ): Query<T> = GetAllForNftMetadataIdAndVisibilityQuery(nftMetadataId, access) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)?.let { RemoteAudioResourceTableAdapter.bitDepthAdapter.decode(it) },
      RemoteAudioResourceTableAdapter.bitRateAdapter.decode(cursor.getLong(5)!!),
      RemoteAudioResourceTableAdapter.sampleRateAdapter.decode(cursor.getLong(6)!!),
      RemoteAudioResourceTableAdapter.channelsAdapter.decode(cursor.getLong(7)!!),
      cursor.getBoolean(8)!!
    )
  }

  public fun getAllForNftMetadataIdAndVisibility(nftMetadataId: String, access: AttachmentAccess):
      Query<GetAllForNftMetadataIdAndVisibility> =
      getAllForNftMetadataIdAndVisibility(nftMetadataId, access) { url, size, container, codec,
      bitDepth, bitRate, sampleRate, channels, lossless ->
    GetAllForNftMetadataIdAndVisibility(
      url,
      size,
      container,
      codec,
      bitDepth,
      bitRate,
      sampleRate,
      channels,
      lossless
    )
  }

  public fun getAllWhereNftMetadataIdNot(nftMetadataId: Collection<String>): Query<String> =
      GetAllWhereNftMetadataIdNotQuery(nftMetadataId) { cursor ->
    cursor.getString(0)!!
  }

  public suspend fun deleteByUrl(url: String) {
    driver.execute(-1_205_417_463, """DELETE FROM RemoteAudioResourceTable WHERE url = ?""", 1) {
          bindString(0, url)
        }.await()
    notifyQueries(-1_205_417_463) { emit ->
      emit("RemoteAudioResourceTable")
    }
  }

  public suspend fun deleteByNftMetadataId(nftMetadataId: String) {
    driver.execute(2_105_969_568,
        """DELETE FROM RemoteAudioResourceTable WHERE nftMetadataId = ?""", 1) {
          bindString(0, nftMetadataId)
        }.await()
    notifyQueries(2_105_969_568) { emit ->
      emit("RemoteAudioResourceTable")
    }
  }

  public suspend fun deleteTable() {
    driver.execute(-1_189_496_769, """DELETE FROM RemoteAudioResourceTable""", 0).await()
    notifyQueries(-1_189_496_769) { emit ->
      emit("RemoteAudioResourceTable")
    }
  }

  public suspend fun upsertAudioTrack(
    id: String,
    url: String,
    access: AttachmentAccess,
    priority: Int,
    size: Long,
    container: String,
    codec: String,
    bitDepth: Int?,
    bitRate: Int,
    sampleRate: Int,
    channels: Int,
    lossless: Boolean,
    nftMetadataId: String,
  ) {
    transaction {
      driver.execute(1_161_180_537, """
          |INSERT OR REPLACE INTO RemoteAudioResourceTable(id, url,access,priority,size,container,codec,bitDepth,bitRate,sampleRate,channels,lossless,nftMetadataId)
          |    VALUES(?, ?, ?, ?,?, ?, ?, ?, ?, ?,?,?, ?)
          """.trimMargin(), 13) {
            bindString(0, id)
            bindString(1, url)
            bindString(2, RemoteAudioResourceTableAdapter.accessAdapter.encode(access))
            bindLong(3, RemoteAudioResourceTableAdapter.priorityAdapter.encode(priority))
            bindLong(4, size)
            bindString(5, container)
            bindString(6, codec)
            bindLong(7, bitDepth?.let { RemoteAudioResourceTableAdapter.bitDepthAdapter.encode(it)
                })
            bindLong(8, RemoteAudioResourceTableAdapter.bitRateAdapter.encode(bitRate))
            bindLong(9, RemoteAudioResourceTableAdapter.sampleRateAdapter.encode(sampleRate))
            bindLong(10, RemoteAudioResourceTableAdapter.channelsAdapter.encode(channels))
            bindBoolean(11, lossless)
            bindString(12, nftMetadataId)
          }.await()
    }
    notifyQueries(-557_450_296) { emit ->
      emit("RemoteAudioResourceTable")
    }
  }

  private inner class GetByUrlQuery<out T : Any>(
    public val url: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("RemoteAudioResourceTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("RemoteAudioResourceTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(393_703_942,
        """SELECT id, url, access, priority, size, container, codec, bitDepth, bitRate, sampleRate, channels, lossless FROM RemoteAudioResourceTable  WHERE url = ?""",
        mapper, 1) {
      bindString(0, url)
    }

    override fun toString(): String = "RemoteAudioResourceTable.sq:getByUrl"
  }

  private inner class GetAllForNftMetadataIdQuery<out T : Any>(
    public val nftMetadataId: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("RemoteAudioResourceTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("RemoteAudioResourceTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_510_119_660, """
    |SELECT url, size, container, codec, bitDepth, bitRate, sampleRate, channels, lossless FROM RemoteAudioResourceTable
    |    WHERE nftMetadataId = ?
    """.trimMargin(), mapper, 1) {
      bindString(0, nftMetadataId)
    }

    override fun toString(): String = "RemoteAudioResourceTable.sq:getAllForNftMetadataId"
  }

  private inner class GetAllForNftMetadataIdAndVisibilityQuery<out T : Any>(
    public val nftMetadataId: String,
    public val access: AttachmentAccess,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("RemoteAudioResourceTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("RemoteAudioResourceTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_390_546_621, """
    |SELECT url, size, container, codec, bitDepth, bitRate, sampleRate, channels, lossless FROM RemoteAudioResourceTable
    |    WHERE nftMetadataId = ? AND access = ?
    """.trimMargin(), mapper, 2) {
      bindString(0, nftMetadataId)
      bindString(1, RemoteAudioResourceTableAdapter.accessAdapter.encode(access))
    }

    override fun toString(): String =
        "RemoteAudioResourceTable.sq:getAllForNftMetadataIdAndVisibility"
  }

  private inner class GetAllWhereNftMetadataIdNotQuery<out T : Any>(
    public val nftMetadataId: Collection<String>,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("RemoteAudioResourceTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("RemoteAudioResourceTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> {
      val nftMetadataIdIndexes = createArguments(count = nftMetadataId.size)
      return driver.executeQuery(null,
          """SELECT url FROM RemoteAudioResourceTable WHERE nftMetadataId NOT IN $nftMetadataIdIndexes""",
          mapper, nftMetadataId.size) {
            nftMetadataId.forEachIndexed { index, nftMetadataId_ ->
              bindString(index, nftMetadataId_)
            }
          }
    }

    override fun toString(): String = "RemoteAudioResourceTable.sq:getAllWhereNftMetadataIdNot"
  }
}
