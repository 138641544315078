package io.eqoty.composables.screens.releaserprofile

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.State
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.mutableStateOf

data class ReleaserProfileFormInputData(
    val firstName: MutableState<String> = mutableStateOf(""),
    val lastName: MutableState<String> = mutableStateOf(""),
    val email: MutableState<String> = mutableStateOf(""),
    val phone: MutableState<String> = mutableStateOf(""),
    val phoneFormatted: MutableState<String?> = mutableStateOf(null),
    val mailingAddress: MutableState<String> = mutableStateOf(""),
    val govId: MutableState<String> = mutableStateOf(""),
    val selfie: MutableState<String> = mutableStateOf("")
) {
    val fullName: State<String> = derivedStateOf {
        if (firstName.value.isNotBlank() || lastName.value.isNotBlank()) {
            "${firstName.value} ${lastName.value}"
        } else {
            ""
        }
    }
}