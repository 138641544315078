package io.eqoty.shared.datalayer.functions

import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.secretk.utils.toUByteArray
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.AesCredentials
import io.eqoty.shared.datalayer.sources.web3.CosmosWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.EthWeb3Provider
import okio.ByteString.Companion.decodeBase64
import kotlin.random.Random
import kotlin.random.nextUBytes

suspend fun Repository.getPurchasePrivateKey(releaseId: UInt, purchaseId: BigInteger): AesCredentials {
    return when (val provider = web3Provider!!) {
        is EthWeb3Provider -> {
            // hardcoded... not random or stored on chain
            val rand = Random(0)
            val iv = rand.nextUBytes(12)
            val key = rand.nextUBytes(16)
            AesCredentials(iv, key)
        }

        is CosmosWeb3Provider -> {
            val childSnip721 = getProductDealerContract(releaseId).childSnip721
            val metadata = getPurchasePrivateMetadata(childSnip721, purchaseId)
            val authKey = metadata?.extension?.media?.firstOrNull()?.authentication?.key
                ?: getRelease(releaseId)?.privateAuthenticationKey!!
            val ivKeyBase = authKey.decodeBase64()!!.toUByteArray()
            val iv = ivKeyBase.sliceArray(0 until 12)
            val key = ivKeyBase.sliceArray(12 until 28)
            AesCredentials(iv, key)
        }
    }
}