package io.eqoty.shared.datalayer.functions

import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.sources.web3.InternalWalletTxRequest


suspend fun Repository.mergeRoyaltyTokens(
    releaseId: UInt,
    tokenIds: List<BigInteger>,
    sharesPerToken: List<UInt>,
    internalWltTxRequest: InternalWalletTxRequest?
): String? = withRepoContext {
    val receipt = try {
        val royaltyDealer = getRelease(releaseId)!!.royaltyDealer
        // todo save the childSnip721 info to the db and get from there
        val childSnip721 = queryRoyaltyDealerChildSnip721Contract(royaltyDealer)
        mergeRoyalties(royaltyDealer, childSnip721, tokenIds.toMutableList(), sharesPerToken, internalWltTxRequest)
    } catch (t: Throwable) {
        t.printStackTrace()
        null
    }
    return@withRepoContext receipt?.transactionHash
}