package io.eqoty.shared.viewmodel.screens.myorganizations

import co.touchlab.kermit.Logger
import io.eqoty.shared.datalayer.functions.getOrganizationBatch
import io.eqoty.shared.datalayer.functions.getOrganizationMemberships
import io.eqoty.shared.datalayer.functions.getReleaserId
import io.eqoty.shared.datalayer.functions.getWalletConnected
import io.eqoty.shared.viewmodel.Events
import io.eqoty.shared.viewmodel.screens.Level1Navigation
import io.eqoty.shared.viewmodel.screens.ScreenStack
import kotlinx.coroutines.Job

/********** EVENT functions, called directly by the UI layer **********/


fun Events.refreshMyOrganizationsOnWalletChanges() = inScreenScopeLaunchInBackground(ScreenStack.Main) {
    dataRepository.walletAddressChangeFlow.collect { newWalletAddress ->
        Logger.d("refreshReleaserInfoOnWalletChanges: onCollect:$newWalletAddress")
        displayMyOrganizations()
    }
}

private var displayMyOrganizationsJob: Job? = null

fun Events.displayMyOrganizations() {
    // prevent multiple instances of refresh from being executed
    if (displayMyOrganizationsJob?.isActive == true) {
        return
    }
    displayMyOrganizationsJob = inScreenScopeLaunch(ScreenStack.Main) {
        val walletConnected = dataRepository.getWalletConnected()
        if (!walletConnected) {
            stateManager.updateScreen(ScreenStack.Main, MyOrganizationsScreenState::class) {
                it.copy(
                    loading = false,
                    isConnected = walletConnected
                )
            }
            return@inScreenScopeLaunch
        }

        stateManager.updateScreen(ScreenStack.Main, MyOrganizationsScreenState::class) {
            it.copy(
                loading = true,
                isConnected = walletConnected
            )
        }
        val releaserId = dataRepository.getReleaserId()
        if (releaserId == null) {
            stateManager.navigation.selectLevel1Navigation(ScreenStack.Main, Level1Navigation.HomeProvider.screenIdentifier)
            return@inScreenScopeLaunch
        }

        stateManager.updateScreen(ScreenStack.Main, MyOrganizationsScreenState::class) { it.copy(loading = true) }

        val organizationMemberships = dataRepository.getOrganizationMemberships()
        val organizations = dataRepository.getOrganizationBatch(organizationMemberships)
        stateManager.updateScreen(ScreenStack.Main, MyOrganizationsScreenState::class) {
            it.copy(
                organizations = organizations, loading = false,
            )
        }
        return@inScreenScopeLaunch
    }
}
