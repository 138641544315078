package io.eqoty.shared.viewmodel.screens.bottomnavigationbar

import io.eqoty.shared.viewmodel.ScreenState
import io.eqoty.shared.viewmodel.screens.leftnavigationrail.*

// here is the data class defining the state for this screen

data class BottomNavigationBarState(
    val homeTab: HomeTab = HomeTab(),
    val storeTab: StoreTab = StoreTab(),
    val libraryTab: LibraryTab = SongsTab(),
    val releaserTab: ReleaserTab = ReleaserProfileTab(),
    val selectedTab: NavigationTab = homeTab,
) : ScreenState
