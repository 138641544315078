package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class NftMetadataTableQueries(
  driver: SqlDriver,
  private val NftMetadataTableAdapter: NftMetadataTable.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getById(id: String, mapper: (
    id: String,
    name: String,
    description: String,
    image: String,
    animationUrl: String,
    privateAudioDuration: Long,
    publicAudioDuration: Long,
    artists: List<String>,
    album: String,
  ) -> T): Query<T> = GetByIdQuery(id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getLong(5)!!,
      cursor.getLong(6)!!,
      NftMetadataTableAdapter.artistsAdapter.decode(cursor.getString(7)!!),
      cursor.getString(8)!!
    )
  }

  public fun getById(id: String): Query<NftMetadataTable> = getById(id) { id_, name, description,
      image, animationUrl, privateAudioDuration, publicAudioDuration, artists, album ->
    NftMetadataTable(
      id_,
      name,
      description,
      image,
      animationUrl,
      privateAudioDuration,
      publicAudioDuration,
      artists,
      album
    )
  }

  public fun <T : Any> getAll(mapper: (
    id: String,
    name: String,
    description: String,
    image: String,
    animationUrl: String,
    privateAudioDuration: Long,
    publicAudioDuration: Long,
    artists: List<String>,
    album: String,
  ) -> T): Query<T> = Query(295_391_810, arrayOf("NftMetadataTable"), driver, "NftMetadataTable.sq",
      "getAll", "SELECT * FROM NftMetadataTable") { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getLong(5)!!,
      cursor.getLong(6)!!,
      NftMetadataTableAdapter.artistsAdapter.decode(cursor.getString(7)!!),
      cursor.getString(8)!!
    )
  }

  public fun getAll(): Query<NftMetadataTable> = getAll { id, name, description, image,
      animationUrl, privateAudioDuration, publicAudioDuration, artists, album ->
    NftMetadataTable(
      id,
      name,
      description,
      image,
      animationUrl,
      privateAudioDuration,
      publicAudioDuration,
      artists,
      album
    )
  }

  public suspend fun deleteById(id: String) {
    driver.execute(-1_256_002_444, """DELETE FROM NftMetadataTable WHERE id = ?""", 1) {
          bindString(0, id)
        }.await()
    notifyQueries(-1_256_002_444) { emit ->
      emit("NftMetadataTable")
    }
  }

  public suspend fun deleteTable() {
    driver.execute(-265_437_332, """DELETE FROM NftMetadataTable""", 0).await()
    notifyQueries(-265_437_332) { emit ->
      emit("NftMetadataTable")
    }
  }

  public suspend fun upsert(
    id: String,
    name: String,
    description: String,
    image: String,
    animationUrl: String,
    privateAudioDuration: Long,
    publicAudioDuration: Long,
    artists: List<String>,
    album: String,
  ) {
    transaction {
      driver.execute(210_641_719, """
          |INSERT OR REPLACE INTO NftMetadataTable(id,name,description,image,animationUrl,privateAudioDuration,publicAudioDuration,artists,album)
          |    VALUES(?, ?, ?, ?, ?, ?,?,?,?)
          """.trimMargin(), 9) {
            bindString(0, id)
            bindString(1, name)
            bindString(2, description)
            bindString(3, image)
            bindString(4, animationUrl)
            bindLong(5, privateAudioDuration)
            bindLong(6, publicAudioDuration)
            bindString(7, NftMetadataTableAdapter.artistsAdapter.encode(artists))
            bindString(8, album)
          }.await()
    }
    notifyQueries(706_363_654) { emit ->
      emit("NftMetadataTable")
    }
  }

  private inner class GetByIdQuery<out T : Any>(
    public val id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("NftMetadataTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("NftMetadataTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(567_252_817, """SELECT * FROM NftMetadataTable  WHERE id = ?""", mapper,
        1) {
      bindString(0, id)
    }

    override fun toString(): String = "NftMetadataTable.sq:getById"
  }
}
