package io.eqoty.shared.viewmodel.screens.scaffold

import io.eqoty.shared.viewmodel.ScreenState

// here is the data class defining the state for this screen

data class ScaffoldScreenState(
    val walletScreenExpanded: Boolean = false,
    var smallScaffoldActive: Boolean = false,
) : ScreenState


/********** property classes **********/
