package io.eqoty.shared.datalayer.utils

import com.ionspin.kotlin.bignum.integer.toBigInteger
import kotlin.jvm.JvmName

val String.bi16 get() = this.split('x', ignoreCase = true).last().toBigInteger(16)
val String.bi10 get() = this.toBigInteger()
val Number.bi10 get() = toString().toBigInteger()
val Number.bi16 get() = toString().toBigInteger(16)

@get:JvmName("bi10Nullable")
val Number?.bi10 get() = this?.bi10

@get:JvmName("bi16Nullable")
val Number?.bi16 get() = this?.bi16

val Int.bi get() = this.toBigInteger()