package io.eqoty.shared.viewmodel.utils

import com.ionspin.kotlin.bignum.decimal.BigDecimal
import com.ionspin.kotlin.bignum.decimal.toBigDecimal
import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.cosmwasm.std.types.Coin
import io.eqoty.shared.datalayer.sources.web3.Web3Network
import io.eqoty.shared.datalayer.utils.bi10
import kotlin.math.pow
import kotlin.math.roundToInt


fun Coin.baseUnitToStdUnit(numFractionDigits: Int? = null): Double {
    return when (denom) {
        "wei" -> amount.bi10.weiToEth(numFractionDigits)
        "uscrt" -> amount.bi10.uscrtToScrt(numFractionDigits)
        else -> throw Error("baseUnitToStdDenom called on $this, ${this.denom} is not handled")
    }
}

val Coin.stdUnitDenom: String
    get() = when (denom) {
        "wei" -> Web3Network.EthereumMainnet.currency
        "uscrt" -> Web3Network.SecretMainnet.currency
        else -> throw Error("stdUnitDenom called on $this, ${this.denom} is not handled")
    }


private fun BigInteger.baseUnitToStdUnit(numFractionDigits: Int? = null, baseToStdExponent: Long): Double {
    val exponent = numberOfDecimalDigits() - (baseToStdExponent + 1)
    var stdUnit = BigDecimal.fromBigIntegerWithExponent(this, exponent)
        .doubleValue(false)
    if (numFractionDigits != null) {
        val factor = 10.0.pow(numFractionDigits)
        stdUnit = (stdUnit * factor).roundToInt() / factor
    }
    return stdUnit
}

private enum class DenomBaseUnit(val baseToStdExp: Long) {
    Uscrt(6),
    Wei(18)
}

fun BigInteger.uscrtToScrt(numFractionDigits: Int? = null): Double =
    baseUnitToStdUnit(numFractionDigits, DenomBaseUnit.Uscrt.baseToStdExp)

fun BigInteger.weiToEth(numFractionDigits: Int? = null): Double =
    baseUnitToStdUnit(numFractionDigits, DenomBaseUnit.Wei.baseToStdExp)

fun BigDecimal.ethToWei(): BigInteger {
    val eth = this
    val factor = 1.toBigDecimal(DenomBaseUnit.Wei.baseToStdExp)
    val wei = eth * factor
    return wei.toBigInteger()
}

fun String.ethToWei(): BigInteger {
    return toBigDecimal(DenomBaseUnit.Wei.baseToStdExp).toBigInteger()
}

fun String.scrtToUscrt(): BigInteger {
    return toBigDecimal(DenomBaseUnit.Uscrt.baseToStdExp).toBigInteger()
}
