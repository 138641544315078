package io.eqoty.shared.viewmodel.screens.organizationprofile

import co.touchlab.kermit.Logger
import io.eqoty.shared.datalayer.functions.*
import io.eqoty.shared.datalayer.objects.FormInputImageFile
import io.eqoty.shared.datalayer.objects.FormInputImageUrl
import io.eqoty.shared.datalayer.sources.DataSrc
import io.eqoty.shared.datalayer.sources.REMOTE
import io.eqoty.shared.datalayer.sources.web3.ExternalWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.InternalWeb3Provider
import io.eqoty.shared.datalayer.sources.webservices.ipfsProtocol
import io.eqoty.shared.devicelayer.functions.launchFileChooser
import io.eqoty.shared.viewmodel.Events
import io.eqoty.shared.viewmodel.StateManager
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.mintsong.MimeTypes
import io.eqoty.shared.viewmodel.screens.scaffold.setWalletScreenExpanded
import io.eqoty.shared.viewmodel.screens.topbar.updateWalletBtnInfo
import io.eqoty.shared.viewmodel.screens.wallettxrequest.internalWalletTxRequestResultSharedFlow
import io.eqoty.shared.viewmodel.screens.wallettxrequest.setInternalWalletTxRequest
import korlibs.image.bitmap.Bitmap
import korlibs.image.bitmap.extract
import korlibs.image.bitmap.resized
import korlibs.image.format.PNG
import korlibs.image.format.encode
import korlibs.image.format.readBitmapNative
import korlibs.io.file.std.VfsFileFromData
import korlibs.math.geom.Anchor
import korlibs.math.geom.ScaleMode
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.launch

/********** EVENT functions, called directly by the UI layer **********/


fun Events.refreshOrganizationProfileInfoOnWalletChanges(organizationId: ULong) =
    inScreenScopeLaunchInBackground(ScreenStack.Main) {
        dataRepository.walletAddressChangeFlow.collect { newWalletAddress ->
            Logger.d("refreshOrganizationProfileInfoOnWalletChanges: onCollect:$newWalletAddress")
            displayOrganizationProfileInfo(organizationId, REMOTE)
        }
    }

private var displayOrganizationInfoJob: Job? = null

fun Events.displayOrganizationProfileInfo(organizationId: ULong, dataSrc: DataSrc) {
    // prevent multiple instances of refresh from being executed
    if (displayOrganizationInfoJob?.isActive == true) {
        return
    }
    displayOrganizationInfoJob = inScreenScopeLaunchInBackground(ScreenStack.Main) {
        val currentScreenScope = this
        if (!dataRepository.getWalletConnected()) {
            stateManager.updateScreen(ScreenStack.Main, OrganizationProfileScreenState::class) {
                it.copy(
                    isLoading = false,
                    organization = null,
                    organizationReleaseListItems = emptyList(),
                )
            }
            return@inScreenScopeLaunchInBackground
        }
        stateManager.updateScreen(ScreenStack.Main, OrganizationProfileScreenState::class) {
            it.copy(
                isLoading = true,
                organization = null,
                organizationId = organizationId,
            )
        }
        val organization = dataRepository.getOrganizationBatch(listOf(organizationId)).first()
        stateManager.updateScreen(ScreenStack.Main, OrganizationProfileScreenState::class) {
            it.copy(
                organization = organization, isLoading = false,
            )
        }
        val organizationProfileMetadata = organization.profileUri?.let {
            dataRepository.getOrganizationProfileMetadata(it)
        }
        stateManager.updateScreen(ScreenStack.Main, OrganizationProfileScreenState::class) {
            it.copy(
                organizationProfileMetadata = organizationProfileMetadata,
            )
        }
        val releaseListResults = dataRepository.getReleasesByOrganization(organizationId, currentScreenScope, dataSrc)
        releaseListResults.collect { releaseList ->
            val organizationReleaseListItems =
                releaseList.map { r -> OrganizationReleaseListItem(r, null) }.toMutableList()
            stateManager.updateScreen(ScreenStack.Main, OrganizationProfileScreenState::class) {
                it.copy(
                    organizationReleaseListItems = ArrayList(organizationReleaseListItems),
                )
            }
            // concurrently get all prices
            coroutineScope {
                releaseList.forEachIndexed { i, release ->
                    launch(Dispatchers.Default) {
                        val prices = dataRepository.getPurchasePrice(release.id).first()
                        organizationReleaseListItems[i] = OrganizationReleaseListItem(release, prices)
                        stateManager.updateScreen(ScreenStack.Main, OrganizationProfileScreenState::class) {
                            // copy the lists so that ScreenStates to make sure the state hash changes
                            // and the StateFlow emits the new state
                            it.copy(
                                organizationReleaseListItems = ArrayList(organizationReleaseListItems),
                            )
                        }
                    }
                }
            }
        }
        val wallet = dataRepository.getWalletDisplayAddress()!!
        val isMember = dataRepository.getOrganizationMemberships(wallet).contains(organizationId)
        stateManager.updateScreen(ScreenStack.Main, OrganizationProfileScreenState::class) {
            it.copy(
                editProfileAllowed = isMember,
            )
        }

        // update state, after retrieving data from the repository
        stateManager.updateScreen(ScreenStack.Main, OrganizationProfileScreenState::class) {
            it.copy(
                isLoading = false,
            )
        }
        return@inScreenScopeLaunchInBackground
    }
}

fun Events.chooseBannerImageFile() = inScreenScopeLaunch(ScreenStack.Main) {
    val fileList = try {
        launchFileChooser(MimeTypes.image, saveMode = false, saveFileName = null)
    } catch (t: Throwable) {
        t.printStackTrace()
        emptyList()
    }
    if (fileList.isNotEmpty()) {
        stateManager.updateScreen(ScreenStack.Main, OrganizationProfileScreenState::class) {
            it.copy(
                organizationBannerImgFile = fileList[0]
            )
        }
    }
}

fun Events.showEditProfilePopup(show: Boolean) = inScreenScopeLaunch(ScreenStack.Main) {
    stateManager.updateScreen(ScreenStack.Main, OrganizationProfileScreenState::class) {
        it.copy(
            showEditProfilePopup = show
        )
    }
}

fun Events.validateEditOrganizationProfileForm(
    formInput: EditOrganizationProfileFormInput, onSuccess: (formInput: EditOrganizationProfileFormInput) -> Unit
) = inScreenScopeLaunch(ScreenStack.Main) {
    stateManager.updateScreen(ScreenStack.Main, OrganizationProfileScreenState::class) {
        it.copy(
            editProfileInteractionEnabled = false, editProfileTxErrorMessage = null
        )
    }
    val validationResult = formInput.validate()
    if (!validationResult.hasNoErrors()) {
        stateManager.updateScreen(ScreenStack.Main, OrganizationProfileScreenState::class) {
            it.copy(
                editProfileValidationResult = validationResult,
                editProfileInteractionEnabled = true
            )
        }
    } else {
        stateManager.updateScreen(ScreenStack.Main, OrganizationProfileScreenState::class) {
            it.copy(
                editProfileValidationResult = validationResult,
                showEditProfilePopup = false,
            )
        }
        onSuccess(formInput)
    }
}


fun Events.setOrganizationProfileInfo(
    organizationId: ULong,
    organizationName: String,
    validatedForm: EditOrganizationProfileFormInput,
) = inScreenScopeLaunch(ScreenStack.Main) {
    stateManager.updateScreen(ScreenStack.Main, OrganizationProfileScreenState::class) {
        it.copy(
            editProfileIsLoading = true
        )
    }

    val bannerImageUrl = when (val organizationBannerImage = validatedForm.organizationBannerImage) {
        is FormInputImageUrl -> {
            organizationBannerImage.url
        }

        is FormInputImageFile -> {
            var organizationBannerBytes = organizationBannerImage.file.getBytes()
            val bitmap = VfsFileFromData(organizationBannerBytes).readBitmapNative()
            // resize image if needed
            if (bitmap.width > 2660 || bitmap.height > 1140) {
                organizationBannerBytes = centerCropResize(bitmap, 2660, 1140)
            }
            try {
                val putResponse = dataRepository.webservices.filebaseS3Put(
                    "organization/$organizationId/profile-banner.png",
                    organizationBannerBytes,
                )
                val addedHash = putResponse.headers.entries().first { it.key == "x-amz-meta-cid" }.value.first()
                ipfsProtocol + addedHash
            } catch (t: Throwable) {
                setEditOrganizationProfileScreenError(stateManager, "Banner image upload failed: ${t.message}")
                return@inScreenScopeLaunch
            }
        }
    }

    val profileMetadataUri = try {
        dataRepository.createAndAddOrganizationProfileMetadataToIpfs(
            organizationId,
            organizationName,
            bannerImageUrl,
            validatedForm,
        )
    } catch (t: Throwable) {
        setEditOrganizationProfileScreenError(stateManager, "Failed to save profile info: ${t.message}")
        return@inScreenScopeLaunch
    }
    val txHash = when (val web3Provider = dataRepository.web3Provider) {
        is InternalWeb3Provider<*> -> {
            try {
                setWalletScreenExpanded(true)?.join()
                dataRepository.setOrganizationProfileUri(organizationId, profileMetadataUri) { txRequestInfo ->
                    setInternalWalletTxRequest(txRequestInfo)
                    val requestResult = internalWalletTxRequestResultSharedFlow.first()
                    require(txRequestInfo == requestResult.txRequestInfo)
                    requestResult.result
                }
            } catch (t: Throwable) {
                t.printStackTrace()
                setEditOrganizationProfileScreenError(stateManager, t.message ?: "SetOrganizationProfileUri tx failed")
                null
            } finally {
                setWalletScreenExpanded(false)?.join()
            }
        }

        is ExternalWeb3Provider -> {
            try {
                dataRepository.setOrganizationProfileUri(organizationId, profileMetadataUri, null)
            } catch (t: Throwable) {
                setEditOrganizationProfileScreenError(stateManager, t.message ?: "SetOrganizationProfileUri tx failed")
                null
            }
        }

        else -> throw NotImplementedError("web3Provider: $web3Provider not handled")
    }

    stateManager.updateScreen(ScreenStack.Main, OrganizationProfileScreenState::class) {
        it.copy(
            editProfileIsLoading = false, editProfileInteractionEnabled = true
        )
    }
    updateWalletBtnInfo()?.join()
    displayOrganizationProfileInfo(organizationId, REMOTE)
}


private fun setEditOrganizationProfileScreenError(stateManager: StateManager, errorMessage: String) {
    stateManager.updateScreen(ScreenStack.Main, OrganizationProfileScreenState::class) {
        it.copy(
            editProfileTxErrorMessage = errorMessage, editProfileInteractionEnabled = true
        )
    }
}

private suspend fun centerCropResize(bitmap: Bitmap, targetWidth: Int, targetHeight: Int): ByteArray {
    val origWidth = bitmap.width
    val origHeight = bitmap.height

    val resultBitmap = if (origWidth.toFloat() / origHeight == targetWidth.toFloat() / targetHeight) {
        bitmap.resized(targetWidth, targetHeight, ScaleMode.COVER, Anchor.CENTER, native = true)
    } else {
        val x = (origWidth - targetWidth) / 2
        val y = (origHeight - targetHeight) / 2
        val centerCroppedBitmap = bitmap.extract(x, y, targetWidth, targetHeight)
        centerCroppedBitmap.resized(targetWidth, targetHeight, ScaleMode.COVER, Anchor.CENTER, native = true)
    }
    return resultBitmap.encode(PNG)
}
