package io.eqoty.shared.viewmodel.screens.royaltynftdetail

import io.eqoty.shared.datalayer.objects.Release
import io.eqoty.shared.datalayer.objects.RoyaltyNft
import io.eqoty.shared.viewmodel.ScreenState

// here is the data class defining the state for this screen

data class RoyaltyNftDetailState(
    val isLoading: Boolean = false,
    val royaltyNftDetailDisplayFormatted: RoyaltyNftDetailDisplayFormatted? = null,
    val playing: Boolean = false
) : ScreenState


/********** property classes **********/

data class RoyaltyNftDetailDisplayFormatted(
    private val release: Release,
    private val royaltyNft: RoyaltyNft,
) {
    // in the ViewModel classes, our computed properties only do UI-formatting operations
    // (the arithmetical operations, such as calculating a percentage, should happen in the DataLayer classes)
    val releaseId = release.id
    val id = royaltyNft.id
    val name = release.nftMetadata.name
    val description = release.nftMetadata.description
    val animationUrl = release.nftMetadata.animationUrl
//
}
