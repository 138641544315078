package io.eqoty.shared.viewmodel.screens.generic

import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.objects.AttachmentAccess
import io.eqoty.shared.datalayer.objects.NftMetadata
import io.eqoty.shared.datalayer.objects.PurchaseNft
import io.eqoty.shared.datalayer.objects.Release
import io.eqoty.shared.datalayer.sources.webservices.ResourceDownloadInfo
import io.eqoty.shared.datalayer.sources.webservices.ipfsBaseUrl
import io.eqoty.shared.datalayer.sources.webservices.ipfsProtocol
import io.eqoty.shared.viewmodel.utils.roundToDecimals
import kotlin.time.Duration.Companion.milliseconds

data class TrackRowData(
    val release: Release,
    private val purchase: PurchaseNft?,
    val selectedAudioTrack: AttachmentAccess? = null,
) {
    private val purchaseId: BigInteger? = purchase?.id
    val metadata = purchase?.nftMetadata ?: release.nftMetadata
    val releaseId = release.id
    val name = when (selectedAudioTrack) {
        AttachmentAccess.PUBLIC -> "${metadata.name} (Preview)"
        else -> metadata.name
    }
    val artists = metadata.artists
    val primaryArtist: String
        get() = artists[0]
    val artistsDisplay = metadata.artists.joinToString(", ")
    val album = metadata.album
    val description = metadata.description

    val publicAudioDuration = metadata.publicAudioDuration
    val publicAudioDurationFormatted = publicAudioDuration.milliseconds
        .toComponents { minutes, seconds, _ ->
            "$minutes:${seconds.toString().padStart(2, '0')}"
        }

    val privateAudioDuration = metadata.privateAudioDuration
    val privateAudioDurationFormatted =
        privateAudioDuration.milliseconds.toComponents { minutes, seconds, _ ->
            "$minutes:${seconds.toString().padStart(2, '0')}"
        }
    val privateAudioDownloadInfo = metadata.toAudioDownloadInfoList(releaseId, purchaseId)

    val trackArtUrl = ipfsBaseUrl + metadata.image.removePrefix(ipfsProtocol)
}


fun NftMetadata.toAudioDownloadInfoList(releaseId: UInt, purchaseId: BigInteger?) = privateAudioResources
    .sortedByDescending { it.bitRate }
    .sortedBy { it.container }
    .sortedByDescending { it.lossless }
    .map {
        val rootFileName = "$album - ${artists[0]} - $name"
        val fileName = rootFileName + it.audioContainer.fileExtension
        val sizeMb = (it.size.toDouble() / 1_000_000.0).roundToDecimals(2).toString()
        ResourceDownloadInfo(
            displayText = "${it.container} - ${it.codec} - ${
                if (it.lossless) {
                    "Lossless ${it.bitDepth}bit"
                } else {
                    "${it.bitRate / 1000}kbps"
                }
            }  ($sizeMb MB)",
            url = it.url,
            fileName = fileName,
            sizeMb = sizeMb,
            releaseId = releaseId,
            purchaseId = purchaseId,
        )
    }