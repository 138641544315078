package io.eqoty.shared.datalayer.functions

import co.touchlab.kermit.Logger
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.OrganizationProfileMetadata
import io.eqoty.shared.datalayer.sources.webservices.filebaseIpfsBaseUrl
import io.eqoty.shared.datalayer.sources.webservices.ipfsProtocol


suspend fun Repository.getOrganizationProfileMetadata(uri: String): OrganizationProfileMetadata? = withRepoContext {
    return@withRepoContext try {
        webservices.getProtobufResponse(filebaseIpfsBaseUrl, uri.removePrefix(ipfsProtocol))
    } catch (t: Throwable) {
        t.printStackTrace()
        Logger.d("Failed to get OrganizationProfileMetadata from: $uri")
        null
    }
}
