package io.eqoty.composables.screens.organizationprofile

import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.Button
import dev.icerock.moko.resources.compose.stringResource
import io.eqoty.composables.views.TrackListGroupItem
import io.eqoty.shared.MR
import io.eqoty.shared.viewmodel.screens.generic.TrackRowData
import io.eqoty.shared.viewmodel.screens.organizationprofile.OrganizationReleaseListItem
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.whiteSpace
import org.jetbrains.compose.web.dom.DOMScope
import org.w3c.dom.HTMLUListElement

@Composable
fun DOMScope<HTMLUListElement>.OrganizationProfileReleasesListRow(
    item: OrganizationReleaseListItem,
    onClickPreviewPlay: (UInt) -> Unit,
    onPurchase: (UInt) -> Unit,
    onClickOrganization: (TrackRowData) -> Unit,
) {
    TrackListGroupItem(
        item.trackRowData,
        onClickPreviewPlay,
        onClickOrganization,
    ) {
        when (item.price) {
            null -> {
                Button(stringResource(MR.strings.loading), outlined = true, disabled = true, attrs = {
                    style {
                        height(40.px)
                        whiteSpace("nowrap")
                    }
                }) {}
            }

            else -> {
                Button("${item.price} ${item.denom}", outlined = true, attrs = {
                    style {
                        height(40.px)
                        whiteSpace("nowrap")
                    }
                    onClick { event ->
                        event.stopPropagation()
                        onPurchase(item.trackRowData.releaseId)
                    }
                }) {}
            }
        }
    }
}
