package io.eqoty.shared.datalayer.functions

import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.AudioContainer
import io.eqoty.shared.datalayer.objects.AudioInfo
import io.eqoty.shared.datalayer.sources.filesystem.CommonFile
import jslib.musicmetadata.parseBlob
import kotlinx.coroutines.await
import kotlin.math.roundToInt

actual suspend fun Repository.getAudioInfo(file: CommonFile): AudioInfo = withRepoContext {
    val mediaInfo = parseBlob(file.file).await()
    // reference https://www.headphonesty.com/2019/07/sample-rate-bit-depth-bit-rate/
    return@withRepoContext AudioInfo(
        container = AudioContainer.valueOf(container = mediaInfo.format.container!!),
        codec = mediaInfo.format.codec!!,
        bitDepth = mediaInfo.format.bitsPerSample?.toInt(),//if (mediaInfo.format.bitsPerSample == undefined) null else mediaInfo.format.bitsPerSample?.toInt(),
        bitRate = mediaInfo.format.bitrate!!.toInt(),
        sampleRate = mediaInfo.format.sampleRate?.toInt()!!,
        channels = mediaInfo.format.numberOfChannels?.toInt()!!,
        lossless = mediaInfo.format.lossless ?: false,
        duration = (mediaInfo.format.duration!!.toDouble() * 1000.0).roundToInt(),
        size = file.size
    )
}