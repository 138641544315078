package io.eqoty.shared.devicelayer.functions

import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.sources.webservices.ResourceDownloadInfo
import kotlinx.browser.document
import org.w3c.dom.url.URL
import org.w3c.files.Blob

actual suspend fun Repository.launchSaveAsToFile(
    bytes: ByteArray,
    resourceDownloadInfo: ResourceDownloadInfo
) = withRepoContext {
    val blob = Blob(arrayOf(bytes))
    val blobUrl = URL.createObjectURL(blob)
    val a: dynamic = document.createElement("a")
    a.download = resourceDownloadInfo.fileName
    a.href = blobUrl
    document.body?.appendChild(a)
    a.click()
    a.remove()
    return@withRepoContext
}