package io.eqoty.shared.viewmodel.screens.store

import io.eqoty.cosmwasm.std.types.Coin
import io.eqoty.shared.datalayer.objects.Release
import io.eqoty.shared.viewmodel.ScreenState
import io.eqoty.shared.viewmodel.screens.generic.TrackRowData
import io.eqoty.shared.viewmodel.utils.baseUnitToStdUnit
import io.eqoty.shared.viewmodel.utils.stdUnitDenom

// here is the data class defining the state for this screen

data class StoreState(
    val isLoading: Boolean = false,
    val listType: StoreType,
    val storeListItems: List<StoreListItem> = emptyList(),
) : ScreenState

/********** property classes **********/

enum class StoreType { ALL, OWNED }

data class StoreListItem(
    private val _release: Release,
    private var _price: Coin?
) {
    // in the ViewModel classes, our computed properties only do UI-formatting operations
    // (the arithmetical operations, such as calculating a percentage, should happen in the DataLayer classes)
    val trackRowData = TrackRowData(_release, null)
    val price = _price?.baseUnitToStdUnit(4)
    val denom = _price?.stdUnitDenom
}