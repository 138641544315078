package io.eqoty.shared.viewmodel.screens.walletInfo

import co.touchlab.kermit.Logger
import io.eqoty.shared.datalayer.functions.getWalletConnected
import io.eqoty.shared.datalayer.sources.web3.InternalWeb3Provider
import io.eqoty.shared.viewmodel.StateManager
import io.eqoty.shared.viewmodel.screens.CallOnInitValues
import io.eqoty.shared.viewmodel.screens.ScreenInitSettings
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.wallettxrequest.listenForInternalWalletTxRequestsAndLaunchRequestScreen

// INIZIALIZATION settings for this screen
// this is what should be implemented:
// - a data class implementing the ScreenParams interface, which defines the parameters to the passed to the screen
// - Navigation extension function taking the ScreenParams class as an argument, return the ScreenInitSettings for this screen
// to understand the initialization behaviour, read the comments in the ScreenInitSettings.kt file


fun StateManager.initWalletInfo() = ScreenInitSettings(
    title = "Wallet Info",
    initState = { WalletInfoScreenState() },
    callOnInit = {
        // update state, after retrieving data from the repository
        val walletConnected = dataRepository.getWalletConnected()
        if (walletConnected) {
            if (dataRepository.web3Provider is InternalWeb3Provider<*>) {
                events.listenForInternalWalletTxRequestsAndLaunchRequestScreen()
            }
            events.walletInfoAndCollapseWalletOnSuccess()
        } else {
            Logger.w("Wallet Info Screen launched. But wallet not connected")
        }
        updateScreen(ScreenStack.Wallet, WalletInfoScreenState::class) {
            it.copy(
                releaserMode = dataRepository.localSettings.releaserMode,
            )
        }
    },
    callOnInitAtEachNavigation = CallOnInitValues.CALL_AFTER_SHOWING_SCREEN
)