package io.eqoty.composables.screens.scaffold

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.SaveableStateHolder
import app.softwork.bootstrapcompose.Color
import app.softwork.bootstrapcompose.Column
import app.softwork.bootstrapcompose.Container
import app.softwork.bootstrapcompose.Row
import io.eqoty.composables.navigation.ScreenPicker
import io.eqoty.composables.navigation.bars.SidebarColumn
import io.eqoty.composables.styling.BackgroundDarker
import io.eqoty.composables.styling.BackgroundDarkest
import io.eqoty.composables.styling.ColumnSize
import io.eqoty.composables.styling.ColumnSizes
import io.eqoty.shared.viewmodel.Navigation
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.scaffold.ScaffoldScreenState
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.maxHeight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Main

@Composable
fun Navigation.LargeScaffold(
    state: ScaffoldScreenState,
    saveableStateHolder: SaveableStateHolder
) {
    val walletSidebarId = "walletSideBar"
    Container(
        fluid = true,
        attrs = { classes("h-100") }
    ) {
        Column(
            attrs = {
                classes("h-100", "d-flex", "flex-column")
            }
        ) {
            Row(
                attrs = { classes("flex-grow-1", "overflow-hidden") }
            ) {
                SidebarColumn(
                    "navSideBar",
                    sizes = remember { ColumnSizes(ColumnSize.All.auto()) },
                    applyStyle = {
                        backgroundColor(BackgroundDarker)
                    },
                    applyAttrs = {
                        classes("d-flex", "flex-column")
                    }
                ) {
                    screenStackToNavigationState[ScreenStack.LeftNavigationRail]!!.topScreenIdentifier.let { sId ->
                        saveableStateHolder.SaveableStateProvider(sId.URI) {
                            ScreenPicker(ScreenStack.LeftNavigationRail, sId)
                        }
                    }
                }

                Main(
                    attrs = {
                        style {
                            maxHeight("100%")
                        }
                        classes("col", "overflow-scroll")
                    }
                ) {
                    screenStackToNavigationState[ScreenStack.Header]!!.topScreenIdentifier.let { sId ->
                        saveableStateHolder.SaveableStateProvider(sId.URI) {
                            ScreenPicker(ScreenStack.Header, sId)
                        }
                    }


                    Row {
                        screenStackToNavigationState[ScreenStack.Main]!!.topScreenIdentifier.let { sId ->
                            saveableStateHolder.SaveableStateProvider(sId.URI) {
                                ScreenPicker(ScreenStack.Main, sId)
                            }
                        }
                    }
                }

                SidebarColumn(
                    walletSidebarId, state.walletScreenExpanded,
                    remember { ColumnSizes(ColumnSize.All.auto()) },
                    applyStyle = {
                        paddingTop(40.px)
                        backgroundColor(BackgroundDarkest)
                    },
                    applyAttrs = { classes("px-4") },
                ) {
                    screenStackToNavigationState[ScreenStack.Wallet]!!.topScreenIdentifier.let { sId ->
                        saveableStateHolder.SaveableStateProvider(sId.URI) {
                            ScreenPicker(ScreenStack.Wallet, sId)
                        }
                    }
                }
            }

            // Media Player
            Row(
                attrs = {
                    style {
                        property("box-shadow", "rgba(0, 0, 0, 1.0)0px 5px 15px")
                    }
                },
                styling = {
                    Background {
                        color = Color.Body
                    }
                }
            ) {
                screenStackToNavigationState[ScreenStack.MPlayer]!!.topScreenIdentifier.let { sId ->
                    saveableStateHolder.SaveableStateProvider(sId.URI) {
                        ScreenPicker(ScreenStack.MPlayer, sId)
                    }
                }
            }
        }
    }
}

