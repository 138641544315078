package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.UInt
import kotlin.ULong
import kotlin.collections.Collection

public class ReleaseTableQueries(
  driver: SqlDriver,
  private val ReleaseTableAdapter: ReleaseTable.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getRelease(id: UInt, mapper: (
    id: UInt,
    organizationId: ULong,
    listed: Boolean,
    productDealerAddress: String,
    productDealerCodeHash: String,
    royaltyDealerAddress: String,
    royaltyDealerCodeHash: String,
    nftMetadataId: String,
    privateAuthenticationKey: String?,
  ) -> T): Query<T> = GetReleaseQuery(id) { cursor ->
    mapper(
      ReleaseTableAdapter.idAdapter.decode(cursor.getLong(0)!!),
      ReleaseTableAdapter.organizationIdAdapter.decode(cursor.getLong(1)!!),
      cursor.getBoolean(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7)!!,
      cursor.getString(8)
    )
  }

  public fun getRelease(id: UInt): Query<ReleaseTable> = getRelease(id) { id_, organizationId,
      listed, productDealerAddress, productDealerCodeHash, royaltyDealerAddress,
      royaltyDealerCodeHash, nftMetadataId, privateAuthenticationKey ->
    ReleaseTable(
      id_,
      organizationId,
      listed,
      productDealerAddress,
      productDealerCodeHash,
      royaltyDealerAddress,
      royaltyDealerCodeHash,
      nftMetadataId,
      privateAuthenticationKey
    )
  }

  public fun releaseExists(id: UInt): Query<Boolean> = ReleaseExistsQuery(id) { cursor ->
    cursor.getBoolean(0)!!
  }

  public fun <T : Any> getReleaseList(mapper: (
    id: UInt,
    organizationId: ULong,
    listed: Boolean,
    productDealerAddress: String,
    productDealerCodeHash: String,
    royaltyDealerAddress: String,
    royaltyDealerCodeHash: String,
    nftMetadataId: String,
    privateAuthenticationKey: String?,
  ) -> T): Query<T> = Query(102_483_306, arrayOf("ReleaseTable"), driver, "ReleaseTable.sq",
      "getReleaseList", "SELECT * FROM ReleaseTable") { cursor ->
    mapper(
      ReleaseTableAdapter.idAdapter.decode(cursor.getLong(0)!!),
      ReleaseTableAdapter.organizationIdAdapter.decode(cursor.getLong(1)!!),
      cursor.getBoolean(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7)!!,
      cursor.getString(8)
    )
  }

  public fun getReleaseList(): Query<ReleaseTable> = getReleaseList { id, organizationId, listed,
      productDealerAddress, productDealerCodeHash, royaltyDealerAddress, royaltyDealerCodeHash,
      nftMetadataId, privateAuthenticationKey ->
    ReleaseTable(
      id,
      organizationId,
      listed,
      productDealerAddress,
      productDealerCodeHash,
      royaltyDealerAddress,
      royaltyDealerCodeHash,
      nftMetadataId,
      privateAuthenticationKey
    )
  }

  public fun countReleaseTable(): Query<Long> = Query(1_895_188_347, arrayOf("ReleaseTable"),
      driver, "ReleaseTable.sq", "countReleaseTable", "SELECT COUNT(*) FROM ReleaseTable") {
      cursor ->
    cursor.getLong(0)!!
  }

  public fun getReleaseWhereIdNot(id: Collection<UInt>): Query<UInt> =
      GetReleaseWhereIdNotQuery(id) { cursor ->
    ReleaseTableAdapter.idAdapter.decode(cursor.getLong(0)!!)
  }

  public fun <T : Any> getReleaseListByOrganization(organizationId: ULong, mapper: (
    id: UInt,
    organizationId: ULong,
    listed: Boolean,
    productDealerAddress: String,
    productDealerCodeHash: String,
    royaltyDealerAddress: String,
    royaltyDealerCodeHash: String,
    nftMetadataId: String,
    privateAuthenticationKey: String?,
  ) -> T): Query<T> = GetReleaseListByOrganizationQuery(organizationId) { cursor ->
    mapper(
      ReleaseTableAdapter.idAdapter.decode(cursor.getLong(0)!!),
      ReleaseTableAdapter.organizationIdAdapter.decode(cursor.getLong(1)!!),
      cursor.getBoolean(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7)!!,
      cursor.getString(8)
    )
  }

  public fun getReleaseListByOrganization(organizationId: ULong): Query<ReleaseTable> =
      getReleaseListByOrganization(organizationId) { id, organizationId_, listed,
      productDealerAddress, productDealerCodeHash, royaltyDealerAddress, royaltyDealerCodeHash,
      nftMetadataId, privateAuthenticationKey ->
    ReleaseTable(
      id,
      organizationId_,
      listed,
      productDealerAddress,
      productDealerCodeHash,
      royaltyDealerAddress,
      royaltyDealerCodeHash,
      nftMetadataId,
      privateAuthenticationKey
    )
  }

  public suspend fun deleteById(id: UInt) {
    driver.execute(-1_674_446_088, """DELETE FROM ReleaseTable WHERE id = ?""", 1) {
          bindLong(0, ReleaseTableAdapter.idAdapter.encode(id))
        }.await()
    notifyQueries(-1_674_446_088) { emit ->
      emit("ReleaseTable")
    }
  }

  public suspend fun deleteTable() {
    driver.execute(-352_288_408, """DELETE FROM ReleaseTable""", 0).await()
    notifyQueries(-352_288_408) { emit ->
      emit("ReleaseTable")
    }
  }

  public suspend fun upsertRelease(
    id: UInt,
    organizationId: ULong,
    listed: Boolean,
    productDealerAddress: String,
    productDealerCodeHash: String,
    royaltyDealerAddress: String,
    royaltyDealerCodeHash: String,
    privateAuthenticationKey: String?,
    nftMetadataId: String,
  ) {
    transaction {
      driver.execute(1_590_318_990, """
          |INSERT OR REPLACE INTO ReleaseTable(id, organizationId, listed, productDealerAddress, productDealerCodeHash, royaltyDealerAddress, royaltyDealerCodeHash, privateAuthenticationKey, nftMetadataId)
          |    VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?)
          """.trimMargin(), 9) {
            bindLong(0, ReleaseTableAdapter.idAdapter.encode(id))
            bindLong(1, ReleaseTableAdapter.organizationIdAdapter.encode(organizationId))
            bindBoolean(2, listed)
            bindString(3, productDealerAddress)
            bindString(4, productDealerCodeHash)
            bindString(5, royaltyDealerAddress)
            bindString(6, royaltyDealerCodeHash)
            bindString(7, privateAuthenticationKey)
            bindString(8, nftMetadataId)
          }.await()
    }
    notifyQueries(-172_646_627) { emit ->
      emit("ReleaseTable")
    }
  }

  private inner class GetReleaseQuery<out T : Any>(
    public val id: UInt,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ReleaseTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ReleaseTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-1_311_058_260, """SELECT * FROM ReleaseTable  WHERE id = ?""", mapper,
        1) {
      bindLong(0, ReleaseTableAdapter.idAdapter.encode(id))
    }

    override fun toString(): String = "ReleaseTable.sq:getRelease"
  }

  private inner class ReleaseExistsQuery<out T : Any>(
    public val id: UInt,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ReleaseTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ReleaseTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(973_308_552, """SELECT EXISTS(SELECT 1 FROM ReleaseTable WHERE id=?)""",
        mapper, 1) {
      bindLong(0, ReleaseTableAdapter.idAdapter.encode(id))
    }

    override fun toString(): String = "ReleaseTable.sq:releaseExists"
  }

  private inner class GetReleaseWhereIdNotQuery<out T : Any>(
    public val id: Collection<UInt>,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ReleaseTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ReleaseTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> {
      val idIndexes = createArguments(count = id.size)
      return driver.executeQuery(null, """SELECT id FROM ReleaseTable WHERE id NOT IN $idIndexes""",
          mapper, id.size) {
            id.forEachIndexed { index, id_ ->
              bindLong(index, ReleaseTableAdapter.idAdapter.encode(id_))
            }
          }
    }

    override fun toString(): String = "ReleaseTable.sq:getReleaseWhereIdNot"
  }

  private inner class GetReleaseListByOrganizationQuery<out T : Any>(
    public val organizationId: ULong,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ReleaseTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ReleaseTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(262_492_980, """SELECT * FROM ReleaseTable WHERE organizationId = ?""",
        mapper, 1) {
      bindLong(0, ReleaseTableAdapter.organizationIdAdapter.encode(organizationId))
    }

    override fun toString(): String = "ReleaseTable.sq:getReleaseListByOrganization"
  }
}
