package io.eqoty.composables.screens.myroyalties


import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.Column
import app.softwork.bootstrapcompose.ListGroup
import app.softwork.bootstrapcompose.Row
import com.ionspin.kotlin.bignum.integer.BigInteger
import dev.icerock.moko.resources.compose.stringResource
import io.eqoty.composables.screens.LoadingScreen
import io.eqoty.shared.MR
import io.eqoty.shared.viewmodel.screens.generic.TrackRowData
import io.eqoty.shared.viewmodel.screens.myroyalties.MyRoyaltiesState
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text


@Composable
fun MyRoyaltiesScreen(
    state: MyRoyaltiesState,
    onListItemClick: (UInt, BigInteger, String) -> Unit,
    onClickOrganization: (TrackRowData) -> Unit,
    onClaimBalanceClick: (UInt, BigInteger) -> Unit,
    onMergeRoyaltyTokensClick: (releaseId: UInt, tokenIds: List<BigInteger>, sharesPerToken: List<UInt>) -> Unit,
) {
    Column {
        if (!state.isConnected) {
            PlaceholderContentDisconnected()
        } else {

            Div(
                attrs = {
                    style {
                        display(DisplayStyle.Flex)
                        flexFlow(FlexDirection.Row, FlexWrap.Nowrap)
                        alignItems(AlignItems.Center)
                        fontSize(1.2.em)
                    }
                    classes("text-nowrap")
                }
            ) {
                Div(
                    attrs = {
                        style {
                            flexGrow(1)
                        }
                    }
                ) {
                    Text(stringResource(MR.strings.my_royalties))
                }
            }

            ListGroup {
                state.myRoyaltiesListItems.forEach { item ->
                    MyRoyaltiesListRow(
                        item = item,
                        listType = state.listType,
                        onItemClick = { royaltyId ->
                            onListItemClick(
                                item.id,
                                royaltyId,
                                item.trackRowData.name
                            )
                        },
                        onClaimBalanceClick = { royaltyId -> onClaimBalanceClick(item.id, royaltyId) },
                        onMergeRoyaltyTokensClick = {
                            onMergeRoyaltyTokensClick(
                                item.id,
                                item.royaltySubListItems.subList(0, 2).map { it.id },
                                listOf(item.royaltySubListItems.subList(0, 2).sumOf { it.tokenShares }),
                            )
                        },
                        onClickOrganization = onClickOrganization,
                    )
                }
            }

            Row {
                if (state.isLoading) {
                    LoadingScreen("Releases")
                } else if (state.myRoyaltiesListItems.isEmpty()) {
                    PlaceholderContentConnected()
                }
            }
        }
    }
}


@Composable
private fun PlaceholderContentDisconnected() {
    Div(
        attrs = {
            style {
                display(DisplayStyle.Flex)
                flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                alignItems(AlignItems.Center)
            }
            classes("p-3")
        }
    ) {
        val textSizeStyle: StyleScope.() -> Unit = {
            fontSize(1.cssRem)
        }
        Div(
            attrs = { style { textSizeStyle(); textAlign("center"); paddingBottom(20.px) } }
        ) {
            Text(
                stringResource(MR.strings.connect_wallet_my_releases)
            )
        }
        Div(
            attrs = { style { textSizeStyle(); textAlign("center"); fontSize(0.9.cssRem) } }
        ) {
            Text(stringResource(MR.strings.my_releases_placeholder_1))
        }
        Div(
            attrs = { style { textSizeStyle(); textAlign("center"); fontSize(0.9.cssRem) } }
        ) {
            Text(stringResource(MR.strings.my_releases_placeholder_2))
        }
    }
}

@Composable
private fun PlaceholderContentConnected() {
    Div(
        attrs = {
            style {
                display(DisplayStyle.Flex)
                flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                alignItems(AlignItems.Center)
            }
            classes("p-3")
        }
    ) {
        val textSizeStyle: StyleScope.() -> Unit = {
            fontSize(1.cssRem)
        }
        Div(
            attrs = { style { textSizeStyle(); paddingBottom(20.px) } }
        ) {
            Text(
                stringResource(MR.strings.nothing_here)
            )
        }
        Div(
            attrs = { style { textSizeStyle(); textAlign("center"); fontSize(0.9.cssRem) } }
        ) {
            Text(stringResource(MR.strings.my_releases_placeholder_1))
        }
        Div(
            attrs = { style { textSizeStyle(); textAlign("center"); fontSize(0.9.cssRem) } }
        ) {
            Text(stringResource(MR.strings.my_releases_placeholder_2))
        }
    }
}

