package io.eqoty.shared.viewmodel.screens.organizationregistrationinfo

import io.eqoty.secret.std.contract.msg.EqotyReleaseMsgs
import io.eqoty.shared.viewmodel.ScreenState

// here is the data class defining the state for this screen

data class OrganizationRegistrationInfoScreenState(
    val organizationId: ULong? = null,
    val organization: EqotyReleaseMsgs.Organization? = null,
    val txErrorMessage: String? = null,
    val txHash: String? = null,
    val validationResult: OrganizationRegistrationInfoFormInputErrors = OrganizationRegistrationInfoFormInputErrors(),
    val interactionEnabled: Boolean = true,
    val loading: Boolean = true,
    val shouldReinitializeFormInputData: Boolean = false,
) : ScreenState {
    val isRegisteredOrg: Boolean
        get() = organizationId != null

    val title: String
        get() = if (isRegisteredOrg) "Organization Profile" else "Register an Organization"
}
