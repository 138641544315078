package io.eqoty.composables.screens.store


import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.Column
import app.softwork.bootstrapcompose.ListGroup
import app.softwork.bootstrapcompose.Row
import dev.icerock.moko.resources.compose.stringResource
import io.eqoty.composables.screens.LoadingScreen
import io.eqoty.composables.views.TrackListHeaderRow
import io.eqoty.shared.MR
import io.eqoty.shared.viewmodel.screens.generic.TrackRowData
import io.eqoty.shared.viewmodel.screens.store.StoreState
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun StoreScreen(
    state: StoreState,
    onPurchase: (UInt) -> Unit,
    onClickPreviewPlay: (UInt) -> Unit,
    onClickOrganization: (TrackRowData) -> Unit,
) {
    Column {
        TrackListHeaderRow()
        Row {
            ListGroup(
                attrs = { classes("g-0") }
            ) {
                state.storeListItems.forEach { item ->
                    StoreListRow(
                        item = item,
                        listType = state.listType,
                        onClickPreviewPlay = onClickPreviewPlay,
                        onPurchase = onPurchase,
                        onClickOrganization = onClickOrganization,
                    )
                }
            }
        }
        Row {
            if (state.isLoading) {
                LoadingScreen("Store")
            } else if (state.storeListItems.isEmpty()) {
                PlaceholderContent()
            }
        }
    }
}

@Composable
private fun PlaceholderContent() {
    Div(
        attrs = {
            style {
                display(DisplayStyle.Flex)
                flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                alignItems(AlignItems.Center)
            }
            classes("p-3")
        }
    ) {
        val textSizeStyle: StyleScope.() -> Unit = {
            fontSize(1.cssRem)
            maxWidth(50.percent)
        }
        Div(
            attrs = { style { textSizeStyle(); paddingBottom(20.px) } }
        ) {
            Text(stringResource(MR.strings.connect_wallet))
        }
        Div(
            attrs = { style { textSizeStyle(); textAlign("center"); fontSize(0.9.cssRem) } }
        ) {
            Text(stringResource(MR.strings.wallet_recommendations))
        }
    }
}

