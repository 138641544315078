package io.eqoty.shared.datalayer.functions

import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.cosmwasm.std.types.Coin
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.sources.web3.DirectSigningSecretWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.JsKeplrWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.JsWeb3Provider
import io.eqoty.shared.datalayer.utils.bi16
import kotlinx.coroutines.await

actual suspend fun Repository.getWalletBalance(address: String): Coin = withRepoContext {
    return@withRepoContext when (val provider = web3Provider) {
        is JsWeb3Provider -> {
            Coin(provider.web3?.eth?.getBalance(address)?.await()?.bi16 ?: BigInteger.ZERO, "wei")
        }

        is JsKeplrWeb3Provider -> {
            getWeb3Network()?.let { getClient(it).getBalance(address).balances.firstOrNull() } ?: Coin("0", "uscrt")
        }

        is DirectSigningSecretWeb3Provider -> {
            getWeb3Network()?.let { getClient(it).getBalance(address).balances.firstOrNull() } ?: Coin("0", "uscrt")
        }

        else -> throw Error("Getting balance from unsupported Web3Provider: $provider")
    }
}