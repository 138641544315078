package io.eqoty.shared.datalayer.objects

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ReleaseMetaData(
    val name: String,
    val description: String,
    val image: String,
    @SerialName("animation_url") val animationUrl: String,
    val properties: ReleaseProperties
)

@Serializable
sealed class ReleaseProperties {
    abstract val attachments: List<AttachmentMetadata>
}

@Serializable
@SerialName("audio-release-properties")
class AudioReleaseProperties(
    override val attachments: List<AttachmentMetadata>,
    val artists: List<String>,
    val album: String
) : ReleaseProperties()

/***
 * Not used. But prepping for other kinds of releases besides music like video
 */
@Serializable
@SerialName("video-release-properties")
class VideoReleaseProperties(
    override val attachments: List<AttachmentMetadata>,
    // add video release properties
) : ReleaseProperties()

@Serializable
enum class AttachmentAccess {
    @SerialName("public")
    PUBLIC,

    @SerialName("private")
    PRIVATE
}


@Serializable
sealed class AttachmentMetadata {
    abstract val access: AttachmentAccess
    abstract val priority: Int
}

@Serializable
sealed class MediaAttachmentMetadata : AttachmentMetadata() {
    abstract val duration: Long
    abstract val formats: List<RemoteResource>
}

@Serializable
@SerialName("audio-attachment")
data class AudioAttachmentMetadata(
    override val access: AttachmentAccess,
    override val priority: Int,
    override val duration: Long,
    override val formats: List<RemoteAudioResource>
) : MediaAttachmentMetadata()

@Serializable
@SerialName("video-attachment")
data class VideoAttachmentMetadata(
    override val access: AttachmentAccess,
    override val priority: Int,
    override val duration: Long,
    override val formats: List<RemoteVideoResource>,
) : MediaAttachmentMetadata()

@Serializable
@SerialName("image-attachment")
data class ImageAttachmentMetadata(
    override val access: AttachmentAccess,
    override val priority: Int,
    val formats: List<RemoteImageResource>,
) : AttachmentMetadata()


@Serializable
sealed class RemoteResource {
    abstract val url: String
}

@Serializable
@SerialName("remote-audio-resource")
data class RemoteAudioResource(
    override val url: String,
    val size: Long,
    val container: String,
    val codec: String,
    val bitDepth: Int?,
    val bitRate: Int,
    val sampleRate: Int,
    val channels: Int,
    val lossless: Boolean,
) : RemoteResource() {
    val audioContainer = AudioContainer.valueOf(container = container)
}

@Serializable
@SerialName("remote-video-resource")
data class RemoteVideoResource(
    override val url: String,
    val size: Long,
    val container: String,
    val codec: String,
) : RemoteResource()

@Serializable
@SerialName("remote-image-resource")
data class RemoteImageResource(
    override val url: String,
    val size: Long,
    val container: String,
    val length: Int,
    val width: Int
) : RemoteResource()
