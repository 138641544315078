package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.SuspendingTransactionWithoutReturn
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import io.eqoty.shared.datalayer.objects.NftMetadata
import io.eqoty.shared.datalayer.objects.RemoteAudioResource
import mylocal.db.LocalDb

fun NftMetadataTable.toNftMetadata(
    privateAudioTracks: List<RemoteAudioResource>,
    publicAudioTracks: List<RemoteAudioResource>
) =
    NftMetadata(
        id = id,
        name = name,
        description = description,
        image = image,
        animationUrl = animationUrl,
        privateAudioResources = privateAudioTracks,
        privateAudioDuration = privateAudioDuration,
        publicAudioResources = publicAudioTracks,
        publicAudioDuration = publicAudioDuration,
        artists = artists,
        album = album
    )

suspend fun LocalDb.getNftMetadataForRelease(id: UInt, attachAudioResources: Boolean = true): NftMetadata? {
    return getById(getNftMetadataIdForReleaseId(id), attachAudioResources)
}

suspend fun LocalDb.getNftMetadataForPurchaseNft(
    id: String,
    attachAudioResources: Boolean = true
): NftMetadata? {
    return getById(id, attachAudioResources)
}

private suspend fun LocalDb.getById(id: String, attachAudioResources: Boolean = true): NftMetadata? {
    val privateAudioTracks = if (attachAudioResources) getPrivateAudioTracksById(id) else emptyList()
    val publicAudioTracks = if (attachAudioResources) getPublicAudioTracksById(id) else emptyList()
    return nftMetadataTableQueries.getById(id).awaitAsOneOrNull()
        ?.toNftMetadata(privateAudioTracks, publicAudioTracks)
}

suspend fun LocalDb.upsertNftMetadata(nftMetadata: NftMetadata) {
    nftMetadataTableQueries.transaction {
        upsertNftMetadata(nftMetadataTableQueries, nftMetadata)
    }
}

suspend fun LocalDb.upsertNftMetadataList(list: List<NftMetadata>) {
    nftMetadataTableQueries.transaction {
        list.forEach { m ->
            upsertNftMetadata(nftMetadataTableQueries, m)
        }
    }
}

private suspend fun SuspendingTransactionWithoutReturn.upsertNftMetadata(
    nftMetadataTableQueries: NftMetadataTableQueries,
    m: NftMetadata
) {
    nftMetadataTableQueries.upsert(
        id = m.id,
        name = m.name,
        description = m.description,
        image = m.image,
        animationUrl = m.animationUrl,
        privateAudioDuration = m.privateAudioDuration,
        publicAudioDuration = m.publicAudioDuration,
        artists = m.artists,
        album = m.album
    )
}

suspend fun LocalDb.deleteNftMetadataById(id: String) {
    return nftMetadataTableQueries.deleteById(id)
}

suspend fun LocalDb.deleteAllNftMetadata() {
    return nftMetadataTableQueries.deleteTable()
}

fun getNftMetadataIdForReleaseId(releaseId: UInt): String {
    return "rel:${releaseId}"
}