package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.ColumnAdapter
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public data class NftMetadataTable(
  public val id: String,
  public val name: String,
  public val description: String,
  public val image: String,
  public val animationUrl: String,
  public val privateAudioDuration: Long,
  public val publicAudioDuration: Long,
  public val artists: List<String>,
  public val album: String,
) {
  public class Adapter(
    public val artistsAdapter: ColumnAdapter<List<String>, String>,
  )
}
