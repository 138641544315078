package io.eqoty.shared.datalayer.objects

import io.eqoty.cosmwasm.std.types.Coin
import io.eqoty.cosmwasm.std.types.ContractInfo
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable


@Serializable
data class ProductDealerContract(
    val releaseId: UInt,
    val childSnip721: ContractInfo,
    @Contextual val prices: List<Coin>?,
)
