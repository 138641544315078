package io.eqoty.secret.std.contract.msg

import io.eqoty.cosmwasm.std.types.Coin
import io.eqoty.cosmwasm.std.types.ContractInfo
import io.eqoty.secret.std.contract.msg.shared.RoyaltyConfig
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

object EqotyProductDealerMsgs {
    @Serializable
    data class InstantiateConfig(
        val prices: List<Coin>,
        @SerialName("public_metadata")
        val publicMetadata: Snip721Msgs.Metadata? = null,
        @SerialName("private_metadata")
        val privateMetadata: Snip721Msgs.Metadata? = null,
        val entropy: String,
        @SerialName("royalty_config")
        val royaltyConfig: RoyaltyConfig? = null,
    )

    @Serializable
    data class Execute(
        @SerialName("purchase_mint") val purchaseMint: PurchaseMint? = null,
        @SerialName("migrate_tokens_in") val migrateTokensIn: MigrateCollectionIn? = null,
    ) {
        @Serializable
        class PurchaseMint

        @Serializable
        data class MigrateCollectionIn(
            @SerialName("page_size")
            val pageSize: UInt? = null,
        )
    }

    @Serializable
    data class Query(
        @SerialName("get_prices") val getPrices: GetPrices? = null,
        @SerialName("get_child_snip721") val getChildSnip721: GetChildSnip721? = null,
        @SerialName("migrated_from") val migratedFrom: MigratedFrom? = null,
        @SerialName("migrated_to") val migratedTo: MigratedTo? = null,
        @SerialName("release_info") val releaseInfo: ReleaseInfo? = null,
    ) {
        @Serializable
        class GetPrices

        @Serializable
        class MigratedFrom

        @Serializable
        class MigratedTo

        @Serializable
        class GetChildSnip721

        @Serializable
        class ReleaseInfo
    }

    @Serializable
    data class QueryAnswer(
        @SerialName("get_prices") val getPrices: GetPrices? = null,
        @SerialName("migration_info") val migrationInfo: ContractInfo? = null,
        @SerialName("contract_info") val contractInfo: ContractInfo? = null,
        @SerialName("release_info") val releaseInfo: ReleaseInfo? = null,
    ) {
        @Serializable
        data class GetPrices(val prices: List<Coin>)

        @Serializable
        data class ReleaseInfo(
            val contract: ContractInfo,
            @SerialName("release_id") val releaseId: UInt,
        )

    }

    @Serializable
    data class ExecuteAnswer(
        @SerialName("migrate_tokens_in") val migrateTokensIn: MigrateTokensIn? = null,
    ) {
        @Serializable
        data class MigrateTokensIn(
            val complete: Boolean,
            @SerialName("next_mint_index")
            val nextMintIndex: UInt?,
            val total: UInt?,
        )
    }

}