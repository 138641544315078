package io.eqoty.shared.datalayer.objects

import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.sources.localdb.nfts.getPurchaseNftIdForRelease
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable

@Serializable
data class PurchaseNft(
    @Contextual val id: BigInteger,
    val purchaseCtrkReleaseId: UInt,
    val nftMetadata: NftMetadata?
)

fun ReleaseMetaData?.toPurchaseNft(releaseId: UInt, purchaseId: BigInteger): PurchaseNft {
    val nftMetadata = this?.run {
        when (properties) {
            is AudioReleaseProperties -> {
                val audioMappedByVisibility =
                    properties.attachments.filterIsInstance<AudioAttachmentMetadata>().groupBy { it.access }
                val primaryPrivateAudio = audioMappedByVisibility[AttachmentAccess.PRIVATE]!!.first { it.priority == 0 }
                val primaryPublicAudio = audioMappedByVisibility[AttachmentAccess.PUBLIC]!!.first { it.priority == 0 }
                NftMetadata(
                    id = getPurchaseNftIdForRelease(releaseId, purchaseId),
                    name = name,
                    description = description,
                    image = image,
                    animationUrl = animationUrl,
                    privateAudioResources = primaryPrivateAudio.formats,
                    privateAudioDuration = primaryPrivateAudio.duration,
                    publicAudioResources = primaryPublicAudio.formats,
                    publicAudioDuration = primaryPublicAudio.duration,
                    artists = properties.artists,
                    album = properties.album
                )
            }

            else -> TODO("Only audio-release-properties supported")
        }
    }
    return PurchaseNft(
        id = purchaseId,
        purchaseCtrkReleaseId = releaseId,
        nftMetadata
    )
}


