package io.eqoty.shared.viewmodel.screens

import io.eqoty.shared.datalayer.sources.web3.WalletProvider
import io.eqoty.shared.viewmodel.ScreenIdentifier
import io.eqoty.shared.viewmodel.screens.Screen.*
import io.eqoty.shared.viewmodel.screens.myroyalties.MyRoyaltiesType
import io.eqoty.shared.viewmodel.screens.store.StoreType

// CONFIGURATION SETTINGS
object navigationSettings {
    fun screenStackDefaultStartScreen(stack: ScreenStack) =
        when (stack) {
            ScreenStack.Scaffold -> Level1Navigation.ScaffoldL1NavDefaults
            ScreenStack.Header -> Level1Navigation.TopBarProvider
            ScreenStack.BottomNavigationBar -> Level1Navigation.BottomNavigationBarL1Nav
            ScreenStack.LeftNavigationRail -> Level1Navigation.LeftNavigationRailProvider
            ScreenStack.Main -> Level1Navigation.HomeProvider
            ScreenStack.Wallet -> Level1Navigation.ConnectWalletProvider
            ScreenStack.MPlayer -> Level1Navigation.MediaPlayerWidgetProvider
        }

    val saveLastLevel1Screen = true
    val alwaysQuitOnHomeScreen = true
}

// LEVEL 1 NAVIGATION OF THE APP
enum class Level1Navigation(val screenIdentifier: ScreenIdentifier, val rememberVerticalStack: Boolean = false) {
    ScaffoldL1NavDefaults(ScreenIdentifier.get(Scaffold, null), false),
    TopBarProvider(ScreenIdentifier.get(TopBar, null), true),
    LeftNavigationRailProvider(ScreenIdentifier.get(LeftNavigationRail, null), true),
    BottomNavigationBarL1Nav(ScreenIdentifier.get(BottomNavigationBar, null), true),
    HomeProvider(ScreenIdentifier.get(Home, null), true),

    MyRoyaltiesProvider(
        ScreenIdentifier.get(MyRoyalties, MyRoyaltiesParams(listType = MyRoyaltiesType.ALL)),
        true
    ),
    ConnectWalletProvider(
        ScreenIdentifier.get(ConnectWallet, ConnectWalletParams(WalletProvider.InternalRandom)),
        true
    ),
    StoreProvider(ScreenIdentifier.get(Store, StoreParams(listType = StoreType.ALL)), true),
    NftPurchaseLibraryProvider(ScreenIdentifier.get(NftPurchaseLibrary, null), true),
    MediaPlayerWidgetProvider(ScreenIdentifier.get(MediaPlayerWidget, null), false),
    ReleaserProfileProvider(ScreenIdentifier.get(ReleaserProfile, null), false),
    MyOrganizationsProvider(ScreenIdentifier.get(MyOrganizations, null), false),
}
