package io.eqoty.composables.styling

import org.jetbrains.compose.web.css.Color

val Purple200 = Color("#BB86FC")
val Purple500 = Color("#6200EE")
val Purple700 = Color("#3700B3")
val Teal200 = Color("#03DAC5")
val DarkGrey = Color("#121212")
val Black = Color("black")
val White = Color("white")
var Primary = Color("var(--bs-primary)")
var PrimaryOpaque = Color("rgba(var(--bs-primary-rgb), 1) ")
var Secondary = Color("var(--bs-secondary)")
var Light = Color("var(--bs-light)")
var Background = Color("var(--bs-eqoty_background)")
var BackgroundDarker = Color("var(--bs-eqoty_background_darker)")
var BackgroundDarkest = Color("var(--bs-eqoty_background_darkest)")
var ScrimOverlayBg = Color("var(--bs-scrim-overlay-bg)")