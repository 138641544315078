package io.eqoty.shared

import io.eqoty.shared.datalayer.sources.web3.Web3Network

// todo: #60 should be populated based on the presence compiled contracts in their respective resources network folder
var supportedNetworks =
    mutableListOf(
        Web3Network.GoerliTestnet,
        Web3Network.PrivateTestnet,
        Web3Network.SecretMainnet,
        Web3Network.SecretTestnet
    )

fun Web3Network?.isSupported() = supportedNetworks.contains(this)
