package io.eqoty.shared.viewmodel.screens.scaffold

import io.eqoty.shared.viewmodel.StateManager
import io.eqoty.shared.viewmodel.screens.ScreenInitSettings


// INITIALIZATION settings for this screen
// to understand the initialization behaviour, read the comments in the ScreenInitSettings.kt file


fun StateManager.initScaffold() = ScreenInitSettings(
    title = "",
    initState = {
        ScaffoldScreenState()
    },
    callOnInit = {
    },
)