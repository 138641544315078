package io.eqoty.shared.datalayer.objects

import io.eqoty.cosmwasm.std.types.ContractInfo
import io.eqoty.secret.std.contract.msg.EqotyReleaseMsgs
import io.eqoty.shared.datalayer.sources.localdb.nfts.getNftMetadataIdForReleaseId
import kotlinx.serialization.Serializable

@Serializable
data class Release(
    val id: UInt,
    val organizationId: ULong,
    val listed: Boolean,
    val productDealer: ContractInfo,
    val royaltyDealer: ContractInfo,
    val privateAuthenticationKey: String?,
    val nftMetadata: NftMetadata,
)

fun EqotyReleaseMsgs.Release.toRelease(privateAuthenticationKey: String?, publicReleaseMetadata: ReleaseMetaData) =
    publicReleaseMetadata.run {
        when (properties) {
            is AudioReleaseProperties -> {
                val audioMappedByVisibility =
                    properties.attachments.filterIsInstance<AudioAttachmentMetadata>().groupBy { it.access }
                val primaryPrivateAudio = audioMappedByVisibility[AttachmentAccess.PRIVATE]!!.first { it.priority == 0 }
                val primaryPublicAudio = audioMappedByVisibility[AttachmentAccess.PUBLIC]!!.first { it.priority == 0 }
                Release(
                    id = id,
                    listed = listed,
                    organizationId = organizationId,
                    productDealer = productDealer,
                    royaltyDealer = royaltyDealer,
                    privateAuthenticationKey = privateAuthenticationKey,
                    nftMetadata = NftMetadata(
                        id = getNftMetadataIdForReleaseId(id),
                        name = name,
                        description = description,
                        image = image,
                        animationUrl = animationUrl,
                        privateAudioResources = primaryPrivateAudio.formats,
                        privateAudioDuration = primaryPrivateAudio.duration,
                        publicAudioResources = primaryPublicAudio.formats,
                        publicAudioDuration = primaryPublicAudio.duration,
                        artists = properties.artists,
                        album = properties.album
                    )
                )
            }

            else -> TODO("Only audio-release-properties supported")
        }
    }


