package io.eqoty.composables.views

import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.FormFloating
import org.jetbrains.compose.web.attributes.InputMode
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement


@Composable
fun FormFloatingNumberInputValidation(
    attrs: AttrBuilderContext<HTMLDivElement>,
    value: String,
    label: String,
    onValueChange: (String) -> Unit,
    error: String? = null,
) {
    val hasError = error != null
    FormFloating(
        attrs = attrs
    ) {
        TextInput(value = value) {
            classes("form-control")
            if (hasError) {
                classes("is-invalid")
            }
            id("floatingInput")
            placeholder(label)
            inputMode(InputMode.Decimal)
            onInput {
                onValueChange(it.value)
            }
        }
        Label("floatingInput") {
            Text(label)
        }
        if (hasError) {
            Div(attrs = {
                classes("invalid-feedback")
            }) {
                Text(error!!)
            }
        }
    }
}