package io.eqoty.shared.datalayer.functions

import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.sources.ALL
import io.eqoty.shared.datalayer.sources.DataSrc
import io.eqoty.shared.datalayer.sources.LOCAL
import io.eqoty.shared.datalayer.sources.REMOTE
import io.eqoty.shared.datalayer.sources.localdb.nfts.getRoyaltyNftsByReleaseId


suspend fun Repository.getOwnedRoyaltyNftIds(
    releaseId: UInt, address: String, src: DataSrc
): List<BigInteger> =
    withRepoContext {
        val release = getRelease(releaseId, LOCAL)!!
        // todo save the childSnip721 info to the db and get from there
        val childSnip721 = queryRoyaltyDealerChildSnip721Contract(release.royaltyDealer)
        return@withRepoContext when (src) {
            is LOCAL -> {
                localDb.getRoyaltyNftsByReleaseId(releaseId).map { it.id }
            }

            is ALL, REMOTE -> {
                try {
                    getOwnedRoyaltyTokens(childSnip721, address, false)
                } catch (t: Throwable) {
                    // todo: report this online
                    t.printStackTrace()
                    getOwnedRoyaltyTokens(childSnip721, address, true)
                    emptyList()
                }
            }
        }
    }