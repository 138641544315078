package io.eqoty.shared.datalayer.functions

import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.sources.filesystem.CommonFile
import io.eqoty.shared.datalayer.sources.webservices.ipfsProtocol
import io.ipfs.kotlin.commands.UploadAndAddProgressListener

suspend fun Repository.addEncryptedFileToIpfs(
    iv: UByteArray,
    key: UByteArray,
    file: CommonFile,
    uploadAndAddProgressListener: UploadAndAddProgressListener? = null
): String = withRepoContext {
    val encrypted = aes256Gcm.encrypt(iv, key, file.getBytes().toUByteArray())
    val addedImageHash =
        ipfsClient.encryptAndAddFile(aes256Gcm, iv, key, file, uploadAndAddProgressListener).hash
    val fileUrl = ipfsProtocol + addedImageHash
    return@withRepoContext fileUrl
}
