package io.eqoty.shared.datalayer.sources.webservices.apis

import io.eqoty.shared.datalayer.objects.ReleaseMetaData
import io.eqoty.shared.datalayer.sources.webservices.ApiClient
import io.eqoty.shared.datalayer.sources.webservices.ipfsBaseUrl
import io.eqoty.shared.datalayer.sources.webservices.ipfsProtocol
import co.touchlab.kermit.Logger

suspend fun ApiClient.fetchReleaseMetaData(uri: String): ReleaseMetaData? {
    return try {
        getResponse(ipfsBaseUrl, uri.removePrefix(ipfsProtocol))
    } catch (t: Throwable) {
        Logger.d("Failed to get NFT metadata from: $uri")
        return null
    }
}
