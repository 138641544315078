package io.eqoty.shared.viewmodel.screens.connectwallet

import io.eqoty.shared.datalayer.sources.web3.Web3Network
import io.eqoty.shared.isSupported
import io.eqoty.shared.supportedNetworks
import io.eqoty.shared.viewmodel.ScreenState

// here is the data class defining the state for this screen

data class ConnectWalletScreenState(
    val injectedKeplrProviderAvailable: Boolean = false,
    val injectedEthProviderAvailable: Boolean = false,
    val walletConnected: Boolean = false,
    private val walletAddress: String? = null,
    private val network: Web3Network? = null,
    val displayMessage: String? = null,
) : ScreenState {
    // in the ViewModel classes, our computed properties only do UI-formatting operations
    // (the arithmetical operations, such as calculating a percentage, should happen in the DataLayer classes)
    val networkSupported = network.isSupported()
    val supportedNetworkInfo = "network: ${network?.description}"
    private val supportedNetworksInfo = supportedNetworks.joinToString(", ") { it.description }
    val unsupportedNetworkInfo = "network: ${network?.description} is not supported\nSwitch to $supportedNetworksInfo"
    val formattedAddress = walletAddress ?: ""
    val walletAddressInfo = "wallet address:\n$formattedAddress"
}