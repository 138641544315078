package io.eqoty.shared.datalayer.functions

import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.AesCredentials
import io.eqoty.shared.datalayer.sources.web3.CosmosWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.EthWeb3Provider
import kotlin.random.Random
import kotlin.random.nextUBytes

fun Repository.getRandomAesCredentials(): AesCredentials {
    val rand = when (/*val provider = */web3Provider!!) {
        is EthWeb3Provider -> {
            // hardcoded... not random this is just a hack for eth to generate the same key everytime
            Random(0)
        }

        is CosmosWeb3Provider -> {
            Random
        }
    }
    // todo: use better source of randomness? use libsodium?
    val iv = rand.nextUBytes(12)
    val key = rand.nextUBytes(16)
    return AesCredentials(iv, key)
}