package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.ColumnAdapter
import io.eqoty.cosmwasm.std.types.Coin
import kotlin.Long
import kotlin.String
import kotlin.UInt
import kotlin.collections.List

public data class ProductDealerContractTable(
  public val releaseId: UInt,
  public val childSnip721Address: String,
  public val childSnip721CodeHash: String,
  public val prices: List<Coin>?,
) {
  public class Adapter(
    public val releaseIdAdapter: ColumnAdapter<UInt, Long>,
    public val pricesAdapter: ColumnAdapter<List<Coin>, String>,
  )
}
