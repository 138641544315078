package io.eqoty.shared.datalayer.sources.web3

import io.eqoty.secretk.utils.EncryptionUtils
import io.eqoty.secretk.wallet.Wallet


class JsKeplrWeb3Provider : ExternalWeb3Provider, CosmosWeb3Provider {

    override val wallet: Wallet?
        get() = walletBacking
    var walletBacking: Wallet? = null
    var chainId: String? = null

    override val encryptionUtils: EncryptionUtils?
        get() = externalEncryptionUtils

    var externalEncryptionUtils: EncryptionUtils? = null

    override val activeAccountIndex: Int? get() = activeAccountIndexBacking
    private var activeAccountIndexBacking: Int? = null

    override suspend fun setActiveAccountIndex(accountIndex: Int?) {
        activeAccountIndexBacking = accountIndex
    }


    override suspend fun requestAccounts(): List<String> = wallet?.getAccounts()?.map { it.address } ?: emptyList()

    override suspend fun getAccounts(): List<String> = wallet?.getAccounts()?.map { it.address } ?: emptyList()

    override suspend fun getId(): String? = chainId
    override suspend fun disconnect() {
        setActiveAccountIndex(null)
    }

}
