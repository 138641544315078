package io.eqoty.shared.viewmodel.screens.mediaplayer

import io.eqoty.shared.viewmodel.StateManager
import io.eqoty.shared.viewmodel.screens.ScreenInitSettings


// INIZIALIZATION settings for this screen
// to understand the initialization behaviour, read the comments in the ScreenInitSettings.kt file


fun StateManager.initMediaPlayerWidget() = ScreenInitSettings(
    title = /*params.name ?:*/ "",
    initState = { MediaPlayerWidgetState() },
    callOnInit = {
    }
)