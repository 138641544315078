package io.eqoty.shared.viewmodel.screens.topbar

import io.eqoty.shared.viewmodel.StateManager
import io.eqoty.shared.viewmodel.screens.ScreenInitSettings


// INIZIALIZATION settings for this screen
// to understand the initialization behaviour, read the comments in the ScreenInitSettings.kt file


fun StateManager.initTopBar() = ScreenInitSettings(
    title = /*params.name ?:*/ "",
    initState = {
        TopBarState(
            title = "Top Bar",
            topBarAccountItemData = TopBarAccountItemData()
        )
    },
    callOnInit = {
        events.updateWalletBtnInfo()
    },
)