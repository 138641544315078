package io.eqoty.shared.viewmodel.screens.walletInfo

import io.eqoty.shared.datalayer.sources.web3.Web3Network
import io.eqoty.shared.supportedNetworks
import io.eqoty.shared.viewmodel.ScreenState

// here is the data class defining the state for this screen

data class WalletInfoScreenState(
    val networkSupported: Boolean = false,
    val errorMessage: String? = null,
    val formatted: WalletInfoScreenDisplayFormatted = WalletInfoScreenDisplayFormatted(),
    val releaserMode: Boolean? = null,
) : ScreenState

data class WalletInfoScreenDisplayFormatted(
    val walletAddress: String? = null,
    val network: Web3Network? = null,
    val displayMessage: String? = null,
) {
    // in the ViewModel classes, our computed properties only do UI-formatting operations
    // (the arithmetical operations, such as calculating a percentage, should happen in the DataLayer classes)
    val formattedAddress = walletAddress ?: ""
    val supportedNetworkInfo = "network: ${network?.description}"
    val walletAddressInfo = "wallet address:\n$formattedAddress"
    private val supportedNetworksInfo = supportedNetworks.joinToString(", ") { it.description }
    val unsupportedNetworkInfo = "network: ${network?.description} is not supported\nSwitch to $supportedNetworksInfo"
}