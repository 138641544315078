package io.eqoty.shared.viewmodel.screens.mediaplayer

import io.eqoty.shared.datalayer.objects.AttachmentAccess
import io.eqoty.shared.datalayer.objects.PurchaseNft
import io.eqoty.shared.datalayer.objects.Release
import io.eqoty.shared.viewmodel.ScreenState
import io.eqoty.shared.viewmodel.screens.generic.TrackRowData
import kotlin.time.Duration.Companion.milliseconds

// here is the data class defining the state for this screen

enum class PlaybackState {
    PLAYING,
    PAUSED,
    STOPPED
}

data class MediaPlayerWidgetState(
    val track: MediaPlayerWidgetTrack? = null,
    val playbackState: PlaybackState = PlaybackState.STOPPED,
    val currentPosition: Int? = null
) : ScreenState {
    private val duration = when (track?.access) {
        AttachmentAccess.PUBLIC -> track.displayData.publicAudioDuration
        AttachmentAccess.PRIVATE -> track.displayData.privateAudioDuration
        null -> null
    }
    val durationFormatted = when (track?.access) {
        AttachmentAccess.PUBLIC -> track.displayData.publicAudioDurationFormatted
        AttachmentAccess.PRIVATE -> track.displayData.privateAudioDurationFormatted
        null -> "-:--"
    }

    val currentPositionPercent =
        if (currentPosition != null && duration == null) .15f else currentPosition?.toFloat()?.div(duration!!.toFloat())
    val currentPositionPercentOf100 = currentPositionPercent?.times(100.0)
    val currentPositionFormatted = currentPosition?.milliseconds?.toComponents { minutes, seconds, _ ->
        "$minutes:${seconds.toString().padStart(2, '0')}"
    } ?: "0:00"

}


/********** property classes **********/

data class MediaPlayerWidgetTrack(
    private val release: Release,
    val purchaseNft: PurchaseNft? = null,
    val access: AttachmentAccess
) {
    // in the ViewModel classes, our computed properties only do UI-formatting operations
    // (the arithmetical operations, such as calculating a percentage, should happen in the DataLayer classes)
    val releaseId = release.id
    val purchaseId = purchaseNft?.id
    val displayData = TrackRowData(release, purchaseNft, access)
}
