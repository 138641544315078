package io.eqoty.composables.screens.myorganizations


import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import app.softwork.bootstrapcompose.Button
import app.softwork.bootstrapcompose.Column
import app.softwork.bootstrapcompose.ListGroup
import app.softwork.bootstrapcompose.Row
import dev.icerock.moko.resources.compose.stringResource
import io.eqoty.composables.screens.LoadingScreen
import io.eqoty.composables.screens.organizationregistrationinfo.OrganizationCard
import io.eqoty.shared.MR
import io.eqoty.shared.viewmodel.screens.myorganizations.MyOrganizationsScreenState
import io.michaelrocks.libphonenumber.kotlin.PhoneNumberUtil
import io.michaelrocks.libphonenumber.kotlin.metadata.defaultMetadataLoader
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text


@Composable
fun MyOrganizationsScreen(
    state: MyOrganizationsScreenState,
    onListSongClick: (organizationId: ULong, organizationName: String) -> Unit,
    onEditItemClick: (ULong) -> Unit,
    onClickNewOrganization: () -> Unit
) {
    val phoneNumberUtil: PhoneNumberUtil by remember {
        mutableStateOf(PhoneNumberUtil.createInstance(defaultMetadataLoader()))
    }
    Column {
        if (!state.isConnected) {
            DisconnectedPlaceholderContent()
        } else {
            Div(attrs = {
                style {
                    display(DisplayStyle.Flex)
                    flexFlow(FlexDirection.Row, FlexWrap.Nowrap)
                    alignItems(AlignItems.Center)
                    fontSize(1.2.em)
                }
                classes("text-nowrap")
            }) {
                Div(attrs = {
                    style {
                        flexGrow(1)
                    }
                }) {
                    Text(stringResource(MR.strings.my_artists))
                }
                Button(
                    stringResource(MR.strings.create_new_artist), outlined = true
                ) {
                    onClickNewOrganization()
                }
            }

            ListGroup {
                state.organizations.forEach { organization ->
                    OrganizationCard(phoneNumberUtil, null, organization, onListSongClick, onEditItemClick)
                }
            }

            Row {
                if (state.loading) {
                    LoadingScreen()
                } else if (state.organizations.isEmpty()) {
                    PlaceholderContentConnected()
                }
            }
        }
    }
}


@Composable
private fun DisconnectedPlaceholderContent() {
    Div(attrs = {
        style {
            display(DisplayStyle.Flex)
            flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
            alignItems(AlignItems.Center)
        }
        classes("p-3")
    }) {
        val textSizeStyle: StyleScope.() -> Unit = {
            fontSize(1.cssRem)
        }
        Div(attrs = { style { textSizeStyle(); textAlign("center"); paddingBottom(20.px) } }) {
            Text(
                stringResource(MR.strings.connect_wallet_my_releases)
            )
        }
        Div(attrs = { style { textSizeStyle(); textAlign("center"); fontSize(0.9.cssRem) } }) {
            Text(stringResource(MR.strings.my_releases_placeholder_1))
        }
        Div(attrs = { style { textSizeStyle(); textAlign("center"); fontSize(0.9.cssRem) } }) {
            Text(stringResource(MR.strings.my_releases_placeholder_2))
        }
    }
}

@Composable
private fun PlaceholderContentConnected() {
    Div(attrs = {
        style {
            display(DisplayStyle.Flex)
            flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
            alignItems(AlignItems.Center)
        }
        classes("p-3")
    }) {
        val textSizeStyle: StyleScope.() -> Unit = {
            fontSize(1.cssRem)
        }
        Div(attrs = { style { textSizeStyle(); paddingBottom(20.px) } }) {
            Text(
                stringResource(MR.strings.nothing_here)
            )
        }
        Div(attrs = { style { textSizeStyle(); textAlign("center"); fontSize(0.9.cssRem) } }) {
            Text(stringResource(MR.strings.my_releases_placeholder_1))
        }
        Div(attrs = { style { textSizeStyle(); textAlign("center"); fontSize(0.9.cssRem) } }) {
            Text(stringResource(MR.strings.my_releases_placeholder_2))
        }
    }
}

