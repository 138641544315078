package io.eqoty.composables.screens.releaserprofile

import androidx.compose.runtime.*
import androidx.compose.ui.text.intl.Locale
import app.softwork.bootstrapcompose.Button
import app.softwork.bootstrapcompose.Card
import app.softwork.bootstrapcompose.Column
import app.softwork.bootstrapcompose.Row
import app.softwork.bootstrapcompose.icons.EnvelopeFill
import app.softwork.bootstrapcompose.icons.PersonSquare
import app.softwork.bootstrapcompose.icons.TelephoneFill
import app.softwork.bootstrapcompose.icons.WalletTwo
import dev.icerock.moko.resources.compose.stringResource
import io.eqoty.composables.screens.LoadingRow
import io.eqoty.composables.views.FormFloatingTextAreaValidation
import io.eqoty.composables.views.FormFloatingTextInputValidation
import io.eqoty.secret.std.contract.msg.EqotyReleaseMsgs
import io.eqoty.shared.MR
import io.eqoty.shared.ui.utils.BootstrapBreakpoint
import io.eqoty.shared.ui.utils.ScreenSizeUtils
import io.eqoty.shared.ui.utils.smallScaffoldActive
import io.eqoty.shared.viewmodel.screens.releaserprofile.ReleaserProfileFormInput
import io.eqoty.shared.viewmodel.screens.releaserprofile.ReleaserProfileScreenState
import io.michaelrocks.libphonenumber.kotlin.PhoneNumberUtil
import io.michaelrocks.libphonenumber.kotlin.metadata.defaultMetadataLoader
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.svg.SVGElement


@Composable
fun ReleaserProfileScreen(
    state: ReleaserProfileScreenState, onSubmitClick: (ReleaserProfileFormInput) -> Unit
) {
    val phoneNumberUtil: PhoneNumberUtil by remember {
        mutableStateOf(PhoneNumberUtil.createInstance(defaultMetadataLoader()))
    }
    val region = remember { Locale.current.region }

    var formInputs: ReleaserProfileFormInputData? by remember { mutableStateOf(null) }
    if (state.shouldReinitializeFormInputData) {
        formInputs = null
    }
    if (!state.loading) {
        // fill in the form inputs with the releaser data if it exists after loading is complete
        if (formInputs == null) {
            formInputs = state.releaser?.run {
                val phone = pendingInfo?.phone ?: info?.phone
                val phoneFormatted = phone?.run {
                    phoneNumberUtil.format(
                        phoneNumberUtil.parse(
                            this, null
                        ), PhoneNumberUtil.PhoneNumberFormat.INTERNATIONAL
                    )
                }
                ReleaserProfileFormInputData(
                    firstName = mutableStateOf(pendingInfo?.firstName ?: info?.firstName ?: ""),
                    lastName = mutableStateOf(pendingInfo?.lastName ?: info?.lastName ?: ""),
                    email = mutableStateOf(pendingInfo?.email ?: info?.email ?: ""),
                    phone = mutableStateOf(phone ?: ""),
                    phoneFormatted = mutableStateOf(phoneFormatted),
                    mailingAddress = mutableStateOf(pendingInfo?.mailingAddress ?: info?.mailingAddress ?: ""),
                    govId = mutableStateOf(pendingInfo?.govId ?: info?.govId ?: ""),
                    selfie = mutableStateOf(pendingInfo?.selfie ?: info?.selfie ?: ""),
                )
            } ?: ReleaserProfileFormInputData(
                phone = mutableStateOf("+${phoneNumberUtil.getCountryCodeForRegion(region)}"),
            )
        }
    }
    Column {
        if (state.loading) LoadingRow()
        if (formInputs != null) {
            Row {
                when (ScreenSizeUtils.smallScaffoldActive) {
                    true -> {
                        SmallReleaserProfileScreen(state, phoneNumberUtil, region, formInputs!!, onSubmitClick)
                    }

                    false -> {
                        LargeReleaserProfileScreen(state, phoneNumberUtil, region, formInputs!!, onSubmitClick)
                    }
                }
            }
        }
    }
}


@Composable
private fun SmallReleaserProfileScreen(
    state: ReleaserProfileScreenState,
    phoneNumberUtil: PhoneNumberUtil,
    region: String,
    formInputData: ReleaserProfileFormInputData,
    onSubmitClick: (ReleaserProfileFormInput) -> Unit
) {
    Column {}
    Column(auto = true) {
        Row {
            ReleaserStatusMessage(state)
        }
        Row {
            Column {
                H4 {
                    Text(state.title)
                }
                Row {
                    if (state.releaser != null) ReleaserCard(
                        phoneNumberUtil,
                        state.walletAddress ?: "", formInputData, state.releaser!!,
                    )
                    else ReleaserCard(phoneNumberUtil, state.walletAddress ?: "", formInputData, null)
                }
            }
        }
        Row {
            Column {
                if (!state.loading) {
                    RegisterReleaserForm(state, phoneNumberUtil, region, formInputData, onSubmitClick)
                }
            }
        }
    }
    Column {}
}

@Composable
private fun LargeReleaserProfileScreen(
    state: ReleaserProfileScreenState,
    phoneNumberUtil: PhoneNumberUtil,
    region: String,
    formInputData: ReleaserProfileFormInputData,
    onSubmitClick: (ReleaserProfileFormInput) -> Unit
) {
    Column { }
    Column(auto = true, attrs = {
        classes("w-100")
        style {
            maxWidth(BootstrapBreakpoint.Xxl.px.px)
        }
    }) {
        Row {
            ReleaserStatusMessage(state)
        }
        Row {
            Column { }
            Column(auto = true) {
                Row {
                    Column(auto = true, attrs = { style { width(500.px) } }) {
                        Row(attrs = { classes("justify-content-end") }) {
                            Column {
                                H4 {
                                    Text(state.title)
                                }
                                if (state.releaser != null) ReleaserCard(
                                    phoneNumberUtil,
                                    state.walletAddress ?: "", formInputData, state.releaser!!,
                                )
                                else ReleaserCard(phoneNumberUtil, state.walletAddress ?: "", formInputData, null)
                            }
                        }
                    }
                    if (!state.loading) {
                        Column(attrs = { classes("ps-0", "pt-3", "overflow-hidden"); style { width(350.px) } }) {
                            RegisterReleaserForm(state, phoneNumberUtil, region, formInputData, onSubmitClick)
                        }
                    }
                }
            }
            Column { }
        }
    }
    Column {}
}


@Composable
private fun ReleaserStatusMessage(state: ReleaserProfileScreenState) {
    Column {
        if (!state.isRegisteredReleaser) {
            Div(attrs = {
                style {
                    display(DisplayStyle.Flex)
                    flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                    alignItems(AlignItems.Center)
                }
                classes("p-3")
            }) {
                Div(attrs = { style { fontSize(1.cssRem); paddingBottom(20.px) } }) {
                    Text(stringResource(MR.strings.not_a_registered_releaser))
                }
                Div(attrs = { style { fontSize(0.9.cssRem); paddingBottom(20.px) } }) {
                    Text(stringResource(MR.strings.request_access_to_release))
                }
            }
        }
        state.releaser?.getStatus()?.run {
            when (this) {
                EqotyReleaseMsgs.Status.PENDING -> {
                    Div(attrs = {
                        style {
                            display(DisplayStyle.Flex)
                            flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                            alignItems(AlignItems.Center)
                        }
                        classes("p-3")
                    }) {
                        Div(attrs = { style { fontSize(1.cssRem); paddingBottom(20.px) } }) {
                            Text(stringResource(MR.strings.releaser_registration_on_waitlist))
                        }
                        Div(attrs = { style { fontSize(0.9.cssRem); paddingBottom(20.px) } }) {
                            Text(stringResource(MR.strings.request_access_to_release_pending))
                        }
                    }
                }

                EqotyReleaseMsgs.Status.ENABLED -> {
                    Div(attrs = {
                        style {
                            display(DisplayStyle.Flex)
                            flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                            alignItems(AlignItems.Center)
                        }
                        classes("p-3")
                    }) {
                        Div(attrs = { style { fontSize(1.cssRem); paddingBottom(20.px) } }) {
                            Text(stringResource(MR.strings.releaser_registration_enabled))
                        }
                        Div(attrs = { style { fontSize(0.9.cssRem); paddingBottom(20.px) } }) {
                            Text(stringResource(MR.strings.releaser_registration_enabled_details))
                        }
                    }
                }

                EqotyReleaseMsgs.Status.DISABLED -> {
                    Div(attrs = {
                        style {
                            display(DisplayStyle.Flex)
                            flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                            alignItems(AlignItems.Center)
                        }
                        classes("p-3")
                    }) {
                        Div(attrs = { style { fontSize(1.cssRem); paddingBottom(20.px) } }) {
                            Text(stringResource(MR.strings.releaser_registration_disabled))
                        }
                        Div(attrs = { style { fontSize(0.9.cssRem); paddingBottom(20.px) } }) {
                            Text(stringResource(MR.strings.releaser_registration_disabled_details))
                        }
                    }
                }
            }
        }
    }
}

@Composable
private fun RegisterReleaserForm(
    state: ReleaserProfileScreenState,
    phoneNumberUtil: PhoneNumberUtil,
    region: String,
    formInputData: ReleaserProfileFormInputData,
    onSubmitClick: (ReleaserProfileFormInput) -> Unit
) {
    val phoneNumberFormatter = remember { phoneNumberUtil.getAsYouTypeFormatter(region) }

    Fieldset(attrs = {
        if (!state.interactionEnabled) {
            attr("disabled", "")
        }
    }) {
        Div(attrs = {
            classes("w-100")
            style {
                maxWidth(BootstrapBreakpoint.Sm.px.px)
                paddingTop(20.px)
                paddingBottom(40.px)
                display(DisplayStyle.Flex)
                flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                alignItems(AlignItems.Center)
            }
        }) {
            val widthStyle: StyleScope.() -> Unit = {
                width(100.percent)
            }
            val formAttrs: AttrBuilderContext<HTMLDivElement> = {
                style {
                    widthStyle()
                }
            }

            FormFloatingTextInputValidation(
                attrs = formAttrs,
                value = formInputData.firstName.value,
                label = stringResource(MR.strings.first_name) + " *",
                onValueChange = {
                    formInputData.firstName.value = it
                },
                error = state.validationResult.firstNameError
            )
            FormFloatingTextInputValidation(
                attrs = formAttrs,
                value = formInputData.lastName.value,
                label = stringResource(MR.strings.last_name) + " *",
                onValueChange = {
                    formInputData.lastName.value = it
                },
                error = state.validationResult.lastNameError
            )
            FormFloatingTextInputValidation(
                attrs = formAttrs,
                value = formInputData.email.value,
                label = stringResource(MR.strings.email) + " *",
                onValueChange = {
                    formInputData.email.value = it
                },
                error = state.validationResult.emailError
            )
            FormFloatingTextAreaValidation(
                attrs = formAttrs,
                value = formInputData.phone.value,
                label = stringResource(MR.strings.phone),
                onValueChange = {
                    formInputData.phone.value = it
                    phoneNumberFormatter.clear()
                    it.forEachIndexed { i, char ->
                        val formatted = phoneNumberFormatter.inputDigit(char)
                        if (i == it.length - 1) {
                            formInputData.phoneFormatted.value = formatted
                        }
                    }
                },
                error = state.validationResult.phoneError
            )


            Div(attrs = {
                style {
                    paddingTop(20.px)
                    display(DisplayStyle.Flex)
                    flexFlow(FlexDirection.Row, FlexWrap.Nowrap)
                    alignItems(AlignItems.Start)
                    widthStyle()
                }
            }) {
                Button(
                    title = stringResource(MR.strings.submit),
                ) {
                    onSubmitClick(
                        ReleaserProfileFormInput(
                            phoneNumberUtil,
                            formInputData.firstName.value,
                            formInputData.lastName.value,
                            formInputData.email.value,
                            formInputData.phone.value,
                            formInputData.mailingAddress.value,
                            formInputData.govId.value,
                            formInputData.selfie.value
                        )
                    )
                }
                Div(attrs = {
                    style {
                        display(DisplayStyle.Flex)
                        flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                        alignItems(AlignItems.Start)
                        justifyContent(JustifyContent.Center)
                        paddingLeft(10.px)
                    }
                }) {
                    state.txErrorMessage?.let { errorMessage ->
                        Div(attrs = {
                            classes("text-danger")
                        }) {
                            Text(errorMessage)
                        }
                    }
                    state.txHash?.let { txHash ->
                        Div {
                            Text(stringResource(MR.strings.success_emark))
                        }
                        Div {
                            Text(stringResource(MR.strings.tx_hash) + ": $txHash")
                        }
                    }
                }
            }
        }
    }
}


@Composable
fun ReleaserCard(
    phoneNumberUtil: PhoneNumberUtil,
    walletAddress: String,
    formInputData: ReleaserProfileFormInputData,
    releaser: EqotyReleaseMsgs.Releaser? = null,
) {
    Card(
        attrs = {
            classes("w-100"); style { maxWidth(BootstrapBreakpoint.Sm.px.px) }; classes(
            "d-flex", "flex-column"
        )
        },
        bodyAttrs = { classes("d-flex", "flex-column") },
    ) {
        Row(attrs = { classes("pb-2") }) {
            Column(auto = true) {
                PersonSquare(attrs = {
                    style {
                        width(100.px)
                        height(100.px)
                    }
                })
            }
            Column(attrs = { classes("overflow-hidden") }) {
                Row {
                    Column {
                        val acceptedName = releaser?.info?.run {
                            "$firstName $lastName"
                        }
                        val pendingName = releaser?.pendingInfo?.run {
                            "$firstName $lastName"
                        }
                        UpdatableReleaserInfoText(
                            accepted = acceptedName,
                            pending = pendingName,
                            inputState = formInputData.fullName,
                        )
                    }
                }
                releaser?.run {
                    H5(attrs = {
                        style {
                            overflow("hidden")
                            whiteSpace("nowrap")
                            property("text-overflow", "ellipsis")
                        }
                    }) {
                        Text("id: $id")
                    }
                    H5(attrs = {
                        style {
                            overflow("hidden")
                            whiteSpace("nowrap")
                            property("text-overflow", "ellipsis")
                        }
                    }) {
                        Text("Releaser Status: ${getStatus()}")
                    }
                }
            }
        }
        val iconAttrs: AttrBuilderContext<SVGElement> = {
            style {
                width(20.px)
                height(20.px)
            }
        }
        val iconColumnAttrs: AttrBuilderContext<HTMLDivElement> = {
            classes("pe-1")
        }
        val rowItemAttrs: AttrBuilderContext<HTMLDivElement> = {
            classes("py-1", "align-items-center")
        }

        Row(attrs = rowItemAttrs) {
            Column(auto = true, attrs = iconColumnAttrs) {
                WalletTwo(iconAttrs)
            }
            Column(attrs = { classes("overflow-hidden") }) {
                H5(attrs = {
                    classes("mb-0")
                    style {
                        overflow("hidden")
                        whiteSpace("nowrap")
                        property("text-overflow", "ellipsis")
                    }
                }) {
                    Text(releaser?.address ?: walletAddress)
                }
            }
        }
        Row(attrs = rowItemAttrs) {
            Column(auto = true, attrs = iconColumnAttrs) {
                EnvelopeFill(iconAttrs)
            }
            Column(attrs = { classes("overflow-hidden") }) {
                UpdatableReleaserInfoText(
                    accepted = releaser?.info?.email,
                    pending = releaser?.pendingInfo?.email,
                    inputState = formInputData.email
                )
            }
        }
        Row(attrs = rowItemAttrs) {
            Column(auto = true, attrs = iconColumnAttrs) {
                TelephoneFill(iconAttrs)
            }
            Column(attrs = { classes("overflow-hidden") }) {
                UpdatableReleaserInfoText(
                    accepted = releaser?.info?.phone?.run {
                        phoneNumberUtil.format(
                            phoneNumberUtil.parse(
                                this, null
                            ), PhoneNumberUtil.PhoneNumberFormat.INTERNATIONAL
                        )
                    },
                    pending = releaser?.pendingInfo?.phone?.run {
                        phoneNumberUtil.format(
                            phoneNumberUtil.parse(
                                this, null
                            ), PhoneNumberUtil.PhoneNumberFormat.INTERNATIONAL
                        )
                    },
                    inputState = formInputData.phoneFormatted,
                )
            }
        }
    }
}

@Composable
fun UpdatableReleaserInfoText(
    accepted: String?, pending: String?, inputState: State<String?>?,
    //style: TextStyle = MaterialTheme.typography.bodyLarge,
) {
    val hasInputState = inputState != null
    val inputOrPending = if (hasInputState) inputState!!.value.orEmpty() else pending
    val highestPriorityOnChainInfo = accepted ?: pending

    val showInputOrPending =
        (pending != null && pending != accepted && accepted != null) || (inputOrPending != null && inputOrPending != highestPriorityOnChainInfo && (accepted == null || inputOrPending != pending))

    highestPriorityOnChainInfo?.let { value ->
        H5(attrs = {
            if (showInputOrPending) {
                classes("text-danger")
                style {
                    textDecoration("line-through")
                }
            }
            classes("mb-0")
            style {
                overflow("hidden")
                whiteSpace("nowrap")
                property("text-overflow", "ellipsis")
            }
        }) {
            Text(value)
        }
    }

    if (showInputOrPending && inputOrPending?.isNotBlank() == true) inputOrPending.let { value ->
        H5(attrs = {
            classes("mb-0")
            style {
                overflow("hidden")
                whiteSpace("nowrap")
                property("text-overflow", "ellipsis")
            }
        }) {
            Text(value)
        }
    }
}
