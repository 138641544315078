package io.eqoty.shared.viewmodel.screens.topbar

import io.eqoty.shared.datalayer.sources.web3.Web3Network
import io.eqoty.shared.viewmodel.ScreenState

// here is the data class defining the state for this screen

data class TopBarState(
    val title: String,
    val inLevel1MainStackScreen: Boolean = true,
    val downloadIndicatorData: DownloadIndicatorData? = null,
    val topBarAccountItemData: TopBarAccountItemData,
) : ScreenState


/********** property classes **********/
data class TopBarAccountItemData(
    val network: Web3Network? = null,
    val address: String? = null,
    val balance: String? = null,
) {
    // in the ViewModel classes, our computed properties only do UI-formatting operations
    // (the arithmetical operations, such as calculating a percentage, should happen in the DataLayer classes)
    val networkName = network?.description ?: "Unknown Network"
    val currencyCode = network?.currency
}

data class DownloadIndicatorData(
    val progress: Float,
    val downloadProgressText: String,
    val downloadDescMessage: String
) {
    // in the ViewModel classes, our computed properties only do UI-formatting operations
    // (the arithmetical operations, such as calculating a percentage, should happen in the DataLayer classes)
    val progressString: String = (progress * 100).toInt().toString() + "%"
}
