package io.eqoty.shared.datalayer.functions

import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.sources.*
import io.eqoty.shared.datalayer.sources.localdb.nfts.getReleaseList
import io.eqoty.shared.datalayer.sources.localdb.nfts.getRoyaltyNftsByReleaseId
import co.touchlab.kermit.Logger
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.receiveAsFlow
import kotlinx.coroutines.launch


suspend fun Repository.getOwnedRoyaltyNftsAndTheirReleases(
    screenScope: CoroutineScope,
    src: DataSrc = ALL
): Flow<List<ReleaseAndRoyaltyNfts>> =
    withRepoContext {
        val results = Channel<List<ReleaseAndRoyaltyNfts>>()
        try {
            if (src is LocalSrc) {
                withBackgroundRepoContext(screenScope) {
                    sendLocal(results)
                    if (src !is ALL) {
                        results.close()
                    }
                }
            }
            if (src is RemoteSrc) {
                withBackgroundRepoContext(screenScope) {
                    val walletAddress = getWalletAddress()!!

                    val releases = getAllReleases().mapNotNull { r ->
                        val release = updateRelease(r)
                            ?: return@mapNotNull null
                        release
                    }

                    // concurrently get owned royalty tokens
                    coroutineScope {
                        releases.forEach { ownedRelease ->
                            launch(Dispatchers.Default) {
                                val releaseId = ownedRelease.id
                                val ownedRoyTknIds = getOwnedRoyaltyNftIds(releaseId, walletAddress, REMOTE)
                                if (ownedRoyTknIds.isNotEmpty()) {
                                    ownedRoyTknIds.forEach { id ->
                                        getRoyaltyNft(releaseId, id, REMOTE)
                                    }
                                    sendLocal(results)
                                }
                            }
                        }
                    }
                    results.close()
                }
            }
        } catch (t: Throwable) {
            t.printStackTrace()
            Logger.d("getOwnedRoyaltyNftsAndTheirReleases ERROR MESSAGE: ${t.message}")
        }
        results.receiveAsFlow()
    }

private suspend fun Repository.sendLocal(results: Channel<List<ReleaseAndRoyaltyNfts>>) {
    val dbReleases = localDb.getReleaseList()
    val ownedRoyaltyNftsAndTheirReleases = dbReleases.map { release ->
        ReleaseAndRoyaltyNfts(release, localDb.getRoyaltyNftsByReleaseId(release.id))
    }.filter { releaseAndRoyaltyNfts ->
        val royaltyNfts = releaseAndRoyaltyNfts.royaltyNfts
        royaltyNfts.isNotEmpty() && royaltyNfts.any { it.isOwner }
    }
    results.send(ownedRoyaltyNftsAndTheirReleases)
}