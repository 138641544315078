package io.eqoty.shared.datalayer.functions

import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.jsWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.DirectSigningSecretWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.JsKeplrWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.JsWeb3Provider
import io.eqoty.shared.datalayer.utils.bi10
import io.eqoty.shared.datalayer.utils.bi16
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.receiveAsFlow
import kotlinx.coroutines.launch

actual suspend fun Repository.startNetworkChangeListener(): Flow<BigInteger> = withRepoContext {
    val channel = Channel<BigInteger>()
    when (web3Provider) {
        is JsWeb3Provider -> {
            jsWeb3Provider.web3!!.eth.currentProvider.on("chainChanged") { chainId ->
                val chainIdBigInt = when (chainId) {
                    is Number -> chainId.bi10
                    is String -> {
                        if (chainId.contains('x', true)) {
                            chainId.bi16
                        } else {
                            chainId.bi10
                        }
                    }

                    else -> throw UnsupportedOperationException("chainChanged returned unhandled chainId: $chainId of type ${chainId::class}")
                }
                GlobalScope.launch {
                    channel.send(chainIdBigInt)
                }
            }
        }

        is JsKeplrWeb3Provider -> {
            // https://forum.scrt.network/t/im-going-to-ask-keplr-for-some-features-let-me-know-if-you-want-anything-else-requested/3711/3
            // Keplr doesn’t have the concept of a “selected” network. All configured networks are available to applications
        }

        is DirectSigningSecretWeb3Provider -> {}

        else -> throw UnsupportedOperationException("startNetworkChangeListener with unhandled web3Provider: $web3Provider")
    }
    return@withRepoContext channel.receiveAsFlow()
}