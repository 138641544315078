package io.eqoty.shared.datalayer.sources.web3

import io.eqoty.secretk.utils.EncryptionUtils
import io.eqoty.secretk.wallet.Wallet

sealed interface Web3Provider {

    val activeAccountIndex: Int?

    suspend fun setActiveAccountIndex(accountIndex: Int?)
    suspend fun getActiveAccountAddress(): String? {
        return activeAccountIndex?.let { getAccounts()[it] }
    }

    suspend fun requestAccounts(): List<String>

    suspend fun getAccounts(): List<String>

    suspend fun getId(): String?

    suspend fun disconnect()
}

interface EthWeb3Provider : Web3Provider

private const val gitpodId = "scrtlabs-gitpodlocalsec-lru9cwtp8qu.ws-us74"

interface CosmosWeb3Provider : Web3Provider {
    val wallet: Wallet?
    val encryptionUtils: EncryptionUtils?
}

interface ExternalWeb3Provider

interface InternalWeb3Provider<W> {

    val internalWallet: W?

    fun addAccountToWallet(mnemonic: String): String
    fun addAccountToWallet(privkey: UByteArray): String
}
