package io.eqoty.shared.viewmodel.screens.nftpurchaselibrary

import co.touchlab.kermit.Logger
import io.eqoty.shared.datalayer.functions.downloadIpfsFile
import io.eqoty.shared.datalayer.functions.getOwnedPurchaseNfts
import io.eqoty.shared.datalayer.functions.getWalletConnected
import io.eqoty.shared.datalayer.sources.DataSrc
import io.eqoty.shared.datalayer.sources.REMOTE
import io.eqoty.shared.datalayer.sources.webservices.ResourceDownloadInfo
import io.eqoty.shared.devicelayer.functions.launchSaveAsToFile
import io.eqoty.shared.viewmodel.Events
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.topbar.onDownloadComplete
import io.eqoty.shared.viewmodel.screens.topbar.updateTotalDownloadProgress
import kotlinx.coroutines.Job

/********** EVENT functions, called directly by the UI layer **********/

fun Events.refreshNftPurchaseLibraryScreenOnWalletChanges() = inScreenScopeLaunchInBackground(ScreenStack.Main) {
    dataRepository.walletAddressChangeFlow.collect { newWalletAddress ->
        Logger.d("refreshNftPurchaseLibraryScreenOnWalletChanges: onCollect:$newWalletAddress")
        refreshNftPurchaseLibraryScreen(REMOTE)
    }
}

private var refreshNftPurchaseLibraryScreenJob: Job? = null

fun Events.refreshNftPurchaseLibraryScreen(dataSrc: DataSrc) {
    // prevent multiple instances of refresh from being executed
    if (refreshNftPurchaseLibraryScreenJob?.isActive == true) {
        return
    }
    refreshNftPurchaseLibraryScreenJob = inScreenScopeLaunchInBackground(ScreenStack.Main) {
        val isConnected = dataRepository.getWalletConnected()
        val currentScreenScope = this
        if (!isConnected) {
            stateManager.updateScreen(ScreenStack.Main, NftPurchaseLibraryState::class) {
                it.copy(
                    isLoading = false,
                    nftReleaseListItems = emptyList()
                )
            }
        } else {
            stateManager.updateScreen(ScreenStack.Main, NftPurchaseLibraryState::class) {
                it.copy(
                    isLoading = true,
                )
            }
            val releaseListResults = dataRepository.getOwnedPurchaseNfts(currentScreenScope!!, dataSrc)
            releaseListResults.collect { releaseAndPurchaseIds ->
                val listData = releaseAndPurchaseIds.flatMap {
                    val release = it.first
                    val purchaseNfts = it.second
                    purchaseNfts.map { pNft ->
                        NftPurchaseLibraryListItem(release, pNft)
                    }
                }

                // update state, after retrieving data from the repository
                stateManager.updateScreen(ScreenStack.Main, NftPurchaseLibraryState::class) {
                    it.copy(
                        nftReleaseListItems = listData,
                    )
                }
            }
            stateManager.updateScreen(ScreenStack.Main, NftPurchaseLibraryState::class) {
                it.copy(
                    isLoading = false,
                )
            }
        }
    }
}

fun Events.startDownload(
    resourceDownloadInfo: ResourceDownloadInfo,
) = inAppScopeLaunchInBackground(ScreenStack.Main) {
    val bytes = try {
        dataRepository.downloadIpfsFile(resourceDownloadInfo) { _ ->
            updateTotalDownloadProgress()
        }
    } catch (t: Throwable) {
        dataRepository.downloadManager.onDownloadFailed(resourceDownloadInfo)
        return@inAppScopeLaunchInBackground
    } finally {
        onDownloadComplete().join()
    }
    try {
        dataRepository.launchSaveAsToFile(bytes, resourceDownloadInfo)
    } catch (t: Throwable) {
        Logger.d(t.stackTraceToString())
    }
}