package io.eqoty.shared.viewmodel.screens.releaserprofile

import io.eqoty.secret.std.contract.msg.EqotyReleaseMsgs
import io.eqoty.shared.viewmodel.ScreenState

// here is the data class defining the state for this screen

data class ReleaserProfileScreenState(
    val releaserId: ULong? = null,
    val releaser: EqotyReleaseMsgs.Releaser? = null,
    val walletAddress: String? = null,
    val txErrorMessage: String? = null,
    val txHash: String? = null,
    val validationResult: ReleaserProfileFormInputErrors = ReleaserProfileFormInputErrors(),
    val interactionEnabled: Boolean = true,
    val loading: Boolean = true,
    val shouldReinitializeFormInputData: Boolean = false,
) : ScreenState {
    val isRegisteredReleaser: Boolean
        get() = releaserId != null

    val title: String
        get() = if (isRegisteredReleaser) "Releaser Profile" else "Register as Releaser"
}
