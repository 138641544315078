package io.eqoty.shared.datalayer.objects

import io.eqoty.shared.datalayer.sources.filesystem.CommonFile

enum class AudioContainer(val container: String, val fileExtension: String) {
    MP3("MP3", ".mp3"),
    OGG("OGG", ".ogg"),
    WAV("WAV", ".wav"),
    AIFF("AIFF", ".aiff"),
    FLAC("FLAC", ".flac"),
    ALAC("M4A", ".m4a")
    ;

    companion object {
        private val map = AudioContainer.values().associateBy(AudioContainer::container)
        fun valueOf(container: String): AudioContainer {
            var containerProcessed = container.uppercase()
            containerProcessed = when (containerProcessed) {
                "MPEG-1", "MPEG", "MP3" -> "MP3"
                "WAV", "WAVE" -> "WAV"
                "M4A/ISOM/ISO2", "MOV,MP4,M4A,3GP,3G2,MJ2", "M4A" -> "M4A"
                else -> containerProcessed
            }

            return map.getOrElse(containerProcessed) { throw Error("Could not map container: $containerProcessed to an AudioContainer") }
        }
    }

    fun isLossless(): Boolean {
        return this == WAV || this == FLAC || this == ALAC || this == AIFF
    }
}


data class AudioInfo(
    val container: AudioContainer,
    val codec: String,
    val bitDepth: Int?,
    val bitRate: Int,
    val sampleRate: Int,
    val channels: Int,
    val lossless: Boolean,
    val duration: Int,
    val size: Long
) {

    fun toAudioFormatMetadata(url: String) =
        RemoteAudioResource(
            url = url,
            container = container.container,
            codec = codec,
            bitDepth = bitDepth,
            bitRate = bitRate,
            sampleRate = sampleRate,
            channels = channels,
            lossless = lossless,
            size = size
        )
}

sealed class AudioAndInfo {
    abstract val info: AudioInfo
}

data class AudioFileAndInfo(val file: CommonFile, override val info: AudioInfo) : AudioAndInfo()