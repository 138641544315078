package io.eqoty.shared.datalayer.sources.web3

object SecretEndpoints {
    val scrtGrpcGatewayEndpoint: String
        get() = "https://api.pulsar.scrttestnet.com"//"https://pulsar.api.trivium.network:1317" // "https://1317-$gitpodId.gitpod.io"
    val scrtRpcEndpoint: String
        get() = "https://rpc.pulsar.scrttestnet.com"//"https://pulsar.api.trivium.network:26657" // "https://26657-$gitpodId.gitpod.io"

    val releaseAddr = "secret1e2pxjhgyjv92zntpn3daan6e9e6dmpsfyp2spx"
    val releaseCodeHash = "aa1681244591e746ad8e5bcfc3bd8f54e10b45b7a110351e8c678bdcf9272363"
}