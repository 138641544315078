package io.eqoty.shared.datalayer.functions

import io.eqoty.shared.datalayer.Repository
import co.touchlab.kermit.Logger
import jslib.metamask.EthereumProvider
import jslib.metamask.detectEthereumProvider
import kotlinx.coroutines.await

actual suspend fun Repository.getInjectedEthProviderAvailable(): Boolean = withRepoContext {

    var isMetaMask = false
    @Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
    val provider: EthereumProvider? = detectEthereumProvider().await() as? EthereumProvider
    val providerAvailable = provider != null
    if (providerAvailable) {
        isMetaMask = provider?.isMetaMask!!
    }
    Logger.d("providerAvailable: $providerAvailable isMetaMask: $isMetaMask")
    return@withRepoContext providerAvailable
}