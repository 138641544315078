package io.eqoty.shared.datalayer.sources.web3

import com.benasher44.uuid.uuid4
import io.eqoty.cosmwasm.std.types.Coin
import kotlinx.serialization.Contextual


typealias InternalWalletTxRequest = suspend (TxRequestInfo) -> Boolean

@kotlinx.serialization.Serializable
data class TxRequestInfo(
    @Contextual val amount: Coin?,
    @Contextual val gas: Coin,
    val contractAddress: String,
    val message: String,
    val id: String = uuid4().toString()
)

@kotlinx.serialization.Serializable
data class TxRequestResult(
    val txRequestInfo: TxRequestInfo,
    val result: Boolean
)