package io.eqoty.composables.screens.organizationregistrationinfo

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf

data class OrganizationRegistrationInfoInputData(
    val name: MutableState<String> = mutableStateOf(""),
    val email: MutableState<String> = mutableStateOf(""),
    val phone: MutableState<String> = mutableStateOf(""),
    val phoneFormatted: MutableState<String?> = mutableStateOf(null),
    val mailingAddress: MutableState<String> = mutableStateOf(""),
    val website: MutableState<String> = mutableStateOf(""),
)