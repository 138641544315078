package io.eqoty.shared.datalayer.sources.localsettings

import com.russhwolf.settings.*
import io.eqoty.shared.viewmodel.URI
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.navigationSettings

class MySettings(private val s: Settings) {


    // here we define all our local settings properties,
    // by using the MultiplatformSettings library delegated properties

    var releaserMode by s.boolean(defaultValue = false)
    fun getSavedScreenStackLevel1URI(screenStack: ScreenStack) = s.getString(
        "saved${screenStack}ScreenStackLevel1URI",
        navigationSettings.screenStackDefaultStartScreen(screenStack).screenIdentifier.URI
    )

    fun setSavedScreenStackLevel1URI(screenStack: ScreenStack, uri: URI) {
        s["saved${screenStack}ScreenStackLevel1URI"] = uri
    }

    fun clear() = s.clear()
}