package io.eqoty.shared.datalayer.functions

import io.eqoty.kryptools.aes256gcm.Aes256Gcm
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.sources.webservices.DownloadProgress
import io.eqoty.shared.datalayer.sources.webservices.ResourceDownloadInfo
import io.ktor.client.utils.*
import io.ktor.utils.io.core.*
import okio.Buffer


typealias DownloadProgressListener = ((DownloadProgress) -> Unit)

suspend fun Repository.downloadIpfsFile(
    resourceDownloadInfo: ResourceDownloadInfo,
    downloadProgressListener: DownloadProgressListener? = null,
): ByteArray = withRepoContext {
    val buffer = Buffer()
    ipfsClient.catStream(
        resourceDownloadInfo.url.split("/").last()
    ) { byteChannelAndContentLength ->
        val byteChannel = byteChannelAndContentLength.byteReadChannel
        val contentLength = byteChannelAndContentLength.contentLength
        downloadProgressListener?.invoke(DownloadProgress(0, contentLength))
        while (!byteChannel.isClosedForRead) {
            val packet = byteChannel.readRemaining(DEFAULT_HTTP_BUFFER_SIZE.toLong())
            while (!packet.isEmpty) {
                buffer.write(packet.readBytes())
                val progress = DownloadProgress(buffer.size, contentLength)
                downloadManager.putDownloadItem(resourceDownloadInfo, progress)
                downloadProgressListener?.invoke(progress)

            }
        }
    }
    var bytes = buffer.readByteArray()
    val decrypt = resourceDownloadInfo.purchaseId != null
    if (decrypt) {
        val aesCred = getPurchasePrivateKey(resourceDownloadInfo.releaseId, resourceDownloadInfo.purchaseId!!)
        bytes = aes256Gcm.decrypt(aesCred.iv, aesCred.key, bytes.toUByteArray()).toByteArray()
    }
    return@withRepoContext bytes
}