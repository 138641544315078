package io.eqoty.composables.screens.nftpurchaselibrary

import androidx.compose.runtime.*
import app.softwork.bootstrapcompose.*
import app.softwork.bootstrapcompose.Layout.*
import app.softwork.bootstrapcompose.Layout.Display.Flex
import com.ionspin.kotlin.bignum.integer.BigInteger
import dev.icerock.moko.resources.compose.stringResource
import io.eqoty.composables.screens.LoadingScreen
import io.eqoty.composables.views.TrackListHeaderRow
import io.eqoty.shared.MR
import io.eqoty.shared.datalayer.sources.webservices.ResourceDownloadInfo
import io.eqoty.shared.viewmodel.screens.generic.TrackRowData
import io.eqoty.shared.viewmodel.screens.nftpurchaselibrary.NftPurchaseLibraryState
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun NftPurchaseLibraryScreen(
    state: NftPurchaseLibraryState, onClick: (UInt, BigInteger) -> Unit,
    onClickOrganization: (TrackRowData) -> Unit,
    onDownloadClick: (ResourceDownloadInfo) -> Unit,
) {
    var trackRowDataForModal: TrackRowData? by remember { mutableStateOf(null) }
    val showModal = trackRowDataForModal != null

    @Composable
    fun downloadModal(trackRowData: TrackRowData) {
        val items = trackRowData.privateAudioDownloadInfo
        var selectedIndex by remember { mutableStateOf(0) }
        val selected = items[selectedIndex]
        Modal(
            header = "Download Formats:",
            onDismissRequest = {
                trackRowDataForModal = null
            },
            footer = {
                Row(
                    styling = {
                        Layout {
                            width = Width.Full
                            display(Flex)
                            verticalAlignment = VerticalAlignment.Middle
                        }
                    },
                ) {
                    Column(
                        size = 8,
                    ) {
                        Row { Text("Selected: (${selected.sizeMb} MB)") }
                        Row { Text(selected.fileName) }
                    }
                    Column(
                        size = 4,
                        styling = {
                            Layout {
                                display(Flex)
                                horizontalAlignment = HorizontalAlignment.End
                                verticalAlignment = VerticalAlignment.Middle
                            }
                        }
                    ) {
                        Row(attrs = { classes("align-content-end") }) {
                            Button("Download") {
                                onDownloadClick(selected)
                                trackRowDataForModal = null
                            }
                        }
                    }
                }
            }) {
            RadioGroup {
                items.forEachIndexed { i, item ->
                    Radio(
                        label = item.displayText, checked = selectedIndex == i,
                        styling = {
                            Padding { Vertical { size = SpacingSpecs.SpacingSize.ExtraSmall } }
                        }
                    ) {
                        selectedIndex = i
                    }
                }
            }
        }
    }

    Column {
        TrackListHeaderRow()
        Row {
            ListGroup(attrs = { classes("g-0") }) {
                state.nftReleaseListItems.forEach { item ->
                    NftPurchaseLibraryRow(
                        item = item,
                        onClickOrganization = onClickOrganization,
                        onItemClick = { id -> onClick(item.trackRowData.releaseId, item.purchase.id) },
                    ) { trackRowData: TrackRowData ->
                        trackRowDataForModal = trackRowData
                    }
                }
            }
        }
        Row {
            if (state.isLoading) {
                LoadingScreen("Purchases")
            } else if (state.nftReleaseListItems.isEmpty()) {
                PlaceholderContent()
            }
        }
    }

    if (showModal) {
        downloadModal(trackRowDataForModal!!)
    }
}

@Composable
private fun PlaceholderContent() {
    Div(
        attrs = {
            style {
                display(DisplayStyle.Flex)
                flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                alignItems(AlignItems.Center)
            }
            classes("p-3")
        }
    ) {
        val textSizeStyle: StyleScope.() -> Unit = {
            fontSize(1.cssRem)
        }
        Div(
            attrs = { style { textSizeStyle(); paddingBottom(20.px) } }
        ) {
            Text(stringResource(MR.strings.nothing_here))
        }
        Div(attrs = { style { textSizeStyle() } }) { Text(stringResource(MR.strings.purchase_a_song_in_store)) }
    }
}