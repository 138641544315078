package io.eqoty.shared.datalayer.functions

import co.touchlab.kermit.Logger
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.jsWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.DirectSigningSecretWeb3Provider
import io.eqoty.shared.datalayer.sources.web3.WalletProvider
import jslib.walletconnect.WalletConnectProvider
import kotlinx.browser.window
import kotlinx.coroutines.await

actual suspend fun Repository.requestWalletAccountForSession(
    walletProvider: WalletProvider,
    specifiedInternalWalletAccount: String?
) =
    withRepoContext {

        when (walletProvider) {
            WalletProvider.Keplr -> {
                // no need to "request" accounts in keplr.
                // keplr_keystorechange doesn't emit on subscribe so emit here
                console.log(web3Provider?.getAccounts())
                val account = web3Provider?.getAccounts()?.getOrNull(0)
                web3Provider?.setActiveAccountIndex(accountIndex = if (account == null) null else 0)
                walletAddressChangeFlow.emit(account)
            }

            WalletProvider.MetaMask -> {
                // requestAccounts is needed to actually connect metamask to a dapp
                val account = web3Provider?.requestAccounts()?.firstOrNull()
                // the accountsChanged listener isn't triggered by requestAccounts it seems so emit the address here
                web3Provider?.setActiveAccountIndex(accountIndex = if (account == null) null else 0)
                walletAddressChangeFlow.emit(account)
            }

            WalletProvider.WalletConnect -> {
                //  Enable session (triggers QR Code modal)
                //  this is analogous to requestAccounts on metamask however it does trigger accountsChanged listener
                //  so no need to emit here.
                //  Also, for some reason calling pushstate anytime after launching the WalletConnectProvider on firefox
                //  mobile (v104 tested) throws a NS_ERROR_FAILURE. However, calling pushstate before launching here,
                //  then back() immediately after to clear it
                //  solution inspired by:
                //  https://stackoverflow.com/questions/16926788/error-with-html-iframe-in-firefox-component-returned-failure-code-0x80004005
                window.history.pushState("{}", "", "")
                try {
                    (jsWeb3Provider.web3?.eth?.currentProvider as WalletConnectProvider).enable().await()
                } catch (t: Throwable) {
                    Logger.d("WalletConnectProvider.enable() returned error", t)
                }
                web3Provider?.setActiveAccountIndex(accountIndex = 0)
                window.history.back()
            }

            WalletProvider.Internal, WalletProvider.InternalRandom -> {
                val provider = web3Provider as DirectSigningSecretWeb3Provider
                val accountIndex =
                    specifiedInternalWalletAccount
                        ?.run { provider.internalWallet?.accounts?.indexOfFirst { it.address == this } }
                        ?: provider.internalWallet?.accounts?.lastIndex
                val accountAddress = provider.internalWallet?.accounts?.get(accountIndex!!)?.address
                provider.setActiveAccountIndex(accountIndex = accountIndex)
                accountAddress?.let { walletAddressChangeFlow.emit(it) }
            }
        }

        return@withRepoContext
    }