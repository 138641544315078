/*
 * Copyright (C) 2010 The Libphonenumber Authors
 * Copyright (C) 2022 Michael Rozumyanskiy
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Definition of the class representing metadata for international telephone numbers. This class is
 * hand created based on the class file compiled from phonemetadata.proto. Please refer to that file
 * for detailed descriptions of the meaning of each field.
 *
 *
 * WARNING: This API isn't stable. It is considered libphonenumber-internal and can change at any
 * time. We only declare it as public for easy inclusion in our build tools not in this package.
 * Clients should not refer to this file, we do not commit to support backwards-compatibility or to
 * warn about breaking changes.
 */
package io.michaelrocks.libphonenumber.kotlin

import io.michaelrocks.libphonenumber.kotlin.io.Externalizable
import io.michaelrocks.libphonenumber.kotlin.io.ObjectInput
import io.michaelrocks.libphonenumber.kotlin.io.ObjectOutput
import kotlin.jvm.JvmStatic


class Phonemetadata private constructor() {
    open class NumberFormat : Externalizable {
        /**
         * Provides a dummy builder to 'emulate' the API of the code generated by the latest version of
         * Protocol Buffers. This lets BuildMetadataFromXml class to build with both this hand created
         * class and the one generated by the latest version of Protocol Buffers.
         */
        class Builder : NumberFormat() {
            fun build(): NumberFormat {
                return this
            }

            fun mergeFrom(other: NumberFormat): Builder {
                if (other.hasPattern()) {
                    setPattern(other.pattern)
                }
                if (other.hasFormat()) {
                    setFormat(other.format)
                }
                for (i in 0 until other.leadingDigitsPatternSize()) {
                    addLeadingDigitsPattern(other.getLeadingDigitsPattern(i))
                }
                if (other.hasNationalPrefixFormattingRule()) {
                    setNationalPrefixFormattingRule(other.nationalPrefixFormattingRule)
                }
                if (other.hasDomesticCarrierCodeFormattingRule()) {
                    setDomesticCarrierCodeFormattingRule(other.domesticCarrierCodeFormattingRule)
                }
                if (other.hasNationalPrefixOptionalWhenFormatting()) {
                    setNationalPrefixOptionalWhenFormatting(other.nationalPrefixOptionalWhenFormatting)
                }
                return this
            }
        }

        // required string pattern = 1;
        private var hasPattern = false
        var pattern = ""
            private set

        fun hasPattern(): Boolean {
            return hasPattern
        }

        fun setPattern(value: String): NumberFormat {
            hasPattern = true
            pattern = value
            return this
        }

        // required string format = 2;
        private var hasFormat = false
        var format = ""
            private set

        fun hasFormat(): Boolean {
            return hasFormat
        }

        fun setFormat(value: String): NumberFormat {
            hasFormat = true
            format = value
            return this
        }

        // repeated string leading_digits_pattern = 3;
        private val leadingDigitsPattern_: MutableList<String> = ArrayList()
        fun leadingDigitPatterns(): List<String> {
            return leadingDigitsPattern_
        }

        @Deprecated("")
        fun leadingDigitsPatternSize(): Int {
            return leadingDigitsPatternCount
        }

        val leadingDigitsPatternCount: Int
            get() = leadingDigitsPattern_.size

        fun getLeadingDigitsPattern(index: Int): String {
            return leadingDigitsPattern_[index]
        }

        fun addLeadingDigitsPattern(value: String?): NumberFormat {
            if (value == null) {
                throw NullPointerException()
            }
            leadingDigitsPattern_.add(value)
            return this
        }

        // optional string national_prefix_formatting_rule = 4;
        private var hasNationalPrefixFormattingRule = false
        var nationalPrefixFormattingRule = ""
            private set

        fun hasNationalPrefixFormattingRule(): Boolean {
            return hasNationalPrefixFormattingRule
        }

        fun setNationalPrefixFormattingRule(value: String): NumberFormat {
            hasNationalPrefixFormattingRule = true
            nationalPrefixFormattingRule = value
            return this
        }

        fun clearNationalPrefixFormattingRule(): NumberFormat {
            hasNationalPrefixFormattingRule = false
            nationalPrefixFormattingRule = ""
            return this
        }

        // optional bool national_prefix_optional_when_formatting = 6 [default = false];
        private var hasNationalPrefixOptionalWhenFormatting = false
        var nationalPrefixOptionalWhenFormatting = false
            private set

        fun hasNationalPrefixOptionalWhenFormatting(): Boolean {
            return hasNationalPrefixOptionalWhenFormatting
        }

        fun setNationalPrefixOptionalWhenFormatting(value: Boolean): NumberFormat {
            hasNationalPrefixOptionalWhenFormatting = true
            nationalPrefixOptionalWhenFormatting = value
            return this
        }

        // optional string domestic_carrier_code_formatting_rule = 5;
        private var hasDomesticCarrierCodeFormattingRule = false
        var domesticCarrierCodeFormattingRule = ""
            private set

        fun hasDomesticCarrierCodeFormattingRule(): Boolean {
            return hasDomesticCarrierCodeFormattingRule
        }

        fun setDomesticCarrierCodeFormattingRule(value: String): NumberFormat {
            hasDomesticCarrierCodeFormattingRule = true
            domesticCarrierCodeFormattingRule = value
            return this
        }

        override fun writeExternal(out: ObjectOutput) {
            out.writeUTF(pattern)
            out.writeUTF(format)
            val leadingDigitsPatternSize = leadingDigitsPatternSize()
            out.writeInt(leadingDigitsPatternSize)
            for (i in 0 until leadingDigitsPatternSize) {
                out.writeUTF(leadingDigitsPattern_[i])
            }
            out.writeBoolean(hasNationalPrefixFormattingRule)
            if (hasNationalPrefixFormattingRule) {
                out.writeUTF(nationalPrefixFormattingRule)
            }
            out.writeBoolean(hasDomesticCarrierCodeFormattingRule)
            if (hasDomesticCarrierCodeFormattingRule) {
                out.writeUTF(domesticCarrierCodeFormattingRule)
            }
            out.writeBoolean(nationalPrefixOptionalWhenFormatting)
        }

        override fun readExternal(input: ObjectInput) {
            setPattern(input.readUTF())
            setFormat(input.readUTF())
            val leadingDigitsPatternSize = input.readInt()
            for (i in 0 until leadingDigitsPatternSize) {
                leadingDigitsPattern_.add(input.readUTF())
            }
            if (input.readBoolean()) {
                setNationalPrefixFormattingRule(input.readUTF())
            }
            if (input.readBoolean()) {
                setDomesticCarrierCodeFormattingRule(input.readUTF())
            }
            setNationalPrefixOptionalWhenFormatting(input.readBoolean())
        }

        companion object {
            private const val serialVersionUID: Long = 1
            @JvmStatic
            fun newBuilder(): Builder {
                return Builder()
            }
        }
    }

    open class PhoneNumberDesc : Externalizable {
        /**
         * Provides a dummy builder.
         *
         * @see NumberFormat.Builder
         */
        class Builder : PhoneNumberDesc() {
            fun build(): PhoneNumberDesc {
                return this
            }

            fun mergeFrom(other: PhoneNumberDesc): Builder {
                if (other.hasNationalNumberPattern()) {
                    setNationalNumberPattern(other.nationalNumberPattern)
                }
                for (i in 0 until other.possibleLengthCount) {
                    addPossibleLength(other.getPossibleLength(i))
                }
                for (i in 0 until other.possibleLengthLocalOnlyCount) {
                    addPossibleLengthLocalOnly(other.getPossibleLengthLocalOnly(i))
                }
                if (other.hasExampleNumber()) {
                    setExampleNumber(other.exampleNumber)
                }
                return this
            }
        }

        // optional string national_number_pattern = 2;
        private var hasNationalNumberPattern = false
        var nationalNumberPattern = ""
            private set

        fun hasNationalNumberPattern(): Boolean {
            return hasNationalNumberPattern
        }

        fun setNationalNumberPattern(value: String): PhoneNumberDesc {
            hasNationalNumberPattern = true
            nationalNumberPattern = value
            return this
        }

        fun clearNationalNumberPattern(): PhoneNumberDesc {
            hasNationalNumberPattern = false
            nationalNumberPattern = ""
            return this
        }

        // repeated int32 possible_length = 9;
        private val possibleLength_: MutableList<Int> = ArrayList()
        val possibleLengthList: List<Int>
            get() = possibleLength_
        val possibleLengthCount: Int
            get() = possibleLength_.size

        fun getPossibleLength(index: Int): Int {
            return possibleLength_[index]
        }

        fun addPossibleLength(value: Int): PhoneNumberDesc {
            possibleLength_.add(value)
            return this
        }

        fun clearPossibleLength(): PhoneNumberDesc {
            possibleLength_.clear()
            return this
        }

        // repeated int32 possible_length_local_only = 10;
        private val possibleLengthLocalOnly_: MutableList<Int> = ArrayList()
        val possibleLengthLocalOnlyList: List<Int>
            get() = possibleLengthLocalOnly_
        val possibleLengthLocalOnlyCount: Int
            get() = possibleLengthLocalOnly_.size

        fun getPossibleLengthLocalOnly(index: Int): Int {
            return possibleLengthLocalOnly_[index]
        }

        fun addPossibleLengthLocalOnly(value: Int): PhoneNumberDesc {
            possibleLengthLocalOnly_.add(value)
            return this
        }

        fun clearPossibleLengthLocalOnly(): PhoneNumberDesc {
            possibleLengthLocalOnly_.clear()
            return this
        }

        // optional string example_number = 6;
        private var hasExampleNumber = false
        var exampleNumber = ""
            private set

        fun hasExampleNumber(): Boolean {
            return hasExampleNumber
        }

        fun setExampleNumber(value: String): PhoneNumberDesc {
            hasExampleNumber = true
            exampleNumber = value
            return this
        }

        fun clearExampleNumber(): PhoneNumberDesc {
            hasExampleNumber = false
            exampleNumber = ""
            return this
        }

        fun exactlySameAs(other: PhoneNumberDesc): Boolean {
            return nationalNumberPattern == other.nationalNumberPattern && possibleLength_ == other.possibleLength_ && possibleLengthLocalOnly_ == other.possibleLengthLocalOnly_ && exampleNumber == other.exampleNumber
        }

        override fun writeExternal(out: ObjectOutput) {
            out.writeBoolean(hasNationalNumberPattern)
            if (hasNationalNumberPattern) {
                out.writeUTF(nationalNumberPattern)
            }
            val possibleLengthSize = possibleLengthCount
            out.writeInt(possibleLengthSize)
            for (i in 0 until possibleLengthSize) {
                out.writeInt(possibleLength_[i])
            }
            val possibleLengthLocalOnlySize = possibleLengthLocalOnlyCount
            out.writeInt(possibleLengthLocalOnlySize)
            for (i in 0 until possibleLengthLocalOnlySize) {
                out.writeInt(possibleLengthLocalOnly_[i])
            }
            out.writeBoolean(hasExampleNumber)
            if (hasExampleNumber) {
                out.writeUTF(exampleNumber)
            }
        }

        override fun readExternal(input: ObjectInput) {
            if (input.readBoolean()) {
                setNationalNumberPattern(input.readUTF())
            }
            val possibleLengthSize = input.readInt()
            for (i in 0 until possibleLengthSize) {
                possibleLength_.add(input.readInt())
            }
            val possibleLengthLocalOnlySize = input.readInt()
            for (i in 0 until possibleLengthLocalOnlySize) {
                possibleLengthLocalOnly_.add(input.readInt())
            }
            if (input.readBoolean()) {
                setExampleNumber(input.readUTF())
            }
        }

        companion object {
            private const val serialVersionUID: Long = 1
            @JvmStatic
            fun newBuilder(): Builder {
                return Builder()
            }
        }
    }

    open class PhoneMetadata : Externalizable {
        /**
         * Provides a dummy builder.
         *
         * @see NumberFormat.Builder
         */
        class Builder : PhoneMetadata() {
            fun build(): PhoneMetadata {
                return this
            }

            override fun setId(value: String?): Builder {
                super.setId(value)
                return this
            }

            override fun setInternationalPrefix(value: String): Builder {
                super.setInternationalPrefix(value)
                return this
            }
        }

        // optional PhoneNumberDesc general_desc = 1;
        private var hasGeneralDesc = false
        var generalDesc: PhoneNumberDesc? = null
            private set

        fun hasGeneralDesc(): Boolean {
            return hasGeneralDesc
        }

        val generalDescBuilder: PhoneNumberDesc
            get() {
                if (generalDesc == null) {
                    generalDesc = PhoneNumberDesc()
                }
                return generalDesc!!
            }

        fun setGeneralDesc(value: PhoneNumberDesc?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            hasGeneralDesc = true
            generalDesc = value
            return this
        }

        // optional PhoneNumberDesc fixed_line = 2;
        private var hasFixedLine = false
        var fixedLine: PhoneNumberDesc? = null
            private set

        fun hasFixedLine(): Boolean {
            return hasFixedLine
        }

        fun setFixedLine(value: PhoneNumberDesc?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            hasFixedLine = true
            fixedLine = value
            return this
        }

        // optional PhoneNumberDesc mobile = 3;
        private var hasMobile = false
        var mobile: PhoneNumberDesc? = null
            private set

        fun hasMobile(): Boolean {
            return hasMobile
        }

        fun setMobile(value: PhoneNumberDesc?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            hasMobile = true
            mobile = value
            return this
        }

        // optional PhoneNumberDesc toll_free = 4;
        private var hasTollFree = false
        var tollFree: PhoneNumberDesc? = null
            private set

        fun hasTollFree(): Boolean {
            return hasTollFree
        }

        fun setTollFree(value: PhoneNumberDesc?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            hasTollFree = true
            tollFree = value
            return this
        }

        // optional PhoneNumberDesc premium_rate = 5;
        private var hasPremiumRate = false
        var premiumRate: PhoneNumberDesc? = null
            private set

        fun hasPremiumRate(): Boolean {
            return hasPremiumRate
        }

        fun setPremiumRate(value: PhoneNumberDesc?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            hasPremiumRate = true
            premiumRate = value
            return this
        }

        // optional PhoneNumberDesc shared_cost = 6;
        private var hasSharedCost = false
        var sharedCost: PhoneNumberDesc? = null
            private set

        fun hasSharedCost(): Boolean {
            return hasSharedCost
        }

        fun setSharedCost(value: PhoneNumberDesc?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            hasSharedCost = true
            sharedCost = value
            return this
        }

        // optional PhoneNumberDesc personal_number = 7;
        private var hasPersonalNumber = false
        var personalNumber: PhoneNumberDesc? = null
            private set

        fun hasPersonalNumber(): Boolean {
            return hasPersonalNumber
        }

        fun setPersonalNumber(value: PhoneNumberDesc?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            hasPersonalNumber = true
            personalNumber = value
            return this
        }

        // optional PhoneNumberDesc voip = 8;
        private var hasVoip = false
        var voip: PhoneNumberDesc? = null
            private set

        fun hasVoip(): Boolean {
            return hasVoip
        }

        fun setVoip(value: PhoneNumberDesc?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            hasVoip = true
            voip = value
            return this
        }

        // optional PhoneNumberDesc pager = 21;
        private var hasPager = false
        var pager: PhoneNumberDesc? = null
            private set

        fun hasPager(): Boolean {
            return hasPager
        }

        fun setPager(value: PhoneNumberDesc?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            hasPager = true
            pager = value
            return this
        }

        // optional PhoneNumberDesc uan = 25;
        private var hasUan = false
        var uan: PhoneNumberDesc? = null
            private set

        fun hasUan(): Boolean {
            return hasUan
        }

        fun setUan(value: PhoneNumberDesc?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            hasUan = true
            uan = value
            return this
        }

        // optional PhoneNumberDesc emergency = 27;
        private var hasEmergency = false
        var emergency: PhoneNumberDesc? = null
            private set

        fun hasEmergency(): Boolean {
            return hasEmergency
        }

        fun setEmergency(value: PhoneNumberDesc?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            hasEmergency = true
            emergency = value
            return this
        }

        // optional PhoneNumberDesc voicemail = 28;
        private var hasVoicemail = false
        var voicemail: PhoneNumberDesc? = null
            private set

        fun hasVoicemail(): Boolean {
            return hasVoicemail
        }

        fun setVoicemail(value: PhoneNumberDesc?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            hasVoicemail = true
            voicemail = value
            return this
        }

        // optional PhoneNumberDesc short_code = 29;
        private var hasShortCode = false
        var shortCode: PhoneNumberDesc? = null
            private set

        fun hasShortCode(): Boolean {
            return hasShortCode
        }

        fun setShortCode(value: PhoneNumberDesc?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            hasShortCode = true
            shortCode = value
            return this
        }

        // optional PhoneNumberDesc standard_rate = 30;
        private var hasStandardRate = false
        var standardRate: PhoneNumberDesc? = null
            private set

        fun hasStandardRate(): Boolean {
            return hasStandardRate
        }

        fun setStandardRate(value: PhoneNumberDesc?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            hasStandardRate = true
            standardRate = value
            return this
        }

        // optional PhoneNumberDesc carrier_specific = 31;
        private var hasCarrierSpecific = false
        var carrierSpecific: PhoneNumberDesc? = null
            private set

        fun hasCarrierSpecific(): Boolean {
            return hasCarrierSpecific
        }

        fun setCarrierSpecific(value: PhoneNumberDesc?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            hasCarrierSpecific = true
            carrierSpecific = value
            return this
        }

        // optional PhoneNumberDesc sms_services = 33;
        private var hasSmsServices = false
        var smsServices: PhoneNumberDesc? = null
            private set

        fun hasSmsServices(): Boolean {
            return hasSmsServices
        }

        fun setSmsServices(value: PhoneNumberDesc?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            hasSmsServices = true
            smsServices = value
            return this
        }

        // optional PhoneNumberDesc noInternationalDialling = 24;
        private var hasNoInternationalDialling = false
        var noInternationalDialling: PhoneNumberDesc? = null
            private set

        fun hasNoInternationalDialling(): Boolean {
            return hasNoInternationalDialling
        }

        fun setNoInternationalDialling(value: PhoneNumberDesc?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            hasNoInternationalDialling = true
            noInternationalDialling = value
            return this
        }

        // required string id = 9;
        private var hasId = false
        var id: String? = ""
            private set

        fun hasId(): Boolean {
            return hasId
        }

        open fun setId(value: String?): PhoneMetadata {
            hasId = true
            id = value
            return this
        }

        // optional int32 country_code = 10;
        private var hasCountryCode = false
        var countryCode = 0
            private set

        fun hasCountryCode(): Boolean {
            return hasCountryCode
        }

        fun setCountryCode(value: Int): PhoneMetadata {
            hasCountryCode = true
            countryCode = value
            return this
        }

        // optional string international_prefix = 11;
        private var hasInternationalPrefix = false
        var internationalPrefix: String = ""
            private set

        fun hasInternationalPrefix(): Boolean {
            return hasInternationalPrefix
        }

        open fun setInternationalPrefix(value: String): PhoneMetadata {
            hasInternationalPrefix = true
            internationalPrefix = value
            return this
        }

        // optional string preferred_international_prefix = 17;
        private var hasPreferredInternationalPrefix = false
        var preferredInternationalPrefix = ""
            private set

        fun hasPreferredInternationalPrefix(): Boolean {
            return hasPreferredInternationalPrefix
        }

        fun setPreferredInternationalPrefix(value: String): PhoneMetadata {
            hasPreferredInternationalPrefix = true
            preferredInternationalPrefix = value
            return this
        }

        fun clearPreferredInternationalPrefix(): PhoneMetadata {
            hasPreferredInternationalPrefix = false
            preferredInternationalPrefix = ""
            return this
        }

        // optional string national_prefix = 12;
        private var hasNationalPrefix = false
        var nationalPrefix = ""
            private set

        fun hasNationalPrefix(): Boolean {
            return hasNationalPrefix
        }

        fun setNationalPrefix(value: String): PhoneMetadata {
            hasNationalPrefix = true
            nationalPrefix = value
            return this
        }

        fun clearNationalPrefix(): PhoneMetadata {
            hasNationalPrefix = false
            nationalPrefix = ""
            return this
        }

        // optional string preferred_extn_prefix = 13;
        private var hasPreferredExtnPrefix = false
        var preferredExtnPrefix = ""
            private set

        fun hasPreferredExtnPrefix(): Boolean {
            return hasPreferredExtnPrefix
        }

        fun setPreferredExtnPrefix(value: String): PhoneMetadata {
            hasPreferredExtnPrefix = true
            preferredExtnPrefix = value
            return this
        }

        fun clearPreferredExtnPrefix(): PhoneMetadata {
            hasPreferredExtnPrefix = false
            preferredExtnPrefix = ""
            return this
        }

        // optional string national_prefix_for_parsing = 15;
        private var hasNationalPrefixForParsing = false
        var nationalPrefixForParsing = ""
            private set

        fun hasNationalPrefixForParsing(): Boolean {
            return hasNationalPrefixForParsing
        }

        fun setNationalPrefixForParsing(value: String): PhoneMetadata {
            hasNationalPrefixForParsing = true
            nationalPrefixForParsing = value
            return this
        }

        // optional string national_prefix_transform_rule = 16;
        private var hasNationalPrefixTransformRule = false
        var nationalPrefixTransformRule = ""
            private set

        fun hasNationalPrefixTransformRule(): Boolean {
            return hasNationalPrefixTransformRule
        }

        fun setNationalPrefixTransformRule(value: String): PhoneMetadata {
            hasNationalPrefixTransformRule = true
            nationalPrefixTransformRule = value
            return this
        }

        fun clearNationalPrefixTransformRule(): PhoneMetadata {
            hasNationalPrefixTransformRule = false
            nationalPrefixTransformRule = ""
            return this
        }

        // optional bool same_mobile_and_fixed_line_pattern = 18 [default = false];
        private var hasSameMobileAndFixedLinePattern = false
        var sameMobileAndFixedLinePattern = false
            private set

        fun hasSameMobileAndFixedLinePattern(): Boolean {
            return hasSameMobileAndFixedLinePattern
        }

        fun setSameMobileAndFixedLinePattern(value: Boolean): PhoneMetadata {
            hasSameMobileAndFixedLinePattern = true
            sameMobileAndFixedLinePattern = value
            return this
        }

        fun clearSameMobileAndFixedLinePattern(): PhoneMetadata {
            hasSameMobileAndFixedLinePattern = false
            sameMobileAndFixedLinePattern = false
            return this
        }

        // repeated NumberFormat number_format = 19;
        private val numberFormat_: MutableList<NumberFormat> = ArrayList()
        @Deprecated("")
        fun numberFormats(): List<NumberFormat> {
            return numberFormatList
        }

        val numberFormatList: List<NumberFormat>
            get() = numberFormat_

        @Deprecated("")
        fun numberFormatSize(): Int {
            return numberFormatCount
        }

        val numberFormatCount: Int
            get() = numberFormat_.size

        fun getNumberFormat(index: Int): NumberFormat {
            return numberFormat_[index]
        }

        fun addNumberFormat(value: NumberFormat?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            numberFormat_.add(value)
            return this
        }

        // repeated NumberFormat intl_number_format = 20;
        private val intlNumberFormat_: MutableList<NumberFormat> = ArrayList()
        @Deprecated("")
        fun intlNumberFormats(): List<NumberFormat> {
            return intlNumberFormatList
        }

        val intlNumberFormatList: List<NumberFormat>
            get() = intlNumberFormat_

        @Deprecated("")
        fun intlNumberFormatSize(): Int {
            return intlNumberFormatCount
        }

        val intlNumberFormatCount: Int
            get() = intlNumberFormat_.size

        fun getIntlNumberFormat(index: Int): NumberFormat {
            return intlNumberFormat_[index]
        }

        fun addIntlNumberFormat(value: NumberFormat?): PhoneMetadata {
            if (value == null) {
                throw NullPointerException()
            }
            intlNumberFormat_.add(value)
            return this
        }

        fun clearIntlNumberFormat(): PhoneMetadata {
            intlNumberFormat_.clear()
            return this
        }

        // optional bool main_country_for_code = 22 [default = false];
        private var hasMainCountryForCode = false
        var isMainCountryForCode = false
            private set

        fun hasMainCountryForCode(): Boolean {
            return hasMainCountryForCode
        }

        // Method that lets this class have the same interface as the one generated by Protocol Buffers
        // which is used by C++ build tools.
        fun getMainCountryForCode(): Boolean {
            return isMainCountryForCode
        }

        fun setMainCountryForCode(value: Boolean): PhoneMetadata {
            hasMainCountryForCode = true
            isMainCountryForCode = value
            return this
        }

        fun clearMainCountryForCode(): PhoneMetadata {
            hasMainCountryForCode = false
            isMainCountryForCode = false
            return this
        }

        // optional string leading_digits = 23;
        private var hasLeadingDigits = false
        var leadingDigits = ""
            private set

        fun hasLeadingDigits(): Boolean {
            return hasLeadingDigits
        }

        fun setLeadingDigits(value: String): PhoneMetadata {
            hasLeadingDigits = true
            leadingDigits = value
            return this
        }

        // optional bool mobile_number_portable_region = 32 [default = false];
        private var hasMobileNumberPortableRegion = false
        private var mobileNumberPortableRegion_ = false
        fun hasMobileNumberPortableRegion(): Boolean {
            return hasMobileNumberPortableRegion
        }

        @get:Deprecated("")
        val isMobileNumberPortableRegion: Boolean
            get() = getMobileNumberPortableRegion()

        fun getMobileNumberPortableRegion(): Boolean {
            return mobileNumberPortableRegion_
        }

        fun setMobileNumberPortableRegion(value: Boolean): PhoneMetadata {
            hasMobileNumberPortableRegion = true
            mobileNumberPortableRegion_ = value
            return this
        }

        fun clearMobileNumberPortableRegion(): PhoneMetadata {
            hasMobileNumberPortableRegion = false
            mobileNumberPortableRegion_ = false
            return this
        }

        override fun writeExternal(objectOutput: ObjectOutput) {
            objectOutput.writeBoolean(hasGeneralDesc)
            if (hasGeneralDesc) {
                generalDesc!!.writeExternal(objectOutput)
            }
            objectOutput.writeBoolean(hasFixedLine)
            if (hasFixedLine) {
                fixedLine!!.writeExternal(objectOutput)
            }
            objectOutput.writeBoolean(hasMobile)
            if (hasMobile) {
                mobile!!.writeExternal(objectOutput)
            }
            objectOutput.writeBoolean(hasTollFree)
            if (hasTollFree) {
                tollFree!!.writeExternal(objectOutput)
            }
            objectOutput.writeBoolean(hasPremiumRate)
            if (hasPremiumRate) {
                premiumRate!!.writeExternal(objectOutput)
            }
            objectOutput.writeBoolean(hasSharedCost)
            if (hasSharedCost) {
                sharedCost!!.writeExternal(objectOutput)
            }
            objectOutput.writeBoolean(hasPersonalNumber)
            if (hasPersonalNumber) {
                personalNumber!!.writeExternal(objectOutput)
            }
            objectOutput.writeBoolean(hasVoip)
            if (hasVoip) {
                voip!!.writeExternal(objectOutput)
            }
            objectOutput.writeBoolean(hasPager)
            if (hasPager) {
                pager!!.writeExternal(objectOutput)
            }
            objectOutput.writeBoolean(hasUan)
            if (hasUan) {
                uan!!.writeExternal(objectOutput)
            }
            objectOutput.writeBoolean(hasEmergency)
            if (hasEmergency) {
                emergency!!.writeExternal(objectOutput)
            }
            objectOutput.writeBoolean(hasVoicemail)
            if (hasVoicemail) {
                voicemail!!.writeExternal(objectOutput)
            }
            objectOutput.writeBoolean(hasShortCode)
            if (hasShortCode) {
                shortCode!!.writeExternal(objectOutput)
            }
            objectOutput.writeBoolean(hasStandardRate)
            if (hasStandardRate) {
                standardRate!!.writeExternal(objectOutput)
            }
            objectOutput.writeBoolean(hasCarrierSpecific)
            if (hasCarrierSpecific) {
                carrierSpecific!!.writeExternal(objectOutput)
            }
            objectOutput.writeBoolean(hasSmsServices)
            if (hasSmsServices) {
                smsServices!!.writeExternal(objectOutput)
            }
            objectOutput.writeBoolean(hasNoInternationalDialling)
            if (hasNoInternationalDialling) {
                noInternationalDialling!!.writeExternal(objectOutput)
            }
            objectOutput.writeUTF(id)
            objectOutput.writeInt(countryCode)
            objectOutput.writeUTF(internationalPrefix)
            objectOutput.writeBoolean(hasPreferredInternationalPrefix)
            if (hasPreferredInternationalPrefix) {
                objectOutput.writeUTF(preferredInternationalPrefix)
            }
            objectOutput.writeBoolean(hasNationalPrefix)
            if (hasNationalPrefix) {
                objectOutput.writeUTF(nationalPrefix)
            }
            objectOutput.writeBoolean(hasPreferredExtnPrefix)
            if (hasPreferredExtnPrefix) {
                objectOutput.writeUTF(preferredExtnPrefix)
            }
            objectOutput.writeBoolean(hasNationalPrefixForParsing)
            if (hasNationalPrefixForParsing) {
                objectOutput.writeUTF(nationalPrefixForParsing)
            }
            objectOutput.writeBoolean(hasNationalPrefixTransformRule)
            if (hasNationalPrefixTransformRule) {
                objectOutput.writeUTF(nationalPrefixTransformRule)
            }
            objectOutput.writeBoolean(sameMobileAndFixedLinePattern)
            val numberFormatSize = numberFormatSize()
            objectOutput.writeInt(numberFormatSize)
            for (i in 0 until numberFormatSize) {
                numberFormat_[i].writeExternal(objectOutput)
            }
            val intlNumberFormatSize = intlNumberFormatSize()
            objectOutput.writeInt(intlNumberFormatSize)
            for (i in 0 until intlNumberFormatSize) {
                intlNumberFormat_[i].writeExternal(objectOutput)
            }
            objectOutput.writeBoolean(isMainCountryForCode)
            objectOutput.writeBoolean(hasLeadingDigits)
            if (hasLeadingDigits) {
                objectOutput.writeUTF(leadingDigits)
            }
            objectOutput.writeBoolean(mobileNumberPortableRegion_)
        }

        override fun readExternal(input: ObjectInput) {
            var hasDesc = input.readBoolean()
            if (hasDesc) {
                val desc = PhoneNumberDesc()
                desc.readExternal(input)
                setGeneralDesc(desc)
            }
            hasDesc = input.readBoolean()
            if (hasDesc) {
                val desc = PhoneNumberDesc()
                desc.readExternal(input)
                setFixedLine(desc)
            }
            hasDesc = input.readBoolean()
            if (hasDesc) {
                val desc = PhoneNumberDesc()
                desc.readExternal(input)
                setMobile(desc)
            }
            hasDesc = input.readBoolean()
            if (hasDesc) {
                val desc = PhoneNumberDesc()
                desc.readExternal(input)
                setTollFree(desc)
            }
            hasDesc = input.readBoolean()
            if (hasDesc) {
                val desc = PhoneNumberDesc()
                desc.readExternal(input)
                setPremiumRate(desc)
            }
            hasDesc = input.readBoolean()
            if (hasDesc) {
                val desc = PhoneNumberDesc()
                desc.readExternal(input)
                setSharedCost(desc)
            }
            hasDesc = input.readBoolean()
            if (hasDesc) {
                val desc = PhoneNumberDesc()
                desc.readExternal(input)
                setPersonalNumber(desc)
            }
            hasDesc = input.readBoolean()
            if (hasDesc) {
                val desc = PhoneNumberDesc()
                desc.readExternal(input)
                setVoip(desc)
            }
            hasDesc = input.readBoolean()
            if (hasDesc) {
                val desc = PhoneNumberDesc()
                desc.readExternal(input)
                setPager(desc)
            }
            hasDesc = input.readBoolean()
            if (hasDesc) {
                val desc = PhoneNumberDesc()
                desc.readExternal(input)
                setUan(desc)
            }
            hasDesc = input.readBoolean()
            if (hasDesc) {
                val desc = PhoneNumberDesc()
                desc.readExternal(input)
                setEmergency(desc)
            }
            hasDesc = input.readBoolean()
            if (hasDesc) {
                val desc = PhoneNumberDesc()
                desc.readExternal(input)
                setVoicemail(desc)
            }
            hasDesc = input.readBoolean()
            if (hasDesc) {
                val desc = PhoneNumberDesc()
                desc.readExternal(input)
                setShortCode(desc)
            }
            hasDesc = input.readBoolean()
            if (hasDesc) {
                val desc = PhoneNumberDesc()
                desc.readExternal(input)
                setStandardRate(desc)
            }
            hasDesc = input.readBoolean()
            if (hasDesc) {
                val desc = PhoneNumberDesc()
                desc.readExternal(input)
                setCarrierSpecific(desc)
            }
            hasDesc = input.readBoolean()
            if (hasDesc) {
                val desc = PhoneNumberDesc()
                desc.readExternal(input)
                setSmsServices(desc)
            }
            hasDesc = input.readBoolean()
            if (hasDesc) {
                val desc = PhoneNumberDesc()
                desc.readExternal(input)
                setNoInternationalDialling(desc)
            }
            setId(input.readUTF())
            setCountryCode(input.readInt())
            setInternationalPrefix(input.readUTF())
            var hasString = input.readBoolean()
            if (hasString) {
                setPreferredInternationalPrefix(input.readUTF())
            }
            hasString = input.readBoolean()
            if (hasString) {
                setNationalPrefix(input.readUTF())
            }
            hasString = input.readBoolean()
            if (hasString) {
                setPreferredExtnPrefix(input.readUTF())
            }
            hasString = input.readBoolean()
            if (hasString) {
                setNationalPrefixForParsing(input.readUTF())
            }
            hasString = input.readBoolean()
            if (hasString) {
                setNationalPrefixTransformRule(input.readUTF())
            }
            setSameMobileAndFixedLinePattern(input.readBoolean())
            val nationalFormatSize = input.readInt()
            for (i in 0 until nationalFormatSize) {
                val numFormat = NumberFormat()
                numFormat.readExternal(input)
                numberFormat_.add(numFormat)
            }
            val intlNumberFormatSize = input.readInt()
            for (i in 0 until intlNumberFormatSize) {
                val numFormat = NumberFormat()
                numFormat.readExternal(input)
                intlNumberFormat_.add(numFormat)
            }
            setMainCountryForCode(input.readBoolean())
            hasString = input.readBoolean()
            if (hasString) {
                setLeadingDigits(input.readUTF())
            }
            setMobileNumberPortableRegion(input.readBoolean())
        }

        companion object {
            private const val serialVersionUID: Long = 1
            @JvmStatic
            fun newBuilder(): Builder {
                return Builder()
            }
        }
    }

    open class PhoneMetadataCollection : Externalizable {
        /**
         * Provides a dummy builder.
         *
         * @see NumberFormat.Builder
         */
        class Builder : PhoneMetadataCollection() {
            fun build(): PhoneMetadataCollection {
                return this
            }
        }

        // repeated PhoneMetadata metadata = 1;
        private val metadata_: MutableList<PhoneMetadata> = ArrayList()
        val metadataList: List<PhoneMetadata>
            get() = metadata_
        val metadataCount: Int
            get() = metadata_.size

        fun addMetadata(value: PhoneMetadata?): PhoneMetadataCollection {
            if (value == null) {
                throw NullPointerException()
            }
            metadata_.add(value)
            return this
        }

        override fun writeExternal(out: ObjectOutput) {
            val size = metadataCount
            out.writeInt(size)
            for (i in 0 until size) {
                metadata_[i].writeExternal(out)
            }
        }

        override fun readExternal(input: ObjectInput) {
            val size = input.readInt()
            for (i in 0 until size) {
                val metadata = PhoneMetadata()
                metadata.readExternal(input)
                metadata_.add(metadata)
            }
        }

        fun clear(): PhoneMetadataCollection {
            metadata_.clear()
            return this
        }

        companion object {
            private const val serialVersionUID: Long = 1
            @JvmStatic
            fun newBuilder(): Builder {
                return Builder()
            }
        }
    }
}
