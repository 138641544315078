package io.eqoty.shared.datalayer.functions

import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.AttachmentAccess
import io.eqoty.shared.datalayer.objects.AttachmentEndpoint
import io.eqoty.shared.datalayer.objects.StreamingFormatPreference

suspend fun Repository.getAudioAttachmentEndpoint(
    releaseId: UInt,
    purchaseId: BigInteger?,
    formatPref: StreamingFormatPreference,
    access: AttachmentAccess
): AttachmentEndpoint {
    val nftMetadata = if (purchaseId != null) {
        // the purchase could have no nftMetadata if the user didn't "burn" their own audio files to the nft
        getPurchaseNft(releaseId, purchaseId)?.nftMetadata ?: getRelease(releaseId)!!.nftMetadata
    } else {
        getRelease(releaseId)!!.nftMetadata
    }
    val accessedTracks = when (access) {
        AttachmentAccess.PUBLIC -> nftMetadata.publicAudioResources
        AttachmentAccess.PRIVATE -> nftMetadata.privateAudioResources
    }
    val formatPrefFiltered = accessedTracks
        .filter { it.container == formatPref.container.container }
        .sortedBy { it.size }

    val selection = when (formatPref.quality) {
        StreamingFormatPreference.Quality.Low -> formatPrefFiltered[0]

        StreamingFormatPreference.Quality.Medium -> when (formatPrefFiltered.size) {
            1 -> formatPrefFiltered[0]
            2 -> formatPrefFiltered[1]
            3 -> formatPrefFiltered[1]
            else -> {
                if (formatPrefFiltered.size > 3) {
                    formatPrefFiltered[formatPrefFiltered.size / 2]
                } else {
                    throw Error("No attachments of type ${formatPref.container} are available")
                }
            }
        }

        StreamingFormatPreference.Quality.High -> formatPrefFiltered.last()
    }

    val key = when (access) {
        AttachmentAccess.PUBLIC -> null
        AttachmentAccess.PRIVATE -> getPurchasePrivateKey(releaseId, purchaseId!!)
    }
    return AttachmentEndpoint(selection.url.substringAfterLast("/"), key)
}