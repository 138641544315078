package io.eqoty.shared.viewmodel.screens

import io.eqoty.shared.viewmodel.ScreenIdentifier
import io.eqoty.shared.viewmodel.StateManager
import io.eqoty.shared.viewmodel.screens.bottomnavigationbar.initBottomNavigationBar
import io.eqoty.shared.viewmodel.screens.connectwallet.initConnectWallet
import io.eqoty.shared.viewmodel.screens.home.initHome
import io.eqoty.shared.viewmodel.screens.leftnavigationrail.initLeftNavigationRail
import io.eqoty.shared.viewmodel.screens.mediaplayer.initMediaPlayerWidget
import io.eqoty.shared.viewmodel.screens.mintsong.initMintSong
import io.eqoty.shared.viewmodel.screens.myorganizations.initMyOrganizations
import io.eqoty.shared.viewmodel.screens.myroyalties.initMyRoyalties
import io.eqoty.shared.viewmodel.screens.nftpurchaselibrary.initNftPurchaseLibrary
import io.eqoty.shared.viewmodel.screens.organizationprofile.initOrganizationProfile
import io.eqoty.shared.viewmodel.screens.organizationregistrationinfo.initOrganizationRegistrationInfo
import io.eqoty.shared.viewmodel.screens.releaserprofile.initReleaserProfile
import io.eqoty.shared.viewmodel.screens.royaltynftdetail.initRoyaltyNftDetail
import io.eqoty.shared.viewmodel.screens.scaffold.initScaffold
import io.eqoty.shared.viewmodel.screens.store.initStore
import io.eqoty.shared.viewmodel.screens.topbar.initTopBar
import io.eqoty.shared.viewmodel.screens.walletInfo.initWalletInfo
import io.eqoty.shared.viewmodel.screens.wallettxrequest.initWalletTxRequestScreen


// DEFINITION OF ALL SCREENS IN THE APP

enum class Screen(
    val asString: String,
    val navigationLevel: Int = 1,
    val initSettings: StateManager.(ScreenIdentifier) -> ScreenInitSettings,
) {
    Scaffold("Scaffold", 1, { initScaffold() }),
    TopBar("Appbar", 1, { initTopBar() }),
    BottomNavigationBar("BottomNavBar", 1, { initBottomNavigationBar() }),
    LeftNavigationRail("LeftNavRail", 1, { initLeftNavigationRail() }),
    ConnectWallet("ConnectWallet", 1, { initConnectWallet(it.params()) }),
    WalletInfo("WalletInfo", 2, { initWalletInfo() }),
    WalletTxRequest("WalletTxRequest", 2, { initWalletTxRequestScreen(it.params()) }),
    Home("home", 1, { initHome() }),
    MyRoyalties("MyRoyalties", 1, { initMyRoyalties(it.params()) }),
    MintSong("MintSong", 2, { initMintSong(it.params()) }),
    Store("Store", 1, { initStore(it.params()) }),
    NftPurchaseLibrary("NftPurchaseLibrary", 1, { initNftPurchaseLibrary() }),
    RoyaltyNftDetail("RoyaltyNftDetail", 2, { initRoyaltyNftDetail(it.params()) }),
    MediaPlayerWidget("MediaPlayerWidget", 1, { initMediaPlayerWidget() }),
    ReleaserProfile("ReleaserProfile", 1, { initReleaserProfile() }),
    MyOrganizations("MyOrganizations", 1, { initMyOrganizations() }),
    OrganizationRegistrationInfo("OrganizationRegistrationInfo", 2, { initOrganizationRegistrationInfo(it.params()) }),
    OrganizationProfile("OrganizationProfile", 2, { initOrganizationProfile(it.params()) }),
}
