package io.eqoty.shared.viewmodel

import co.touchlab.kermit.Logger
import io.eqoty.shared.viewmodel.screens.ScreenStack
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch


class Events(val stateManager: StateManager) {

    val dataRepository
        get() = stateManager.dataRepository

    // we run each event function on a Dispatchers.Main coroutine
    fun inScreenScopeLaunch(screenStack: ScreenStack, block: suspend () -> Unit): Job? {
        debugLogger.i("/$screenStack:${stateManager.currentScreenIdentifier(screenStack).URI}: an Event is called")
        return stateManager.runInScreenScope(screenStack) { block() }
    }

    // to start cpu intensive or network event tasks in background inside event functions on a Dispatchers.Default coroutine
    fun inScreenScopeLaunchInBackground(screenStack: ScreenStack, block: suspend CoroutineScope.() -> Unit): Job? {
        debugLogger.i("/$screenStack:${stateManager.currentScreenIdentifier(screenStack).URI}: an Event started a background task")
        return stateManager.inScreenScopeLaunchInBackground(screenStack) { block() }
    }

    // to start cpu intensive or network event tasks in background inside event functions on a Dispatchers.Default coroutine
    fun inAppScopeLaunchInBackground(screenStack: ScreenStack, block: suspend () -> Unit): Job {
        Logger.d("/" + stateManager.currentScreenIdentifier(screenStack).URI + ": an Event started a background app task")
        return MainScope().launch {
            try {
                block()
            } catch (t: Throwable) {
                // TODO: report these uncaught errors to bugsnag or similar exception tracker
                Logger.e(t) {
                    "Uncaught exception in backgroundAppCoroutine. " +
                            "TODO: report these uncaught errors to bugsnag or similar exception tracker"
                }
            }
        }
    }


}