package io.eqoty.composables.navigation

import androidx.compose.runtime.saveable.SaveableStateHolder
import co.touchlab.kermit.Logger
import io.eqoty.shared.ui.utils.ScreenSizeUtils
import io.eqoty.shared.ui.utils.smallScaffoldActive
import io.eqoty.shared.viewmodel.Navigation
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.screens.scaffold.ScaffoldScreenState
import io.eqoty.shared.viewmodel.screens.scaffold.setWalletScreenExpanded
import kotlinx.browser.window

fun Navigation.HandleBackButton(saveableStateHolder: SaveableStateHolder) {
    window.onpopstate = { it ->
        Logger.d(it.toString())
        val navState = screenStackToNavigationState[ScreenStack.Scaffold]!!
        val scaffoldScreenIdentifier = navState.topScreenIdentifier

        val scaffoldScreenState =
            stateProvider.getScreenStateFlow(
                ScreenStack.Scaffold,
                scaffoldScreenIdentifier
            ).value as ScaffoldScreenState

        if (ScreenSizeUtils.smallScaffoldActive && scaffoldScreenState.walletScreenExpanded) {
            stateManager.events.setWalletScreenExpanded(false)
        } else if (!screenStackToNavigationState[ScreenStack.Main]!!.nextBackQuitsApp) {
            onBackPressed(saveableStateHolder)
        } else {
            Logger.d("onpopstate called but already in root L1 screen")
        }
    }

}

fun Navigation.onBackPressed(
    saveableStateHolder: SaveableStateHolder,
) {
    val navState = screenStackToNavigationState[ScreenStack.Main]!!
    val originScreenIdentifier = navState.topScreenIdentifier
    exitScreen(ScreenStack.Main, originScreenIdentifier)
    saveableStateHolder.removeState(originScreenIdentifier)
}