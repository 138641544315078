package io.eqoty.shared.ui.utils

object ScreenSizeUtils {

    fun isPortrait(width: Int, height: Int) = width < height

    fun isSmallScreen(width: Int, threshold: Int) = width < threshold


    fun isSmallScaffoldMode(maxWidth: Int, maxHeight: Int) =
        isPortrait(maxWidth, maxHeight) || isSmallScreen(maxWidth, BootstrapBreakpoint.Md.px)
}