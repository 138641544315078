package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOne
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import io.eqoty.cosmwasm.std.types.ContractInfo
import io.eqoty.shared.datalayer.objects.AttachmentAccess
import io.eqoty.shared.datalayer.objects.Release
import mylocal.db.LocalDb


suspend fun LocalDb.getRelease(id: UInt): Release? {
    val r = releaseTableQueries.getRelease(id).awaitAsOneOrNull()
    return r?.let { buildRelease(it) }
}

suspend fun LocalDb.releaseExists(id: UInt): Boolean {
    return releaseTableQueries.releaseExists(id).awaitAsOne()
}

suspend fun LocalDb.getReleasesByOrganization(organizationId: ULong, sorted: Boolean = true): List<Release> {
    val releases = releaseTableQueries.getReleaseListByOrganization(organizationId).awaitAsList()
    return releases.map { r ->
        buildRelease(r)
    }.apply {
        if (sorted) {
            sortedBy { it.id }
        }
    }
}

suspend fun LocalDb.getReleaseList(sorted: Boolean = true): List<Release> {
    val releases = releaseTableQueries.getReleaseList().awaitAsList()
    return releases.map { r ->
        buildRelease(r)
    }.apply {
        if (sorted) {
            sortedBy { it.id }
        }
    }
}

suspend fun LocalDb.buildRelease(releaseTable: ReleaseTable): Release {
    return Release(
        id = releaseTable.id,
        organizationId = releaseTable.organizationId,
        listed = releaseTable.listed,
        productDealer = ContractInfo(
            releaseTable.productDealerAddress,
            releaseTable.productDealerCodeHash
        ),
        royaltyDealer = ContractInfo(
            releaseTable.royaltyDealerAddress,
            releaseTable.royaltyDealerCodeHash
        ),
        privateAuthenticationKey = releaseTable.privateAuthenticationKey,
        nftMetadata = getNftMetadataForRelease(releaseTable.id)!!
    )
}

suspend fun LocalDb.upsertReleaseList(list: List<Release>) {
    upsertNftMetadataList(list.map { it.nftMetadata })
    releaseTableQueries.transaction {
        list.forEach { release ->
            releaseTableQueries.upsertRelease(
                id = release.id,
                organizationId = release.organizationId,
                listed = release.listed,
                productDealerAddress = release.productDealer.address,
                productDealerCodeHash = release.productDealer.codeHash,
                royaltyDealerAddress = release.royaltyDealer.address,
                royaltyDealerCodeHash = release.royaltyDealer.codeHash,
                privateAuthenticationKey = release.privateAuthenticationKey,
                nftMetadataId = release.nftMetadata.id
            )
        }
    }
}

suspend fun LocalDb.upsertRelease(release: Release) {
    upsertNftMetadata(release.nftMetadata)
    val dbMetadataId = getNftMetadataIdForReleaseId(release.id)
    upsertAudioTrackList(
        dbMetadataId,
        release.nftMetadata.publicAudioResources,
        AttachmentAccess.PUBLIC,
        0
    )
    upsertAudioTrackList(
        dbMetadataId,
        release.nftMetadata.privateAudioResources,
        AttachmentAccess.PRIVATE,
        0
    )
    releaseTableQueries.transaction {
        releaseTableQueries.upsertRelease(
            id = release.id,
            organizationId = release.organizationId,
            listed = release.listed,
            productDealerAddress = release.productDealer.address,
            productDealerCodeHash = release.productDealer.codeHash,
            royaltyDealerAddress = release.royaltyDealer.address,
            royaltyDealerCodeHash = release.royaltyDealer.codeHash,
            privateAuthenticationKey = release.privateAuthenticationKey,
            nftMetadataId = release.nftMetadata.id
        )
    }
}

fun LocalDb.countReleaseTable() =
    releaseTableQueries.countReleaseTable()

fun LocalDb.getReleaseWhereIdNot(ids: Collection<UInt>) =
    releaseTableQueries.getReleaseWhereIdNot(ids)

suspend fun LocalDb.deleteRelease(id: UInt) {
    return releaseTableQueries.deleteById(id)
}

suspend fun LocalDb.deleteAllReleases() {
    return releaseTableQueries.deleteTable()
}