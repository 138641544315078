package io.eqoty.shared.datalayer.sources.filesystem

expect class CommonFile {
    val name: String
    val path: String
    val platformFile: Any
    val size: Long
    suspend fun getBytes(): ByteArray
}

val CommonFile.nameWithoutExtension: String
    get() = name.substring(0, name.lastIndexOf('.'))
