package io.eqoty.shared.datalayer.functions

import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.PurchaseNft
import io.eqoty.shared.datalayer.objects.Release
import io.eqoty.shared.datalayer.sources.*
import co.touchlab.kermit.Logger
import io.eqoty.shared.datalayer.sources.localdb.nfts.getReleaseList
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.receiveAsFlow
import kotlinx.coroutines.launch

typealias ReleaseAndPurchases = Pair<Release, List<PurchaseNft>>

suspend fun Repository.getOwnedPurchaseNfts(
    screenScope: CoroutineScope,
    src: DataSrc
): Flow<List<ReleaseAndPurchases>> =
    withRepoContext {
        val results = Channel<List<ReleaseAndPurchases>>()
        try {
            if (src is LocalSrc) {
                withBackgroundRepoContext(screenScope) {
                    sendLocal(results)
                    if (src !is ALL) {
                        results.close()
                    }
                }
            }
            if (src is RemoteSrc) {
                withBackgroundRepoContext(screenScope) {
                    val wallet = getWalletAddress() ?: return@withBackgroundRepoContext

                    val releases = getAllReleases().mapNotNull { r ->
                        val release = updateRelease(r)
                            ?: return@mapNotNull null
                        release
                    }

                    // concurrently get all owned purchase tokens
                    coroutineScope {
                        releases.forEach { release ->
                            launch(Dispatchers.Default) {
                                val releaseId = release.id
                                val purchaseIds = getOwnedPurchaseTokenIds(releaseId, wallet, REMOTE)
                                if (purchaseIds.isNotEmpty()) {
                                    val purchaseNfts =
                                        purchaseIds.mapNotNull { id -> getPurchaseNft(releaseId, id, REMOTE) }
                                    release to purchaseNfts
                                    sendLocal(results)
                                }
                            }
                        }
                    }
                    results.close()
                }
            }

        } catch (t: Throwable) {
            Logger.d("getOwnedPurchaseNfts ERROR MESSAGE: ${t.message}")
        }
        return@withRepoContext results.receiveAsFlow()
    }

private suspend fun Repository.sendLocal(results: Channel<List<ReleaseAndPurchases>>) {
    val wallet = getWalletAddress() ?: return
    val releaseToPurchasesList = localDb.getReleaseList().map { it }
        .mapNotNull { release ->
            val releaseId = release.id
            val purchaseIds = getOwnedPurchaseTokenIds(releaseId, wallet, LOCAL)
            if (purchaseIds.isEmpty()) {
                null
            } else {
                val purchaseNfts = purchaseIds.mapNotNull { id -> getPurchaseNft(releaseId, id, LOCAL) }
                release to purchaseNfts
            }
        }
    results.send(releaseToPurchasesList)
}