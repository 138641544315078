package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import io.eqoty.cosmwasm.std.types.ContractInfo
import io.eqoty.shared.datalayer.objects.ProductDealerContract
import mylocal.db.LocalDb

fun ProductDealerContractTable.toProductDealerContract() =
    ProductDealerContract(
        releaseId = releaseId,
        childSnip721 = ContractInfo(childSnip721Address, childSnip721CodeHash),
        prices = prices
    )

suspend fun LocalDb.getProductDealerContract(releaseId: UInt): ProductDealerContract? {
    return productDealerContractTableQueries.getById(releaseId).awaitAsOneOrNull()?.toProductDealerContract()
}

suspend fun LocalDb.getAllProductDealerContracts(): List<ProductDealerContract> {
    return productDealerContractTableQueries.getAll().awaitAsList().map { it.toProductDealerContract() }
}

suspend fun LocalDb.upsertProductDealerContract(contract: ProductDealerContract) {
    productDealerContractTableQueries.transaction {
        productDealerContractTableQueries.upsert(
            releaseId = contract.releaseId,
            childSnip721Address = contract.childSnip721.address,
            childSnip721CodeHash = contract.childSnip721.codeHash,
            prices = contract.prices
        )
    }
}

suspend fun LocalDb.upsertProductDealerContracts(list: List<ProductDealerContract>) {
    productDealerContractTableQueries.transaction {
        list.forEach { contract ->
            productDealerContractTableQueries.upsert(
                releaseId = contract.releaseId,
                childSnip721Address = contract.childSnip721.address,
                childSnip721CodeHash = contract.childSnip721.codeHash,
                prices = contract.prices
            )
        }
    }
}

suspend fun LocalDb.deleteProductDealerContractByReleaseId(releaseId: UInt) {
    return productDealerContractTableQueries.deleteByReleaseId(releaseId)
}

suspend fun LocalDb.deleteAllProductDealerContracts() {
    return productDealerContractTableQueries.deleteTable()
}
