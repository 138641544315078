package io.eqoty.composables.screens.leftnavigationrail


import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.ButtonListItem
import app.softwork.bootstrapcompose.ListGroup
import app.softwork.bootstrapcompose.ListItem
import app.softwork.bootstrapcompose.icons.CaretDownFill
import app.softwork.bootstrapcompose.icons.CaretRightFill
import app.softwork.bootstrapcompose.icons.Folder
import app.softwork.bootstrapcompose.icons.Person
import io.eqoty.composables.resources.ImageResources
import io.eqoty.shared.viewmodel.screens.leftnavigationrail.*
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLUListElement
import org.w3c.dom.svg.SVGElement

@Composable
fun LeftNavigationRail(
    state: LeftNavigationRailState,
    selectTab: (NavigationTab) -> Unit,
    toggleExpanded: (NavigationTabFolder) -> Unit,
) {
    ListGroup(
        attrs = {
            style {
                fontSize(1.cssRem)
            }
            classes("row", "rounded-0", "h-100")
        }
    ) {
        ListItem(
            attrs = {
                style {
                    fontSize(1.4.cssRem)
                    height(50.px)
                }
                classes("d-flex", "justify-content-left", "px-3", "py-1")
            }
        ) {
            A(
                attrs = {
                    style {
                        display(DisplayStyle.Flex)
                        height(40.px)
                        width(40.px)
                        padding(5.px)
                    }
                }
            ) {
                Img(src = ImageResources.eqotyIconWhite)
            }
        }

        ListItem(attrs = { style { height(20.px) } }) {}
        ListItem(
            attrs = {
                classes("list-group-item-action", "px-3")
                style {
                    fontSize(0.6.cssRem)
                }
            }, disabled = true
        ) { Text("EXPLORE") }
        NavigationRailItem(
            icon = { },
            label = { Text("Home") },
            selected = state.selectedTab is HomeTab,
            onClick = {
                selectTab(state.homeTab)
            }
        )
        NavigationRailItem(
            icon = { },
            label = { Text("Store") },
            selected = state.selectedTab is StoreTab,
            onClick = {
                selectTab(state.storeTab)
            }
        )

        ListItem(attrs = { style { height(20.px) } }) {}
        ListItem(
            attrs = {
                classes("list-group-item-action", "px-3")
                style {
                    fontSize(0.6.cssRem)
                }
            }, disabled = true
        ) { Text("LIBRARY") }
        NavigationRailItem(
            icon = { },
            label = { Text("Songs") },
            selected = state.selectedTab is SongsTab,
            onClick = {
                selectTab(state.songsTab)
            }
        )
        NavigationRailItem(
            icon = { },
            label = { Text("Royalties") },
            selected = state.selectedTab is RoyaltiesTab,
            onClick = {
                selectTab(state.royaltiesTab)
            }
        )
        if (state.myArtistsTab.visible) {
            NavigationRailFolder(
                label = { Text("My Artists") },
                selected = state.selectedTab is MyArtistsTab,
                expanded = state.myArtistsTab.expanded,
                onClick = {
                    selectTab(state.myArtistsTab)
                },
                onClickToggleExpand = {
                    toggleExpanded(state.myArtistsTab)
                }
            )
        }
    }

    ListGroup(
        attrs = {
            style {
                fontSize(1.cssRem)
            }
            classes("row", "rounded-0", "py-3")
        }
    ) {
        if (state.releaserProfileTab.visible) {
            NavigationRailItem(
                icon = { Person(attrs = { classes("me-2") }) },
                label = { Text("Releaser Profile") },
                selected = state.selectedTab is ReleaserProfileTab,
                onClick = {
                    selectTab(state.releaserProfileTab)
                }
            )
        }
    }
}

@Composable
private fun ElementScope<HTMLUListElement>.NavigationRailItem(
    icon: @Composable ElementScope<HTMLButtonElement>.() -> Unit,
    label: @Composable ElementScope<HTMLButtonElement>.() -> Unit,
    selected: Boolean,
    onClick: () -> Unit
) {
    ButtonListItem(attrs = {
        classes("list-group-item-action", "px-3", "py-1", "d-flex", "align-items-center")
        onClick { _ ->
            onClick()
        }
        if (selected) {
            this.disabled()
            classes("text-primary")
        }
    }) {
        icon()
        label()
    }
}

@Composable
private fun ElementScope<HTMLUListElement>.NavigationRailFolder(
    label: @Composable ElementScope<HTMLButtonElement>.() -> Unit,
    selected: Boolean,
    expanded: Boolean,
    onClick: () -> Unit,
    onClickToggleExpand: () -> Unit,
) {
    ButtonListItem(attrs = {
        classes("list-group-item-action", "px-3", "py-1")
        onClick { _ ->
            onClick()
        }
        if (selected) {
//            this.disabled()
            classes("text-primary")
        }
    }) {
        Folder(attrs = { style { paddingRight(8.px); height(28.px); width(28.px) } })
        Div(attrs = {
            classes("d-inline-block", "align-middle")
        }) {
            label()
        }
        val caratAttrs: AttrBuilderContext<SVGElement> = {
            style { paddingLeft(8.px); height(20.px); width(20.px) }
            onClick { event ->
                event.stopPropagation()
                onClickToggleExpand()
            }
        }
        if (expanded) CaretDownFill(attrs = caratAttrs) else CaretRightFill(attrs = caratAttrs)
    }
}
