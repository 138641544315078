package io.eqoty.shared.datalayer.functions

import io.eqoty.cosmwasm.std.types.Coin
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.sources.localdb.nfts.upsertProductDealerContract

suspend fun Repository.getPurchasePrice(releaseId: UInt): List<Coin> = withRepoContext {
    val release = getRelease(releaseId)!!
    val productDealerContract = getProductDealerContract(releaseId)
    val prices = productDealerContract.prices ?: run {
        val prices = getPurchasePrice(release.productDealer)
        localDb.upsertProductDealerContract(productDealerContract.copy(prices = prices))
        getProductDealerContract(releaseId).prices!!
    }
    return@withRepoContext prices
}