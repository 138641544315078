package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.ColumnAdapter
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.UInt
import kotlin.ULong

public data class ReleaseTable(
  public val id: UInt,
  public val organizationId: ULong,
  public val listed: Boolean,
  public val productDealerAddress: String,
  public val productDealerCodeHash: String,
  public val royaltyDealerAddress: String,
  public val royaltyDealerCodeHash: String,
  public val nftMetadataId: String,
  public val privateAuthenticationKey: String?,
) {
  public class Adapter(
    public val idAdapter: ColumnAdapter<UInt, Long>,
    public val organizationIdAdapter: ColumnAdapter<ULong, Long>,
  )
}
