package io.eqoty.composables.views

import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.Column
import app.softwork.bootstrapcompose.Row
import dev.icerock.moko.resources.compose.stringResource
import io.eqoty.shared.MR
import io.eqoty.shared.datalayer.sources.filesystem.CommonFile
import org.jetbrains.compose.web.attributes.ButtonType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text


@Composable
fun FileInputValidation(
    applyStyles: List<StyleScope.() -> Unit>,
    selectedFile: CommonFile?,
    topLabel: String,
    selectedContentText: String?,
    placeholderText: String,
    onChooseFileClick: () -> Unit,
    error: String? = null,
) {
    val hasError = error != null
    Column(attrs = {
        style {
            overflow("hidden")
            applyStyles.forEach { it.invoke(this) }
        }
    }) {
        Div { Text(topLabel) }
        Row(attrs = { classes("g-0", "d-flex", "align-items-center") }) {
            Column(auto = true, attrs = { classes("pe-1",) }) {
                app.softwork.bootstrapcompose.Button(
                    title = stringResource(MR.strings.browse),
                    outlined = true,
                    type = ButtonType.Button,
                    attrs = {
                        classes("form-control")
                        if (hasError) {
                            classes("is-invalid")
                        }
                    }) {
                    onChooseFileClick()
                }
            }

            Column(attrs = { classes("overflow-hidden") }) {
                Row(attrs = { classes("g-0") }) {
                    Div(attrs = { style { overflow("scroll"); whiteSpace("nowrap") } }) {
                        Text(selectedFile?.name ?: placeholderText)
                    }
                }
                selectedContentText?.apply {
                    Row { Div { Text(selectedContentText) } }
                }
            }
        }
        Div(attrs = {
            classes("text-danger", "small")
            style {
                height(1.cssRem)
            }
            attr("role", "alert")
        }) {
            if (hasError) {
                Text(error!!)
            }
        }

    }
}

