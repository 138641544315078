package io.eqoty.shared.viewmodel.screens.myroyalties

import io.eqoty.shared.datalayer.sources.ALL
import io.eqoty.shared.viewmodel.screens.MyRoyaltiesParams
import io.eqoty.shared.viewmodel.StateManager
import io.eqoty.shared.viewmodel.screens.CallOnInitValues
import io.eqoty.shared.viewmodel.screens.ScreenInitSettings

// INITIALIZATION settings for this screen
// this is what should be implemented:
// - a data class implementing the ScreenParams interface, which defines the parameters to the passed to the screen
// - Navigation extension function taking the ScreenParams class as an argument, return the ScreenInitSettings for this screen
// to understand the initialization behaviour, read the comments in the ScreenInitSettings.kt file


fun StateManager.initMyRoyalties(params: MyRoyaltiesParams) = ScreenInitSettings(
    title = "My Releases: " + params.listType.name,
    initState = { MyRoyaltiesState(listType = params.listType) },
    callOnInit = {
        events.refreshMyRoyaltiesScreen(ALL)
        events.refreshMyRoyaltiesScreenOnEthWalletChanges()
    },
    callOnInitAtEachNavigation = CallOnInitValues.CALL_AFTER_SHOWING_SCREEN
)