import jslib.ffmpeg.CreateFFmpegOptions
import jslib.ffmpeg.LogParams
import jslib.ffmpeg.ProgressParams

typealias FSMethodArgs = Any

typealias FSMethodReturn = Any

typealias LogCallback = (logParams: LogParams) -> Any

typealias ProgressCallback = (progressParams: ProgressParams) -> Any

class CreateFFmpegOptionsInstance(
    corePath: String? = null,
    log: Boolean? = null,
    logger: LogCallback? = null,
    progress: ProgressCallback? = null
) : CreateFFmpegOptions {
    init {
        if (corePath != null) {
            this.corePath = corePath
        }
        if (log != null) {
            this.log = log
        }
        if (logger != null) {
            this.logger = logger
        }
        if (progress != null) {
            this.progress = progress
        }
    }
}