package io.eqoty.shared.datalayer.functions

import app.cash.sqldelight.async.coroutines.awaitAsList
import co.touchlab.kermit.Logger
import io.eqoty.shared.datalayer.Repository
import io.eqoty.shared.datalayer.objects.Release
import io.eqoty.shared.datalayer.sources.*
import io.eqoty.shared.datalayer.sources.localdb.nfts.*
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.receiveAsFlow


suspend fun Repository.getReleaseList(
    screenScope: CoroutineScope,
    src: DataSrc = ALL
): Flow<List<Release>> = withRepoContext {
    val results = Channel<List<Release>>()
    try {
        if (src is LocalSrc) {
            withBackgroundRepoContext(screenScope) {
                results.send(localDb.getReleaseList())
                if (src !is ALL) {
                    results.close()
                }
            }
        }
        if (src is RemoteSrc) {
            withBackgroundRepoContext(screenScope) {
                if (!getWalletConnected()) return@withBackgroundRepoContext
                val releases = getAllReleases().mapNotNull { r ->
                    val release = updateRelease(r)
                        ?: return@mapNotNull null
                    release
                }
                results.send(localDb.getReleaseList())

                val releasesToDelete = localDb.getReleaseWhereIdNot(releases.map { it.id }).awaitAsList()

                releasesToDelete.forEach { releaseId ->
                    /***
                     * todo: delete NftMetadata and RemoteAudioResourceTable rows for releaseId?
                     */
                    localDb.deletePurchaseNftsByReleaseId(releaseId)
                    localDb.deleteProductDealerContractByReleaseId(releaseId)
                    localDb.deleteRoyaltyNftsByReleaseId(releaseId)
                    localDb.deleteAudioTracksForReleaseId(releaseId)
                    localDb.deleteRelease(releaseId)
                }
                results.send(localDb.getReleaseList())
                results.close()
            }
        }
    } catch (t: Throwable) {
        Logger.d("getReleaseList ERROR MESSAGE: ${t.message}")
    }
    results.receiveAsFlow()
}