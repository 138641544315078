package io.eqoty.shared.datalayer.sources.localdb.nfts

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import com.ionspin.kotlin.bignum.integer.BigInteger
import io.eqoty.shared.datalayer.objects.AttachmentAccess
import io.eqoty.shared.datalayer.objects.PurchaseNft
import io.eqoty.shared.datalayer.utils.bi10
import mylocal.db.LocalDb

suspend fun LocalDb.getPurchaseNftById(id: String): PurchaseNft? {
    val p = purchaseNftTableQueries.getById(id).awaitAsOneOrNull() ?: return null
    return buildPurchaseNft(p)
}

suspend fun LocalDb.getPurchaseNftsByReleaseId(id: UInt): List<PurchaseNft> {
    val purchases = purchaseNftTableQueries.getByReleaseId(id).awaitAsList()
    return purchases.map { r ->
        buildPurchaseNft(r)
    }.sortedBy { it.id }
}

suspend fun LocalDb.buildPurchaseNft(purchaseNftTable: PurchaseNftTable): PurchaseNft {
    return PurchaseNft(
        id = purchaseNftTable.id.substringAfterLast(':').bi10,
        purchaseCtrkReleaseId = purchaseNftTable.purchaseCtrkReleaseId,
        nftMetadata = getNftMetadataForPurchaseNft(purchaseNftTable.id)
    )
}

suspend fun LocalDb.upsertPurchaseNft(purchaseNft: PurchaseNft) {
    val dbPurchaseNftId = getPurchaseNftIdForRelease(purchaseNft.purchaseCtrkReleaseId, purchaseNft.id)
    purchaseNft.nftMetadata?.let {
        upsertNftMetadata(it)
        upsertAudioTrackList(
            dbPurchaseNftId,
            it.publicAudioResources,
            AttachmentAccess.PUBLIC,
            0
        )
        upsertAudioTrackList(
            dbPurchaseNftId,
            it.privateAudioResources,
            AttachmentAccess.PRIVATE,
            0
        )
    }
    purchaseNftTableQueries.upsert(
        id = dbPurchaseNftId,
        purchaseCtrkReleaseId = purchaseNft.purchaseCtrkReleaseId,
        metadataId = purchaseNft.nftMetadata?.id
    )
}

suspend fun LocalDb.deletePurchaseNftsByReleaseId(releaseId: UInt) {
    return purchaseNftTableQueries.deleteByReleaseId(releaseId)
}

suspend fun LocalDb.deleteAllPurchaseNfts() {
    return purchaseNftTableQueries.deleteTable()
}

fun getPurchaseNftIdForRelease(releaseId: UInt, purchaseId: BigInteger): String {
    return "rel:$releaseId:pur:$purchaseId"
}
