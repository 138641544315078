package io.eqoty.composables.navigation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import io.eqoty.composables.navigation.bars.BottomNavigationBar
import io.eqoty.composables.screens.connectwallet.ConnectWalletScreen
import io.eqoty.composables.screens.home.HomeScreen
import io.eqoty.composables.screens.leftnavigationrail.LeftNavigationRail
import io.eqoty.composables.screens.mediaplayer.MediaPlayerWidget
import io.eqoty.composables.screens.mintsong.MintSongScreen
import io.eqoty.composables.screens.myorganizations.MyOrganizationsScreen
import io.eqoty.composables.screens.myroyalties.MyRoyaltiesScreen
import io.eqoty.composables.screens.nftpurchaselibrary.NftPurchaseLibraryScreen
import io.eqoty.composables.screens.organizationprofile.OrganizationProfileScreen
import io.eqoty.composables.screens.organizationregistrationinfo.OrganizationRegistrationInfoScreen
import io.eqoty.composables.screens.releaserprofile.ReleaserProfileScreen
import io.eqoty.composables.screens.royaltynftdetail.RoyaltyNftDetailScreen
import io.eqoty.composables.screens.store.StoreScreen
import io.eqoty.composables.screens.topbar.TopBar
import io.eqoty.composables.screens.walletinfo.WalletInfoScreen
import io.eqoty.shared.datalayer.objects.AttachmentAccess
import io.eqoty.shared.viewmodel.Navigation
import io.eqoty.shared.viewmodel.ScreenIdentifier
import io.eqoty.shared.viewmodel.screens.*
import io.eqoty.shared.viewmodel.screens.Screen.*
import io.eqoty.shared.viewmodel.screens.bottomnavigationbar.BottomNavigationBarState
import io.eqoty.shared.viewmodel.screens.connectwallet.ConnectWalletScreenState
import io.eqoty.shared.viewmodel.screens.connectwallet.disconnectWallet
import io.eqoty.shared.viewmodel.screens.home.HomeState
import io.eqoty.shared.viewmodel.screens.leftnavigationrail.*
import io.eqoty.shared.viewmodel.screens.mediaplayer.*
import io.eqoty.shared.viewmodel.screens.mintsong.*
import io.eqoty.shared.viewmodel.screens.myorganizations.MyOrganizationsScreenState
import io.eqoty.shared.viewmodel.screens.myroyalties.MyRoyaltiesState
import io.eqoty.shared.viewmodel.screens.myroyalties.claimRoyaltyBalance
import io.eqoty.shared.viewmodel.screens.myroyalties.mergeRoyaltyTokens
import io.eqoty.shared.viewmodel.screens.nftpurchaselibrary.NftPurchaseLibraryState
import io.eqoty.shared.viewmodel.screens.nftpurchaselibrary.startDownload
import io.eqoty.shared.viewmodel.screens.organizationprofile.*
import io.eqoty.shared.viewmodel.screens.organizationregistrationinfo.OrganizationRegistrationInfoScreenState
import io.eqoty.shared.viewmodel.screens.organizationregistrationinfo.registerOrganization
import io.eqoty.shared.viewmodel.screens.organizationregistrationinfo.setOrganizationInfo
import io.eqoty.shared.viewmodel.screens.organizationregistrationinfo.validateOrganizationRegistrationForm
import io.eqoty.shared.viewmodel.screens.releaserprofile.ReleaserProfileScreenState
import io.eqoty.shared.viewmodel.screens.releaserprofile.registerReleaser
import io.eqoty.shared.viewmodel.screens.releaserprofile.validateReleaserRegistrationForm
import io.eqoty.shared.viewmodel.screens.royaltynftdetail.RoyaltyNftDetailState
import io.eqoty.shared.viewmodel.screens.royaltynftdetail.splitRoyalty
import io.eqoty.shared.viewmodel.screens.scaffold.toggleWalletScreenExpanded
import io.eqoty.shared.viewmodel.screens.store.StoreState
import io.eqoty.shared.viewmodel.screens.store.makePurchase
import io.eqoty.shared.viewmodel.screens.topbar.TopBarState
import io.eqoty.shared.viewmodel.screens.walletInfo.WalletInfoScreenState

@Composable
fun Navigation.ScreenPicker(
    screenStack: ScreenStack,
    screenIdentifier: ScreenIdentifier,
) {
    val state by stateProvider.getScreenStateFlow(screenStack, screenIdentifier).collectAsState()

    when (screenIdentifier.screen) {
        TopBar -> {
            TopBar(state = state as TopBarState, onClickWalletAction = {
                stateManager.events.toggleWalletScreenExpanded()
            })
        }

        BottomNavigationBar -> {
            BottomNavigationBar(
                state as BottomNavigationBarState,
                selectTab = {
                    stateManager.events.selectNavigationTab(it)
                },
            )
        }

        LeftNavigationRail -> {
            LeftNavigationRail(
                state as LeftNavigationRailState,
                selectTab = {
                    stateManager.events.selectNavigationTab(it)
                },
                toggleExpanded = {
                    when (it) {
                        is MyArtistsTab -> {
                            stateManager.events.toggleExpandTabFolder(it)
                        }

                        else -> {}
                    }
                },
            )
        }

        Home -> {
            HomeScreen(state = state as HomeState, onClickOpenStore = {
                selectLevel1Navigation(ScreenStack.Main, Level1Navigation.StoreProvider.screenIdentifier)
            })
        }

        MyRoyalties -> {
            MyRoyaltiesScreen(
                state = state as MyRoyaltiesState,
                onListItemClick = { releaseId, id, name ->
                    navigateToScreen(
                        screenStack, ScreenIdentifier.get(
                            RoyaltyNftDetail, RoyaltyNftDetailParams(releaseId = releaseId, id = id, name = name)
                        )
                    )
                },
                onMergeRoyaltyTokensClick = { releaseId, royaltyIds, sharesPerToken ->
                    stateManager.events.mergeRoyaltyTokens(releaseId, royaltyIds, sharesPerToken)
                },
                onClaimBalanceClick = { releaseId, royaltyId ->
                    stateManager.events.claimRoyaltyBalance(releaseId, royaltyId)
                },
                onClickOrganization = { trackRowData ->
                    navigateToScreen(
                        screenStack, ScreenIdentifier.get(
                            OrganizationProfile,
                            OrganizationProfileParams(trackRowData.release.organizationId, trackRowData.primaryArtist)
                        )
                    )
                },
            )
        }

        ConnectWallet -> {
            ConnectWalletScreen(state = state as ConnectWalletScreenState, onClickConnect = { walletProvider ->
                selectLevel1Navigation(
                    ScreenStack.Wallet, ScreenIdentifier.get(ConnectWallet, ConnectWalletParams(walletProvider))
                )
            })
        }

        WalletInfo -> {
            WalletInfoScreen(state = state as WalletInfoScreenState,
                onDisconnectWallet = stateManager.events::disconnectWallet,
                onReleaserModeCheckedChange = { checked ->
                    stateManager.events.setReleaserMode(checked)
                })
        }

        MintSong -> {
            MintSongScreen(state = state as MintSongScreenState, onChoosePrivateAudioFileClick = {
                stateManager.events.chooseAudioFile(AttachmentAccess.PRIVATE)
            }, onChoosePublicAudioFileClick = {
                stateManager.events.chooseAudioFile(AttachmentAccess.PUBLIC)
            }, onChooseAlbumArtFileClick = {
                stateManager.events.chooseAlbumArtFile()
            }, disableAutofillFromFile = {
                stateManager.events.disableAutofillFromFile()
            }, onMintClick = { formInput ->
                stateManager.events.validateMintTrackForm(formInput, onSuccess = { validatedMintTrackFormInput ->
                    stateManager.events.mint(validatedMintTrackFormInput)
                })
            })
        }

        Store -> {
            StoreScreen(
                state = state as StoreState,
                onClickPreviewPlay = { releaseId ->
                    stateManager.events.loadTrackInMediaPlayer(releaseId, null)
                },
                onPurchase = { releaseId ->
                    stateManager.events.makePurchase(releaseId)
                },
                onClickOrganization = { trackRowData ->
                    navigateToScreen(
                        screenStack, ScreenIdentifier.get(
                            OrganizationProfile,
                            OrganizationProfileParams(trackRowData.release.organizationId, trackRowData.primaryArtist)
                        )
                    )
                },
            )
        }

        RoyaltyNftDetail -> {
            RoyaltyNftDetailScreen(state = state as RoyaltyNftDetailState,
                onSplitRoyalty = { releaseId, tokenId, splitCount ->
                    stateManager.events.splitRoyalty(releaseId, tokenId, splitCount)
                })
        }

        MediaPlayerWidget -> {
            MediaPlayerWidget(
                state = state as MediaPlayerWidgetState,
                onPlayClick = { track -> track?.let { stateManager.events.playSong(it) } },
                onCloseClick = stateManager.events::stopSongAndCloseMediaPlayer,
                onPauseClick = stateManager.events::pauseSong,
                onClickOrganization = { trackRowData ->
                    navigateToScreen(
                        ScreenStack.Main, ScreenIdentifier.get(
                            OrganizationProfile,
                            OrganizationProfileParams(trackRowData.release.organizationId, trackRowData.primaryArtist)
                        )
                    )
                },
            )
        }

        NftPurchaseLibrary -> {
            NftPurchaseLibraryScreen(
                state = state as NftPurchaseLibraryState,
                onClick = { releaseId, purchaseId ->
                    stateManager.events.loadTrackInMediaPlayer(releaseId, purchaseId)
                },
                onClickOrganization = { trackRowData ->
                    navigateToScreen(
                        screenStack, ScreenIdentifier.get(
                            OrganizationProfile,
                            OrganizationProfileParams(trackRowData.release.organizationId, trackRowData.primaryArtist)
                        )
                    )
                },
                onDownloadClick = { resourceDownloadInfo ->
                    stateManager.events.startDownload(resourceDownloadInfo)
                },
            )
        }

        ReleaserProfile -> {
            ReleaserProfileScreen(state as ReleaserProfileScreenState, onSubmitClick = { formInput ->
                stateManager.events.validateReleaserRegistrationForm(formInput, onSuccess = { releaserInfo ->
                    stateManager.events.registerReleaser(releaserInfo)
                })
            })
        }

        MyOrganizations -> {
            MyOrganizationsScreen(state as MyOrganizationsScreenState, onEditItemClick = { orgId ->
                navigateToScreen(
                    screenStack,
                    ScreenIdentifier.get(OrganizationRegistrationInfo, OrganizationRegistrationInfoParams(orgId))
                )
            }, onListSongClick = { organizationId, organizationName ->
                navigateToScreen(
                    screenStack, ScreenIdentifier.get(MintSong, MintSongScreenParams(organizationId, organizationName))
                )
            }, onClickNewOrganization = {
                navigateToScreen(
                    screenStack,
                    ScreenIdentifier.get(OrganizationRegistrationInfo, OrganizationRegistrationInfoParams(null))
                )
            })
        }

        OrganizationRegistrationInfo -> {
            OrganizationRegistrationInfoScreen(
                state as OrganizationRegistrationInfoScreenState,
                onSubmitClick = { orgId, formInput ->
                    stateManager.events.validateOrganizationRegistrationForm(formInput, onSuccess = { info ->
                        when (orgId) {
                            null -> stateManager.events.registerOrganization(info)

                            else -> stateManager.events.setOrganizationInfo(orgId, info)
                        }
                    })

                })
        }

        OrganizationProfile -> {
            val orgProfileScreenState = state as OrganizationProfileScreenState
            OrganizationProfileScreen(
                orgProfileScreenState,
                onPurchase = { releaseId ->
                    stateManager.events.makePurchase(releaseId)
                },
                onClickPreviewPlay = { releaseId ->
                    stateManager.events.loadTrackInMediaPlayer(releaseId, null)
                },
                onClickOrganization = { trackRowData ->
                    navigateToScreen(
                        screenStack, ScreenIdentifier.get(
                            OrganizationProfile,
                            OrganizationProfileParams(trackRowData.release.organizationId, trackRowData.primaryArtist)
                        )
                    )
                },
                onChooseBannerImageFileClick = {
                    stateManager.events.chooseBannerImageFile()
                },
                onShowEditProfilePopup = { show ->
                    stateManager.events.showEditProfilePopup(show)
                },
                onValidateSaveEditProfile = { formInput ->
                    stateManager.events.validateEditOrganizationProfileForm(formInput, onSuccess = { info ->
                        stateManager.events.setOrganizationProfileInfo(
                            orgProfileScreenState.organizationId,
                            orgProfileScreenState.organizationName,
                            info
                        )
                    })
                },
            )
        }

        else -> TODO()

    }

}
