package io.eqoty.composables.resources


object ImageResources {
    val metamaskFox = "./images/metamask-fox.svg"
    val eqotyIconWhite = "./images/eqoty-icon-white.svg"
    val eqotyLogoWhite = "./images/eqoty-logo-white.svg"
    val keplr = "./images/keplr-logo.svg"
    val walletConnect = "./images/walletconnect-logo.svg"
    val homeBannerLg = "./images/home-banner-lg.svg"
    val homeBannerSm = "./images/home-banner-sm.svg"
}