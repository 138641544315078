package io.eqoty.shared.viewmodel.screens.topbar

import io.eqoty.shared.datalayer.functions.getWalletAddress
import io.eqoty.shared.datalayer.functions.getWalletBalance
import io.eqoty.shared.datalayer.functions.getWalletDisplayAddress
import io.eqoty.shared.datalayer.functions.getWeb3Network
import io.eqoty.shared.datalayer.sources.web3.WalletProvider
import io.eqoty.shared.datalayer.sources.web3.Web3Network
import io.eqoty.shared.viewmodel.Events
import io.eqoty.shared.viewmodel.screens.ScreenStack
import io.eqoty.shared.viewmodel.utils.baseUnitToStdUnit
import io.eqoty.shared.viewmodel.utils.bytesToMegabytes
import kotlinx.coroutines.delay


/********** NO EVENT FUNCTION IS DEFINED ON THIS SCREEN **********/

fun Events.setTopBarTitleAndNavState(title: String, inLevel1MainStackScreen: Boolean) {
    stateManager.updateScreen(ScreenStack.Header, TopBarState::class) {
        it.copy(
            title = title,
            inLevel1MainStackScreen = inLevel1MainStackScreen
        )
    }
}

fun Events.updateTotalDownloadProgress() = inScreenScopeLaunch(ScreenStack.Header) {
    val dm = dataRepository.downloadManager
    stateManager.updateScreen(ScreenStack.Header, TopBarState::class) {
        it.copy(
            downloadIndicatorData = dm.combinedActiveItemsProgress?.let { progress ->
                val done = dm.activeDownloadItemsAllCompleted
                val activeInProgress = dm.activeDownloadItemsInProgress.size
                val activeTotal = dm.activeItemsCount
                val message = when (activeTotal) {
                    1 -> {
                        val singleDownloadEntry = dm.activeItems.entries.first()
                        val singleDownloadInfo = singleDownloadEntry.key
                        singleDownloadInfo.fileName
                    }

                    else -> {
                        if (done) {
                            "($activeTotal/$activeTotal) files"
                        } else {
                            "($activeInProgress/$activeTotal) files"
                        }
                    }
                }
                val megaByteProgress = when (activeTotal) {
                    1 -> {
                        val singleDownloadEntry = dm.activeItems.entries.first()
                        val singleDownloadProgress = singleDownloadEntry.value
                        val bytesReceived = singleDownloadProgress.bytesReceived.bytesToMegabytes()
                        val byteSize = singleDownloadProgress.byteSize.bytesToMegabytes()
                        if (done) {
                            "${byteSize}MB"
                        } else {
                            "$bytesReceived/${byteSize}MB"
                        }
                    }

                    else -> {
                        val bytesReceived = dm.activeDownloadsTotalBytesReceived.bytesToMegabytes()
                        val byteSize = dm.activeDownloadsTotalBytes.bytesToMegabytes()
                        if (done) {
                            "${byteSize}MB"
                        } else {
                            "$bytesReceived/${byteSize}MB"
                        }
                    }
                }
                DownloadIndicatorData(progress, megaByteProgress, message)
            },
        )
    }
}

fun Events.onDownloadComplete() = inAppScopeLaunchInBackground(ScreenStack.Header) {
    if (dataRepository.downloadManager.activeDownloadItemsAllCompleted) {
        dataRepository.downloadManager.moveActiveItemsToCompletedHistory()
    }
    delay(5000)
    updateTotalDownloadProgress()?.join()
}


fun Events.updateWalletBtnInfo() = inScreenScopeLaunch(ScreenStack.Header) {

    val walletAddress: String?
    val network: Web3Network?
    val formattedAddress: String?
    val balance: String?
    if (dataRepository.selectedWalletProvider == WalletProvider.InternalRandom) {
        walletAddress = null
        network = null
        formattedAddress = null
        balance = null
    } else {
        walletAddress = dataRepository.getWalletAddress()
        network = dataRepository.getWeb3Network()
        formattedAddress = dataRepository.getWalletDisplayAddress()
        balance = walletAddress?.let { dataRepository.getWalletBalance(walletAddress).baseUnitToStdUnit(3).toString() }
    }
    stateManager.updateScreen(ScreenStack.Header, TopBarState::class) {
        it.copy(
            topBarAccountItemData = TopBarAccountItemData(
                network = network,
                address = formattedAddress,
                balance = balance,
            ),
        )
    }
}